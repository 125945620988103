import { CustomTooltip } from 'Components/Communication/components';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from 'recharts';


const COLORS = ['#47CDEA','#0ABC2F','#FF4F4F'];

const AiTechnicalScoreChart = ({data}) => {
    console.log(data,"arrData");
    var arrData = [];
    data &&
    Object.entries(data)?.forEach(([key, value]) => {
      return arrData.push({ name: key, value: value });
    });

  return (
    <div className='h-[332px]'>
      <div className="flex items-center justify-center">
        <PieChart width={240} height={240} className="my-6">
          <Pie
            data={arrData}
            cx={'50%'}
            cy={'50%'}
            innerRadius={56}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {arrData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </div>
      <div className="flex items-center justify-center gap-4">
        <div className="flex items-start gap-1">
          <div className="mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#0ABC2F" />
            </svg>
          </div>
          <div>
            <p className="text-[#535353] text-[10px]">Correct</p>
            <p className="text-[#535353] text-[12px] font-semibold">{data?.correct_answers_percentage}%</p>
          </div>
        </div>
        <div className="flex items-start gap-1">
          <div className="mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#47CDEA" />
            </svg>
          </div>
          <div>
            <p className="text-[#535353] text-[10px]">Skipped</p>
            <p className="text-[#535353] text-[12px] font-semibold">{data?.skipped_questions}%</p>
          </div>
        </div>
        <div className="flex items-start gap-1">
          <div className="mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#FF4F4F" />
            </svg>
          </div>
          <div>
            <p className="text-[#535353] text-[10px]">Incorrect</p>
            <p className="text-[#535353] text-[12px] font-semibold">{data?.incorrect_answers}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiTechnicalScoreChart;
