import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { BiArrowBack } from 'react-icons/bi';
import { isEmpty } from 'lodash';

import {
  useFinalStatus,
  useCompanyDetails,
  useGetFeedbackHeaderData,
} from 'ServiceHooks/interviewHooks';
import Assessment from './Assessment';
import 'Styles/AiInterviewContent.scss';
import AssessmentNew from './AssessmentNew';
import AnnotationsNew from './AnnotationsNew';
import FeedbackHeader from './FeedBackHeader';
import IdentityCheckNew from './IdentityCheckNew';
import EmailPDF from 'pages/Hr/Interviewer/EmailPDF';
import ReviewFeedbackForm from './ReviewFeedbackForm';
import { usePersistedStore } from 'Store/persistedStore';
import interviewService from 'Services/interviewService';
import { INTERVIEW_TAB_TYPES } from 'Utilities/constants';
import Button from 'Components/CommonComponents/Button/Button';
import { useGetAiAssessmentQuestionAnswers } from 'ServiceHooks/interviewHooks';

const PreviewPage = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mode = props?.mode;
  const isModal = mode === 'ACTIVITY_MODAL';
  const isActivity = location?.pathname?.includes('activity');

  const interviewId = isModal
    ? props?.interviewId
    : location?.state?.interviewId;

  const evaluationId = isModal
    ? props?.evaluationId
    : location?.state?.interviewer_evaluation_feedback;

  const interviewMethod = props?.interviewMethod;

  const [assesmentData, setAssesmentData] = useState();

  const { setCurrentInterviewerTab } = usePersistedStore((actions) => ({
    setCurrentInterviewerTab: actions.setCurrentInterviewerTab,
  }));

  useEffect(() => {
    if (interviewId) getAssessmentData(interviewId);
  }, []);

  const getAssessmentData = async (interviewId) => {
    await interviewService
      .getAssessment(interviewId)
      .then((data) => {
        setAssesmentData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data: finalStatus } = useFinalStatus({
    params: {
      id: evaluationId,
    },
    enabled: Boolean(evaluationId),
  });

  const { data: feedBackHeaderData } = useGetFeedbackHeaderData({
    params: {
      interviewId: interviewId,
    },
    enabled: !!interviewId,
  });

  const {
    data: aiAssessmentQuestionAnswers,
    status: aiAssessmentQuestionAnswersStatus,
    refetch: refetchAiAssessmentQuestionAnswers,
  } = useGetAiAssessmentQuestionAnswers({
    params: {
      interviewId: interviewId,
    },
    enabled: !!interviewId,
  });

  const closeTheWin = () => {
    navigate(location?.state?.backPage);
  };

  const { data: companyDeatils } = useCompanyDetails({
    enabled: true,
  });

  return (
    <>
      <div>
        {!isActivity && (
          <div className="h-10 flex flex-row justify-between items-center">
            <Button
              type="ghost"
              className="flex items-center mb-4 cursor-pointer font-bold px-0"
              onClick={() => {
                // setCurrentInterviewerTab(
                //   INTERVIEW_TAB_TYPES.REVIEW_COMPLETED,
                // );
                navigate(-1);
              }}
            >
              <BiArrowBack /> <span className="ml-2">Back</span>
            </Button>
            {!isEmpty(feedBackHeaderData) &&
              !isEmpty(aiAssessmentQuestionAnswers) &&
              !isEmpty(finalStatus) &&
              !isEmpty(companyDeatils) &&
              !isEmpty(assesmentData) && (
                <Button isSecondary>
                  <PDFDownloadLink
                    document={
                      <EmailPDF
                        isFormDownload={true}
                        feedbackHeader={feedBackHeaderData}
                        nInterviewId={location?.state?.interviewId}
                        QData={aiAssessmentQuestionAnswers?.results}
                        cStatus={finalStatus}
                        oCompanyDeatils={companyDeatils}
                        passMarks={
                          assesmentData.technical_score
                            .correct_answers_percentage
                        }
                      />
                    }
                    fileName={feedBackHeaderData?.candidate + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download PDF'
                    }
                  </PDFDownloadLink>
                </Button>
              )}
          </div>
        )}

        {/* <PDFViewer> */}
        <div className="aiReviewContent">
          <div class="headerWrapper mb-4">
            <FeedbackHeader data={feedBackHeaderData} />
          </div>
          <AssessmentNew
            nInterviewId={interviewId}
            interview_type={
              location?.state?.selectedData?.interview_method ===
              'AI_INTERVIEW_WITHOUT_VIDEO'
                ? 'Online Test'
                : location?.state?.selectedData?.interview_method
            }
          />
          {![
            interviewMethod,
            location?.state?.selectedData?.interview_method,
          ].includes('AI_INTERVIEW_WITHOUT_VIDEO') && (
            <IdentityCheckNew nInterviewId={interviewId} />
          )}
          <div className="annotations">
            <p className="font-semibold mb-4 mt-4">Annotations</p>
            <AnnotationsNew
              mode={'ACTIVITY_MODAL'}
              interviewId={interviewId}
              evaluationId={evaluationId}
              interviewMethod={interviewMethod}
            />
          </div>
          <div>
            <p className="font-semibold mb-4 mt-4">Feedback Form </p>
            <ReviewFeedbackForm
              isModal={isModal}
              nEvaluationFeedbackId={evaluationId}
              interview_status={feedBackHeaderData?.status}
            />
          </div>
        </div>
        {/* </PDFViewer> */}

        {!isModal && (
          <div className="mt-4 float-right">
            <Button type="primary" onClick={() => closeTheWin()}>
              Close
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default PreviewPage;
