import Button from 'Components/CommonComponents/Button/Button';
import { Image, Modal, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToggle } from 'react-use';
import CandidateProjectsImg from 'Assets/images/candidateProjects.png';
import ViewParsedText from 'Components/CommonComponents/ViewParsedText';
import ResumeFormEducationModal from './ResumeFormEducationModal';
import dayjs from 'dayjs';

const ResumeFormEducation = (props) => {
  const { qualifications, setTemplateData } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [isAddEducationModalOpen, toggleAddEducationModal] =
    useToggle(false);
  const [currentEditingProject, setCurrentEditingEducation] =
    useState();
    const [editIndex, setEditIndex] = useState(null);
  
  useEffect(()=> {
    if(!isAddEducationModalOpen){
      setCurrentEditingEducation({})
      setEditIndex(null)
    }
  },[isAddEducationModalOpen])


  const RemoveEducation = (index)=> {
    setTemplateData((prev) => {
      const qualifications = prev?.qualifications?.value || [];
      const updatedQualifications = qualifications.filter((_, i) => i !== index);
      return {
        ...prev,
        qualifications: {
          ...prev.qualifications,
          value: updatedQualifications
        }
      };
    });
  }

  return (
    <div>
      <div className="border rounded-md p-spacing3 w-full">
          <div className="flex flex-row justify-between items-center">
            <div className="text-lg font-medium">Educations</div>
            <Button isSecondary onClick={toggleAddEducationModal}>
              Add Education
            </Button>
          </div>
          <section className="projectsSection max-h-[30rem] overflow-x-hidden overflow-y-auto customScrollBar my-4">
            {!isEmpty(qualifications) ? (
              <div className="flex flex-wrap -mx-2">
                {qualifications?.map((qualificationItem, index) => {
                  return (
                    <div
                      className="w-full px-2 mb-spacing4 flex flex-row justify-between"
                      key={`project-${index}`}
                    >
                      <div className="flex flex-row items-baseline gap-4">
                        <div className="rounded-full bg-primaryMain h-2 w-2"></div>
                        <div>
                          <h1 className="font-medium text-lg">
                            {qualificationItem?.qualification}
                          </h1>
                          <p className="font-medium text-base">
                            {qualificationItem?.college}
                          </p>
                          <p className="text-[#848484] text-sm">
                          {qualificationItem?.course_start_date &&
                          dayjs(qualificationItem?.course_start_date).format(
                            'DD/MM/YYYY',
                          ) !== 'Invalid Date'
                            ? dayjs(qualificationItem?.course_start_date).format(
                                'DD/MM/YYYY',
                              )
                            : ''}
                            -{' '}
                            {qualificationItem?.course_completed_date &&
                          dayjs(qualificationItem?.course_completed_date).format(
                            'DD/MM/YYYY',
                          ) !== 'Invalid Date'
                            ? dayjs(qualificationItem?.course_completed_date).format(
                                'DD/MM/YYYY',
                              )
                            : qualificationItem?.course_start_date ? 'Present': ''}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row gap-4">
                      <FiEdit
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={() => {
                          setCurrentEditingEducation(qualificationItem);
                          toggleAddEducationModal();
                          setEditIndex(index)
                        }}
                      />
                      <FiTrash
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={()=> RemoveEducation(index)}
                      />
                      {contextHolder}
                    </div>
                    </div>
                  );
                })}
              </div>
            ) : (
                <div className="flex flex-col justify-center items-center">
                <Image
                  height={128}
                  preview={false}
                  alt="no-experience"
                  src={CandidateProjectsImg}
                />
                <div className="text=[#656565] text-lg font-light capitalize pb-28">
                  Not any Education Added yet!
                </div>
              </div>
            )}
          </section>
        </div>
      {/* -----------------------  Modal for Add Experience starts ----------------------- */}
      {isAddEducationModalOpen && (
        <Modal
          className="addExperienceModal"
          width={700}
          open={isAddEducationModalOpen}
          title={'Add Educations'}
          onCancel={toggleAddEducationModal}
          footer={null}
          destroyOnClose
        >
          <ResumeFormEducationModal
            isEditForm={!isEmpty(currentEditingProject)}
            close={toggleAddEducationModal}
            setTemplateData={setTemplateData}
            qualifications={qualifications}
            details={currentEditingProject}
            editIndex={editIndex}
          />
        </Modal>
      )}
    </div>
  );
};

export default ResumeFormEducation;
