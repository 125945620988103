import { useEffect } from "react";

const FeedbackHeader = (props) => {


  useEffect(() => {
    console.log(props.data);
  });

  

  return (
    <div className="aiInterview_header">
      <div className="aiInterview_header_div">
        <div>
          <h3>{props.data && props.data.job_title}</h3>
          <ul>
            <li>
              <span className="ai_left_header">Candidate Name : </span>
              <span className="ai_right_header">
                {props.data && props.data.candidate}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <button className={`float-right aiInterview_header_button ${props?.data?.status === "FAILED" && 'interview_failed'}`}>
            {props.data && props.data.status}
          </button>
        </div>
      </div>
      <div className="pt-3">
        <h3>
          <span className="ai_level">
            {props.data && props.data.interview_level}
          </span>
          <span className="ai_interview_details">
            Request Date: {props.data && props.data.created_date_formatted} |
            Attended Date {props.data && props.data.interview_attended_date}
          </span>
        </h3>
      </div>
    </div>
  );
};

export default FeedbackHeader;
