import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse, Popover, Slider, Tooltip, message } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import linkedIn from '../../Assets/images/linkedIn.png';
import indeedIcon from '../../Assets/images/indeed.png';
import naukri from '../../Assets/images/naukri.png';
import CheckboxGroup from 'Components/CommonComponents/ReactHooKForm/CheckboxGroup/CheckboxGroup';
import InterviewSettings from './InterviewSettings';
import {
  useCreateSocialMediaSettings,
  useGetSocialMediaSettings,
} from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;

const SocialMediaSettings = (props) => {
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      LINKEDIN: false,
      INDEED: false,
      NAUKRI: false,
      social_media: false,
    },
  });
  const mediaType = watch('social_media');

  const { data: mediaSettingsData, refetch: isMediaRefectch } =
    useGetSocialMediaSettings({
      paramsData: {
        companyId: props.companyId,
      },
    });
  useEffect(() => {
    if (isEmpty(mediaSettingsData)) return;
    reset(mediaSettingsData);
  }, [mediaSettingsData]);

  const { mutateAsync: createMediaSettings } =
    useCreateSocialMediaSettings({
      onSuccess: async (data, variables, context) => {
        toast.success('Successfully Registered..!!');
        await isMediaRefectch();
      },
      onError: async (error) => {
        console.error('Creation Error:', error);
        const message = error?.response?.data?.error_info;
        await Object.entries(message)?.forEach(([key, message]) => {
          toast.error(message || DEFAULT_ERROR_MESSAGE);
          //   messageApi.open({
          //     type: 'error',
          //     content: message ?? DEFAULT_ERROR_MESSAGE,
          //   });
        });
      },
    });

  const onSubmit = async (data) => {
    console.log('data', data);
    createMediaSettings({
      data,
      comapnyId: props.companyId,
    });
  };
  return (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            className="bg-white rounded-lg"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="mt-4">
                  {!isActive ? (
                    <img src={iconAccordion} alt="accordion" />
                  ) : (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  )}
                </div>
              );
            }}
          >
            {() => {
              return (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <p className="mt-8 px-6  text-medium font-medium">
                        Social Media Job Posting
                      </p>
                      <div className="[&>*]:m-0 mt-8 px-6 ">
                        <Switch
                          name="social_media"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                  }
                  key={mediaType ? '1' : '0'}
                  //style={panelStyle}
                  className="jobDetailContent bg-transparent"
                  // extra={genExtra()}
                >
                  <div className="px-6 mt-12 mb-10">
                    <div className="flex justify-between">
                      <img src={linkedIn} alt="limkedin" />
                      <div className="[&>*]:m-0 mt-2">
                        <Switch
                          name="LINKEDIN"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                    <div className="mt-8 mb-6  h-[1px] w-full bg-[#E1E1E1]"></div>
                    <div className="flex justify-between">
                      <img src={indeedIcon} alt="limkedin" />
                      <div className="[&>*]:m-0 mt-2">
                        <Switch
                          name="INDEED"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                    <div className="mt-8 mb-6 h-[1px] w-full bg-[#E1E1E1]"></div>
                    <div className="flex justify-between">
                      <img src={naukri} alt="limkedin" />
                      <div className="[&>*]:m-0 mt-2">
                        <Switch
                          name="NAUKRI"
                          id="3"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-14 ">
                    <Button
                      className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
        </form>
      </Card>
    </div>
  );
};
export default SocialMediaSettings;
