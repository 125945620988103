import { Button } from "antd"
import paymnetIcon from '../../Assets/icons/iconPayment.png'

const Subscrption = () => {


    return (
        <div className="mt-6 w-full h-full overflow-auto scrollbar-hide">
            <div className="lg:flex  lg:space-x-6">
                <div className="lg:w-1/2  w-full rounded-lg border border-solid  border-green-500 bg-white ">
                    <div className="mt-6 px-6">
                        <div className="flex flex-wrap justify-between flex-row w-full">
                            <p className="text-lg font-semibold not-italic text-gray-800">HR Empower</p>
                            <button className="rounded-2xl text-white bg-[#16C877] font-medium text-lg px-4">
                                Current Plan
                            </button>
                        </div>
                        <p className="text-gray-600 text-sm font-medium">
                            100 Token with Unlimited Validity*
                        </p>
                        <div className="h-[1px] w-full bg-[#E1E1E1] mt-4 "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Subscription Start Date </p>
                            <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Subscription End Date </p>
                            <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">SReceived Amount</p>
                            <p className="text-gray-800 font-poppins text-lg ">25999</p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Payment Mode</p>
                            <div className="flex ">
                                <p className="text-gray-800 font-poppins text-lg pr-1 ">Mastercard</p>
                                <img src={paymnetIcon} alt="paymnetIcon" className="" />
                            </div>

                        </div>

                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-8">
                            <p className=" text-teal-500 text-center font-poppins text-sm font-medium underline">View Plan Details</p>
                            <p className="text-orange-500 text-center font-poppins text-sm font-medium ">30 days left</p>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full flex rounded-lg border border-solid border-gray-300 bg-white lg:mt-0 mt-6">
                    <div className="mt-6 px-6 w-full">
                        <div className="flex flex-wrap justify-between flex-row w-full">
                            <p className="text-lg font-semibold not-italic text-gray-800">HR Visionary</p>
                            <button className="rounded-2xl text-white bg-[#E19393] font-medium text-lg px-4">
                                Expired
                            </button>
                        </div>
                        <p className="text-gray-600 text-sm font-medium">
                            100 Token with Unlimited Validity*
                        </p>
                        <div className="h-[1px] w-full bg-[#E1E1E1] mt-4 "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Subscription Start Date </p>
                            <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Subscription End Date </p>
                            <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">SReceived Amount</p>
                            <p className="text-gray-800 font-poppins text-lg ">25999</p>
                        </div>
                        <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                            <p className="text-gray-800 font-poppins text-lg ">Payment Mode</p>
                            <div className="flex ">
                                <p className="text-gray-800 font-poppins text-lg pr-1 ">Mastercard</p>
                                <img src={paymnetIcon} alt="paymnetIcon" className="" />
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-8">
                            <p className=" text-teal-500 text-center font-poppins text-sm font-medium underline">View Plan Details</p>
                            <p className="text-orange-500 text-center font-poppins text-sm font-medium ">0 days left</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 w-full flex rounded-lg border border-solid border-gray-300 bg-white mt-6">
                <div className="mt-6 px-6 w-full">
                    <div className="flex flex-wrap justify-between flex-row w-full">
                        <p className="text-lg font-semibold not-italic text-gray-800">HR Pinnacle</p>
                        <button className="rounded-2xl text-white bg-[#FDA33A] font-medium text-lg px-4">
                            Pending
                        </button>
                    </div>
                    <p className="text-gray-600 text-sm font-medium">
                        100 Token with Unlimited Validity*
                    </p>
                    <div className="h-[1px] w-full bg-[#E1E1E1] mt-4 "></div>
                    <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                        <p className="text-gray-800 font-poppins text-lg ">Subscription Start Date </p>
                        <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                    </div>
                    <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                    <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                        <p className="text-gray-800 font-poppins text-lg ">Subscription End Date </p>
                        <p className="text-gray-800 font-poppins text-lg ">27-11-2023 </p>
                    </div>
                    <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                    <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                        <p className="text-gray-800 font-poppins text-lg ">SReceived Amount</p>
                        <p className="text-gray-800 font-poppins text-lg ">25999</p>
                    </div>
                    <div className=" border-t border-dashed h-[1px] w-full bg-[#E1E1E1] "></div>
                    <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-2">
                        <p className="text-gray-800 font-poppins text-lg ">Payment Mode</p>
                        <p className="text-gray-800 font-poppins text-lg ">Pending</p>
                    </div>

                    <div className="flex flex-wrap justify-between flex-row w-full mt-5 mb-8">
                        <p className=" text-teal-500 text-center font-poppins text-sm font-medium underline">View Plan Details</p>
                        <p className="text-orange-500 text-center font-poppins text-sm font-medium ">Payment not Confirmed !</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Subscrption 