import Button from 'Components/CommonComponents/Button/Button';
import { useState } from 'react';
import jobService from '../../Services/JobService';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { useForm } from 'react-hook-form';

var strSelectedReviewer = [];

const AddReviewer = (props) => {
  const [List, setList] = useState();
  const [isError, setError] = useState();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const onSearch = async (e) => {
    await jobService
      .getInterviwer(e, props.jobId)
      .then((oResponse) => {
        console.log(oResponse.data.data);
        var arrList = getListOption(oResponse.data.data.results);
        setList(arrList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListOption = (optionList) => {
    var arrData = [];
    optionList &&
      optionList.map((odata) => {
        arrData.push({
          // key: odata.first_name,
          cat: odata.id,
          email: odata.email,
          first_name: odata.first_name,
          id: odata.id,
          mobile_number: odata.mobile_number,
          hire_manage_user: odata.hire_manage_user,
          assigned_jobs: odata.assigned_jobs,
          designation: odata.designation,
          key: odata.id,
          value: odata.first_name,
        });
      });
    return arrData;
  };

  const onSelect = (e) => {
    strSelectedReviewer = e;
  };

  const submitReviwer = () => {
    if (strSelectedReviewer.length > 0) {
      props.onSelectedValue(strSelectedReviewer);
      props.isClose();
      strSelectedReviewer = [];
    } else {
      setError(true);
    }
  };

  const getSelectedReviwer = (recuiter) => {
    List.map((item) => {
      if (item.id === recuiter.key) {
        console.log('item', item);
        strSelectedReviewer.push(item);
      }
    });
    setError(false);
    console.log(strSelectedReviewer);
  };

  const onSubmit = (data) => {
    console.log('strSelectedRecruiter', data);
  };

  const onDeselect = (deSelectData) => {
    const filterRecruiter = strSelectedReviewer.filter(
      (item) => item.id !== deSelectData.key,
    );
    console.log('filterRecruiter', filterRecruiter);
    strSelectedReviewer = filterRecruiter;
  };

  return (
    <div className="addReviewerMainDiv border-t-2">
      <div
        style={{ marginTop: '40px', textAlign: 'center', padding: '15px' }}
      >
        <div style={{ height: '210px', padding: '10px' }}>
          {/* <h5>Name of the {props.strHeaderName} *</h5>
          <Multiselect
            displayValue="key"
            isObject
            onRemove={(e) => onSelect(e)}
            onSearch={(e) => onSearch(e)}
            onSelect={(e) => onSelect(e)}
            options={List}
            className="multi_select"
            id={props.id}
            style={{
              chips: {},
              multiselectContainer: {},
              searchBox: {
                border: "none",
                padding: "4px",
                paddingLeft: "5px",
              },
            }}
            placeholder="Search"
          />
          {isError && (
            <p className="validate_message">* Please Select The Reviewer</p>
          )}
          <Button className="popup_button" onClick={() => submitReviwer()}>
            Submit
          </Button> */}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Select
              label={'Name of the ' + props.strHeaderName + ' *'}
              placeholder={'Search for ' + props.strHeaderName}
              options={List}
              name={'testRec'}
              control={control}
              showArrow={false}
              rules={{
                required: 'This field is required',
              }}
              style={{
                textAlign: 'left',
              }}
              mode="multiple"
              onSearch={(e) => {
                onSearch(e);
              }}
              onSelect={(e) => {
                console.log(e);
                getSelectedReviwer(e);
              }}
              onDeselect={(e) => {
                console.log(e);
                onDeselect(e);
              }}
            />

            {isError && (
              <p className="validate_message">
                * Please Select The Reviewer
              </p>
            )}
            <Button
              className="popup_button"
              onClick={() => submitReviwer()}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddReviewer;
