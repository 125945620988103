export const schedulesFilterList = [
  {
    text: 'Completed',
    value: 'COMPLETED',
  },
  {
    text: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    text: 'Not Attended',
    value: 'NOT ATTENDED',
  },
];

export const scheduleRequestsPrescreeningTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'ID',
    dataIndex: 'candidate_id',
    key: 'candidate_id',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Grade',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Requested Date',
    dataIndex: 'requested_date',
    key: 'requested_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Requested Time',
    dataIndex: 'requested_time',
    key: 'requested_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
];

export const scheduleReportsInterviewTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'ID',
    dataIndex: 'candidate_id',
    key: 'candidate_id',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Score',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Requested Date',
    dataIndex: 'requested_date',
    key: 'requested_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Requested Time',
    dataIndex: 'requested_time',
    key: 'requested_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
];

export const jobDetailsScheduleColumnConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'candidate_name',
    key: 'candidate_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'candidate_email',
    key: 'candidate_email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'candidate_phone',
    key: 'candidate_phone',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Times Rescheduled',
    dataIndex: 'interview_schedule_count',
    key: 'interview_schedule_count',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
  },
  {
    fixed: '',
    width: 200,
    title: 'Score',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Schedule Date',
    dataIndex: 'interview_start_date',
    key: 'interview_start_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Start Time',
    dataIndex: 'interview_start_time',
    key: 'interview_start_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    fixed: '',
    width: 200,
    title: 'End Time',
    dataIndex: 'interview_end_time',
    key: 'interview_end_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'interview_status',
    key: 'interview_status',
    fieldShow: false,
    isExpanded: false,
    is_sort: true,
    is_search: false,
    is_filter: true,
    filters: schedulesFilterList,
    position: 8,
  },
  {
    fixed: 'right',
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    position: 9,
    className: 'ant-table-column-action',
  },
];

export const SCHEDULE_STATUS_COLOR_TYPES = {
  Initiated: 'text-[#036FD3]',
  Pending: 'text-[#FF9A02]',
  Attended: 'text-[#00BA77]',
  Expired: 'text-[#E57878]',
};
