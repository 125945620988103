import React from 'react';
import iconEmpty from 'Assets/icons/iconEmpty.svg';
import { Empty as AntEmpty } from 'antd';
import { BiSolidError } from 'react-icons/bi';
import './Empty.scss';

const Empty = ({
  useDefaultTheme = true,
  className = '',
  description = null,
  isError = false,
  imageWidth = 50,
}) => {
  const image = isError ? (
    <BiSolidError
      className="errorIcon h-auto"
      style={{ width: `${imageWidth}px` }}
    />
  ) : (
    iconEmpty
  );
  return (
    <AntEmpty
      className={`
        customEmpty
        ${isError ? 'error' : ''}
        ${className}
        ${useDefaultTheme ? 'flex items-center flex-col py-16' : ''}
      `}
      image={image}
      imageStyle={{ height: 60 }}
      description={description}
    />
  );
};

export default Empty;
