import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Input } from 'antd';

import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import interviewService from 'Services/interviewService';
import next from '../../../Assets/icons/next.svg';
import hold from '../../../Assets/icons/hold.svg';
import reject from '../../../Assets/icons/reject.svg';
import { GET_COMPLETED_INTERVIEW_FEEDBACK } from 'ServiceHooks/interviewHooks';

import '../../../Styles/AiInterviewContent.scss';
const { TextArea } = Input;
var isFinalScreenDefaultValue;

export default function ReviewFeedbackForm(props) {
  const location = useLocation();

  const isModal = props?.isModal;
  const evaluationId = isModal
    ? props?.nEvaluationFeedbackId
    : location?.state?.interviewer_evaluation_feedback;

  const query = useQuery({
    queryKey: [GET_COMPLETED_INTERVIEW_FEEDBACK, evaluationId],
    queryFn: async () => {
      const details =
        await interviewService.getInterviewAnswerById(evaluationId);
      details?.data?.data?.questions?.map((item) => {
        if (item?.questions?.length === 0) {
          isFinalScreenDefaultValue = item?.status;
        }
      });
      return details;
    },
  });

  const hiringStatusData = query?.data?.data?.data?.questions?.find(
    (item) => item.section_label === 'Hiring Status',
  );

  const disabledFun = () => {
    if (
      location?.state?.selectedData.status === 'REVIEW_COMPLETED' ||
      location?.state?.selectedData.status === 'PASSED' ||
      location?.state?.selectedData.status === 'FAILED' ||
      location?.state?.selectedData.status === 'ONHOLD' ||
      isModal
    ) {
      return true;
    }
  };

  const buildQuestionsLayout = (QuestionData) => {
    if (QuestionData?.answer_type === 'SHORT_ANS') {
      return (
        <div className="w-full m-4">
          <p className="question_p">
            {QuestionData.question}{' '}
            {location?.state?.selectedData !== 'REVIEW_COMPLETED' &&
              QuestionData.is_mandatory &&
              (QuestionData.answer === undefined ||
                QuestionData.answer === ' ') && (
                <sup className="validate_message">* </sup>
              )}
          </p>
          <input
            className="answer_box"
            id={QuestionData.id}
            defaultValue={QuestionData?.answer}
            //onChange={(e) => handelShortAns(e, QuestionData.id)}
            disabled={disabledFun()}
          ></input>
        </div>
      );
    }
    if (QuestionData?.answer_type === 'LONG_ANS') {
      return (
        <div className="w-full m-4">
          <p className="question_p">
            {QuestionData.question}{' '}
            {location?.state?.selectedData !== 'REVIEW_COMPLETED' &&
              QuestionData.is_mandatory &&
              (QuestionData.answer == undefined ||
                QuestionData.answer == '') && (
                <sup className="validate_message">* </sup>
              )}
          </p>
          <textarea
            className="answer_box"
            id={QuestionData.id}
            defaultValue={QuestionData?.answer}
            //onChange={(e) => handelLongAns(e, QuestionData.id)}
            disabled={disabledFun()}
          ></textarea>
        </div>
      );
    }
    if (QuestionData?.answer_type === 'OPTIONS') {
      return (
        <div className="w-full m-4">
          <p className="question_p">
            {QuestionData.question}{' '}
            {location?.state?.selectedData !== 'REVIEW_COMPLETED' &&
              QuestionData.is_mandatory &&
              QuestionData.isOptionSelected == undefined && (
                <sup className="validate_message">* </sup>
              )}
          </p>
          <div className="vertical_maindiv">
            {QuestionData.multi_options_answers?.map((oOption) => {
              if (QuestionData.is_vertical) {
                return (
                  <div className="vertical">
                    <div>
                      <span>{oOption.option}</span>
                    </div>
                    <div>
                      <input
                        defaultChecked={
                          oOption.id == oOption.isOptionSelected
                        }
                        type="radio"
                        id={oOption.id}
                        value={oOption.id}
                        name={QuestionData.id}
                        //onChange={(e) => handelRadioChange(e, QuestionData.id)}
                        disabled={disabledFun()}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="nonvertical_maindiv">
            {QuestionData.multi_options_answers?.map((oOption) => {
              if (!QuestionData.is_vertical) {
                return (
                  <div className="nonvertical">
                    <input
                      type="radio"
                      defaultChecked={
                        oOption.id == oOption.isOptionSelected
                      }
                      id={oOption.id}
                      value={oOption.id}
                      name={QuestionData.id}
                      //onChange={(e) => handelRadioChange(e, QuestionData.id)}
                      disabled={disabledFun()}
                    />
                    <span className="pl-4">{oOption.option}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
    if (QuestionData?.answer_type === 'MULTI_CHOICE') {
      return (
        <div className="w-full m-4">
          <p className="question_p">
            {QuestionData.question}
            {location?.state?.selectedData !== 'REVIEW_COMPLETED' &&
              QuestionData.is_mandatory && (
                <sup className="validate_message">* </sup>
              )}
          </p>
          <div className="vertical_maindiv">
            {QuestionData.multi_options_answers?.map((oOption) => {
              if (QuestionData.is_vertical) {
                return (
                  <div className="vertical">
                    <div>
                      <span>{oOption.option}</span>
                    </div>
                    <div>
                      <Checkbox
                        defaultChecked={
                          oOption.id == oOption.isOptionSelected
                        }
                        type="checkbox"
                        id={oOption.id}
                        value={oOption.id}
                        //onChange={(e) => handelCheckChange(e, QuestionData)}
                        disabled={disabledFun()}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="nonvertical_maindiv">
            {QuestionData.multi_options_answers?.map((oOption) => {
              if (!QuestionData.is_vertical) {
                return (
                  <div className="nonvertical">
                    <Checkbox
                      defaultChecked={
                        oOption.id == oOption.isOptionSelected
                      }
                      type="checkbox"
                      id={oOption.id}
                      value={oOption.id}
                      //onChange={(e) => handelCheckChange(e, QuestionData)}
                      disabled={disabledFun()}
                    />
                    <span className="pl-4">{oOption?.option}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
    if (QuestionData?.answer_type === 'BOOL') {
      return (
        <div className="w-full m-4">
          <p className="question_p">
            {QuestionData.question}{' '}
            {location?.state?.selectedData !== 'REVIEW_COMPLETED' &&
              QuestionData.is_mandatory &&
              QuestionData.isYesAreNo == undefined && (
                <sup className="validate_message">* </sup>
              )}
          </p>
          <div className="vertical_maindiv">
            {QuestionData.is_vertical && (
              <>
                <div>
                  <input
                    type="radio"
                    id={QuestionData.id}
                    name={QuestionData.id}
                    value="true"
                    defaultChecked={QuestionData.isYesAreNo}
                    //onChange={(e) => handelBoolChange(e, QuestionData.id)}
                    disabled={disabledFun()}
                  />
                  <span className="pl-4">Yes</span>
                </div>
                <div>
                  <input
                    type="radio"
                    id={QuestionData.id}
                    name={QuestionData.id}
                    value="false"
                    defaultChecked={QuestionData.isYesAreNo}
                    //onChange={(e) => handelBoolChange(e, QuestionData.id)}
                    disabled={disabledFun()}
                  />
                  <span className="pl-4">NO</span>
                </div>
              </>
            )}
          </div>
          <div className="nonvertical_maindiv">
            {!QuestionData.is_vertical && (
              <>
                <div>
                  <input
                    type="radio"
                    id={QuestionData.id}
                    name={QuestionData.id}
                    value={true}
                    defaultChecked={QuestionData.isYesAreNo}
                    //onChange={(e) => handelBoolChange(e, QuestionData.id)}
                    disabled={disabledFun()}
                  />
                  <span className="pl-4">Yes</span>
                </div>
                <div>
                  <input
                    type="radio"
                    id={QuestionData.id}
                    name={QuestionData.id}
                    value={false}
                    defaultChecked={QuestionData.isYesAreNo}
                    //onChange={(e) => handelBoolChange(e, QuestionData.id)}
                    disabled={disabledFun()}
                  />
                  <span className="pl-4">NO</span>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const buildFinalScreen = () => {
    return (
      <>
        <div className="p-6 pb-0 overflow-x-hidden flex justify-center items-center gap-8">
          <div className="text-center items-center pb-10">
            <div style={{ width: '  369px', margin: 'auto' }}>
              <h3 className="font-bold text-left ">
                Interviewer Feedback
              </h3>
            </div>
            {isFinalScreenDefaultValue === 'PASSED' && (
              <div className="passBox">
                <div className="flex items-center justify-between">
                  <span className="pass_span">Cleared for next level</span>
                  <img
                    src={next}
                    alt=""
                    style={{ paddingLeft: '0px' }}
                  ></img>
                </div>
              </div>
            )}

            {isFinalScreenDefaultValue === 'ONHOLD' && (
              <div className="onHoldBox">
                <div className="flex items-center justify-between">
                  <span className="onHold_span">On Hold</span>
                  <img
                    src={hold}
                    alt=""
                    style={{ paddingLeft: '0px' }}
                  ></img>
                </div>
              </div>
            )}
            {isFinalScreenDefaultValue === 'FAILED' && (
              <div className="rejectBox">
                <div className="flex items-center justify-between">
                  <span className="rejectBox_span">Rejected</span>
                  <img
                    src={reject}
                    alt=""
                    style={{ paddingLeft: '0px' }}
                  ></img>
                </div>
              </div>
            )}
          </div>

          <div className="text-center items-center pb-10">
            <div style={{ width: '  369px', margin: 'auto' }}>
              <h3 className="font-bold text-left ">AI Feedback</h3>
            </div>
            {props?.interview_status === 'PASSED' && (
              <div className="passBox">
                <div className="flex items-center justify-between">
                  <span className="pass_span">Cleared for next level</span>
                  <img
                    src={next}
                    alt=""
                    style={{ paddingLeft: '0px' }}
                  ></img>
                </div>
              </div>
            )}
            {props?.interview_status === 'FAILED' && (
              <div className="rejectBox">
                <div className="flex items-center justify-between">
                  <span className="rejectBox_span">Rejected</span>
                  <img
                    src={reject}
                    alt=""
                    style={{ paddingLeft: '0px' }}
                  ></img>
                </div>
              </div>
            )}
          </div>
        </div>
        {true && (
          <div className=" mx-auto w-1/3">
            <h3 className="mb-4 font-bold text-left">Reason</h3>
            <TextArea
              rows={4}
              disabled={true}
              value={hiringStatusData?.status_reason}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {query?.status === 'loading' ? (
        'Loading....'
      ) : query?.status === 'error' ? (
        'Error'
      ) : (
        <div>
          {query?.data?.data?.data?.questions.map((data) => (
            <div className="interview_feedback_info">
              <h3 className="header_name">{data?.section_label}</h3>
              <div className="feedBackContent">
                <div className="p-6 pb-0 overflow-x-hidden">
                  {data?.section_label === 'Hiring Status'
                    ? buildFinalScreen()
                    : data?.questions?.map((q) =>
                        buildQuestionsLayout(q?.question),
                      )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
