import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useGetCompanyDetailsWithoutLogin } from 'ServiceHooks/authHooks';

const LoginDialogHeader = (props) => {
  const { data: companyDetails } = useGetCompanyDetailsWithoutLogin({});
  return (
    <Dialog
      open={true}
      sx={{
        '& .MuiBackdrop-root': {
          background: '#F0F6F6',
        },
        '& .MuiPaper-root': {
          borderRadius: '18px',
          backgroundColor: 'white',
        },
        // width:"85%"
      }}
    >
      <DialogTitle>
        <div className="text-center">
          <div>
            <img
              src={companyDetails?.company_logo}
              alt="logo"
              className="login_logo"
            />
          </div>
          <div>
            <span className="text-base uppercase">
              {companyDetails?.company_name ?? ''}
            </span>
          </div>
        </div>
      </DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialogHeader;
