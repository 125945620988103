import { useQuery } from '@tanstack/react-query';
import ResumeBuilderService from 'Services/resumeBuilderService';

export const GET_RESUME_TEMPLATES = 'GET_RESUME_TEMPLATES';
const useGetResumeTemplates = (config) => {
  const query = useQuery({
    queryKey: [GET_RESUME_TEMPLATES],
    queryFn: async () => {
      const response = await ResumeBuilderService.getResumeTemplates();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

export const GET_RESUME_TEMPLATES_DATA = 'GET_RESUME_TEMPLATES_DATA';
const useGetResumeTemplatesData = (config) => {
  const { params = {}, ...rest } = config;
  console.log(params,"config123");
  const query = useQuery({
    queryKey: [GET_RESUME_TEMPLATES_DATA],
    queryFn: async () => {
      const response = await ResumeBuilderService.getResumeTemplatesData(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

export {
    useGetResumeTemplates,
    useGetResumeTemplatesData
  };