import { useQuery } from '@tanstack/react-query';

import { getCompanyInformation } from 'Services/layoutServices';

/* 
  Fetches the company information based on the user's metadata.
*/
export const VIEW_COMPANY_INFORMATION = 'VIEW_COMPANY_INFORMATION';
export const useGetCompanyInformation = (config) => {
  const query = useQuery({
    queryKey: [VIEW_COMPANY_INFORMATION],
    queryFn: async () => {
      const response = await getCompanyInformation();
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};
