export const departmentsColumns = [
  {
    width: 200,
    title: 'Department Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];
export const skillColumns = [
  {
    width: 200,
    title: 'Skills',
    dataIndex: 'skill',
    key: 'skill',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];

export const evaluationParameterColumns = [
  {
    width: 200,
    title: 'Questions',
    dataIndex: 'question',
    key: 'question',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'Parameter Type',
    dataIndex: 'answer_type',
    key: 'answer_type',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];
export const teamMemberColumns = [
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },

  {
    width: 150,
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 2,
    className: 'ant-table-column-action',
  },

  {
    width: 150,
    title: 'Mobile Number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 3,
    className: 'ant-table-column-action',
  },

  {
    width: 150,
    title: 'Invitation Send Date',
    dataIndex: 'last_invitation_send_date_time',
    key: 'last_invitation_send_date_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 4,
    className: 'ant-table-column-action',
  },

  {
    width: 150,
    title: 'Joined Date',
    dataIndex: 'invitation_accepted_date_time',
    key: 'invitation_accepted_date_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 5,
    className: 'ant-table-column-action',
  },
  {
    width: 150,
    title: 'Roles',
    dataIndex: 'role_and_permissions_name',
    key: 'role_and_permissions_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 6,
    className: 'ant-table-column-action',
  },
  {
    width: 150,
    title: 'Invitation Status',
    dataIndex: 'invitation_status',
    key: ' invitation_status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: '',
    position: 6,
    className: 'ant-table-column-action',
  },
  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 7,
    className: 'ant-table-column-action',
  },
];

export const profileRoleColumn = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'role_name',
    key: 'role_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Permissons',
    dataIndex: 'role_name',
    key: 'role_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 3,
    className: 'ant-table-column-action',
  },
];

export const locationColumn = [
  {
    width: 200,
    title: 'Locations',
    dataIndex: 'city_state_address',
    key: 'city_state_address',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 1,
    className: 'ant-table-column-action',
  },
];

export const educationDetailsColumn = [
  {
    width: 200,
    title: 'Education Name',
    dataIndex: 'qualification',
    key: 'qualification',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Education Name',
    dataIndex: 'code',
    key: 'code',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];

export const certificationColumn = [
  {
    width: 200,
    title: 'Certification Name',
    dataIndex: 'certification',
    key: 'certification',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    title: 'Certification code',
    dataIndex: 'code',
    key: 'code',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];

export const evaluationQuestionnaireColumn = [
  {
    width: 200,
    title: 'Form Name',
    dataIndex: 'title',
    key: 'title',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 1,
    className: 'ant-table-column-action',
  },
];
export const hiringTypeColumns = [
  {
    width: 200,
    title: 'Hiring Type',
    dataIndex: 'hiring_type',
    key: 'hiring_type',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];
export const consultanyColumns = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'client',
    key: 'client',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Contact Person',
    dataIndex: 'primary_contact_person',
    key: 'primary_contact_person',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Contact Number',
    dataIndex: 'primary_contact_mobile',
    key: 'primary_contact_mobile',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 5,
    className: 'ant-table-column-action',
  },
];

export const prescreenParameterColumn = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];

export const consultancyClientColumn = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'client',
    key: 'client',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Contact Person',
    dataIndex: 'primary_contact_person',
    key: 'primary_contact_person',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Contact Number',
    dataIndex: 'primary_contact_mobile',
    key: 'primary_contact_mobile',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 4,
    className: 'ant-table-column-action',
  },
];

export const consultancyUserColumn = [
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 200,
    title: 'Mobile Number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    fixed: '',
  },
  {
    width: 200,
    title: 'Invitation Send Date',
    dataIndex: 'last_invitation_send_date_time',
    key: 'last_invitation_send_date_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
    fixed: '',
  },
  {
    width: 200,
    title: 'Joined Date',
    dataIndex: 'invitation_accepted_date_time',
    key: 'invitation_accepted_date_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
    fixed: '',
  },
  {
    width: 200,
    title: 'Roles',
    dataIndex: 'role_and_permissions_name',
    key: 'role_and_permissions_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
    fixed: '',
  },
  {
    width: 200,
    title: 'Invitation Status',
    dataIndex: 'invitation_status',
    key: 'invitation_status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 8,
    className: 'ant-table-column-action',
  },
];

export const documentTypeColumns = [
  {
    width: 200,
    title: 'Document Type',
    dataIndex: 'document_name',
    key: 'document_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];
