import { Tabs as AntTabs } from 'antd';
import './Tabs.scss';

const Tabs = ({
  onChange = () => null,
  ...others
}) => {
  return (
    <div className="customTabs w-full">
      <AntTabs
        onChange={onChange}
        {...others}
      />
    </div>
  );
};

export default Tabs;