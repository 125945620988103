import { Select as AntSelect, Form, Spin, Tag } from 'antd';
import { useController } from 'react-hook-form';
import './Select.scss';

import Empty from 'Components/CommonComponents/Empty/Empty';

function Select({
  onSearch,
  options,
  fetching,
  mode = null,
  // hook form specific
  onChange: customOnChange = null,
  name,
  control,
  defaultValue,
  filterOption = false,
  rules = {},
  label,
  readOnly,
  useDefaultTheme = true,
  ...props
}) {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <>
      <div className="rfhSelect">
        <Form.Item
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          help={errorText}
          validateStatus={status}
        >
          <AntSelect
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              if (customOnChange) {
                customOnChange(e, onChange);
              } else {
                onChange(e);
              }
            }}
            // select props
            mode={mode}
            labelInValue
            onSearch={onSearch}
            filterOption={filterOption}
            notFoundContent={
              fetching ? (
                <div className="flex justify-center py-5">
                  <Spin size="small" />
                </div>
              ) : (
                <div className="flex justify-center">
                  <Empty
                    useDefaultTheme={false}
                    description={'No Options Available'}
                    className="flex items-center flex-col py-4"
                  />
                </div>
              )
            }
            options={options}
            status={status}
            {...props}
            disabled={readOnly}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Select;
