import React from 'react'
import { useController } from 'react-hook-form';
import { Checkbox as AntCheckbox, Form } from 'antd';
import './Checkbox.scss'


const Checkbox = ({
  label,
  disabled = false,
  // hook form specific
  name, control, defaultValue, rules = {},
  showUpperLabel = true,
  onChange : customOnChange
}) => {
  const {
    field: { ref, value, onChange: hookFormOnChange },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules
  });
  const errorText = error?.message
  const status = errorText ? 'error' : '';
  const onChange = (e) => {
    if(customOnChange)
    {
      customOnChange(e, hookFormOnChange);
    }
    else
    {
      hookFormOnChange(e)
    }
  }
  return (
    <Form.Item
      label={showUpperLabel ? label : null}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
      status={status}
    >
      <AntCheckbox
        name={name}
        ref={ref}
        checked={value}
        disabled={disabled}
        onChange={onChange}
        className='customCheckbox'
      >
        {label}
      </AntCheckbox>
    </Form.Item>
  );
};

export default Checkbox;