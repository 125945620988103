import { useEffect, useState } from 'react';
import { Janus, RecordPlayJanusPlugin } from '@techteamer/janus-api';
import { useToggle } from 'react-use';
import Webcam from 'react-webcam';
import { isEmpty } from 'lodash';
import 'webrtc-adapter';

const url = 'wss://video.yvi.ai';

export const config = {
  url,
  keepAliveIntervalMs: 10000,
};

const peerConnectionConfig = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:global.stun.twilio.com:3478' },
  ],
  bundlePolicy: 'balanced',
  iceCandidatePoolSize: 0,
  iceTransportPolicy: 'all',
  rtcpMuxPolicy: 'require',
};

const PluginMessageTypes = {
  Message: 'message',
  Candidate: 'candidate',
  Stop: 'stop',
};

const PluginEvents = {
  RecordingId: 'recordingId',
  Jsep: 'jsep',
};

export default function JanusTest() {
  const [janus, setJanus] = useState(null);
  const [plugin, setPlugin] = useState(null);

  const [connection] = useToggle(true);
  const [stream, setStream] = useState(null);
  const [recordingId, setRecordingId] = useState(null);

  //TODO: maybe, change it to useCallback
  const initializeJanus = async () => {
    if (!Boolean(stream)) return;
    try {
      const janus = new Janus(config, console);
      const plugin = new RecordPlayJanusPlugin(console, false);

      setJanus(janus);
      setPlugin(plugin);
      await janus.connect();
      console.log('Server Connection Init.');

      await janus.addPlugin(plugin);
      plugin.configure();
      plugin.onmessage = (data, json) => {
        /*
          NPM side: RecordPlay plugin's default behavior when a recording ends is deconstructing the plugin.
          Method is re-declared here to override the plugin's default behavior in an effort of reusing the plugin.
          In case if the default behavior of automatic deconstruction is preferred, then remove the onmessage method. 
        */
      };
      plugin.on(PluginEvents.RecordingId, (recordingId) => {
        //Here's the plugin ID in case if tracking it is needed
        setRecordingId(recordingId);
      });
    } catch (error) {
      console.log('Connection Init Error:', error);
    }
  };

  const startRecording = async () => {
    //Handles First JSEP: Offer
    if (plugin && janus) {
      try {
        const peerConnection = new RTCPeerConnection(peerConnectionConfig);
        stream?.getTracks().forEach((track) => {
          peerConnection.addTrack(track);
        });

        peerConnection.onicecandidate = (event) => {
          if (!event.candidate || !event.candidate.candidate) {
            //ICE complete phase
            plugin.consume({
              type: PluginMessageTypes.Candidate,
              message: { completed: true },
            });
          } else {
            //ICE setting phase
            const data = event.candidate;
            const trickleMessage = {
              candidate: data.candidate,
              sdpMid: data.sdpMid,
              sdpMLineIndex: data.sdpMLineIndex,
            };
            plugin.consume({
              type: PluginMessageTypes.Candidate,
              message: trickleMessage,
            });
          }
        };

        plugin.on(PluginEvents.Jsep, (jsep) => {
          //Handles Second JSEP: Answer
          const answer = new RTCSessionDescription(jsep);
          peerConnection.setRemoteDescription(answer);
          console.log('JSEP Exchange Complete: ', answer);
        });

        const offer = await peerConnection?.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });
        peerConnection.setLocalDescription(offer);
        const jsep = { type: offer.type, sdp: offer.sdp };

        console.log('new peer: ', peerConnection);

        //Starts recording
        await plugin.consume({
          type: PluginMessageTypes.Message,
          message: { jsep },
        });
      } catch (error) {
        console.log('Recording init Error: ', error);
      }
    } else {
      console.log('Start prereq Error: ', plugin, janus);
    }
  };
  console.log('plugin: ', plugin);
  console.log('stream: ', stream);

  useEffect(() => {
    if (connection && stream) {
      initializeJanus();
    }
  }, [connection, stream]);

  const stopRecording = async () => {
    if (!isEmpty(plugin)) {
      try {
        await plugin.consume({ type: PluginMessageTypes.Stop });

        setRecordingId(null);
      } catch (error) {
        console.log('Janus Cleanup Error:', error);
      }
    }
  };

  const stopSession = async () => {
    if (janus) {
      await janus.destroy();
    }
  };

  return (
    <main className="h-screen w-screen">
      <h1>
        Janus Test{Boolean(recordingId) ? ': Recording Started' : ''}
      </h1>
      <br />
      <div className="ml-4 flex flex-col space-y-4 items-start">
        <button onClick={startRecording}>Start Recording</button>
        <button onClick={stopRecording}>Stop Recording</button>
        <button onClick={stopSession}>End Server Connection</button>
      </div>
      <Webcam
        muted
        mirrored
        playsInline
        audio={true}
        audioConstraints={{
          echoCancellation: true,
          noiseSuppression: true,
        }}
        onUserMedia={(stream) => {
          setStream(stream);
        }}
        videoConstraints={{
          width: 1280,
          height: 720,
          frameRate: 30,
          facingMode: { ideal: 'user' },
        }}
        screenshotFormat="image/jpeg"
        className="m-auto h-80 w-80 rounded-full object-cover"
      />
    </main>
  );
}
