import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse, Popover, Slider, Tooltip, message } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import linkedIn from '../../Assets/images/linkedIn.png';
import indeedIcon from '../../Assets/images/indeed.png';
import naukri from '../../Assets/images/naukri.png';
import CheckboxGroup from 'Components/CommonComponents/ReactHooKForm/CheckboxGroup/CheckboxGroup';
import InterviewSettings from './InterviewSettings';
import {
  useCreateJobSettings,
  useCreateSocialMediaSettings,
  useGetSocialMediaSettings,
  useJobSettings,
} from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import ReviewSettings from './ReviewSettings';
import CompanyWorkingTime from './CompanyWorkingTime';
import JobSettings from './JobSettings';
import QuestionnaireSettings from './QuestionnaireSettings';
import SocialMediaSettings from './SocialMediaSettings';
import LinkExpirySettings from './LinkExpirySettings';

const { Panel } = Collapse;

const BranchSettings = (props) => {
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      LINKEDIN: false,
      INDEED: false,
      NAUKRI: false,
    },
  });

  const jobReferenceType = watch('is_enabled');
  console.log('is_enabled', jobReferenceType);
  const prefixJobReferenceType = watch('is_prefix_needed');
  const selectedYearType = watch('year_prefix');
  const selectedMonthType = watch('month_prefix');

  return (
    <div className="w-full mt-7">
      <div className="lg:flex lg:space-x-12">
        <div className="lg:w-1/2 w-full">
          <JobSettings companyId={props.companyId} />
        </div>
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8">
          <ReviewSettings comapnyId={props.companyId} />
        </div>
      </div>

      <div className="lg:flex lg:space-x-12 mt-8 ">
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8">
          <InterviewSettings comapnyId={props.companyId} />
        </div>
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8">
          <CompanyWorkingTime companyId={props.companyId} />
        </div>
      </div>
      <div className="lg:flex lg:space-x-12 mt-8 ">
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8 ">
          <SocialMediaSettings companyId={props.companyId} />
        </div>
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8 ">
          <QuestionnaireSettings companyId={props.companyId} />
        </div>
      </div>
      <div className="lg:flex lg:space-x-12 mt-8 ">
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8 ">
          <LinkExpirySettings companyId={props.companyId} />
        </div>
        <div className="lg:w-1/2 w-full lg:mt-0 mt-8 ">
        </div>
      </div>
    </div>
  );
};
export default BranchSettings;
