import { useState, useRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import TextEdit from '../CommonComponents/TextEdit';
import { Switch } from 'antd';
import { ContentCopy } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import CommunicationService from '../../Services/communicationService';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import { useGetCommunicationTransactions } from 'ServiceHooks/communicationHooks';

var updatedText;
const SMS = () => {
  const [getTransactionId, setGetTransactionId] = useState('');
  const [activeForm, setActiveForm] = useState(false);
  const [details, setDetails] = useState();
  const [description, setJobDescription] = useState();
  const [subjectName, setSubjectName] = useState();
  const [error, setError] = useState(false);
  const [isValidateError, setIsisValidateError] = useState(false);

  const inputRef = useRef();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onChange = (e) => {
    setJobDescription(e);
  };

  const getTranscationDetails = async (id) => {
    await CommunicationService.getTranscationSmsById(id).then(
      (response) => {
        setDetails(response.data.data);
        setSubjectName(response?.data?.data?.kaleyra_template_id);
        setJobDescription(response?.data?.data?.sms_content);
      },
    );
  };
  const handelReset = async () => {
    await CommunicationService.getRestTransactionById(getTransactionId)
      .then((response) => {
        setDetails(response.data.data);
        setSubjectName(response?.data?.data?.subject);
        setJobDescription(response?.data?.data?.html_content);
        toast.success('Successfully Updated');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNameCopy = (item) => {
    updatedText = `{${item}}`;
    navigator.clipboard.writeText(updatedText);
    toast.success('Copied');
  };
  const { data: transactions } = useGetCommunicationTransactions({});

  const transactionOptions = useMemo(() => {
    if (isEmpty(transactions?.results)) return [];
    const options = transactions?.results?.map((item) => {
      return {
        label: item.value,
        value: item.key,
      };
    });
    return options;
  }, [transactions]);

  const selectTransactionHandler = ({ key }) => {
    setGetTransactionId(key);

    // Form Activate
    if (key === '') {
      setActiveForm(false);
    } else {
      setActiveForm(true);
    }
    getTranscationDetails(key);
  };
  const handleSwtich = async (e) => {
    await CommunicationService.activeSmsTranscation(getTransactionId, e)
      .then((res) => {
        toast.success('Successfully updated');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = async (data, e) => {
    const array = details?.parameters;
    const emptyBracesRegex = /{\s*}/g;
    const regex = /{([^{}]+)}/g;
    const matches = description.match(regex);

    // Test if the input matches the regex pattern
    const emptybracesvalidate = emptyBracesRegex.test(description);
    const isValidSentence = matches.some((match) => {
      // Remove curly braces from the match
      const element = match.slice(1, -1).trim();
      return !array.includes(element);
    });

    console.log('tesdes', isValidSentence);
    if (emptybracesvalidate) {
      if (emptybracesvalidate === true) {
        setError(true);
      } else {
        setError(false);
      }
    } else if (isValidSentence === true) {
      setIsisValidateError(true);
    } else if (details?.id == null) {
      await CommunicationService.addSmsContent(
        data,
        getTransactionId,
        subjectName,
        description,
      )
        .then(
          (res) => {
            console.log(res);
            toast.success('Successfully Created');
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            console.log('Error', error);
            toast.error('Something went wrong..!!');
          },
        )
        .catch((err) => {
          console.log(err);
        });
    } else if (details?.id) {
      await CommunicationService.editSmsContent(
        data,
        getTransactionId,
        details?.id,
        subjectName,
        description,
      )
        .then(
          (res) => {
            console.log(res);
            toast.success('Successfully Created');
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            console.log('Error', error);
            toast.error('Something went wrong..!!');
          },
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="bg-secondaryBgMain rounded-lg mt-4 pb-4">
          <div className="ml-8">
            <form
              className="overflow-y-auto max-h-[67vh]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="md:flex md:flex-row flex-col flex-wrap">
                <div className="md:w-1/2 px-6 mt-10 rounded-md border border-gray-300">
                  <div className="w-full mt-8">
                    <div className="mt-4 w-full">
                      <Select
                        label={'Transaction'}
                        name={'transOption'}
                        placeholder="Select an Option"
                        options={transactionOptions}
                        control={control}
                        onChange={(e) => selectTransactionHandler(e)}
                      />
                    </div>
                    <div className="mt-8 w-full">
                      <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                        Subject
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setSubjectName(e.target.value)}
                        defaultValue={details && details.subject}
                        required
                        className="text-sm  h-12 w-60 md:w-full px-2 py-2 flex bg-white rounded-md border border-gray-400 border-opacity-75 shadow-md"
                      />
                    </div>
                    <div className="w-full mt-8 pb-10">
                      <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                        Content
                      </label>
                      <TextEdit
                        id="description"
                        required
                        onChange={onChange}
                        initialValue={
                          details?.html_content
                            ? details?.html_content
                            : ''
                        }
                      />

                      {error && (
                        <p className="text-red-600 text-xs font-semibold text-start">
                          Curly braces cannot be empty
                        </p>
                      )}
                      {isValidateError && (
                        <p className="text-red-600 text-xs font-semibold text-start">
                          Invalid data inside curly braces
                        </p>
                      )}
                      {details && (
                        <div className="flex justify-end mb-4">
                          <Switch
                            checked={details?.is_active}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            onChange={(e) => handleSwtich(e)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="md:w-1/2 px-8">
                  {activeForm ? (
                    <div className="w-full h-fit p-2 mt-10 rounded-md border-dashed border-gray-300 border-2">
                      <div className="pl-4 w-full flex-col flex mt-6">
                        {details &&
                          details.parameters.map((item, index) => (
                            <div>
                              {/* {index === 0 && (
                                <div className="activeButton mb-4">
                                  <Switch
                                    checked={item.is_active}
                                    checkedChildren="Active"
                                    unCheckedChildren="InActive"
                                    onChange={(e) => handleSwtich(e)}
                                  />
                                </div>
                              )} */}
                              <div className="flex ">
                                <div
                                  key={`trans-name-${index}`}
                                  className="text-sm w-full px-4 py-2 mb-8 flex bg-white focus:outline-none rounded-md border border-blue-500"
                                >
                                  {item}
                                </div>
                                <button
                                  className="text-indigo-300 flex flex-wrap"
                                  title="copy"
                                  onClick={() => handleNameCopy(item)}
                                >
                                  <span className="mt-[6px] ml-[5px]">
                                    <ContentCopy />
                                  </span>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <Empty
                        description={
                          <>{<p> No Selected Transaction</p>}</>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold mr-10 mb-10">
                <div className="flex items-center justify-between">
                  <Button isSecondary onClick={handelReset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    className="ml-6"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMS;
