import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Webcam from 'react-webcam';
import { nets, tinyFaceDetector } from 'face-api.js';
import { message, Popover, Progress } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import logo from '../../../Assets/logo.png';
// import Wrong from '../../../Assets/images/wrong.svg';
import Wrong from '../../../Assets/images/close.svg';
import Right from '../../../Assets/images/right1.svg';
import circle from '../../../Assets/images/Ellipse.svg';
import start_count_bg from '../../../Assets/images/start_count_bg.svg';
import Camera from '../../../Assets/images/camera_capture.svg';
import dots from '../../../Assets/images/count-down-dots.svg';
import Config from 'Services/Config';
import authService from 'Services/authService';
import Header from '../WebScreens/Header';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { getFileFromBase64 } from 'Utilities/componentSpecificUtilities';
import { getOS } from 'Utilities/utils';

nets.tinyFaceDetector.loadFromUri(`${process.env.PUBLIC_URL}/models`);

// speed
// const imageAddr =
//   'https://4k-uhd.nl/wp-content/uploads/2018/08/4K-3840x2160-Wallpaper-Uitzicht-5.jpg';

const imageAddr = Config.API_URL + 'static/logos/load_test.jpg';
const downloadSize = 2411725; //bytes

const AITestScreen = (props) => {
  let isTestingMic = false;
  const [startCount, setStartCount] = useState(3);
  const [isWebStart, setWebStartScreen] = useState();
  const [isLowLight, setLowLight] = useState(false);
  const [isMicGood, setMicGood] = useState(false);
  const [isStart, setStartInterviewScreen] = useState(false);
  const [isNetSpeedGood, setNetSpeedGood] = useState(false);
  const [liveSnap, setLiveSnap] = useState();
  const [faces, setFaces] = useState([]);
  const [faceThreshold, setFaceThreshold] = useState(0);
  const systemOS = getOS();

  const params = useParams();
  const videoRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const isMobileDevice = useDeviceDetect();


  //  mic
  const audioContext = new (window.AudioContext ||
    window.webkitAudioContext)();
  const analyser = audioContext.createAnalyser();

  const checkLowLight = () => {
    const video = videoRef.current.video;

    const canvasContext = videoRef?.current?.getCanvas()?.getContext('2d');

    const currentWidth = videoRef.current.video.clientWidth;
    const currentHeight = videoRef.current.video.clientHeight;

    canvasContext?.drawImage(video, 0, 0, currentWidth, currentHeight);
    const imageData = canvasContext?.getImageData(
      0,
      0,
      currentWidth,
      currentHeight,
    ).data;

    let totalBrightness = 0;

    for (let i = 0; i < imageData?.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      // const brightness = (r + g + b)/3;
      totalBrightness += brightness;
    }

    const avgBrightness = totalBrightness / (currentWidth * currentHeight);

    const lowLightThreshold = 30;

    if (avgBrightness < lowLightThreshold) {
      setLowLight(true);
    } else {
      setLowLight(false);
    }
  };

  useEffect(() => {
    let id = setInterval(async () => {
      const detections = await tinyFaceDetector(videoRef.current.video);
      setFaces(detections);

      if (detections?.length === 1) {
        const boxArea = Math.floor(detections[0].box.area);

        const frameHeight = videoRef.current.video.clientHeight;
        const frameWidth = videoRef.current.video.clientWidth;

        const frameArea = frameHeight * frameWidth;

        const detectedFaceThreshold = Math.floor(
          (boxArea / frameArea) * 100,
        );
        setFaceThreshold(detectedFaceThreshold);
      } else {
        setFaceThreshold(0);
      }
      checkLowLight();
    }, 500);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    checkRequirements();
  }, []);

  // const getDeviceName = () => {
  //   const devname = navigator.userAgent;
  //   console.log("devname", devname);
  //   console.log("l", navigator.language);
  //   console.log("av", navigator.appVersion);
  //   console.log("p", navigator.userAgent.match(/^[^(]+\((\w+)/));

  //   console.log("devicedata deviceDetect", deviceDetect());
  //   console.log("devicedata browserName", mobileModel);
  // };

  const startCamera = (constraints) => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log('Dispatched: ', stream);
      })
      .catch((err) => {
        console.error('error:', err);

        if (err === ' DOMException: Permission denied') {
          console.error('error:', err);
          messageApi.open({
            type: 'error',
            content: 'Error Accessing Camera: ' + err,
          });
          messageApi.open({
            type: 'error',
            content:
              'Camera Permission Denied. Please allow Camera Permission.',
          });
        }
      });
  };

  const stopMicTest = () => {
    isTestingMic = false;
    messageApi.open({
      type: 'success',
      content: 'Mic. is Accessible.',
    });
    setMicGood(true);
  };

  // mic Test.
  const startMicTest = () => {
    setMicGood(false);
    if (isTestingMic) {
      return;
    }
    isTestingMic = true;

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: { exact: true },
        },
      })
      .then((stream) => {
        setTimeout(stopMicTest, 5000);

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.connect(audioContext.destination);
      })
      .catch((err) => {
        isTestingMic = false;
        setMicGood(false);
      });
  };

  const showResults = (startTime, endTime) => {
    const duration = (endTime - startTime) / 1000;
    const bitsLoaded = downloadSize * 8;
    const speedBps = (bitsLoaded / duration).toFixed(2);
    const speedKbps = (speedBps / 1024).toFixed(2);
    const speedMbps = (speedKbps / 1024).toFixed(2);

    if (speedKbps < 265) {
      setNetSpeedGood(false);
      messageApi.open({
        type: 'error',
        content:
          'Current Internet Connection is Unsupported. Please Change to Proceed.',
        duration: 60,
      });
    } else {
      setNetSpeedGood(true);
    }
  };

  const measureConnectionSpeed = () => {
    let endTime;
    let download = new Image();
    const startTime = new Date().getTime();

    download.onload = function () {
      endTime = new Date().getTime();
      showResults(startTime, endTime);
    };

    download.onerror = function (err, msg) {
      setNetSpeedGood(false);
    };

    let cacheBuster = '?nnn=' + startTime;
    download.src = imageAddr + cacheBuster;
  };

  const checkRequirements = async () => {
    startCamera();
    startMicTest();
    await measureConnectionSpeed();
    // getDeviceName();
  };

  const multipleFacesDetected = faces?.length > 1;
  const invalidCaptureConditions =
    faces?.length !== 1 || faceThreshold < 30;

  const isDisabled = () => {
    if (
      invalidCaptureConditions ||
      isLowLight === true ||
      liveSnap == null ||
      isMicGood === false ||
      isNetSpeedGood === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  const progressColorType = faceThreshold < 30 ? '#EA1A1A' : '#4BB543';

  const startMobileInterview = () => {
    if (invalidCaptureConditions) {
      messageApi.open({
        type: 'error',
        content: 'Please keep your face centered and sturdy enough.',
      });
      return;
    }
    if (isLowLight) {
      messageApi.open({
        type: 'error',
        content: 'Light Conditions are Unsuitable.',
      });
      return;
    }
    if (isMicGood) {
      if (liveSnap != null) {
        setStartInterviewScreen(true);
        startCountAndInterview();
      } else {
        messageApi.open({
          type: 'error',
          content: 'Please Capture your Picture',
        });
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please Allow the Mic Permission',
      });
    }
  };

  const startWebInterview = () => {
    if (invalidCaptureConditions) {
      messageApi.open({
        type: 'error',
        content: 'Please keep your face centered and sturdy enough.',
      });
      return;
    }
    if (isLowLight) {
      messageApi.open({
        type: 'error',
        content: 'Light Conditions are Unsuitable.',
      });
      return;
    }
    if (isMicGood) {
      if (liveSnap != null) {
        setWebStartScreen(true);
        startCountAndInterview();
      } else {
        messageApi.open({
          type: 'error',
          content: 'Please Capture your Picture',
        });
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please Allow the Mic Permission',
      });
    }
  };

  const startCountAndInterview = () => {
    setInterval(() => {
      setStartCount((prev) => {
        if (prev === 1) {
          props.nextScreen('questionScreen');
          window.open(
            `${Config.API_URL}api/v1/interview/start-ai-interview/${params.token}/`,
            '_self',
          );
        }
        if (prev !== 1) {
          return prev - 1;
        }
      });
    }, 1000);
  };

  const sendSnap = async (image) => {
    try {
      await authService.sendLiveSnap(image, params.token);
      await messageApi.open({
        type: 'success',
        content: 'Successfully Captured',
      });
    } catch (error) {
      console.log('Uploading Error: ', error);
    }
  };

  const capture = () => {
    if (invalidCaptureConditions) return;
    window.requestAnimationFrame(async() => {
      const detections = await tinyFaceDetector(videoRef.current.getCanvas());
      if(detections?.length > 1){
        await messageApi.open({
          type: 'error',
          content: 'Multiface Detected',
        });
      }else {
        const imageString = videoRef.current.getScreenshot();
        const file = getFileFromBase64(
          imageString,
          'profile.jpeg',
          'image/jpeg',
        );
        setLiveSnap(file);
        sendSnap(file);
      }
    });
  };

  const content = (
    <div className={`text-[#FFF]`}>
      <p>{faceThreshold < 30 ? 'Poor Quality' : 'Good Quality'}</p>
    </div>
  );
  const backgroundColor = faceThreshold < 30 ? '#E94A47' : '#49A546';
  const buildWebLayout = () => {
    return (
      <>
        {contextHolder}
        {isWebStart ? (
          <div className="p-[30px] flex tab:flex-row flex-col-reverse items-center gap-5 min-h-screen w-full justify-center">
            <div className=" flex-col items-center justify-center  mt-[10px] flex tab:w-[40%] w-[80%]">
              <h1 className="test_count text-center text-[40px] font-bold text-[#000000] italic">
                You Look Smart & <br />
                Confident!
              </h1>
              <h4 className="test_count text-[22px] font-semibold text-[#000000] my-5">
                Your Interview Will Start In
              </h4>
              {startCount !== 'NaN' && startCount > 0 && (
                <div class="bg-cover w-[200px] h-[120px] flex items-center justify-center">
                  <h1 className="text-[50px] text-[#04D0C7] font-sans ont-bold">
                    <span>{startCount ? startCount : ''}</span>
                  </h1>
                </div>
              )}
            </div>
            <div className="camera_preview sm:h-[22rem] sm:w-[22rem] tab:h-[25rem] tab:w-[25rem] h-[20rem] w-[20rem]">
              <Webcam
                playsInline
                ref={videoRef}
                screenshotFormat="image/jpeg"
                audioConstraints={{
                  advanced: [
                    { echoCancellation: true },
                    { noiseSuppression: true },
                  ],
                }}
                videoConstraints={{
                  advanced: [{ facingMode: { ideal: 'user' } }],
                }}
                className="m-auto sm:h-[22rem] sm:w-[22rem] tab:h-[25rem] tab:w-[25rem] h-[20rem] w-[20rem] rounded-full object-cover"
              />
            </div>
          </div>
        ) : (
          <div className="p-[30px] flex flex-col justify-center items-center w-full min-h-screen gap-5">
            <div className="flex items-center gap-5 justify-center w-full tab:flex-row flex-col flex-col-reverse">
              {!isWebStart && (
                <div className="testscreen_div tab:w-[40%] w-[80%]">
                  <div className=" p-5">
                    <ul>
                      <li className="test_list_web">
                        <h4>
                          {liveSnap !== undefined
                            ? 'Picture Captured for Future Reference.'
                            : 'Please click the Capture Button.'}
                        </h4>
                        <span>
                          <img
                            src={liveSnap !== undefined ? Right : Wrong}
                            alt=""
                          />
                        </span>
                      </li>
                      <li className="test_list_web">
                        <h4>Ensure Adequate Lighting</h4>
                        <span>
                          <img src={!isLowLight ? Right : Wrong} alt="" />
                        </span>
                      </li>
                      <li className="test_list_web">
                        <h4>Minimum Bandwidth</h4>{' '}
                        <span>
                          <img
                            src={isNetSpeedGood ? Right : Wrong}
                            alt=""
                          />
                        </span>
                      </li>
                      <li className="test_list_web">
                        <h4>Accessible Mic and Camera</h4>
                        <span>
                          <img src={isMicGood ? Right : Wrong} alt="" />
                        </span>
                      </li>
                      <li className="test_list_web">
                        <div className="w-[90%]">
                          <h4>Operating System</h4>
                          <p>{systemOS}</p>
                        </div>
                        <span>
                          <img src={Right} alt="" />
                        </span>
                      </li>
                    </ul>

                    {isDisabled() === false ? (
                      <>
                        <div className="flex justify-center mt-12">
                          <h1 className="ms:text-[24px] text-[20px] font-semibold text=[#000000]">
                            This system is good to go!
                          </h1>
                        </div>
                        {!isWebStart &&
                          (isDisabled() === false ? (
                            <div className="checkbox_div mt-11 tab:hidden flex justify-center">
                              <button
                                className={
                                  isDisabled()
                                    ? 'web_screen_button_disabled rounded-full ms:w-64 w-full'
                                    : 'web_screen_button rounded-full bg-[#11B2AB] web_screen_button_new ms:w-64 w-full'
                                }
                                style={{ float: 'right' }}
                                onClick={() => startWebInterview()}
                              >
                                Start Interview
                              </button>
                            </div>
                          ) : null)}
                      </>
                    ) : (
                      <div className="p-5 pt-5 w-full flex items-center justify-center mt-11">
                        <button
                          className="rounded-3xl border border-[#000] py-3 px-8 tab:w-[40%] w-full"
                          onClick={async () => await checkRequirements()}
                        >
                          Check Again
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* {isWebStart && (
                <div className=" flex-col items-center justify-center  mt-[10px] tab:hidden flex tab:w-[40%] w-[80%]">
                  <h1 className="test_count text-center text-[40px] font-bold text-[#000000]">
                    You Look Smart & <br />
                    Confident!
                  </h1>
                  <h4 className="test_count text-[22px] font-semibold text-[#000000] my-5">
                    Your Interview Will Start In
                  </h4>
                  <div class="bg-cover w-[200px] h-[120px] flex items-center justify-center">
                    <h1 className="text-[50px] text-[#04D0C7] font-sans ont-bold">
                      <span>{startCount ? startCount : ''}</span>
                    </h1>
                  </div>
                </div>
              )} */}

              <div className="bg-transparent ai_progress relative flex items-center flex-col tab:w-[40%] w-[80%]">
                <div className="camera_preview absolute sm:h-[25rem] sm:w-[25rem] h-[20rem] w-[20rem]">
                  <Webcam
                    playsInline
                    ref={videoRef}
                    screenshotFormat="image/jpeg"
                    audioConstraints={{
                      advanced: [
                        { echoCancellation: true },
                        { noiseSuppression: true },
                      ],
                    }}
                    videoConstraints={{
                      advanced: [{ facingMode: { ideal: 'user' } }],
                    }}
                    className="m-auto sm:h-[25rem] sm:w-[25rem] h-[20rem] w-[20rem] rounded-full object-cover"
                  />
                </div>
                {!isWebStart && (
                  <Popover
                    content={content}
                    open={true}
                    className="progress_tooltip"
                    overlayInnerStyle={{
                      background: backgroundColor,
                      padding: '5px',
                    }}
                    overlayClassName={`progress_tooltip ${backgroundColor === '#E94A47' ? 'arrow-red' : 'arrow-green'}`}
                  >
                    <Progress
                      percent={faceThreshold}
                      status="active"
                      strokeColor={progressColorType}
                      showInfo
                      type="circle"
                      className="relative"
                      strokeWidth="2"
                      format={() => {}}
                    />
                  </Popover>
                )}

                {!isWebStart && (
                  <div className="mt-4 flex flex-col justify-center items-center gap-4">
                    <p className="text-base font-normal  tab-mt-0 mt-8">
                      Image Quality (Min. 30% needed)
                    </p>
                    <button
                      className={`py-2 px-6 rounded-full flex items-center gap-2 ${invalidCaptureConditions ? 'bg-[#BFBFBF]' : 'bg-[#11B2AB]'}`}
                      onClick={() => capture()}
                    >
                      <img src={Camera} />
                      <span className="text-[#fff]">Capture</span>
                    </button>
                  </div>
                )}
                {!isWebStart && (
                  <div className="mt-4 flex flex-col justify-center items-center gap-4 w-full absolute bottom-[160px]">
                    {multipleFacesDetected && (
                      <div className="p-2 text-base font-medium bg-[#FFDB96] rounded-full border border-[#795B23] px-3 py-1 text-[#795B23]">
                        Multiple Faces Detected
                      </div>
                    )}
                    {faces?.length < 1 && (
                      <div className="p-2 text-base font-medium bg-[#FFDB96] rounded-full border border-[#795B23] px-3 py-1 text-[#795B23]">
                        No Faces Detected
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              {!isWebStart &&
                (isDisabled() === false ? (
                  <div className="checkbox_div tab:flex hidden justify-center">
                    <button
                      className={
                        isDisabled()
                          ? 'web_screen_button_disabled rounded-full ms:w-64 w-full'
                          : 'web_screen_button rounded-full bg-[#11B2AB] web_screen_button_new ms:w-64 w-full'
                      }
                      style={{ float: 'right' }}
                      onClick={() => startWebInterview()}
                    >
                      Start Interview
                    </button>
                  </div>
                ) : null)}
            </div>
          </div>
        )}
      </>
    );
  };

  // return <>{isMobileDevice ? buildMobileLayout() : buildWebLayout()}</>;
  return <>{buildWebLayout()}</>;
};
export default AITestScreen;
