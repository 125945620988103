import { Button as AntdButton } from 'antd';
import './Button.scss';

const Button = ({
  className = '',
  children,
  type,
  isSecondary = false,
  isRemove = false,
  isCancel = false,
  ...others
}) => {
  const secondaryStyle = isSecondary
    ? 'text-secondaryMain border-secondaryMain'
    : '';
  const cancelStyle = isCancel ? 'text-cancel border-cancel' : '';
  const buttonType =
    isCancel || isRemove || isSecondary ? 'default' : type;
  return (
    <AntdButton
      type={buttonType}
      className={`customButton ${className} font-medium ${secondaryStyle} ${cancelStyle}`}
      danger={isRemove}
      {...others}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
