import { useMemo, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { Collapse, message, Divider } from 'antd';

import Accordion from '../CommonComponents/Accordion/Accordion';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import {
  useCreateInterviewSettings,
  useCreateReviewttings,
  useFetchAllTimezones,
  useCreateWorkingTime,
  useReviewSettings,
  useWorkingTime,
} from 'ServiceHooks/registrationHooks';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import CheckboxGroup from 'Components/CommonComponents/ReactHooKForm/CheckboxGroup/CheckboxGroup';
import TimeRangePicker from 'Components/CommonComponents/ReactHooKForm/TimePicker/TimePicker';

const { Panel } = Collapse;

const dateFormatOptions = [
  {
    label: 'D-M-Y',
    value: '%d-%m-%Y',
  },
  {
    label: 'M-D-Y',
    value: '%m-%d-%Y',
  },
  {
    label: 'Y-M-D',
    value: '%Y-%m-%d',
  },
];

const timeFormatOptions = [
  {
    label: '12 Hours',
    value: '%I:%M %p',
  },
  {
    label: '24 Hours',
    value: '%I:%M',
  },
];

const CompanyWorkingTime = (props) => {
  const [selectedTimes, setSelectedTimes] = useState();
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      is_fulltime_working: false,
    },
  });
  const workType = watch('workType');
  const workingTime = watch('is_fulltime_working');
  const selectedTime = watch('selectedTime');

  const options = [
    { label: 'S', value: 'SUN' },
    { label: 'M', value: 'MON' },
    { label: 'T', value: 'TUE' },
    { label: 'W', value: 'WED' },
    { label: 'T', value: 'THU' },
    { label: 'F', value: 'FRI' },
    { label: 'S', value: 'SAT' },
  ];
  const generateTimeRange = (startTime, endTime) => {
    const values = {
      working_time_starts_from: {
        hour: startTime.hour(),
        minute: startTime.minute(),
        second: startTime.second(),
      },
      working_time_ends_to: {
        hour: endTime.hour(),
        minute: endTime.minute(),
        second: endTime.second(),
      },
    };
    setSelectedTimes(values);
  };

  const { data: timezonesList } = useFetchAllTimezones({});

  const timezoneOptions = useMemo(() => {
    if (isEmpty(timezonesList)) return [];

    const options = timezonesList.map((name) => ({
      label: name,
      value: name,
    }));

    return options;
  }, []);

  const { data: workingTimeData, refetch: isDataRefectch } =
    useWorkingTime({
      paramsData: {
        companyId: props.companyId,
      },
    });

  useEffect(() => {
    if (isEmpty(workingTimeData)) return;
    reset(workingTimeData);
  }, [workingTimeData]);

  const { mutateAsync: createWorkingTime } = useCreateWorkingTime({
    onSuccess: async (data, variables, context) => {
      toast.success('Successfully Registered..!!');
      await isDataRefectch();
    },
    onError: async (error) => {
      // console.error('Creation Error:', error);
      // const message = error?.response?.data?.error_info;
      // await Object.entries(message)?.forEach(([key, message]) => {
      //     toast.error(message || DEFAULT_ERROR_MESSAGE);
      //     //   messageApi.open({
      //     //     type: 'error',
      //     //     content: message ?? DEFAULT_ERROR_MESSAGE,
      //     //   });
      // })
    },
  });
  const onSubmit = async (values) => {
    const {
      first_week,
      second_week,
      third_week,
      fourth_week,
      fifth_week,
      company_timezone,
      company_date_format,
      company_time_format,
    } = values;
    const data = {
      first_week,
      second_week,
      third_week,
      fourth_week,
      fifth_week,
      company_timezone: company_timezone?.value.toUpperCase(),
      company_date_format: company_date_format?.value,
      company_time_format: company_time_format?.value,
    };
    createWorkingTime({ data, companyId: props.companyId });
  };
  return (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            className="bg-white rounded-lg"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="mt-4">
                  {!isActive ? (
                    <img src={iconAccordion} alt="accordion" />
                  ) : (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  )}
                </div>
              );
            }}
          >
            {() => {
              return (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <p className="mt-8 px-6  text-medium font-medium">
                        Company Working Time
                      </p>
                      <div className="[&>*]:m-0 mt-8 px-6 ">
                        <Switch
                          name="workType"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                  }
                  key={workType ? '1' : '0'}
                  //style={panelStyle}
                  className=""
                  // extra={genExtra()}
                >
                  <div className="px-6 ">
                    <div className="">
                      <CheckboxGroup
                        name="first_week"
                        label="1st Week"
                        control={control}
                        className="w-full"
                        options={options}
                        disabled={workingTime === true}
                      />
                    </div>
                    <div className="">
                      <CheckboxGroup
                        name="second_week"
                        label="2nd Week"
                        control={control}
                        className="w-full"
                        options={options}
                        disabled={workingTime === true}
                      />
                    </div>
                    <div className="">
                      <CheckboxGroup
                        name="third_week"
                        label="3rd Week"
                        control={control}
                        className="w-full"
                        options={options}
                        disabled={workingTime === true}
                      />
                    </div>
                    <div className="">
                      <CheckboxGroup
                        name="fourth_week"
                        label="4th Week"
                        control={control}
                        className="w-full"
                        options={options}
                        disabled={workingTime === true}
                      />
                    </div>
                    <div className="">
                      <CheckboxGroup
                        name="fifth_week"
                        label="5th Week"
                        control={control}
                        className="w-full"
                        options={options}
                        disabled={workingTime === true}
                      />
                    </div>
                    <div className="mt-8 mb-8  h-[1px] w-full bg-[#E1E1E1]"></div>

                    <div className="w-full flex space-x-6">
                      <div className="w-1/2">
                        <TimeRangePicker
                          label="Working Time Starts From and Ends to"
                          control={control}
                          name="selectedTime"
                          onChange={(e, onChange) => {
                            onChange(e);

                            if (e) {
                              const startTime = e[0];
                              const endTime = e[1];
                              generateTimeRange(startTime, endTime);
                            }
                          }}
                          disabled={workingTime === true}
                        />
                      </div>
                    </div>
                    <div className="mt-8 mb-6 flex justify-center">
                      <div className="h-[1px] w-1/4 mt-3 bg-[#E1E1E1]"></div>
                      <p className="mt-0 px-2 font-semibold text-base">
                        Or
                      </p>
                      <div className="h-[1px] w-1/4 mt-3 bg-[#E1E1E1]"></div>
                    </div>
                    <div className="w-full">
                      <Checkbox
                        showUpperLabel={false}
                        disabled={selectedTime}
                        name="is_fulltime_working"
                        label="24 x 7 Hours"
                        control={control}
                      />
                    </div>
                    <Divider />
                    <div className="w-1/2">
                      <Select
                        allowClear
                        showSearch
                        control={control}
                        label={'Time Zone'}
                        name="company_timezone"
                        options={timezoneOptions}
                        filterOption={true}
                        filterSort={(first, second) => {
                          const outcome = (first?.label ?? '')
                            .toLowerCase()
                            .localeCompare(
                              (second?.label ?? '').toLowerCase(),
                            );

                          return outcome;
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <Select
                        allowClear
                        control={control}
                        label={'Date Format'}
                        name="company_date_format"
                        options={dateFormatOptions}
                      />
                    </div>
                    <div className="w-1/2">
                      <Select
                        allowClear
                        control={control}
                        label={'Time Format'}
                        name="company_time_format"
                        options={timeFormatOptions}
                      />
                    </div>
                    <div className="flex justify-end mt-10 mb-6">
                      <Button
                        className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                        htmlType="submit"
                        type="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
        </form>
      </Card>
    </div>
  );
};
export default CompanyWorkingTime;
