import Button from 'Components/CommonComponents/Button/Button';
import { Image, Modal, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToggle } from 'react-use';
import CandidateProjectsImg from 'Assets/images/candidateProjects.png';
import ResumeFormProjectModal from './ResumeFormProjectModal';
import ViewParsedText from 'Components/CommonComponents/ViewParsedText';
import { useDeleteCandidateProject } from 'ServiceHooks/candidatesHooks';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import RequiredFieldProjectModal from './RequiredFieldProjectModal';

const RequiredFormProjects = (props) => {
  const { projects, candidateId, setEnable } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [isAddProjectModalOpen, toggleAddProjectModal] =
    useToggle(false);
  const [currentEditingProject, setCurrentEditingProject] =
    useState();
    const [editIndex, setEditIndex] = useState(null);
  
  useEffect(()=> {
    if(!isAddProjectModalOpen){
      setCurrentEditingProject({})
      setEditIndex(null)
    }
  },[isAddProjectModalOpen])

  const { mutate: deleteProject } = useDeleteCandidateProject({
    onSuccess: async () => {
        await messageApi.open({
          type: 'success',
          content: "Project Removed successfully",
        })},
    onError: async (error) => {
      await messageApi.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
      console.error('Project Error: ', error);
    },
  });
  

  return (
    <div>
      <div className="border rounded-md p-spacing3 w-2/3">
        <RequiredFieldProjectModal
            isEditForm={!isEmpty(currentEditingProject)}
            close={toggleAddProjectModal}
            projects={projects}
            details={currentEditingProject}
            editIndex={editIndex}
            setEnable={setEnable}
            candidateId={candidateId}
          />
          {/* <div className="flex flex-row justify-between items-center">
            <div className="text-lg font-medium">Projects</div>
            <Button isSecondary onClick={toggleAddProjectModal}>
              Add Project
            </Button>
          </div>
          <section className="projectsSection max-h-[30rem] overflow-x-hidden overflow-y-auto customScrollBar my-4">
            {!isEmpty(projects) ? (
              <div className="flex flex-wrap -mx-2">
                {projects?.map((projectItem, index) => {
                  return (
                    <div
                      className="w-full px-2 mb-spacing4 flex flex-row justify-between"
                      key={`project-${index}`}
                    >
                      <div className="flex flex-row items-baseline gap-4">
                        <div className="rounded-full bg-primaryMain h-2 w-2"></div>
                        <div>
                          <p className="text=[#535353] text-sm">
                            {projectItem?.project}
                          </p>
                          <p className="text=[#535353] text-sm">
                            <ViewParsedText
                              content={projectItem?.description}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row gap-4">
                      <FiEdit
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={() => {
                          setCurrentEditingProject(projectItem);
                          toggleAddProjectModal();
                          setEditIndex(index)
                        }}
                      />
                      <FiTrash
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={() =>
                            deleteProject({
                              data: {
                                candidate: candidateId,
                              },
                              projectId: projectItem.id,
                            })
                          }
                      />
                      {contextHolder}
                    </div>
                    </div>
                  );
                })}
              </div>
            ) : (
                <div className="flex flex-col justify-center items-center">
                <Image
                  height={128}
                  preview={false}
                  alt="no-experience"
                  src={CandidateProjectsImg}
                />
                <div className="text=[#656565] text-lg font-light capitalize pb-28">
                  Not any project Added yet!
                </div>
              </div>
            )}
          </section> */}
        </div>
      {/* -----------------------  Modal for Add Experience starts ----------------------- */}
      {isAddProjectModalOpen && (
        <Modal
          className="addExperienceModal"
          width={700}
          open={isAddProjectModalOpen}
          title={'Add Projects'}
          onCancel={toggleAddProjectModal}
          footer={null}
          destroyOnClose
        >
          <RequiredFieldProjectModal
            isEditForm={!isEmpty(currentEditingProject)}
            close={toggleAddProjectModal}
            projects={projects}
            details={currentEditingProject}
            editIndex={editIndex}
            setEnable={setEnable}
            candidateId={candidateId}
          />
        </Modal>
      )}
    </div>
  );
};

export default RequiredFormProjects;
