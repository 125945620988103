import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ReadyState } from 'react-use-websocket';
import { immer } from 'zustand/middleware/immer';
import { column_prefereces } from 'Components/CommonComponents/AntdTable/constants';
import authService from 'Services/authService';
import { devtools } from 'zustand/middleware';

const sortFn = (first, second) => {
  if (first.read === second.read) {
    return 0;
  } else if (first.read === false) {
    return -1; // "first" comes before "second"
  } else {
    return 1; // "first" comes after "second"
  }
};

export const useLayoutStore = create(
  persist(
    devtools(
      immer((set, get) => {
        return {
          connection: false,
          notifications: [],
          connectionState: ReadyState.UNINSTANTIATED,
          toggleNotification: (response) =>
            set(
              (state) => {
                state.connection = response;
              },
              false,
              'notification/toggle',
            ),
          addNotification: (item) =>
            set(
              (state) => {
                state.notifications.unshift(item);
              },
              false,
              'notification/add',
            ),
          clearNotifications: (response) =>
            set(
              (state) => {
                state.notifications = [];
              },
              false,
              'notification/clear-all',
            ),
          clearReadNotifications: (response) =>
            set(
              (state) => {
                const allNotes = state.notifications;
                const unreadNotes = allNotes.filter(
                  (item) => item.read === false,
                );
                state.notifications = unreadNotes;
              },
              false,
              'notification/clear-read',
            ),
          setConnectionState: (type) =>
            set(
              (state) => {
                state.connectionState = type;
              },
              false,
              'notification/changing-state',
            ),
          markNoteAsRead: (index) =>
            set(
              (state) => {
                state.notifications[index].read = true;

                state.notifications.sort(sortFn);
              },
              false,
              'notification/mark-as-read',
            ),
          markAllAsRead: (index) =>
            set(
              (state) => {
                state.notifications.forEach((note) => {
                  note.read = true;
                });
              },
              false,
              'notification/mark-all-read',
            ),
          column_preferece: column_prefereces,
          setGridColumnPreference: async (value) => {
            try {
              await authService.updateUserPreference(value);
              set(
                (state) => {
                  state.column_preferece = value;
                },
                false,
                'grid/columns',
              );
            } catch (error) {
              console.error('Error updating column preference:', error);
            }
          },
          resetGridColumPreference: () =>
            set(
              (state) => {
                state.column_preferece = column_prefereces;
              },
              false,
              'grid/columns-reset',
            ),
          dashboard_preference: {},
          setDashboardPreference: async (value) => {
            set(
              (state) => {
                state.dashboard_preference = value;
              },
              false,
              'dashboard/permission',
            );
          },
        };
      }),
    ),
    {
      name: 'web-push', // name of the item in the storage (must be unique)
    },
  ),
);
