import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
const { Search } = Input;

const SearchInput = ({setSearch, search=""}) => {
  return (
    <Space.Compact size="middle">
      <Input addonBefore={<SearchOutlined />} placeholder="Search..." onChange={(e)=> setSearch(e.target.value)} value={search} />
    </Space.Compact>
  );
};

export default SearchInput;
