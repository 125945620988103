import axios from "axios";
import Config from "./Config";

const getQuestions = (nInterviewId, isGetSkipQuestion) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/ai-interview-question-answer/${nInterviewId}/?show_skip_question=${isGetSkipQuestion}`
  );
};

const submitAnswer = (nInterviewId, data) => {
  var submitAnswerdata = JSON.stringify(data);
  return axios.post(
    Config.API_URL +
      `api/v1/interview/ai-interview-question-answer/${nInterviewId}/`,
    submitAnswerdata,
    {
      headers: {
        "Content-Type": "application/json",
        // "X-CSRFToken": "{{csrf_token}}",
      },
    }
  );
};

const submitQuestion = (nInterviewId, data) => {
    var submitAnswerdata = JSON.stringify(data);
    return axios.patch(
      Config.API_URL +
        `api/v1/interview/ai-interview-question-answer/${nInterviewId}/`,
      submitAnswerdata,
      {
        headers: {
          "Content-Type": "application/json",
          // "X-CSRFToken": "{{csrf_token}}",
        },
      }
    );
  };

  const complete = (nInterviewId) => {
    return axios.put(
      Config.API_URL +
        `api/v1/interview/ai-interview-question-answer/${nInterviewId}/?is_finished=true`,
    );
  };

  const submitAllInterview = (nInterviewId) => {
    return axios.get(
      Config.API_URL +
        `api/v1/interview/ai-interview-details/${nInterviewId}/`,
    );
  };

  const sendTabActive = (nInterviewId, submitdata)=>
  {
    return axios.post(
      Config.API_URL +
        `api/v1/interview/ai-interview-user-infractions/${nInterviewId}/`,
      submitdata,
      {
        headers: {
          "Content-Type": "application/json",
          // "X-CSRFToken": "{{csrf_token}}",
        },
      }
    );
  }

  const getInterviewInfo = (nInterviewId) => {
    return axios.get(
      Config.API_URL +
        `api/v1/interview/ai-interview-details/${nInterviewId}/`,
    );
  };
  
  
  

const InhouseTestService = {
  getQuestions,
  submitAnswer,
  submitQuestion,
  complete,
  submitAllInterview,
  sendTabActive,
  getInterviewInfo
};
export default InhouseTestService;
