import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import LoginDialogHeader from '../../Components/CommonComponents/LoginDialogHeader';
import routes from 'Routes';

import authService from '../../Services/authService';
import Progress from '../../Components/CommonComponents/Progress';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isprogress, setProgress] = useState(false);

  const onSubmit = async (data) => {
    setProgress(true);
    data['company_domain'] = 'http://ndz.yvi.com/';

    await authService
      .forgotPasswordUrl(data)
      .then(
        (oResponse) => {
          setProgress(false);
          toast.success(oResponse.data.data.message);
          navigate(routes.LOGIN);
        },
        (error) => {
          const errorMessage =
            error.response.data.error_info.non_field_errors;
          toast.error(errorMessage);
          setProgress(false);
        },
      )
      .catch((err) => {
        console.log(err);
        setProgress(false);
      });
  };

  const cancel = () => {
    navigate(routes.LOGIN);
  };

  return (
    <LoginDialogHeader>
      <div className="login">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Email"
            {...register('email_or_mobile_number', {
              required: 'Please Enter Your Email',
            })}
          ></input>
          {errors && errors.email_or_mobile_number && (
            <span className="required">
              {errors.email_or_mobile_number.message}
            </span>
          )}
          <div className="flex">
            <button onClick={cancel}>Cancel</button>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      {isprogress && <Progress />}
    </LoginDialogHeader>
  );
};
export default ForgotPassword;
