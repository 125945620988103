import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Avatar, Spin } from 'antd';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';
import { MdFace6 } from 'react-icons/md';

import 'Styles/commonStyles.scss';
import texture from 'Assets/images/whatsappBackground.png';
import { useGetWhatsAppPreview } from 'ServiceHooks/communicationHooks';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Empty from 'Components/CommonComponents/Empty/Empty';

function replacePlaceholders(template, data) {
  return template.replace(/{{(\w+)}}/g, (_, key) => data[key] || '');
}

export default function PreviewWhatsAppTemplate(props) {
  const { file, templateName } = props;

  let imageUrl = '';
  if (!isEmpty(file)) {
    imageUrl = URL.createObjectURL(file[0]);
  }

  const { control, watch } = useForm({ mode: 'all' });
  const currentFields = watch();

  const { data: template, isLoading: isLoadingTemplates } =
    useGetWhatsAppPreview({
      params: { template_name: templateName },
      enabled: Boolean(templateName),
    });
  const initialTemplate = template?.[0]?.body;
  const processedMessage = initialTemplate
    ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, '<strong>$1</strong>')
    ?.replace(/\n/g, '<br>');
  const safeMessage = DOMPurify.sanitize(processedMessage);

  const [message, setMessage] = useState(safeMessage);

  const formNames = useMemo(() => {
    if (!Boolean(template?.[0]?.params)) return [];
    const allNames = template?.[0]?.params
      ?.map((item) => item.paramName)
      ?.filter((name) => !['wati_file'].includes(name));
    const uniqueNames = [...new Set(allNames)]; //remove duplications

    return uniqueNames;
  }, [template]);

  useEffect(() => {
    const initial = replacePlaceholders(safeMessage, currentFields);
    setMessage(initial);
  }, [initialTemplate, JSON.stringify(currentFields)]);

  const isEmptyTemplate = isEmpty(initialTemplate);

  return (
    <>
      {isLoadingTemplates ? (
        <div className="w-full h-40 flex items-center justify-center">
          <Spin />
        </div>
      ) : isEmptyTemplate ? (
        <>
          <Empty description={'No Templates Found'} />
        </>
      ) : (
        <form className="flex justify-between items-center">
          <div className="w-1/3 mr-4 flex flex-col space-y-4 max-h-96 overflow-y-auto customScrollBar">
            {formNames?.map((name) => (
              <Input
                name={name}
                control={control}
                className={'w-3/4'}
                placeholder={`Enter ${name.replace('_', ' ')}`}
              />
            ))}
          </div>
          <div className="w-2/3">
            <div className="bg-[#074E46] flex justify-start items-center h-20 py-4 px-6 space-x-4 rounded-t-lg">
              <Avatar
                size={'large'}
                shape={'circle'}
                icon={<MdFace6 size={'2.5rem'} />}
                className="flex justify-center items-center"
              />
              <div className="text-white font-medium text-lg">User</div>
            </div>
            <div
              className="rounded-b-lg pl-16 pr-12 py-4 flex flex-col-reverse max-h-[35rem] scrollbar-hide overflow-y-auto"
              style={{
                backgroundImage: `url(${texture})`,
              }}
            >
              <div className="flex flex-row items-end rounded-lg">
                <div className="mt-8 p-1 pl-2 bg-white overflow-y-auto rounded-lg w-3/5">
                  {!isEmpty(file) && (
                    <img src={imageUrl} className="rounded-3xl p-1 pl-2" onContextMenu={(e) => e.preventDefault()} />
                  )}
                  <div dangerouslySetInnerHTML={{ __html: message }} />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
