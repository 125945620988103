import { useState, useEffect, useRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import TextEdit from '../CommonComponents/TextEdit';
import { MdContentCopy } from 'react-icons/md';
import CommunicationService from '../../Services/communicationService';
import { useGetOfferTransactionTypes } from 'ServiceHooks/communicationHooks';
import toast from 'react-hot-toast';
import { Switch, Input } from 'antd';
import { isEmpty } from 'lodash';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';

var updatedText;
const OfferLetter = () => {
  const [getTransactionId, setGetTransactionId] = useState('');
  const [activeForm, setActiveForm] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [details, setDetails] = useState();
  const [htmlContent, setHtmlContent] = useState();
  const [subjectName, setSubjectName] = useState();
  const [signatureDetails, setSignatureDetails] = useState();

  const inputRef = useRef();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content_html: '',
      email_subject: '',
      email_signature: '',
    },
  });

  const { data: letterTransactionTypes } = useGetOfferTransactionTypes();

  const transactionOptions = useMemo(() => {
    if (isEmpty(transactionData?.results)) return [];
    const options = transactionData?.results?.map((item) => {
      return {
        label: item.transaction,
        value: item.id,
      };
    });
    return options;
  }, [transactionData]);

  const transactionInfo = async () => {
    try {
      const response =
        await CommunicationService.getOfferLetterTransaction();
      setTransactionData(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    transactionInfo();
  }, []);
  const getTransactionDetails = async (id) => {
    try {
      const response =
        await CommunicationService.getOfferLetterTransactionById(id);
      setDetails(response?.data?.data);
      setSubjectName(response?.data?.data?.email_subject);
      setHtmlContent(response?.data?.data?.content_html);
      setSignatureDetails(response?.data?.data?.email_signature);
    } catch (error) {
      console.log(error);
    }
  };
  const handleNameCopy = (item) => {
    updatedText = `{${item}}`;
    navigator.clipboard.writeText(updatedText);

    toast.success('Copied');
  };

  const handleReset = async () => {
    try {
      const response =
        await CommunicationService.getRestTransactionById(
          getTransactionId,
        );
      setDetails(response.data.data);
      setSubjectName(response?.data?.data?.email_subject);
      setHtmlContent(response?.data?.data?.content_html);
    } catch (err) {
      console.log(err);
    }
  };

  const selectTransactionHandler = (field) => {
    const { value } = field;
    setGetTransactionId(value);

    if (value !== '') {
      setActiveForm(true);
    }
    getTransactionDetails(value);
  };
  const handleSwitch = async (e) => {
    setDetails((prev) => {
      return {
        ...prev,
        is_active: e,
      };
    });
    await CommunicationService.activeOfferLetterTranscation(
      getTransactionId,
      e,
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  console.log('detailsactive', details?.is_active);
  const onSubmit = async (data, e) => {
    try {
      const response = await CommunicationService.updateOfferTransaction(
        data,
        getTransactionId,
        details?.id,
        subjectName,
        htmlContent,
        signatureDetails,
      );
      toast.success('Successfully Created');
      e.target.reset();
      inputRef.current.resetSelectedValues();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="w-full">
        <div className="bg-secondaryBgMain rounded-lg my-4 pb-8">
          <div className="ml-8">
            <form
              className="overflow-y-auto max-h-[63vh]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="md:flex md:flex-row flex-col flex-wrap">
                <div className="md:w-1/2 px-6 mt-10 rounded-md border border-gray-300">
                  <div className="w-full mt-8">
                    <div className="mt-4 w-full">
                      <Select
                        label={'Transaction'}
                        name={'transOption'}
                        placeholder="Select an Option"
                        options={transactionOptions}
                        control={control}
                        onChange={(e) => selectTransactionHandler(e)}
                      />
                    </div>
                    <div className="mt-8 w-full">
                      <label htmlFor="email_subject">Subject</label>
                      <Input
                        id={'email_subject'}
                        value={subjectName}
                        defaultValue={subjectName}
                        onChange={(text) =>
                          setSubjectName(text.target.value)
                        }
                      />
                    </div>
                    <div className="w-full mt-8">
                      <label
                        htmlFor="letterContent"
                        className="text-black text-sm font-normal pr-2 pb-2 flex"
                      >
                        Content
                      </label>
                      <TextEdit
                        id="letterContent"
                        required
                        onChange={(text) => setHtmlContent(text)}
                        initialValue={htmlContent ?? ''}
                      />
                    </div>
                    {details && (
                      <div className="flex justify-end mb-4">
                        <Switch
                          checked={details?.is_active}
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          onChange={(e) => handleSwitch(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:w-1/2 px-8 border-black">
                  {activeForm &&
                  !isEmpty(letterTransactionTypes?.results) ? (
                    <div className="w-full h-fit p-2 mt-10 rounded-md border-dashed border-gray-300 border-2">
                      <div className="pl-4 w-full flex-col flex mt-6">
                        {letterTransactionTypes?.results?.map(
                          (item, index) => (
                            <div key={`letter-trans-${index}`}>
                              {/* {index === 0 && (
                                  <div className="mb-4">
                                    <Switch
                                      // checked={details?.parameters[index].is_active}
                                      checkedChildren="Active"
                                      unCheckedChildren="Inactive"
                                      //  className="text-gray-700"
                                      onChange={(e) => handleSwitch(e)}
                                    />
                                  </div>
                                )} */}
                              <div className="flex">
                                <div className="text-sm w-full px-4 py-2 mb-8 flex bg-white focus:outline-none rounded-md border border-blue-500">
                                  {item.value}
                                </div>
                                <button
                                  type="button"
                                  className="text-primaryMain flex flex-wrap"
                                  title="copy"
                                  onClick={() =>
                                    handleNameCopy(item.value)
                                  }
                                >
                                  <span className="mt-[6px] ml-[5px]">
                                    <MdContentCopy className="text-primaryMain" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <Empty
                        description={
                          <>{<p> No Selected Transaction</p>}</>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold mr-10 ">
                <div className="flex items-center justify-between">
                  <Button
                    isSecondary
                    className="ml-6"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    type={'primary'}
                    htmlType="submit"
                    className="ml-4"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferLetter;

// Signature Textbox
// const onChangeSignature = (e) => {
//   setSignatureDetails(e);
// };
{
  /* <div className="w-full mt-8 pb-10">
<label className="text-black text-sm font-normal pr-2 pb-2 flex">
  Signature
</label>
<TextEdit
  id="description"
  required
  onChange={onChangeSignature}
  initialValue={details?.email_signature ? details?.email_signature : ''}
/>
</div> */
}
