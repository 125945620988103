import React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useParams } from 'react-router-dom';

const JitsiMeet = () => {
    let { roomName,displayName } = useParams();
    
  return (
    <div style={{ height: '100vh', width: '100%' }} className='jitsi-meeting'>
      <JitsiMeeting
        domain="yvivideoconference.yvi.ai/"
        roomName={roomName}
        configOverwrite={{
          // Configuration options
        }}
        interfaceConfigOverwrite={{
          // Interface configuration options
        }}
        userInfo={{
          displayName: displayName,
        }}
        onApiReady={(externalApi) => {
          // Handle the Jitsi Meet API instance
          console.log('Jitsi Meet API is ready', externalApi);
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = '100%';
          iframeRef.style.width = '100%';
        }}
      />
    </div>
  );
};

export default JitsiMeet;