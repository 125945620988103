import 'Styles/VideoInterview.scss';
import InterviewDetails from './InterviewDetails';

const Interview = () => {
  return (
    <div className="VideoInterview">
      <InterviewDetails />
    </div>
  );
  // }
};

export default Interview;
