import { Select as AntSelect, Divider, Form, Spin } from "antd";
import { useController } from "react-hook-form";
import "../SignleSearch/SearchSelect.scss";
import { useRef, useState } from "react";
import Button from "Components/CommonComponents/Button/Button";

function AddMultiSearch({
  onSearch,
  options,
  fetching,
  mode = null,
  // hook form specific
  onChange: customOnChange = null,
  name,
  control,
  defaultValue,
  rules = {},
  label,
  searchURL = null,
  useDefaultTheme = true,
  onSelectValue,
  renderItem,
  sendAddedData,
  ...props
}) {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const errorText = error?.message;
  const status = errorText ? "error" : "";

  const inputRef = useRef();
  const [searchValueNotPresent, setSearchValueNotPresent] = useState();

  const addItem = (url, name) => {
    sendAddedData(searchValueNotPresent, url, name);
    // inputRef.current.value = "";
    setSearchValueNotPresent("")
  };

  const handelInputChange = (e) =>
  {
     console.log(e);

  }

  return (
    <>
      <div className="rfhSelect">
        <Form.Item
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          help={errorText}
          validateStatus={status}
        >
          <AntSelect
            prefixIcon={<div>test</div>}
            ref={ref}
            value={value}
            onSelect={(e, value) => {
              onSelectValue(e, value);
            }}
            onDeselect={(e) => {
              console.log("deselect", e);
            }}
            onInputKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("enter");
              }
            }}
            onChange={(e) => {
              console.log("multi", e);
              if (customOnChange) {
                customOnChange(e, onChange);
              } else {
                onChange(e);
              }
            }}
            defaultActiveFirstOption={true}
            autoClearSearchValue={true}
            mode="multiple"
            labelInValue
            onSearch={(e) => {
              console.log("options", options);
              onSearch(e, searchURL, name);
              console.log(e,  "serachvalue");
              console.log("inputRef", inputRef);
              setSearchValueNotPresent(e)
              if (options?.length === 0) {
                // inputRef.current.value = e;
                
              }
            }}
            maxTagCount="responsive"
            notFoundContent={
              fetching ? (
                <div className="flex justify-center py-5">
                  <Spin size="small" />
                </div>
              ) : (
                <>
                  {options?.length === 0 ? (
                    <>
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <div className="addItemSpan">
                        <input value={searchValueNotPresent}/>
                        <Button
                          type="primary"
                          style={{ flexGrow: "1" }}
                          className="p-1"
                          onClick={() => addItem(searchURL, name)}
                        >
                          +Add
                        </Button>
                      </div>
                    </>
                  ) : (<>
                  </>)}
                </>
              )
            }
            options={options}
            status={status}
            {...props}
            defaultValue={defaultValue}
            showSearch
            // dropdownRender={(menu) => (
            //   <>
            //     {menu}
            //     <Divider
            //       style={{
            //         margin: "8px 0",
            //       }}
            //     />
            //     <div className="addItemSpan">
            //       <input
            //         placeholder=""
            //         ref={inputRef}
            //       />
            //       <Button
            //         type="primary"
            //         style={{ flexGrow: "1" }}
            //         className="p-1"
            //         onClick={() => addItem(searchURL, name)}
            //       >
            //         +Add
            //       </Button>
            //     </div>
            //   </>
            // )}
            onClear = {()=>
            {
              setSearchValueNotPresent("")
            }}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default AddMultiSearch;
