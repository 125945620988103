import { DatePicker as AntDatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import {  useController } from 'react-hook-form';

const DatePicker = ({
  label,
  onChange : customOnChange = null,
  showTime = false,
  showToday = false,
  noTextInput = false,
  allowClear = true,
  className = '',
  disablePreviousDays = false,
  disableFutureDays = false,
  // hook form specific
  name, 
  control, 
  defaultValue, 
  rules = {},
  ...others
}) => {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState : { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <Form.Item 
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntDatePicker
        showTime={showTime}
        showToday={showToday}
        allowClear={allowClear}
        inputReadOnly={noTextInput}
        onBlur={onBlur}
        onChange={(e, dateStrings) => {
          if(customOnChange)
          {
            customOnChange(onChange, e);
          }
          else
          {
            onChange(e);
          }
        }}
        ref={ref}
        value={value}
        className={`w-full ${className}`}
        disabledDate={(current) => {
          if(disablePreviousDays)
          {
            return current && current < dayjs(new Date()).startOf('day');
          } else if(disableFutureDays) {
            return current && current > dayjs(new Date()).startOf('day');
          }
          return false;
        }}
        {...others}
      />
    </Form.Item>
    
  );
};

export default DatePicker;