import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

import 'Styles/commonStyles.scss';
import Button from 'Components/CommonComponents/Button/Button';
import EditCandidate from 'Components/Candidate/EditCandidate';

export default function CreateCandidate(props) {
  const navigate = useNavigate();

  return(
    <div className="createCandidate">
      <Button
        type="ghost"
        className="flex items-center mb-4 cursor-pointer font-bold px-0"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BiArrowBack /> <span className="ml-2">Back</span>
      </Button>
      <h1 className="mb-4 text-heading1">
        Create New Candidate
      </h1>
      <div className="div mt-5 border p-spacing3 rounded-md bg-secondaryBgMain overflow-y-auto customScrollBar max-h-[calc(90vh-8rem)]">
        <EditCandidate
          manualUpload={true}
          // onClose={()=>onCloseModel()} //TODO: figure out refetching after 
        />
      </div>
    </div>
  );
}