import { Select as AntSelect, Form, Spin } from 'antd';
import { useController } from 'react-hook-form';
import '../SignleSearch/SearchSelect.scss';

function MultiSearchSelect({
  onSearch,
  options,
  fetching,
  mode = null,
  // hook form specific
  onChange: customOnChange = null,
  name,
  control,
  defaultValue,
  rules = {},
  label,
  searchURL = null,
  useDefaultTheme = true,
  onSelectValue,
  ...props
}) {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const errorText = error?.message;
  const status = errorText ? 'error' : '';

  return (
    <>
      <div className="rfhSelect capitalize">
        <Form.Item
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          help={errorText}
          validateStatus={status}
        >
          <AntSelect
            prefixIcon={<div>test</div>}
            ref={ref}
            value={value}
            onSelect={(e, value) => {
              onSelectValue(e, value);
            }}
            onDeselect={(e) => {
              console.log('deselect', e);
            }}
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                console.log('enter');
              }
            }}
            onChange={(e) => {
              console.log('multi', e);
              if (customOnChange) {
                customOnChange(e, onChange);
              } else {
                onChange(e);
              }
            }}
            defaultActiveFirstOption={true}
            autoClearSearchValue={true}
            mode="multiple"
            labelInValue
            onSearch={(e) => onSearch(e, searchURL, name)}
            maxTagCount="responsive"
            // filterOption={true}
            notFoundContent={
              fetching ? (
                <div className="flex justify-center py-5">
                  <Spin size="small" />
                </div>
              ) : null
            }
            options={options}
            status={status}
            {...props}
            defaultValue={defaultValue}
            showSearch
            // onMouseLeave={(e) => {
            //   console.log("ev", e);
            //   var spans = document.getElementsByClassName(
            //     "ant-select-selection-item"
            //   );
            //   console.log(spans);

            //     // var lastSpan = spans[spans.length - 1];
            //     // console.log(lastSpan);
            //     // // lastSpan.parentNode.removeChild(lastSpan);
            //     // // console.log(spans);

            // }}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default MultiSearchSelect;
