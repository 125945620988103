import { create } from 'zustand';
import { shared } from 'use-broadcast-ts';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';

export const useInterviewsStore = create(
  shared(
    immer((set, get) => {
      return {
        reviewMetadata: {},
        setReviewMetadata: (value) =>
          set(
            (state) => {
              state.reviewMetadata = value;
            },
            false,
            'interviews/set-review-metadata',
          ),
        clearReviewMetadata: () =>
          set(
            (state) => {
              state.reviewMetadata = {};
            },
            false,
            'interviews/clear-review-metadata',
          ),
      };
    }),
    {
      name: 'interviews-metadata', // broadcast channel name
    },
  ),
);
