import 'Styles/InterviewScreen.scss';
import LOGO from '../../Assets/images/interview/yviLogo.png';
import womenStudy from '../../Assets/images/interview/Woman_studying_online.png';
import { useState } from 'react';
import GuidlinesScreen from 'Components/InHouseTest/GuidlinesScreen';
import { useGetInterviewInformation } from 'ServiceHooks/interviewHooks';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const InterviewDetails = () => {
  const [userSettings] = useLocalStorage('user');
  const COMPANY_LOGO = userSettings?.company_logo? userSettings?.company_logo : LOGO;
  const [isGuidlineScreen, setGuildlineScreen] = useState(false);

  const params = useParams();

  const {
    data: interviewInfo,
    isFetching: isInterviewInfoFetching,
    status: interviewInfoStatus,
    error: interviewInfoError,
  } = useGetInterviewInformation({ paramsData: params?.token });

  const goToGuidlineScreen = () => {
    setGuildlineScreen(true);
  };

  const buildDetailScreen = () => {
    return (
      <div className="interviewDetailsMainDiv">
        <div className="interviewDetailsSubDivOne">
          <div>
            <img className="online_test_logo" src={COMPANY_LOGO} alt="logo" width={100} height='auto' />
            <h1 className="helloText">Hey, Hello</h1>
            <h1 className="welcomeText">Welcome to Online Interview</h1>
          </div>
        </div>
        <div className="centerDiv">
          <div className="flex justify-between items-center space-x-4 p-4">
            <div>
              <h1 className="text-black text-2xl font-normal">
                {interviewInfo?.title}
              </h1>

              <ul className="mt-3 text-black text-xl font-normal">
                <li>Job Code: {interviewInfo?.job_reference_number}</li>
                <li>Duration: {interviewInfo?.total_hours}</li>
              </ul>
            </div>
            <div className="h-auto w-52 m-auto">
              <img src={womenStudy} alt="woman" className="" />
            </div>
          </div>
        </div>
        <div className="interviewDetailsSubDivTwo">
          <div className="firstPage_btn_div">
            <button className="goToInterview" onClick={goToGuidlineScreen}>
              Go to Interview
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="interviewMainDiv">
      {isGuidlineScreen ? (
        <GuidlinesScreen interviewInfoData={interviewInfo} />
      ) : (
        buildDetailScreen()
      )}
    </div>
  );
};

export default InterviewDetails;
