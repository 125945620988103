import { useState, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { message, Upload, Modal } from 'antd';
import { FiUpload } from 'react-icons/fi';
import { useToggle } from 'react-use';
import { isEmpty } from 'lodash';

import {
  useUpdateWhatsAppTemplate,
  useCreateWhatsAppTemplate,
  useGetCommunicationTransactions,
  useGetCommunicationTransactionsTemplate,
} from 'ServiceHooks/communicationHooks';
import authHeader from 'Services/authHeader';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import Button from 'Components/CommonComponents/Button/Button';
import PreviewWhatsAppTemplate from './Modals/PreviewWhatsAppTemplate';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import axios from 'axios';

export default function WhatsAPP() {
  const [file, setFile] = useState([]);
  const [isPreviewModalOpen, togglePreviewModal] = useToggle(false);
  const {
    reset,
    control,
    setValue,
    setError,
    register,
    resetField,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      is_image: false,
    },
  });
  const is_image = useWatch({ control, name: 'is_image' });
  const transaction_id = useWatch({ control, name: 'transaction_id' });
  const isCreatingNewTemplate = useWatch({
    control,
    name: 'is_new_template',
  });
  const templateName = useWatch({ control, name: 'template' });

  const { data: transactions, isLoading: isLoadingTransactions } =
    useGetCommunicationTransactions({});

  const {
    data: whatsappTemplates,
    refetch: refetchTemplates,
    isLoading: isLoadingTemplates,
  } = useGetCommunicationTransactionsTemplate({
    params: {
      is_image,
      transaction_key: transaction_id?.value,
    },
    enabled: Boolean(transaction_id?.value),
  });

  const { mutateAsync: updateTemplate, isLoading: isUpdating } =
    useUpdateWhatsAppTemplate({});

  const { mutateAsync: addTemplate, isLoading: isCreating } =
    useCreateWhatsAppTemplate({});

  const transactionOptions = useMemo(() => {
    if (isEmpty(transactions?.results)) return [];
    const options = transactions?.results?.map((item) => {
      return {
        label: item.value,
        value: item.key,
      };
    });
    return options;
  }, [transactions]);

  const templateOptions = useMemo(() => {
    if (isEmpty(whatsappTemplates?.results)) return [];
    const options = whatsappTemplates?.results?.map((item) => {
      return {
        key: item.id,
        label: item.wati_template_name,
        value: item.wati_template_name,
        ...(Boolean(item.wati_file) && { file: item.wati_file }),
      };
    });
    return options;
  }, [whatsappTemplates]);

  const filterFile = (file) => {
    //PDF and DOCX might be needed later.
    //Bring in mime-types when API support comes.
    const types = ['.png', '.jpg', '.jpeg', 'image/png', 'image/jpeg'];

    if (types.includes(file.type)) {
      clearErrors('filename');
      setValue('filename', file?.name);
      //AntD's Upload needs arrays.
      setFile([file]);
    } else {
      setError('filename', {
        message: 'Unsupported File Format',
      });
    }

    //Stops AntD's automatic post request
    return false;
  };

  const onSubmit = async (data, e) => {
    try {
      const formData = new FormData();
      const values = {
        is_image: data?.is_image,
        transaction: data?.transaction_id?.value,
        wati_template_name: isCreatingNewTemplate
          ? data?.newTemplateName
          : data?.template?.value,
      };
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (is_image) {
        formData.append('wati_file', file[0]);
      }

      const variables = {
        params: {},
        body: formData,
        id: isCreatingNewTemplate
          ? data?.transaction_id?.value
          : data?.template?.key,
      };

      if (isCreatingNewTemplate) {
        await addTemplate(variables);
        await refetchTemplates();
        message.success('Created Successfully');
      } else {
        await updateTemplate(variables);
        message.success('Updated Successfully');
      }

      setFile([]);
      reset();
    } catch (error) {
      console.error('Template Submission Error: ', error);
      message.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  const isMutating = isCreating || isUpdating;
  const areFilesAvailable =
    !isCreatingNewTemplate && Boolean(templateName?.value);

  return (
    <>
      <div className="w-full">
        <div className="bg-secondaryBgMain rounded-lg md:w-full">
          <div className="mx-8 mt-4 pb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex md:flex-row flex-col flex-wrap">
                <div className="md:w-3/4 px-10 mt-10 rounded-md border border-gray-300 pb-10 ">
                  <div className="w-full mt-8">
                    <div className="mt-4 w-full">
                      <Select
                        allowClear
                        control={control}
                        label={'Transaction'}
                        name={'transaction_id'}
                        options={transactionOptions}
                        placeholder="Select an Option"
                        fetching={isLoadingTransactions}
                        rules={{
                          required: 'This field is required',
                        }}
                        onChange={(value, onChange) => {
                          onChange(value);
                          resetField('template');
                        }}
                      />
                    </div>
                    <div className="mt-4 w-full">
                      <Checkbox
                        label={'Include Images?'}
                        name={'is_image'}
                        control={control}
                        showUpperLabel={false}
                      />
                    </div>
                    <div className="mt-4 w-full">
                      <Checkbox
                        control={control}
                        showUpperLabel={false}
                        name={'is_new_template'}
                        label={'Add a New Template?'}
                      />
                    </div>
                    <div className="mt-4 w-full">
                      {isCreatingNewTemplate ? (
                        <Input
                          control={control}
                          label="New Template"
                          name={'newTemplateName'}
                          placeholder="Add a new Template here"
                          rules={{
                            required: isCreatingNewTemplate
                              ? 'This Field is Required'
                              : false,
                          }}
                        />
                      ) : (
                        <Select
                          allowClear
                          name={'template'}
                          control={control}
                          label={'Templates'}
                          options={templateOptions}
                          fetching={isLoadingTemplates}
                          placeholder="Select an Option"
                          rules={{
                            required: isCreatingNewTemplate
                              ? false
                              : 'This Field is Required',
                          }}
                          onChange={async (field, onChange) => {
                            if (is_image) {
                              const filePath = templateOptions.find(
                                (item) => item?.value === field?.value,
                              )?.file;
                              if (Boolean(filePath)) {
                                const response = await axios({
                                  method: 'get',
                                  url: filePath,
                                  responseType: 'blob',
                                  headers: {
                                    ...authHeader(),
                                  },
                                });

                                const file = new File(
                                  [response.data],
                                  'Uploaded File',
                                  {
                                    type: response.data.type,
                                  },
                                );
                                setFile([file]);
                              }
                            }
                            onChange(field);
                          }}
                        />
                      )}
                    </div>
                    {is_image && (
                      <div className="mt-6 w-1/2">
                        <div>
                          Upload Template Image
                          <span
                            className={'text-gray-400 text-sm font-light'}
                          >
                            {'  '}(Accept: .jpeg, .png)
                          </span>
                        </div>
                        <Upload
                          {...register('filename', {
                            required: is_image
                              ? 'Please choose an image'
                              : false,
                          })}
                          type="file"
                          maxCount={1}
                          fileList={file}
                          multiple={false}
                          directory={false}
                          className="w-full"
                          onChange={() => {}}
                          accept=".jpeg, .jpg, .png, image/jpeg, image/png"
                          beforeUpload={(file) => filterFile(file)}
                          onRemove={() => setFile([])}
                        >
                          <Button
                            isCancel
                            className="mt-1"
                            htmlType="button"
                            children={'Upload'}
                            disabled={isMutating}
                            icon={<FiUpload color="gray" />}
                          />
                        </Upload>
                        {errors?.filename && (
                          <div className="text-[#ff4d4f]">
                            {errors?.filename?.message}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`flex mt-4 mr-10 mb-6 text-white text-sm items-center font-semibold 
                 ${areFilesAvailable ? 'justify-between' : 'justify-end'}
                `}
              >
                {areFilesAvailable && (
                  <Button
                    isSecondary
                    htmlType="button"
                    onClick={togglePreviewModal}
                  >
                    Preview
                  </Button>
                )}
                <Button type={'primary'} htmlType="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        className="previewModal"
        width={1200}
        title={'Preview'}
        footer={null}
        destroyOnClose
        open={isPreviewModalOpen}
        onCancel={() => togglePreviewModal()}
      >
        <PreviewWhatsAppTemplate
          file={file}
          templateName={templateName?.value}
        />
      </Modal>
    </>
  );
}
