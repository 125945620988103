import axios from 'axios';
import Config from '../Services/Config';
import authHeader from './authHeader';

const getCategoryData = (data) => {
  var formData = data;
  return axios.post(
    Config.API_URL + `api/v1/settings/company-job-questions-category/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const questionCategoryList = () => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-job-questions-category/`,
    {
      headers: authHeader(),
    },
  );
};
const categoryInfo = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions-category/${id}/`,

    {
      headers: authHeader(),
    },
  );
};
const EditCategory = (id, data) => {
  var formData = data;
  return axios.patch(
    Config.API_URL +
      `api/v1/settings/company-job-questions-category/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const deleteCategory = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/settings/company-job-questions-category/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getSubCatergoryData = (data) => {
  var formData = data;
  return axios.post(
    Config.API_URL + `api/v1/settings/company-job-questions-sub-category/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const questionSubCategoryList = () => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-job-questions-sub-category/`,
    {
      headers: authHeader(),
    },
  );
};
const subCategoryInfo = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions-sub-category/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const deleteSubCategory = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/settings/company-job-questions-sub-category/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const EditSubCategory = (data, id) => {
  var formData = data;
  return axios.patch(
    Config.API_URL +
      `api/v1/settings/company-job-questions-sub-category/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getCategorySubCategory = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions-sub-category/?question_category_id=${id ? id : ''}`,
    {
      headers: authHeader(),
    },
  );
};

const searchSubCategory = (searchString, id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions-sub-category/?sub_category=${searchString}&question_category_id=${id}`,
    {
      headers: authHeader(),
    },
  );
};

const questionCreation = (
  data,
  essay,
  multiple,
  option,
  arrayOption,
  arraySelected,
  level,
  selectedSubCategory,
) => {
  const formData = new FormData();
  if (essay) {
    formData.append('question', data.question ? data.question : '');
    formData.append('category', data.category);
    formData.append('difficulty', data.difficulty);
    formData.append('answer', data.answer ? data.answer : '');
    formData.append('sub_category', data.sub_category);
    formData.append('question_type', data.question_type);
  } else if (option) {
    {
      console.log('options', arrayOption);
    }
    formData.append('question', data.question ? data.question : '');
    formData.append('category', data.category);
    formData.append('difficulty', data.difficulty);
    formData.append('question_type', data.question_type);
    formData.append('sub_category', data.sub_category);
    formData.append('multi_options_answers_type', 'CHAR');
    formData.append('multi_options_answers', JSON.stringify(arrayOption));
  } else if (multiple) {
    formData.append('question', data.question ? data.question : '');
    formData.append('difficulty', data.difficulty);
    formData.append('category', data.category);
    formData.append('question_type', data.question_type);
    formData.append('sub_category', data.sub_category);
    formData.append('multi_options_answers_type', 'CHAR');
    formData.append('multi_options_answers', JSON.stringify(arrayOption));
  }
  return axios.post(
    Config.API_URL + `api/v1/settings/company-job-questions/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getQuestionList = (
  selectedCategory,
  selectedSubCategory,
  selectedType,
  difficulty,
) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions/?category=${selectedCategory ? selectedCategory : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getSubCatergoryList = (e, selectedCategory) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions/?sub_category=${e ? e : ''}&category=${selectedCategory ? selectedCategory : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getDifficulty = (e, selectedCategory, selectedSubCategory) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions/?difficulty=${e ? e : ''}&category=${selectedCategory ? selectedCategory : ''}&sub_category=${selectedSubCategory ? selectedSubCategory : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getQuestionType = (
  e,
  selectedCategory,
  selectedSubCategory,
  difficulty,
) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-job-questions/?question_type=${e ? e : ''}&category=${selectedCategory ? selectedCategory : ''}&sub_category=${selectedSubCategory ? selectedSubCategory : ''}
    &difficulty=${difficulty ? difficulty : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const questionDetailsById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-job-questions/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const questionDeletion = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-job-questions/${id}/`,
    {
      headers: authHeader(),
    },
  );
};

/* 
 Get job list
*/
const getJobListing = (params) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/list-ai-interviews-by-job/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

/* 
  API for generating questions
*/
const generateQuestions = (data) => {
  return axios.post(
    Config.API_URL + `api/v1/settings/generate-chatgpt-questions/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

/* 
  API for generating insufficient questions to complete Questionnaire count
*/
const generateRemainingQuestions = (body) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/settings/generate-remaining-questions-from-chatgpt/`,
    body,
    config,
  );
};

/* 
  API for fetching the generated questions
*/
const fetchGeneratedQuestions = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };

  return axios.get(
    Config.API_URL + `api/v1/settings/generate-chatgpt-questions/`,
    config,
  );
};

/* 
  For creating a new job interview questionnaire
*/
const createJobInterviewQuestionnaire = (data) => {
  return axios.post(
    `${Config.API_URL}api/v1/jobs/job-questionaire/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

/* 
  Creating a new job interview questionnaire
  through a file upload. Currently supporting 
  excel files only.
*/
const createFileUploadedInterviewQuestionnaire = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.post(
    `${Config.API_URL}api/v1/jobs/excel-upload-job-questions/?excel_file`,
    data,
    config,
  );
};

const getSampleQuestionnaireFile = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/settings/sample-excel-questionaire-format/`,
    config,
  );
};

/* 
  For changing the questionnaire's details such as marks 
  and allocated questions.
*/
const editQuestionnaireConfiguration = (params) => {
  let { questionBankId, body } = params;
  const config = {
    params: {
      basic_edit: true,
    },
    headers: authHeader(),
  };
  return axios.put(
    `${Config.API_URL}api/v1/jobs/job-questionaire/${questionBankId}/`,
    body,
    config,
  );
};

/* 
  For Editing a new job interview questionnaire
*/
const editJobInterviewQuestionnaire = (data) => {
  return axios.put(
    `${Config.API_URL}api/v1/jobs/job-questionaire/${data.questionBankId}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

/* 
  to get questionnaire list
*/
const getQuestionnaireList = (params) => {
  return axios.get(`${Config.API_URL}api/v1/jobs/job-questionaire/`, {
    params,
    headers: authHeader(),
  });
};

/* 
  For deleting a questionnaire
*/
const deleteQuestionnaire = (urlParam) => {
  console.log('test', authHeader());
  return axios.delete(
    `${Config.API_URL}api/v1/jobs/job-questionaire/${urlParam}`,
    {
      headers: authHeader(),
    },
  );
};

/* 
  For deleting a questionnaire
*/
const getCreatedQuestionsBasedOnQuestionnaire = (params, urlParam) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/list-questionaire-questions/${urlParam}`,
    config,
  );
};

/* 
  For displaying created questions
*/
const getCreatedQuestions = (params) => {
  return axios.get(Config.API_URL + `api/v1/company/list-job-questions/`, {
    params,
    headers: authHeader(),
  });
  // return axios.get(`${Config.API_URL}api/v1/company/list-job-questions/`, {
  //   headers: authHeader(),
  // });
};
const getSearchList = (params) => {
  return axios.get(
    Config.API_URL + `api/v1/company/list-job-questions-category/`,
    {
      params,
      headers: authHeader(),
    },
  );
  // return axios.get(`${Config.API_URL}api/v1/company/list-job-questions/`, {
  //   headers: authHeader(),
  // });
};

// http://127.0.0.1:8000/api/v1/settings/list-ai-interviews-by-job/?search_string=pyth

const editQuestion = (
  data,
  id,
  essay,
  multiple,
  option,
  arrayOption,
  selectedSubCategory,
  selectedCategory,
) => {
  const formData = new FormData();
  if (essay) {
    formData.append('question', data.question);
    formData.append('category', selectedCategory);
    formData.append('difficulty', data.difficulty);
    formData.append('answer', data.answer);
    formData.append('sub_category', selectedSubCategory);
    formData.append('question_type', 'CHAR');
  } else if (option) {
    {
      console.log('options', arrayOption);
    }
    formData.append('question', data.question);
    formData.append('category', selectedCategory);
    formData.append('difficulty', data.difficulty);
    formData.append('question_type', 'OPTIONS');
    formData.append('sub_category', selectedSubCategory);
    formData.append('multi_options_answers_type', 'CHAR');
    formData.append('multi_options_answers', JSON.stringify(arrayOption));
  } else if (multiple) {
    formData.append('question', data.question);
    formData.append('category', selectedCategory);
    formData.append('difficulty', data.difficulty);
    formData.append('question_type', 'MULTI_CHOICE');
    formData.append('sub_category', selectedSubCategory);
    formData.append('multi_options_answers_type', 'CHAR');
    formData.append('multi_options_answers', JSON.stringify(arrayOption));
  }
  return axios.put(
    Config.API_URL + `api/v1/settings/company-job-questions/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const createQuestionnaireQuestions = (data) => {
  return axios.post(
    Config.API_URL +
      `api/v1/jobs/job-questions-from-question-bank-to-create-questionaire/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const createQuestionsFromQuestionBank = (data) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/job-questionaire-from-question-bank/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const QuestionBanksService = {
  getCategoryData,
  questionCategoryList,
  EditCategory,
  categoryInfo,
  deleteCategory,
  getCategorySubCategory,
  getSubCatergoryData,
  questionSubCategoryList,
  subCategoryInfo,
  EditSubCategory,
  deleteSubCategory,
  searchSubCategory,
  questionCreation,
  getQuestionList,
  getSubCatergoryList,
  getDifficulty,
  getQuestionType,
  questionDeletion,
  questionDetailsById,
  editQuestion,
  getJobListing,
  generateQuestions,
  generateRemainingQuestions,
  fetchGeneratedQuestions,
  createJobInterviewQuestionnaire,
  createFileUploadedInterviewQuestionnaire,
  getSampleQuestionnaireFile,
  getQuestionnaireList,
  deleteQuestionnaire,
  getCreatedQuestionsBasedOnQuestionnaire,
  editQuestionnaireConfiguration,
  editJobInterviewQuestionnaire,
  getCreatedQuestions,
  getSearchList,
  createQuestionnaireQuestions,
  createQuestionsFromQuestionBank,
};
export default QuestionBanksService;
