export const enquiriesTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Business Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Enquiry Person',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone Number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: 'right',
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
];
