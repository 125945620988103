//PENDING: placeholder column data to fill the table
export const licenseTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'License Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: 'right',
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
];
