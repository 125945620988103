import { Dropdown } from 'antd';

import { TableActionsIcon } from './Icons';
import { useEffect, useState } from 'react';

export default function ActionsDropdown({
  items = [],
  trigger = 'hover',
  containerType = null,
  dropdownVisible = null,
  setDropdownVisble = ()=> {},
}) {
  const [open, setOpen] = useState(false)
  useEffect(()=> {
    if(!open){
      setDropdownVisble(null)
    }
  },[open])

  return (
    <div className="flex justify-center items-center">
      <Dropdown
        trigger={trigger}
        placement="bottomRight"
        getPopupContainer={() => containerType}
        // getPopupContainer={() => (containerType?.() || document.body)}
        menu={{
          items,
        }}
        open={(dropdownVisible !== null && dropdownVisible !== false) || open}
        onOpenChange={(value)=> setOpen(value)}
      >
        <button>
          <TableActionsIcon className="pb-4" />
        </button>
      </Dropdown>
    </div>
  );
}
