import React from "react";
import EmployerRegisterComp from "../../../Components/EmployerRegister/EmployerRegisterComp";

const EmployerRegister = (props) => {
  console.log("EmployerRegister", props);
  return (
    <div className="main-height">
      <EmployerRegisterComp />
    </div>
  );
};

export default EmployerRegister;
