import { useGetResumeTemplates, useGetResumeTemplatesData } from 'ServiceHooks/resumeBuilderHooks';
import {
  apiResponseData,
  apiResponseData1,
  apiResponseData2,
  generateResumeHTML,
  getSafeHtml,
  htmlContent,
  resume_demo_datas,
} from 'Utilities/ResumeBuilder/constants';
import { Modal } from 'antd';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import ResumeForm from './ResumeForm';
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import Button from 'Components/CommonComponents/Button/Button';

const ListResumeTemplates = () => {
    const {id} = useParams()
    const navigate = useNavigate();
  const { data: resume_templates } = useGetResumeTemplates();
  

  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  //   const resumes = [apiResponseData,apiResponseData1,apiResponseData2]

  

  const handleTemplateSelect = (data) => {
    setSelectedData(data)
      setOpenTemplate(true);
    };
    console.log('enter>>>', openTemplate);

//   console.log(resume_templates, 'resume_templates',openTemplate);
console.log(selectedData,"selectedData");
  return (
    <>
    <Button
        className="flex items-center cursor-pointer"
        type="ghost"
        onClick={() => navigate(-1)}
      >
        <BiArrowBack />
        <span className="ml-4 font-bold">
         Back
        </span>
      </Button>
      <h2 className="text-[20px] w-full">Select Template</h2>
      <div className="mt-5">
        <div className="flex gap-8 justify-start flex-wrap w-full items-center">
          {resume_templates?.results?.map((re, index) => (
            <div className="w-[100%] mid-s:w-[45%] 2xl:w-[31%]">
              <p className="mb-2">{re?.template_name}</p>
              <div
                onClick={() => handleTemplateSelect(re)}
                className="bg-[#fff] cursor-pointer w-full hover:shadow-2xl max-h-[90vh] h-[90vh] overflow-y-auto customScrollBar"
                dangerouslySetInnerHTML={{
                  __html: getSafeHtml(re?.resume_template,resume_demo_datas),
                }}
              />
            </div>
          ))}
        </div>
        {openTemplate && (
          <Modal
            className="offlineReportModal"
            width={1200}
            open={openTemplate}
            title={null}
            onCancel={()=>setOpenTemplate(false)}
            footer={null}
            destroyOnClose
            bodyStyle={{
              paddingTop: '2rem',
            }}
          >
            <ResumeForm template={selectedData?.resume_template} title={selectedData?.template_name} fields={selectedData?.required_fields} candidate_id={id} template_id={selectedData?.id} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default ListResumeTemplates;
