import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEdit = (props) => {
  const [editorState, setEditorState] = useState(props.initialValue);

  //  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // const [checkPlaceHolder, setPlaceHolder] = useState("");
  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   // setEditorValue(editorState);
  //   var strIsvalue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   if (strIsvalue == "<p></p>") {
  //     setPlaceHolder("");
  //   } else {
  //     setPlaceHolder(strIsvalue);
  //   }

  //   return props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  // };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  useEffect(() => {
    if (props.initialValue) {
      // var test =  removeTags( props.initialValue)
      // console.log("test_desc", test);
      setEditorState(props.initialValue);
    }
  }, [props.initialValue]);

  // const onContentStateChange = (contentState) => {
  //   console.log(contentState);
  //   return contentState;
  //   // var strIsvalue = contentState.blocks[0].type;
  //   // setPlaceHolder(strIsvalue);
  // };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.onChange(editorState);
  };

  return (
    <>
      <div className="editor">
        {/* <Editor
        placeholder=""
        spellCheck
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        onEditorStateChange={onEditorStateChange}
        onContentStateChange={onContentStateChange}
    
      /> */}
        <ReactQuill
          theme="snow"
          value={editorState}
          onChange={onEditorStateChange}
        />
      </div>
    </>
  );
};
export default TextEdit;
