import React from 'react';
import { Checkbox as AntCheckbox, Form } from 'antd';
import { useController } from 'react-hook-form';

const CheckboxGroup = ({
  options,
  disabledArray = [],
  customOnChange = null,
  withCustomContent = false,
  nonLoopContent = null,
  containerRef = null,
  childRef = null,
  radioGroupClassName = '',
  // hook form specific
  name,
  control,
  defaultValue,
  rules = {},
  label,
  isChangeLabelColor = false,
  ...others
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return withCustomContent ? (
    <Form.Item
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntCheckbox.Group
        onChange={(e) => {
          customOnChange ? customOnChange(e, onChange) : onChange(e);
        }}
        value={value}
        {...others}
      >
        {options.map((item) => {
          return (
            <div className="w-full checkboxGroupItem" key={item.value}>
              <AntCheckbox
                value={item.value}
                disabled={disabledArray.includes(item.value)}
                className={`${isChangeLabelColor && item?.className}`}
              >
                {item.label}
              </AntCheckbox>
              {item.content}
            </div>
          );
        })}
      </AntCheckbox.Group>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntCheckbox.Group
        onChange={(e) => {
          customOnChange ? customOnChange(e, onChange) : onChange(e);
        }}
        value={value}
        {...others}
      >
        {options.map((item) => {
          return (
            <AntCheckbox
              key={item.value}
              value={item.value}
              disabled={disabledArray.includes(item.value)}
            >
              {item.label}
            </AntCheckbox>
          );
        })}
      </AntCheckbox.Group>
    </Form.Item>
  );
};

export default CheckboxGroup;
