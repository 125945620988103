import { isEmpty } from 'lodash';

import daysIcon from '../../../Assets/images/days_img.svg';
import TimeIcon from '../../../Assets/images/time_img.svg';
import dateIcon from '../../../Assets/images/date_img.svg';
import 'Styles/ViewJob.scss';
import 'Styles/commonStyles.scss';
import Card from 'Components/CommonComponents/Card/Card';
import Empty from 'Components/CommonComponents/Empty/Empty';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import ViewParsedText from 'Components/CommonComponents/ViewParsedText';

const daysType = {
  SUN: 'Sunday',

  MON: 'Monday',

  TUE: 'Tuesday',

  WED: 'Wednesday',

  THU: 'Thursday',

  FRI: 'Friday',

  SAT: 'Saturday',
};

const ViewJob = ({
  className = 'mt-4',
  showDescription = false,
  jobId,
  status,
  error,
  jobInfoData,
}) => {
  const renderJobDetails = (jobInfoData) => {
    const {
      data: {
        data: {
          description,
          job_days,
          job_work_timings,
          job_work_end_date,
          job_work_from_date,
        },
      },
    } = jobInfoData;

    let scheduleRange;
    if (Boolean(job_work_end_date) && Boolean(job_work_from_date)) {
      scheduleRange = `${job_work_from_date} to ${job_work_end_date}`;
    } else if (Boolean(job_work_end_date) || Boolean(job_work_from_date)) {
      scheduleRange =
        `Till: ${job_work_end_date}` ?? `From: ${job_work_from_date}`;
    } else {
      scheduleRange = false;
    }

    const isJobSchedulesInvisible =
      isEmpty(job_days) &&
      isEmpty(job_work_timings) &&
      !Boolean(job_work_end_date) &&
      !Boolean(job_work_from_date);

    return (
      <Card>
        <div className={`ViewJob ${className}`}>
          {!isJobSchedulesInvisible && (
            <div className="jobscheduleContentSectionThree">
              <p className="font-semibold">Job Schedule</p>
              <div className="flex flex-wrap gap-5 mt-5">
                {!isEmpty(job_days) && (
                  <div className="box p-4 !h-auto">
                    <div>
                      <img src={daysIcon} />
                    </div>
                    <p>Days</p>
                    <div className="mt-2 flex flex-wrap gap-2 ">
                      {job_days.map((days) => {
                        return (
                          <p className="font_value_colour">
                            {daysType[days]},
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
                {Boolean(scheduleRange) && (
                  <div className="box p-4 !h-auto">
                    <div>
                      <img src={dateIcon} />
                    </div>
                    <p>Date</p>
                    <div className="mt-2">
                      <p className="font_value_colour">
                        {' '}
                        {scheduleRange}{' '}
                      </p>
                    </div>
                  </div>
                )}
                {!isEmpty(job_work_timings) && (
                  <div className="box p-4 !h-auto">
                    <div>
                      <img src={TimeIcon} />
                    </div>
                    <p>Time</p>
                    <div className="mt-2">
                      {job_work_timings?.map((time, index) => (
                        <p className="font_value_colour">
                          <span className="font-semibold">
                            {' '}
                            {index + 1}.{' '}
                          </span>
                          {time.work_from_time} to {time.work_end_time}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {showDescription && (
            <div className="jobDescription bg-secondaryBgMain">
              <ViewParsedText
                content={description}
                className="dangerousHtml"
              />
            </div>
          )}
        </div>
      </Card>
    );
  };

  return status === 'loading' ? (
    <span>Loading...</span>
  ) : status === 'error' ? (
    <div className="mt-4">
      <Empty
        isError
        description={
          <span>
            {' '}
            {error?.response?.data?.error_info?.detail ||
              DEFAULT_ERROR_MESSAGE}
          </span>
        }
      />
    </div>
  ) : (
    renderJobDetails(jobInfoData)
  );
};

export default ViewJob;
