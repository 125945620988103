import { memo } from 'react';
import DOMPurify from 'dompurify';
import { FaFacebook } from 'react-icons/fa6';
import { FaSquareInstagram } from 'react-icons/fa6';
import { FaLinkedin } from 'react-icons/fa6';
import { isEmpty } from 'lodash';

const PreviewTemplate = ({ htmlContent,subject,imageUrl }) => {
  const details = JSON.parse(localStorage?.getItem('user'));

  const mySafeHTML = DOMPurify.sanitize(htmlContent);
  return (
    <div className="w-full table-fixed p-[20px] bg-[#F1F5F9]">
      <table className="w-full max-w-[600px] bg-[#fff]">
        <tr>
          <td className="flex justify-center items-center p-[20px] border-b border-b-[#e8e8e8]">
            <div className="w-[65px] h-[65px]">
              <img
                src={details?.company_logo}
                alt="company logo"
                className="w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="p-[20px]">
            <table className="w-full">
              <tr className='flex items-center justify-center'>
              {imageUrl && (
                  <img src={imageUrl} className="rounded-3xl p-1 pl-2 w-[31.25rem] h-auto" onContextMenu={(e) => e.preventDefault()} />
                )}
              </tr>
              <tr>
                <td>
                  <h1 className="text-[18px] text-[#005968] font-bold text-center mb-5">
                    {subject}
                  </h1>
                  <div dangerouslySetInnerHTML={{ __html: mySafeHTML }} />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="bg-#FBFBFB text-center border-b-[4px] border-b-[#0a7f8f]">
            <table className="w-full">
              <tr>
                <td className="p-[20px] pb-[10px] border-b border-b-[#e8e8e8] flex flex-col items-center gap-2">
                  <div className="w-[40px] h-[40px]">
                    <img
                      src={details?.company_logo}
                      alt="company logo"
                      className="w-full h-full"
                    />
                  </div>
                  <p className="text-center text-[#464646] text-[13px]">
                    Find Us Online
                  </p>
                  <div className="flex items-center gap-2 justify-center mt-1">
                    <FaFacebook />
                    <FaSquareInstagram />
                    <FaLinkedin />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-[20px] leading-4 text-[11px] text-left text-[#898989] py-0">
                  <p className='py-3'>
                    Disclaimer: This email and any attachments are
                    confidential and intended solely for the use of the
                    individual or entity to which they are addressed. If
                    you have received this email in error, please notify
                    the sender immediately and delete the message from your
                    system. Any unauthorized use, disclosure, or copying of
                    this email or its attachments is strictly prohibited.
                    The views and opinions expressed in this email are
                    those of the sender and do not necessarily reflect the
                    official views or opinions of [Your
                    Organization/Company]. [Your Organization/Company] does
                    not guarantee the accuracy or completeness of any
                    information contained in this email or its attachments.
                    Although precautions have been taken to ensure that
                    this email and its attachments are free from viruses,
                    [Your Organization/Company] cannot accept any
                    responsibility for any loss or damage arising from the
                    use of this email or its attachments.
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default memo(PreviewTemplate);
