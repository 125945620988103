import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlinePublishedWithChanges, MdPublish } from 'react-icons/md';
import { BsCalendarCheck } from 'react-icons/bs';
import { Modal, message, Popconfirm } from 'antd';

import {
  JOB_TAB_TYPES,
  JOB_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
  JOB_ACTIVITY_LOGGER_TYPES,
} from 'Utilities/constants';
import EditJobStore from '../../Store/EditJobStore';
import EditJobPublish from '../../Store/EditJobPublish';
import EditJobHiringStore from 'Store/EditJobHiringStore';
import Progress from 'Components/CommonComponents/Progress';
import Button from 'Components/CommonComponents/Button/Button';
import jobService from '../../Services/JobService';
import candidateService from 'Services/candidateService';
import {
  useUpdateJobStatus,
  useCreateActivityLoggerId,
  useGetJobCreationCommonData,
} from 'ServiceHooks/jobHooks';
import { useAuthentication } from 'ServiceHooks/authHooks';
import createActivityLoggerId from 'Utilities/Jobs/createActivityLoggerId';
import Config from 'Services/Config';
import {
  publish,
  schedule,
  send,
} from 'Components/CommonComponents/Icons';

function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, '');
}

export default function Publish(props) {
  const navigate = useNavigate();

  const [isChecked, setCheckBox] = useState();
  const [usageLimit, setUsageLimit] = useState();
  const [isProcessing, setProcessing] = useState();
  const [scheduleDate, setScheduleDate] = useState();
  const [isPublishPopup, setPublishPopup] = useState();
  const [isLinkedInApplied, setIsLinkedInApplied] = useState(false);

  const oEditJobHiringResponse = EditJobHiringStore(
    (state) => state.EditJobHiringResponse,
  );
  const oEditJobPublishResponse = EditJobPublish(
    (state) => state.EditJobPublishResponse,
  );
  const oEditJobResponse = EditJobStore((state) => state.EditJobtResponse);

  const { fullPermissionInfo } = useAuthentication();
  const [messageApi, contextHolder] = message.useMessage();
  const { data: references } = useGetJobCreationCommonData({});
  const { mutateAsync: updateJobStatus } = useUpdateJobStatus({});
  const { mutateAsync: sendActivityLog } = useCreateActivityLoggerId({});

  useEffect(() => {
    if (oEditJobPublishResponse) {
      if (oEditJobPublishResponse.send_for_approval) {
        setCheckBox('Publish Now');
      } else if (oEditJobPublishResponse.scheduled_for_later) {
        setCheckBox('Schedule For Later');
        setScheduleDate(oEditJobPublishResponse.scheduled_for_later);
      } else if (oEditJobPublishResponse.published) {
        setCheckBox('Publish Now');
      } else if (oEditJobPublishResponse.is_hold) {
        setCheckBox('is_hold');
      }
    } else {
      setCheckBox('');
    }
  }, [oEditJobPublishResponse]);

  const isLinkedInShareAvailable =
    references?.social_share?.includes('LINKEDIN');

  const host = Config.API_URL;
  const companyLogo = JSON.parse(
    localStorage.getItem('user'),
  )?.company_logo;
  const description = removeTags(oEditJobResponse?.description);
  const linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${host}jobs/${oEditJobResponse?.job_id}/?source=linkedin&title=${oEditJobResponse?.title}&description=${description}&imageurl=${companyLogo}`;

  const handelCheckBox = (strName) => {
    setCheckBox(strName);
  };

  const handeldateChange = (e) => {
    setScheduleDate(e.target.value);
  };

  const approvePublishJob = async (isClosingJob = false) => {
    try {
      const hasHoldTabPermissions = fullPermissionInfo.VIEW_HOLD_JOB_TAB;
      let body = {
        scheduled_for_later:
          isChecked === 'Schedule For Later' ? scheduleDate : null,
        published: isChecked === 'Publish Now' ? true : false,
        send_for_approval: isChecked === 'Sent To Approval' ? true : false,
        is_hold: isChecked === 'is_hold' ? true : false,
      };

      if (isClosingJob) {
        body = {
          scheduled_for_later: null,
          published: false,
          send_for_approval: false,
          is_hold: false,
          is_closed: true,
        };
      }

      const variables = {
        body,
        id: oEditJobResponse.job_publish_status_id,
      };

      const response = await updateJobStatus(variables);
      const status = response?.data?.status;

      if (
        hasHoldTabPermissions &&
        [
          JOB_STATUS_TYPES.HOLD,
          JOB_STATUS_TYPES.FORCEFULLY_CLOSED,
        ].includes(status)
      ) {
        navigate(`/hr/jobs/${JOB_TAB_TYPES.HOLD}`);
      } else {
        navigate(`/hr/jobs/${JOB_TAB_TYPES.ACTIVE}`);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error_info?.message?.[0] ??
        DEFAULT_ERROR_MESSAGE;
      message.error(errorMessage);
      console.error('Job Approval Error: ', error);
    }
  };

  const getCreatePermission = async () => {
    setProcessing(true);
    try {
      const response =
        await candidateService.getScheduleCount('job_publish');

      const data = response?.data?.data;
      setProcessing(false);
      if (data?.is_unlimited) {
        if (
          [
            JOB_STATUS_TYPES.HOLD,
            JOB_STATUS_TYPES.PENDING,
            JOB_STATUS_TYPES.SCHEDULED_FOR_LATER,
          ].includes(oEditJobPublishResponse?.status)
        ) {
          await approvePublishJob();
        } else {
          await publishJobNow();
        }
      } else {
        setPublishPopup(!isPublishPopup);
        setUsageLimit(data?.remaining_count);
      }
    } catch (error) {
      const content =
        error?.response?.data?.error_info?.message ??
        DEFAULT_ERROR_MESSAGE;
      await messageApi.open({
        type: 'error',
        content,
      });
      console.error('Limit Checking Error: ', error);
      setProcessing(false);
    }
  };

  const beginJobPublishing = async () => {
    try {
      if (
        isChecked === 'Sent To Approval' &&
        oEditJobPublishResponse.status != 'HOLD'
      ) {
        if (!oEditJobHiringResponse?.reviewer?.length) {
          const content = 'Reviewer should be selected for Approval';
          await messageApi.open({
            type: 'error',
            content,
          });
          return false;
        }
        await publishJobNow();
      } else {
        await getCreatePermission();
      }
    } catch (error) {
      await messageApi.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
      console.error('Publish Prepping Error:', error);
    }
  };

  const publishJobNow = async () => {
    setProcessing(true);
    const logger_id = createActivityLoggerId();
    const data = {
      job: oEditJobResponse && oEditJobResponse?.job_id,
      scheduled_for_later:
        isChecked === 'Schedule For Later' ? scheduleDate : null,
      published: isChecked === 'Publish Now' ? true : false,
      send_for_approval: isChecked === 'Sent To Approval' ? true : false,
      is_hold: isChecked === 'is_hold' ? true : false,
    };
    const ACTIVITY_MAPPING = {
      is_hold: JOB_ACTIVITY_LOGGER_TYPES.JOB_HOLD,
      'Publish Now': JOB_ACTIVITY_LOGGER_TYPES.JOB_PUBLISHED,
      'Schedule For Later':
        JOB_ACTIVITY_LOGGER_TYPES.JOB_SCHEDULED_FOR_LATER,
      'Sent To Approval': JOB_ACTIVITY_LOGGER_TYPES.JOB_SENT_FOR_APPROVAL,
    };

    const isPendingJob = oEditJobPublishResponse?.status === 'PENDING';
    try {
      if (!oEditJobResponse?.job_id) {
        throw new Error('Job Information form is Incomplete.');
      }

      const log = {
        logger_id,
        job: oEditJobResponse?.job_id,
        activity: isPendingJob
          ? JOB_ACTIVITY_LOGGER_TYPES.JOB_APPROVED
          : ACTIVITY_MAPPING[isChecked],
      };
      await sendActivityLog(log);
      await jobService.publishJobs(data, { logger_id });

      if (isLinkedInApplied) {
        console.log('triggered 2', linkedinShareLink);
        window.open(linkedinShareLink, '_blank');
      }
      setProcessing(false);
      await navigate(`/hr/jobs/${JOB_TAB_TYPES.ACTIVE}`);
    } catch (error) {
      const content =
        //  error content is currently breaking the form. Revert once they fix it.
        error?.response?.data?.error_info?.job?.[0] ??
        error ??
        DEFAULT_ERROR_MESSAGE;
      await messageApi.open({
        type: 'error',
        content,
      });
      console.error('Job Publishing Error: ', error);
      setProcessing(false);
    }
  };

  const CONFIRM_TYPE = {
    is_hold: 'Confirm Putting the Job on Hold?',
    'Schedule For Later': 'Published at another time. Confirm?',
    'Sent To Approval': 'Approver receives the Job Details. Confirm?',
    'Publish Now': 'Are you sure about Publishing?',
  };

  const okToPublish = async () => {
    try {
      if (
        ['SCHEDULED_FOR_LATER', 'PENDING'].includes(
          oEditJobPublishResponse?.status,
        )
      ) {
        await approvePublishJob();
      } else {
        await publishJobNow();
      }
    } catch (error) {
      console.error('Unexpected Approval Error:', error);
    }
  };

  const onHold = () => {
    Modal.confirm({
      title:
        'You are about to put this job on hold. The job will remain in Hold Status.',
      onOk: async () => {
        await messageApi.open({
          type: 'warning',
          content: 'This Job will be put on Hold.',
        });

        await approvePublishJob();
      },
      onCancel() {},
    });
  };

  const isPendingStatus =
    oEditJobPublishResponse?.status === JOB_STATUS_TYPES.PENDING;

  const isHoldStatus =
    oEditJobPublishResponse?.status === JOB_STATUS_TYPES.HOLD;

  return (
    <>
      {contextHolder}
      <div className="publish_maindiv bg-white rounded-lg border-[0.5px] border-[#D7D7D7] p-[4rem]">
        <div className="flex flex-col gap-8">
          {props.fields.map((item) => {
            return item.fields.map((field) => {
              if (
                (field.field_code === 'published' &&
                  field.show === true &&
                  field.is_mandatory === true) ||
                (field.field_code === 'published' &&
                  field.show === true &&
                  props.isViewMoreField === true)
              ) {
                if (oEditJobPublishResponse?.published) {
                  return (
                    <p className="par flex">
                      <span className="flex mr-6">
                        <MdPublish
                          style={{ fontSize: 'large', color: 'green' }}
                        />{' '}
                        The change's are updated
                      </span>
                      <span className="flex">
                        <MdOutlinePublishedWithChanges
                          style={{
                            fontSize: 'large',
                            color: 'red',
                            marginRight: '5px',
                          }}
                        />
                        <input
                          name="publishJob"
                          type="checkbox"
                          checked={isChecked === 'is_hold'}
                          onChange={() => {
                            handelCheckBox('is_hold');
                            setIsLinkedInApplied(false);
                          }}
                          className='cursor-pointer'
                        />{' '}
                        The job will be on Hold
                      </span>
                    </p>
                  );
                } else {
                  return (
                    <div
                      key={field.field_code}
                      className="pb-2 flex gap-3"
                    >
                      <div className="flex items-center justify-center h-[46px] w-[46px] border-[0.5px] border-[#D7D7D7] rounded-full">
                        <img src={publish} alt="publish" />
                      </div>
                      <div>
                        <span className="publishNoe_icon">
                          Publish Now
                        </span>

                        <p className="par">
                          <input
                            name="publishJob"
                            disabled={oEditJobPublishResponse?.published}
                            type="checkbox"
                            checked={isChecked === 'Publish Now'}
                            onChange={() => {
                              handelCheckBox('Publish Now');
                              setIsLinkedInApplied(false);
                            }}
                            className="cursor-pointer"
                          />{' '}
                          The job will be published immediately
                        </p>

                        {isLinkedInShareAvailable &&
                          isChecked === 'Publish Now' && (
                            <p className="par">
                              <input
                                name="linkedin-share"
                                disabled={
                                  oEditJobPublishResponse?.published
                                }
                                type="checkbox"
                                checked={isLinkedInApplied}
                                onChange={() =>
                                  setIsLinkedInApplied((prev) => !prev)
                                }
                                className="mr-2 cursor-pointer"
                              />
                              Publish to{' '}
                              <span className="text-[#0077B5] font-semibold">
                                LinkedIn?
                              </span>
                            </p>
                          )}
                      </div>
                    </div>
                  );
                }
              } else if (
                (field.field_code === 'send_for_approval' &&
                  field.show === true &&
                  field.is_mandatory === true) ||
                (field.field_code === 'send_for_approval' &&
                  field.show === true &&
                  props.isViewMoreField === true)
              ) {
                if (
                  (!oEditJobPublishResponse?.published ||
                    oEditJobPublishResponse?.published == undefined) &&
                  !(
                    isPendingStatus && oEditJobPublishResponse?.is_reviewer
                  )
                ) {
                  return (
                    <div className="pb-[2rem] flex gap-3">
                      <div className="flex items-center justify-center h-[46px] w-[46px] border-[0.5px] border-[#D7D7D7] rounded-full">
                        <img src={send} alt="send" />
                      </div>
                      <div>
                        <span className="publishNoe_icon">
                            Sent To Approval
                        </span>

                        <p className="par">
                          <input
                            name="publishJob"
                            type="checkbox"
                            disabled={oEditJobPublishResponse?.published}
                            checked={isChecked === 'Sent To Approval'}
                            onChange={() => {
                              handelCheckBox('Sent To Approval');
                              setIsLinkedInApplied(false);
                            }}
                            className='cursor-pointer'
                          />{' '}
                          The job will be send to approver
                        </p>
                      </div>
                    </div>
                  );
                }
              } else if (
                (field.field_code === 'scheduled_for_later' &&
                  field.show === true &&
                  field.is_mandatory === true) ||
                (field.field_code === 'scheduled_for_later' &&
                  field.show === true &&
                  props.isViewMoreField === true)
              ) {
                if (
                  !oEditJobPublishResponse.published ||
                  oEditJobPublishResponse.published == undefined
                ) {
                  return (
                    <div className="py-[2rem] flex gap-3 border-y-[0.5px] border-[#F0F0F0]">
                      <div className="flex items-center justify-center h-[46px] w-[46px] border-[0.5px] border-[#D7D7D7] rounded-full">
                        <img src={schedule} alt="schedule" />
                      </div>
                      <div>
                        <span className="publishNoe_icon">
                            Schedule For Later
                        </span>

                        <p className="par">
                          <input
                            name="publishJob"
                            type="checkbox"
                            disabled={oEditJobPublishResponse?.published}
                            checked={isChecked === 'Schedule For Later'}
                            onChange={() => {
                              handelCheckBox('Schedule For Later');
                              setIsLinkedInApplied(false);
                            }}
                            className="cursor-pointer"
                          />{' '}
                          The job will be published later
                        </p>
                        {isChecked === 'Schedule For Later' && (
                          <div className="mt-3">
                            <span>Choose Your Date</span>
                            <input
                              type="date"
                              value={scheduleDate}
                              className="data_time m-0"
                              //type="datetime-local"
                              onChange={(e) => handeldateChange(e)}
                              min={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              }
            });
          })}
        </div>
      </div>

      {oEditJobPublishResponse.published ? (
        <div style={{ textAlign: 'end', marginTop: '4px' }}>
          <Button
            isCancel
            className="mr-4 h-12"
            onClick={() => {
              navigate(`/hr/jobs/${JOB_TAB_TYPES.ACTIVE}`);
            }}
          >
            Cancel
          </Button>
          <Button
            {...(isChecked === 'is_hold' && { type: 'primary' })}
            className="mr-4 h-12"
            onClick={() => {
              onHold();
            }}
            disabled={isChecked !== 'is_hold'}
          >
            Hold
          </Button>
        </div>
      ) : (
        <div
          style={{ textAlign: 'end', marginTop: '4px', padding: '10px' }}
        >
          {isHoldStatus && (
            <Button
              isRemove
              htmlType="button"
              children={'Close'}
              className="mr-4 h-12"
              onClick={async () => {
                await approvePublishJob(true);
              }}
            />
          )}
          <Button
            isCancel
            htmlType="button"
            className="mr-4 h-12 border-[0.5px] border-[#5B6B79] rounded text-[#5B6B79]"
            onClick={() => {
              navigate(`/hr/jobs/${JOB_TAB_TYPES.ACTIVE}`);
            }}
          >
            Cancel
          </Button>
          {oEditJobPublishResponse.status === 'PENDING' ? (
            <Popconfirm
              placement="topLeft"
              title={'Confirm'}
              description={
                isLinkedInApplied
                  ? 'Are you sure about Publishing through LinkedIn?'
                  : CONFIRM_TYPE[isChecked]
              }
              onConfirm={async () => {
                await beginJobPublishing();
              }}
              okText="Yes"
              okType="danger"
              cancelText="No"
            >
              <Button type={'primary'} className="h-12 bg-[#1C4980] rounded text-white" onClick={() => {}}>
                Approve And Publish
              </Button>
            </Popconfirm>
          ) : oEditJobPublishResponse.status === 'SCHEDULED_FOR_LATER' ? (
            <Button
              type={'primary'}
              className="h-12 bg-[#1C4980] rounded text-white"
              onClick={async () => {
                await beginJobPublishing();
              }}
            >
              Publish
            </Button>
          ) : (
            <Popconfirm
              placement="topLeft"
              title={'Confirm'}
              description={
                isLinkedInApplied
                  ? 'Are you sure about Publishing through LinkedIn?'
                  : CONFIRM_TYPE[isChecked]
              }
              onConfirm={async () => {
                await beginJobPublishing();
              }}
              okText="Yes"
              okType="danger"
              cancelText="No"
            >
              <Button className="h-12 bg-[#1C4980] rounded text-white">
                Publish
              </Button>
            </Popconfirm>
          )}
        </div>
      )}
      <Modal
        width={400}
        footer={null}
        destroyOnClose
        open={isPublishPopup}
        title={'Publish Job'}
        className="confirmWithPlanLimitsModal"
        onCancel={() => {
          setPublishPopup(!isPublishPopup);
        }}
      >
        <div className="flex justify-center items-center overflow-hidden">
          <div className="w-full text-center">
            <div className="mb-5">
              <h1 className="text-lg ">
                Hello, your current plan permits you to publish{' '}
                <span className="text-secondaryMain text-lg font-bold">
                  {' '}
                  {usageLimit}
                </span>{' '}
                additional jobs. To extend this limit, please reach out to
                the Administrator. Would you like to continue with
                publishing a job? If so, click "Ok."
              </h1>
            </div>

            <Button
              type="primary"
              className="h-12"
              onClick={async () => {
                await okToPublish();
              }}
            >
              Ok
            </Button>
            <Button
              isCancel
              className="ml-3"
              onClick={() => {
                setPublishPopup(!isPublishPopup);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {isProcessing && <Progress />}
    </>
  );
}
