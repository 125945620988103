import axios from 'axios';
import Config from '../Services/Config';
import authHeader from './authHeader';

const getCompanydata = (params) => {
  return axios.get(
    Config.API_URL + `api/v1/company/register-company-by-super-admin/`,
    {
      params,
      headers: authHeader(),
    },
  );
};

const createRegistration = (data, phoneCode, companyCode) => {
  var phoneNumber = phoneCode + data.mobile_number;
  var companyNumber = companyCode + data.company_contact_number;
  const formData = new FormData();
  formData.append('name', data.name ? data.name : '');
  formData.append(
    'primary_address',
    data.primary_address ? data.primary_address : '',
  );
  formData.append('location', data.location.label);
  formData.append('company_contact_number', data.company_contact_number);
  // formData.append('company_country', country);
  formData.append('zip_code', data.zip_code ? data.zip_code : '');
  formData.append(
    'company_email',
    data.company_email ? data.company_email : '',
  );
  formData.append('email', data.email ? data.email : '');
  formData.append('company_contact_number', companyNumber);
  formData.append('mobile_number', phoneNumber);

  formData.append('first_name ', data.first_name ? data.first_name : '');
  formData.append('last_name ', data.last_name ? data.last_name : '');
  formData.append(
    'about_the_company',
    data.about_the_company ? data.about_the_company : '',
  );
  formData.append('logo', data.logo && data.logo ? data.logo : '');
  formData.append('licensing', data.licensing);
  formData.append('linkedin', data.linkedin ? data.linkedin : '');
  formData.append('facebook', data.facebook ? data.facebook : '');
  formData.append('instagram', data.instagram ? data.instagram : '');
  formData.append('twitter', data.twitter ? data.twitter : '');
  formData.append('domain_name', data.domain_name ? data.domain_name : '');
  formData.append(
    'ctc_currency_code',
    data.ctc_currency_code ? data.ctc_currency_code.value : '',
  );
  formData.append(
    'subscription',
    data.subscription ? data.subscription : '',
  );
  formData.append('industry', data.industry);
  return axios.post(
    Config.API_URL + `api/v1/company/register-company-by-super-admin/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getRegistartioData = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/register-company-by-super-admin/${id ? id : ''}/`,
    {
      headers: authHeader(),
    },
  );
};
const updateRegistration = (id, data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return axios.patch(
    Config.API_URL +
      `api/v1/company/register-company-by-super-admin/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getInterviewSettings = (comapnyId) => {
  return axios.get(
    Config.API_URL + `api/v1/company/interview-settings/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};
const addInterviewSettings = (data, refrenceNumberFormat, comapnyId) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/interview-settings/${comapnyId}/`,
    {
      interview_settings_enabled: data.interview_settings_enabled,
      interview_reference_no_format: refrenceNumberFormat,
      interview_refno: data.interview_refno,
      prefix_needed: data.prefix_needed,
      year_required: data.year_required,
      month_required: data.month_required,
      company_code: data.company_code,
      default_interview_slot_duration:
        data.default_interview_slot_duration,
      send_interview_pass_result: data.send_interview_pass_result,
      send_interview_fail_result: data.send_interview_fail_result,
      interviewer_review_in_result_pdf:
        data.interviewer_review_in_result_pdf,
      show_AI_interview_skipped_questions_again:
        data.show_AI_interview_skipped_questions_again,
      auto_interview_schedule: data.auto_interview_schedule,
      auto_prescreen_schedule: data.auto_prescreen_schedule,
      year_required_format: data.year_required_format,
      month_required_format: data.month_required_format,
      send_jd_pdf_with_interview: data.send_jd_pdf_with_interview,
      auto_prescreen_schedule_score: data.auto_prescreen_schedule_score,
      auto_interview_schedule_score: data.auto_interview_schedule_score,
    },
    {
      headers: authHeader(),
    },
  );
};
const getJobSettings = (comapnyId) => {
  return axios.get(
    Config.API_URL + `api/v1/company/job-extra-settings/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};
const addJobSettings = (data, refrenceFormat, comapnyId) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/job-extra-settings/${comapnyId}/`,
    {
      reference_number: data.reference_number,
      is_prefix_needed: data.is_prefix_needed,
      year_required: data.year_required,
      month_required: data.month_required,
      company_code: data.company_code,
      reference_number_format: refrenceFormat,
      auto_suggest_previous_job_data: data.auto_suggest_previous_job_data,
      is_recommend_candidates_score: data.is_recommend_candidates_score,
      recommend_candidates_score: data.recommend_candidates_score,
      neglect_applicants_already_applied:
        data.neglect_applicants_already_applied,
      is_enabled: data.is_enabled,
      year_required_format: data.year_required_format?.value,
      month_required_format: data.month_required_format?.value,
    },
    {
      headers: authHeader(),
    },
  );
};
const getReviewSettings = (comapnyId) => {
  return axios.get(
    Config.API_URL + `api/v1/company/review-settings/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};
const addReviewSettings = (data, refrenceNumberFormat, comapnyId) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/review-settings/${comapnyId}/`,
    {
      is_review_settings: data.is_review_settings,
      interview_review_reference_number:
        data.interview_review_reference_number,
      prefix_needed: data.prefix_needed,
      year_required: data.year_required,
      year_required_format: data.year_required_format.value,
      month_required: data.month_required,
      month_required_format: data.month_required_format.value,
      company_code: data.company_code,
      job_reference_number_format: refrenceNumberFormat,
    },
    {
      headers: authHeader(),
    },
  );
};
const addCompanyWorkingTime = (data, comapnyId) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/company-working-time/${comapnyId}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getWorkingTime = (comapnyId) => {
  return axios.get(
    Config.API_URL + `api/v1/company/company-working-time/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};
const getLisensePlan = () => {
  return axios.get(
    Config.API_URL + `api/v1/subscriptions/licensing-creation/`,
    {
      headers: authHeader(),
    },
  );
};

const addSocialMediaSettings = (data, comapnyId) => {
  return axios.patch(
    Config.API_URL +
      `api/v1/company/social-media-job-posting/${comapnyId}/`,
    {
      company_id: comapnyId,
      LINKEDIN: data.LINKEDIN,
      INDEED: data.INDEED,
      NAUKRI: data.NAUKRI,
      social_media: data.social_media,
    },
    {
      headers: authHeader(),
    },
  );
};
const getSocialMediaSettings = (comapnyId) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/social-media-job-posting/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};
const getLocations = (serachValue) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/location-search/?search=${serachValue}`,
    {
      headers: authHeader(),
    },
  );
};
const addQuestionnaireSettings = (data, refrenceFormat, comapnyId) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/questionnaire-settings/${comapnyId}/`,
    {
      is_questionnaire_settings: data.is_questionnaire_settings,
      prefix_needed: data.prefix_needed,
      year_required: data.year_required,
      month_required: data.month_required,
      company_code: data.company_code,
      reference_number_format: refrenceFormat,
      questionnaire_reference_number: data.questionnaire_reference_number,
      year_required_format: data.year_required_format?.value,
      month_required_format: data.month_required_format?.value,
    },
    {
      headers: authHeader(),
    },
  );
};
const getQuestionnaireSettings = (comapnyId) => {
  return axios.get(
    Config.API_URL + `api/v1/company/questionnaire-settings/${comapnyId}/`,
    {
      headers: authHeader(),
    },
  );
};

const addExpirySettings = (data) => {
  return axios.put(
    Config.API_URL +
      `api/v1/company/company-prescreen-expiry/${data?.id}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

const getExpirySettings = () => {
  return axios.get(
    Config.API_URL + `api/v1/company/company-prescreen-expiry/`,
    {
      headers: authHeader(),
    },
  );
};

// Public API
const fetchAllTimezones = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(`${Config.API_URL}api/v1/settings/timezones/`, config);
};

const companyRegistration = {
  getCompanydata,
  createRegistration,
  getRegistartioData,
  addInterviewSettings,
  addJobSettings,
  getJobSettings,
  getInterviewSettings,
  getReviewSettings,
  addReviewSettings,
  addCompanyWorkingTime,
  fetchAllTimezones,
  getWorkingTime,
  updateRegistration,
  getLisensePlan,
  addSocialMediaSettings,
  getSocialMediaSettings,
  getLocations,
  addQuestionnaireSettings,
  getQuestionnaireSettings,
  addExpirySettings,
  getExpirySettings,
};
export default companyRegistration;
