import dayjs from 'dayjs';
import { SLOT_TYPES, TYPE_OF_EVENT_TYPES } from './constants';
import {
  dateConverter,
  dateFormatter,
  generateTimeSlots,
  toLowercaseAndReplaceSpaces,
} from './utils';
import Config from 'Services/Config';
import { cloneDeep } from 'lodash';

export const getScheduleInterviewAPIParams = (
  values,
  formType,
  jobId,
  selectedRowItems,
  idIsNotCandidateId,
) => {
  let params = null;
  const medium =
    values.advancedInterviewType === 'LIVE'
      ? values.advancedInterviewMedium
      : 'VIDEO';
  const isDueDate =
    values.advancedInterviewDurationType === SLOT_TYPES.dueDate.value;
  const availableSlots =
    values.advancedInterviewDurationType ===
    SLOT_TYPES.availableSlots.value;
  let combinedEndDateAndEndTime = null;
  let combinedStartDateAndStartTime = null;
  if (isDueDate) {
    combinedStartDateAndStartTime = null;
    combinedEndDateAndEndTime = dateConverter(
      values.advancedInterviewDueDateEndDate,
      null,
      false,
    );
  } else if (availableSlots) {
    const selectedSlot = values.slots.find((item) => item.checked);
    const startDate = dayjs(selectedSlot.startDate);
    const startTime = dayjs(selectedSlot.startDate);
    const endDate = dayjs(selectedSlot.endDate);
    const endTime = dayjs(selectedSlot.endDate);
    combinedStartDateAndStartTime = dateConverter(
      startDate,
      startTime,
      true,
    );
    combinedEndDateAndEndTime = dateConverter(endDate, endTime, true);
  } else {
    const startDate = dayjs(values.advancedInterviewSlotStartDate);
    const startTime = dayjs(values.advancedInterviewSlotStartTime);
    const endDate = dayjs(values.advancedInterviewSlotEndDate);
    const endTime = dayjs(values.advancedInterviewSlotEndTime);
    combinedStartDateAndStartTime = dateConverter(
      startDate,
      startTime,
      true,
    );
    combinedEndDateAndEndTime = dateConverter(endDate, endTime, true);
  }
  params = {
    job: jobId,
    candidates: selectedRowItems.map((item) => {
      if (idIsNotCandidateId) {
        return item.candidate_id;
      } else {
        return item.id;
      }
    }),
    interview_level: values.INTERVIEW_LEVEL,
    is_due_date: isDueDate,
    interview_type: values.advancedInterviewType,
    interview_date_time_from:
      combinedStartDateAndStartTime?.convertedValue ||
      combinedStartDateAndStartTime,
    interview_date_time_to:
      combinedEndDateAndEndTime?.convertedValue ||
      combinedEndDateAndEndTime,
    interview_medium: medium,
    subject: values.advancedInterviewSubject,
    round_name: values.round_name,
    ...(!!values.advancedInterviewDuration && {
      duration: values.advancedInterviewDuration,
    }),
  };
  if (values.advancedInterviewInterviewer) {
    params.interviewer_id = values.advancedInterviewInterviewer;
  }
  return params;
};

/**
 *
 * @param { string } resumeUrl
 * @returns
 */
export const getResumePath = (resumeUrl) => {
  return `${Config.API_URL}media/${resumeUrl}`;
};

export const getFileFromBase64 = (encodedString, fileName, type) => {
  const trimmedString = encodedString?.replace(`data:${type};base64,`, '');
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { type });
};

/**
 *
 * @param { object } permissions
 * @returns
 */
export const filterMenusWithPermissions = (
  permissions,
  menus,
  fullMenuList,
) => {
  const hasJobPermission = permissions.VIEW_JOB_MENU;
  const hasCandidatesPermission = permissions.VIEW_CANDIDATE_MENU;
  const hasInterviewPermission = permissions.VIEW_INTERVIEW_MENU;
  const hasCommunicationPermission = permissions.VIEW_COMMUNICATION_MENU;
  const hasQuestionBankPermission = permissions.VIEW_QUESTION_BANK_MENU;
  const hasSettingsPermission = permissions.VIEW_SETTINGS_MENU;
  const hasRegistrationPermission = permissions.VIEW_REGISTRATION_MENU;
  const hasDashboardPermission = permissions.VIEW_DASHBOARD_MENU;
  // const hasInterviewPipelinePermission = true;
  const hasReportPermission = permissions.VIEW_REPORT_MENU;
  const hasSourcingReportPermission = permissions.VIEW_SOURCING_REPORT_TAB;
  const hasScreeningReportPermission = permissions.VIEW_SCREENING_REPORT;
  const hasCommunicationtabReportPermission =
    permissions.VIEW_COMMUNICATION_TAB;
  const hasLicensePermission = permissions.VIEW_LICENSE_MENU;
  const hasEnquiryPermission = permissions.VIEW_ENQUIRIES_MENU;
  const hasQuestionPermission = false; //TODO: Include the permission key when it becomes available
  const hasHiringPipelinePermission =
    permissions.VIEW_HIRING_PIPELINE_MENU;
  const hasMembersPermission = permissions.VIEW_MEMBERS_MENU;
  const hasTaskListPermission = permissions.VIEW_TASK_LIST_MENU;
  const hasMessagesPermission = permissions.VIEW_MESSAGES_MENU;
  const hasNotesPermission = permissions.VIEW_NOTES_MENU;

  const filteredMenuItems = menus.filter((item) => {
    let hasPermission = false;
    if (
      hasJobPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.jobs.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasCandidatesPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.candidates.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasInterviewPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.interviews.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasRegistrationPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.registration.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasCommunicationPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.communication.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasSettingsPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.settings.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasQuestionBankPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.questionBank.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasDashboardPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.dashboard.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasLicensePermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.license.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasReportPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.report.menuName)
    ) {
      if (item?.subMenu && item?.subMenu?.length > 0) {
        if (!hasCommunicationtabReportPermission) {
          item.subMenu = item?.subMenu?.filter(
            (sub) => sub?.menuKey !== 'Report.Communication Report',
          );
        }
        if (
          item.subMenu &&
          !hasSourcingReportPermission &&
          !hasScreeningReportPermission
        ) {
          item.subMenu = item?.subMenu?.filter(
            (sub) => sub?.menuKey !== 'Report.Candidate Report',
          );
        } else if (item.subMenu) {
          if (!hasSourcingReportPermission) {
            item.subMenu[1].subMenu = item?.subMenu[1]?.subMenu.filter(
              (sub) =>
                sub?.menuKey !== 'Report.Candidate Report.Sourcing Report',
            );
          } else if (!hasScreeningReportPermission) {
            item.subMenu[1].subMenu = item?.subMenu[1]?.subMenu.filter(
              (sub) =>
                sub?.menuKey !==
                'Report.Candidate Report.Screening Report',
            );
          }
        }
      }
      hasPermission = true;
    } else if (
      hasEnquiryPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.enquires.menuName)
    ) {
      hasPermission = true;
    } else if (
      hasQuestionPermission &&
      toLowercaseAndReplaceSpaces(item.menuName) ===
        toLowercaseAndReplaceSpaces(fullMenuList.questions.menuName)
    ) {
      hasPermission = true;
    }
    return hasPermission;
  });
  return filteredMenuItems;
};

/**
 *
 * @param {string} eventType
 * @returns function for injecting fullcalendar related styling data
 */
export const getEventStaticInformation = (eventType) => {
  if (eventType === TYPE_OF_EVENT_TYPES.AVAILABLE.value) {
    return TYPE_OF_EVENT_TYPES.AVAILABLE.data;
  } else if (eventType === TYPE_OF_EVENT_TYPES.BOOKED.value) {
    return TYPE_OF_EVENT_TYPES.BOOKED.data;
  } else if (eventType === TYPE_OF_EVENT_TYPES.LEAVE.value) {
    return TYPE_OF_EVENT_TYPES.LEAVE.data;
  }
};

/**
 *
 * @param {array} events
 * @returns events array with full calendar styling data added
 */
export const transformEventsWithStylingData = (events) => {
  const generalEventData = (item) => {
    return {
      editable: false,
      selectable: false,
      borderColor: 'transparent',
      id: item.id,
      start: item.start,
      end: item.end,
      // other non full calendar tags
      typeOfEvent: item.typeOfEvent,
      interview_link: item.interview_link,
      interview_medium: item.interview_medium,
    };
  };
  const eventsClone = cloneDeep(events);
  const transFormedEventsData = eventsClone.map((item) => {
    if (item.typeOfEvent === TYPE_OF_EVENT_TYPES.AVAILABLE.value) {
      return {
        // integrated from fe side
        ...getEventStaticInformation(TYPE_OF_EVENT_TYPES.AVAILABLE.value),
        ...generalEventData(item),
      };
    } else if (item.typeOfEvent === TYPE_OF_EVENT_TYPES.BOOKED.value) {
      return {
        // integrated from fe side
        ...getEventStaticInformation(TYPE_OF_EVENT_TYPES.BOOKED.value),
        ...generalEventData(item),
      };
    } else if (item.typeOfEvent === TYPE_OF_EVENT_TYPES.LEAVE.value) {
      return {
        // integrated from fe side
        ...getEventStaticInformation(TYPE_OF_EVENT_TYPES.LEAVE.value),
        ...generalEventData(item),
      };
    }
    return item;
  });
  return transFormedEventsData;
};

/**
 *
 * @param { string } typeOfEvent
 * @returns will return true or false event type flags
 */
export const getEventBooleanInformation = (typeOfEvent) => {
  const isAvailableEvent =
    typeOfEvent === TYPE_OF_EVENT_TYPES.AVAILABLE.value;
  // const isNotAvailableEvent = typeOfEvent === TYPE_OF_EVENT_TYPES.NOT_AVAILABLE.value;
  // const isFilledEvent = typeOfEvent === TYPE_OF_EVENT_TYPES.FILLED.value;
  const isBookedEvent = typeOfEvent === TYPE_OF_EVENT_TYPES.BOOKED.value;
  const isLeaveEvent = typeOfEvent === TYPE_OF_EVENT_TYPES.LEAVE.value;
  return {
    isAvailableEvent,
    // isNotAvailableEvent,
    // isFilledEvent,
    isBookedEvent,
    isLeaveEvent,
  };
};

/**
 *
 * @param {*} leaveStartDateTime
 * @param {*} leaveEndDateTime
 * @param {*} companyStartingHour
 * @param {*} companyEndingHour
 * @returns function that handles logic for determining full day or half day
 */
export const getLeaveType = (
  leaveStartDateTime,
  leaveEndDateTime,
  companyStartingHour,
  companyEndingHour,
) => {
  // functionality put on halt
  return {
    isHalfDayLeave: false,
    isFullDayLeave: true,
  };
};

/**
 *
 * @param { array } eventsArray
 * @returns events array with modified title to display in calendar, as of now only title is being modified
 */
export const transformEventsWithLogicDetails = (eventsArray) => {
  const transformedEvents = eventsArray.map((item) => {
    const { allDay } = item;
    const startDate = dateFormatter(item.start, 'h:mm a');
    const endDate = dateFormatter(item.end, 'h:mm a');
    const getTitle = (item) => {
      const { isLeaveEvent } = getEventBooleanInformation(
        item.typeOfEvent,
      );
      let title = item.title;
      if (isLeaveEvent) {
        const { isHalfDayLeave } = getLeaveType();
        const titleTransformed = isHalfDayLeave
          ? `${title} (Half Day)`
          : title;
        return titleTransformed;
      } else if (allDay) {
        return title;
      } else {
        const titleTransformed = `${title} (${startDate} - ${endDate})`;
        return titleTransformed;
      }
    };
    const title = getTitle(item);
    return {
      ...item,
      title: title,
    };
  });
  return transformedEvents;
};

export const generateTimeSlotsArray = (companySettings, checked) => {
  const companyStartTimingData = companySettings.working_time_starts_from;
  const companyEndTimingData = companySettings.working_time_ends_to;
  const slotArray = generateTimeSlots(
    companySettings.default_interview_slot_duration,
    companyStartTimingData,
    companyEndTimingData,
  );
  const newArray = slotArray.map((item) => {
    return {
      ...item,
      checked,
    };
  });
  return newArray;
};

export const generateTimeSlotsForCandidates = (companySettings) => {
  const companyStartTimingData = companySettings.working_time_starts_from;
  const companyEndTimingData = companySettings.working_time_ends_to;
  const slotArray = generateTimeSlots(
    companySettings.default_interview_slot_duration,
    companyStartTimingData,
    companyEndTimingData,
    { labelStartFormat: 'hh:mm A', labelEndFormat: 'hh:mm A' },
  );
  const newArray = slotArray.map((item) => {
    return {
      ...item,
      checked: false,
    };
  });
  return newArray;
};

export const displayRequiredIndicator = (mandatory) => {
  if (mandatory) {
    return ' *';
  } else return ' ';
};
