import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, debounce } from 'lodash';

import { useGetConsultancies } from 'ServiceHooks/jobHooks';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';

export default function ConsultancyFormModal(props) {
  const { addConsultancies, close } = props;
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      consultancy: [],
    },
  });
  const [search, setSearch] = useState(null);

  const { data: consultancyList, isFetching: isFetchingConsultancies } =
    useGetConsultancies({
      params: {
        ...(search !== '' && { search }),
      },
    });

  const consultancyOptions = useMemo(() => {
    if (isEmpty(consultancyList)) return [];
    const options = consultancyList?.results.map((item) => {
      return {
        label: item.client,
        value: item.id,
      };
    });
    return options;
  }, [consultancyList]);

  const onSubmit = (values) => {
    addConsultancies(values?.consultancy);
    close();
  };

  return (
    <form
      className="flex flex-col w-2/3 mx-auto my-24"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full">
        <Select
          allowClear
          control={control}
          mode={'multiple'}
          showSearch={true}
          name={'consultancy'}
          options={consultancyOptions}
          fetching={isFetchingConsultancies}
          placeholder="Choose a Company Name"
          label={
            <p>
              Name of the Consultancy
              <span className="text-errorMain">*</span>
            </p>
          }
          onSearch={debounce((value) => setSearch(value), 500)}
          onClear={() => {
            setValue('consultancy', []);
            setSearch(null);
          }}
          rules={{
            required: 'This Field is Required',
          }}
          onChange={(field, onChange) => onChange(field)}
        />
      </div>
      <div className="mx-auto">
        <Button
          type="primary"
          size={'large'}
          htmlType="submit"
          className="m-4"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
