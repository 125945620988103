import React from 'react'
import { Input as AntInput, Form } from 'antd';
import { useController } from 'react-hook-form';

const Input = ({
    label,
    placeholder ='',
    suffix,
    prefix,
    className,
    readOnly=false,
    onChange : customOnChange = null,
    type="text",
    // hook form specific
    name, control, defaultValue, rules = {},
    ...others
}) => {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState : { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <Form.Item 
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntInput 
        onBlur={onBlur}
        onChange={(e) => {
          if(customOnChange)
          {
            customOnChange(onChange, e)
          }
          else
          {
            onChange(e)
          }
        }}
        value={value}
        ref={ref}
        placeholder={placeholder}
        suffix={suffix}
        prefix={prefix}
        className={className}
        readOnly={readOnly}
        type={type}
        {...others}
      />
    </Form.Item>
  )
}

export default Input