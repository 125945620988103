import axios from 'axios';
import Config from '../Services/Config';
import authHeader from './authHeader';

const getPrescreenFeild = (jobId, candidateId, token) => {
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/job-prescreen-questions/${token}/?job=${jobId}&candidate_applied=${candidateId}`,
    {
      headers: authHeader(),
    },
  );
};
const getSearchValues = (searchValue, url) => {
  return axios.get(url + '?search=' + searchValue, {
    headers: authHeader(),
  });
};
const addPrescreenForm = (data, jobId, userId, token) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/job-prescreen-answering/?token=${token}`,
    {
      job: jobId,
      candidate_applied: userId,
      answers: data,
    },
    {
      headers: authHeader(),
    },
  );
};
const getCompanyLogo = () => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-basic-info-without-login/`,
  );
};
const getBig5TraitsList = (params) => {
  return axios.get(
    Config.API_URL +
      `api/v1/analysis/personality-question/${params ? params : ''}`,
  );
};
const addBig5traitsData = (body, token) => {
  return axios.post(
    Config.API_URL + `api/v1/analysis/personality-question/${token}/`,
    body,
  );
};

const getPersonalityInterviewReport = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/personality-report/${id !== '' ? `${id}/` : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getbigfiveDetails = (id, status, reason = '') => {
  return axios.post(
    `${Config.API_URL}api/v1/interview/interview-status-update/`,
    {
      interview_id: id,
      candidate_status: status,
      status_reason: reason,
    },
    {
      headers: authHeader(),
    },
  );
};
const PrescreenService = {
  getPrescreenFeild,
  getSearchValues,
  addPrescreenForm,
  getBig5TraitsList,
  addBig5traitsData,
  getPersonalityInterviewReport,
  getCompanyLogo,
  getbigfiveDetails,
};

export default PrescreenService;
