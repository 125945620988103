import React, { useEffect, useState } from 'react';
import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { Radio, DatePicker, Space } from 'antd';
import RangePicker from 'Components/CommonComponents/ReactHooKForm/RangePicker/RangePicker';
import Button from 'Components/CommonComponents/Button/Button';

const CreateCommunication = () => {
  const [isCreditsValue, setIsCreditsValue] = useState(1);
  const [selectedOption, setSelectedOption] = useState(false);
  const [licencedValue, setLicencedValue] = useState(1);
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const aiInterviewType = watch('ai_interview');
  const liveInterviewType = watch('live_interview');
  const prefixJobReferenceType = watch('skill_type');

  const dropDownData = [
    { value: 'long', label: 'Life Long' },
    { value: 'custom', label: 'Custom' },
  ];
  const onRadioOnchange = (e) => {
    //setLicencedValue(e.target.value)
    setIsCreditsValue(e.target.value);
  };
  const handleDropDown = (e, onChange) => {
    onChange(e);
    if (e.value == 'custom') {
      setSelectedOption(true);
    } else {
      setSelectedOption(false);
    }
  };
  const tabsArray = [
    {
      tabName: 'WhatsApp',
      children: (() => {
        return (
          <>
            <div>
              <form className="customForm">
                <div className="">
                  <Checkbox
                    name="checkbox"
                    showUpperLabel={false}
                    label="WhatsApp"
                    control={control}
                    // disabled
                    rules={{
                      required: 'Please check.',
                      maxLength: 20,
                    }}
                  />
                </div>
                <div class="">
                  <h3 className="text-black text-base font-normal mb-4 mt-6 px-3">
                    WhatsApp Details
                  </h3>
                  <div class="flex -mx-1 ">
                    <div class="w-1/2 px-4">
                      <Input
                        name="name"
                        label="Service Provider"
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                      {/* <div class="bg-gray-400 h-12"></div> */}
                    </div>
                    <div class="w-1/2 px-4 flex-wrap ">
                      <div>
                        <Radio.Group
                          onChange={onRadioOnchange}
                          value={isCreditsValue}
                        >
                          <Radio value={1}>Credits</Radio>
                          <Radio value={2}>Token</Radio>
                        </Radio.Group>
                      </div>

                      <div className="mt-3">
                        <Input
                          name="name"
                          label=""
                          control={control}
                          rules={{
                            required: 'This field is required',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex -mx-1 ">
                    <div class="w-1/2 px-4">
                      <Select
                        name="department"
                        label="Choose a Plan"
                        placeholder=""
                        control={control}
                        options={dropDownData}
                        onChange={handleDropDown}
                        rules={{
                          required: 'This field is required',
                        }}
                        // valueLabel="role_name"
                        // optionLabel="role_name"
                      />
                    </div>
                    <div class="w-1/2 px-4">
                      {selectedOption && (
                        <RangePicker
                          label="Choose a Date"
                          control={control}
                          name="slotDateTimeRange"
                          rules={{
                            required: 'Role name is required',
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div class="flex -mx-1 ">
                    <div class="w-1/2 px-4">
                      <Input
                        name="name"
                        label="API key"
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </div>

                    <div class="w-1/2 px-4">
                      <Input
                        name="name"
                        label="Registered Number"
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-wrap md:flex-row flex-col">
                  <div className="w-1/2"></div>
                  <div className="w-1/2"></div>
                </div>
                <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold ">
                  <Button
                    type="primary"
                    className="ml-4"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </>
        );
      })(),
    },
    {
      tabName: 'SMS',
      children: (
        <div>
          <form className="customForm">
            <div className="">
              <Checkbox
                name="checkbox"
                showUpperLabel={false}
                label="SMS"
                control={control}
                // disabled
                rules={{
                  required: 'Please check.',
                  maxLength: 20,
                }}
              />
            </div>
            <div class="">
              <h3 className="text-black text-base font-normal mb-4 mt-6">
                SMS Details
              </h3>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="Service Provider"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                  {/* <div class="bg-gray-400 h-12"></div> */}
                </div>
                <div class="w-1/2 px-2 flex-wrap ">
                  <div>
                    <Radio.Group
                      onChange={onRadioOnchange}
                      value={isCreditsValue}
                    >
                      <Radio value={1}>Credits</Radio>
                      <Radio value={2}>Token</Radio>
                    </Radio.Group>
                  </div>

                  <div className="mt-3">
                    <Input
                      name="name"
                      label=""
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Select
                    name="department"
                    label="Choose a Plan"
                    placeholder=""
                    control={control}
                    options={dropDownData}
                    onChange={handleDropDown}
                    rules={{
                      required: 'This field is required',
                    }}
                    // valueLabel="role_name"
                    // optionLabel="role_name"
                  />
                </div>
                <div class="w-1/2 px-2">
                  {selectedOption && (
                    <RangePicker
                      label="Choose a Date"
                      control={control}
                      name="slotDateTimeRange"
                      rules={{
                        required: 'Role name is required',
                      }}
                    />
                  )}
                </div>
              </div>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="API key"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>

                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="Registered Number"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex flex-wrap md:flex-row flex-col">
              <div className="w-1/2">
                <Input
                  name="name"
                  label="Sender ID"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="w-1/2"></div>
            </div>
            <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      ),
    },
    {
      tabName: 'Email',
      children: (
        <div>
          <form className="customForm">
            <div className="">
              <Checkbox
                name="checkbox"
                showUpperLabel={false}
                label="Email"
                control={control}
                // disabled
                rules={{
                  required: 'Please check.',
                  maxLength: 20,
                }}
              />
            </div>
            <div class="">
              <h3 className="text-black text-base font-normal mb-4 mt-6">
                Email Details
              </h3>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="Service Provider"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                  {/* <div class="bg-gray-400 h-12"></div> */}
                </div>
                <div class="w-1/2 px-2 flex-wrap ">
                  <div>
                    <Radio.Group
                      onChange={onRadioOnchange}
                      value={isCreditsValue}
                    >
                      <Radio value={1}>Credits</Radio>
                      <Radio value={2}>Token</Radio>
                    </Radio.Group>
                  </div>

                  <div className="mt-3">
                    <Input
                      name="name"
                      label=""
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Select
                    name="department"
                    label="Choose a Plan"
                    placeholder=""
                    control={control}
                    options={dropDownData}
                    onChange={handleDropDown}
                    rules={{
                      required: 'This field is required',
                    }}
                    // valueLabel="role_name"
                    // optionLabel="role_name"
                  />
                </div>
                <div class="w-1/2 px-2">
                  {selectedOption && (
                    <RangePicker
                      label="Choose a Date"
                      control={control}
                      name="slotDateTimeRange"
                      rules={{
                        required: 'Role name is required',
                      }}
                    />
                  )}
                </div>
              </div>
              <div class="flex -mx-1 ">
                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="API key"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>

                <div class="w-1/2 px-2">
                  <Input
                    name="name"
                    label="Registered Number"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex flex-wrap md:flex-row flex-col">
              <div className="w-1/2">
                <Input
                  name="name"
                  label="Sender ID"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="w-1/2"></div>
            </div>
            <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      ),
    },
    {
      tabName: 'Voice Bot',
      children: (
        <div className="resume">
          <div>
            <form className="customForm">
              <div className="">
                <Checkbox
                  name="checkbox"
                  showUpperLabel={false}
                  label="Voice Bot"
                  control={control}
                  // disabled
                  rules={{
                    required: 'Please check.',
                    maxLength: 20,
                  }}
                />
              </div>
              <div class="">
                <h3 className="text-black text-base font-normal mb-4 mt-6">
                  Voice Bot Details
                </h3>
                <div class="flex -mx-1 ">
                  <div class="w-1/2 px-2">
                    <Input
                      name="name"
                      label="Service Provider"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                    {/* <div class="bg-gray-400 h-12"></div> */}
                  </div>
                  <div class="w-1/2 px-2 flex-wrap ">
                    <div>
                      <Radio.Group
                        onChange={onRadioOnchange}
                        value={isCreditsValue}
                      >
                        <Radio value={1}>Credits</Radio>
                        <Radio value={2}>Token</Radio>
                      </Radio.Group>
                    </div>

                    <div className="mt-3">
                      <Input
                        name="name"
                        label=""
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="flex -mx-1 ">
                  <div class="w-1/2 px-2">
                    <Select
                      name="department"
                      label="Choose a Plan"
                      placeholder=""
                      control={control}
                      options={dropDownData}
                      onChange={handleDropDown}
                      rules={{
                        required: 'This field is required',
                      }}
                      // valueLabel="role_name"
                      // optionLabel="role_name"
                    />
                  </div>
                  <div class="w-1/2 px-2">
                    {selectedOption && (
                      <RangePicker
                        label="Choose a Date"
                        control={control}
                        name="slotDateTimeRange"
                        rules={{
                          required: 'Role name is required',
                        }}
                      />
                    )}
                  </div>
                </div>
                <div class="flex -mx-1 ">
                  <div class="w-1/2 px-2">
                    <Input
                      name="name"
                      label="API key"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>

                  <div class="w-1/2 px-2">
                    <Input
                      name="name"
                      label="Registered Number"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-wrap md:flex-row flex-col">
                <div className="w-1/2">
                  <Input
                    name="name"
                    label="Sender ID"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
                <div className="w-1/2"></div>
              </div>
              <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      ),
    },
    {
      tabName: 'Interviews',
      children: (() => {
        return (
          <>
            <div>
              <form className="customForm ">
                <h3 className="text-black text-base font-normal mb-4 mt-6">
                  Interview Type
                </h3>
                <div className="flex mt-4">
                  <div className="">
                    <Checkbox
                      name="ai_interview"
                      showUpperLabel={false}
                      label="AI Interview"
                      control={control}
                      // disabled
                      rules={{
                        required: 'Please check.',
                        maxLength: 20,
                      }}
                    />
                  </div>
                  <div className="px-10">
                    <Checkbox
                      name="live_interview"
                      showUpperLabel={false}
                      label="Live Interview"
                      control={control}
                      // disabled
                      rules={{
                        required: 'Please check.',
                        maxLength: 20,
                      }}
                    />
                  </div>
                  <div className="">
                    <Checkbox
                      name="skill_type"
                      showUpperLabel={false}
                      label="Skill Test"
                      control={control}
                      // disabled
                      rules={{
                        required: 'Please check.',
                        maxLength: 20,
                      }}
                    />
                  </div>
                </div>
                <div className="flex space-x-6">
                  {aiInterviewType === true && (
                    <div className="w-1/2 rounded-md border-dashed border border-gray-300 p-4 mt-6">
                      <h3 className="text-black text-base font-normal mb-4 mt-4">
                        AI interview Details
                      </h3>
                      <div className="w-full flex">
                        <div class="w-1/2 pr-10">
                          <Select
                            name="department"
                            label="Service Provider"
                            placeholder=""
                            control={control}
                            options={dropDownData}
                            onChange={handleDropDown}
                            rules={{
                              required: 'This field is required',
                            }}
                            // valueLabel="role_name"
                            // optionLabel="role_name"
                          />
                        </div>
                        <div class="w-1/2 pr-10">
                          <Select
                            name="department"
                            label="Choose a date"
                            placeholder=""
                            control={control}
                            options={dropDownData}
                            onChange={handleDropDown}
                            rules={{
                              required: 'This field is required',
                            }}
                            // valueLabel="role_name"
                            // optionLabel="role_name"
                          />
                        </div>
                      </div>
                      <div class="w-1/2 px-2 mt-4 flex-wrap mb-6">
                        <div>
                          <Radio.Group
                            onChange={onRadioOnchange}
                            value={isCreditsValue}
                          >
                            <Radio value={1}>Credits</Radio>
                            <Radio value={2}>Token</Radio>
                          </Radio.Group>
                        </div>

                        <div className="mt-3 pr-10">
                          <Input
                            name="name"
                            label=""
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {liveInterviewType === true && (
                    <div className="w-1/2 rounded-md border-dashed border border-gray-300 p-4 mt-6">
                      <h3 className="text-black text-base font-normal mb-4 mt-4">
                        Live interview Details
                      </h3>
                      <div className="w-full flex">
                        <div class="w-1/2 pr-10">
                          <Select
                            name="department"
                            label="Service Provider"
                            placeholder=""
                            control={control}
                            options={dropDownData}
                            onChange={handleDropDown}
                            rules={{
                              required: 'This field is required',
                            }}
                            // valueLabel="role_name"
                            // optionLabel="role_name"
                          />
                        </div>
                        <div class="w-1/2 pr-10">
                          <Select
                            name="department"
                            label="Choose a date"
                            placeholder=""
                            control={control}
                            options={dropDownData}
                            onChange={handleDropDown}
                            rules={{
                              required: 'This field is required',
                            }}
                            // valueLabel="role_name"
                            // optionLabel="role_name"
                          />
                        </div>
                      </div>
                      <div class="w-1/2 px-2 mt-4 flex-wrap mb-6">
                        <div>
                          <Radio.Group
                            onChange={onRadioOnchange}
                            value={isCreditsValue}
                          >
                            <Radio value={1}>Credits</Radio>
                            <Radio value={2}>Token</Radio>
                          </Radio.Group>
                        </div>

                        <div className="mt-3 pr-10">
                          <Input
                            name="name"
                            label=""
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {prefixJobReferenceType === true && (
                  <div className="w-1/2 rounded-md border-dashed border border-gray-300 p-4 mt-6">
                    <h3 className="text-black text-base font-normal mb-4 mt-4">
                      Skill Test Details
                    </h3>
                    <div className="w-full flex">
                      <div class="w-1/2 pr-10">
                        <Select
                          name="department"
                          label="Service Provider"
                          placeholder=""
                          control={control}
                          options={dropDownData}
                          onChange={handleDropDown}
                          rules={{
                            required: 'This field is required',
                          }}
                          // valueLabel="role_name"
                          // optionLabel="role_name"
                        />
                      </div>
                      <div class="w-1/2 pr-10">
                        <Select
                          name="department"
                          label="Choose a date"
                          placeholder=""
                          control={control}
                          options={dropDownData}
                          onChange={handleDropDown}
                          rules={{
                            required: 'This field is required',
                          }}
                          // valueLabel="role_name"
                          // optionLabel="role_name"
                        />
                      </div>
                    </div>
                    <div class="w-1/2 px-2 mt-4 flex-wrap mb-6">
                      <div>
                        <Radio.Group
                          onChange={onRadioOnchange}
                          value={isCreditsValue}
                        >
                          <Radio value={1}>Credits</Radio>
                          <Radio value={2}>Token</Radio>
                        </Radio.Group>
                      </div>

                      <div className="mt-3 pr-10">
                        <Input
                          name="name"
                          label=""
                          control={control}
                          rules={{
                            required: 'This field is required',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold ">
                  <Button
                    type="primary"
                    className="ml-4"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </>
        );
      })(),
    },
  ];
  const tabsItems = tabsArray.map((item, index) => {
    return {
      key: item.tabName,
      label: item.tabName,
      children: item.children,
    };
  });
  return (
    <div className="w-full">
      <div className="bg-secondaryBgMain rounded-lg flex flex-wrap pb-10 mt-4 overflow-y-auto max-h-[calc(100vh-14rem)]">
        <div className="w-full px-8">
          <Tabs
            defaultActiveKey={tabsItems[0]?.label}
            items={tabsItems}
            onChange={(value) => {
              console.log('value', value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CreateCommunication;
