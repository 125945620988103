import excelentEmoji from '../../../../Assets/images/interview/review_icons/excelent.svg';
import poorEmoji from '../../../../Assets/images/interview/review_icons/poor.png';
import mediumEmoji from '../../../../Assets/images/interview/review_icons/medium.svg';
import goodEmoji from '../../../../Assets/images/interview/review_icons/good.svg';

const CommunicationChart = ({ data,interview_type }) => {
  return (
    <div className="h-[332px] flex items-center justify-center flex-col mt-5">
      <div className="h-[110px] w-[110px] mt-[40px]">
        <img
          src={
            data?.overall_score === 'POOR'
              ? poorEmoji
              : data?.overall_score === 'MEDIUM'
                ? mediumEmoji
                : data?.overall_score === 'GOOD'
                  ? goodEmoji
                  : excelentEmoji
          }
          alt="Excelent"
          className="h-full w-full"
        />
      </div>
      <h2 className="text-[#000] text-[18px] font-medium my-[24px]">
        {data?.overall_score === 'POOR'
          ? 'Poor'
          : data?.overall_score === 'MEDIUM'
            ? 'Medium'
            : data?.overall_score === 'GOOD'
              ? 'Good'
              : 'Excellent'}
      </h2>
      <div className="flex items-center justify-center gap-8">
        <div>
          <p className="text-[#535353] text-[10px]">Grammar</p>
          <p className="text-[#535353] text-[12px] font-semibold">
            {data?.grammer}%
          </p>
        </div>
        {interview_type !== 'Online Test' &&
        <>
        <div>
          <p className="text-[#535353] text-[10px]">Vocabulary</p>
          <p className="text-[#535353] text-[12px] font-semibold">
            {data?.vocabulary}%
          </p>
        </div>
        <div>
          <p className="text-[#535353] text-[10px]">Fluency</p>
          <p className="text-[#535353] text-[12px] font-semibold">
            {data?.fluency}%
          </p>
        </div>
        </>
        }
      </div>
    </div>
  );
};

export default CommunicationChart;
