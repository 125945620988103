import React, { useState, useEffect } from 'react';
import {
  useForm,
  Controller,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import {
  BarsOutlined,
  AppstoreOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import TextEdit from '../CommonComponents/TextEdit';
import settingService from '../../Services/settingsService';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cloneDeep, isEmpty, replace } from 'lodash';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Button from 'Components/CommonComponents/Button/Button';

var arrayOption = [];
var arraySelected = [];
var skill = [];
var arrayEdit = [];
const AddPrescreenForm = (props) => {
  const navigate = useNavigate();
  const [option, setOption] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [optionValue, setOptionValue] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const [selected, setSelected] = useState(false);
  const [shortAns, setShortAns] = useState(false);
  const [longAns, setLongAns] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [showTextBox, setShowTextBox] = useState(false);
  const [parameterData, setParameterData] = useState();
  const [isEditCheck, setIsEditCheck] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      optionData: [],
      is_mandatory_field: false,
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'optionData', // unique name for your Field Array
    });
  <option selected value="">
    Choose Parameter Type
  </option>;

  const parameterTypes = [
    { value: 'CHAR', label: 'Text' },
    { value: 'INT', label: 'Number' },
    { value: 'MULTI_SELECT', label: 'Multiple Choices' },
    { value: 'SINGLE_SELECT', label: 'Options' },
    { value: 'DATE', label: 'Date Field' },
    { value: 'BOOL', label: 'Yes/No' },
    { value: 'SKILLS', label: 'Skills' },
    { value: 'QUALIFICATION', label: 'Qualification' },
    { value: 'CERTIFICATION', label: 'Certification' },
  ];
  const onCancel = () => {
    if (!props.parameterId) {
      props.close(false);
    } else {
      props.editClose(false);
    }
  };
  const choosenType = watch('field_type');
  console.log('anseer', choosenType);

  const isMultiSelect = choosenType === 'MULTI_SELECT';
  const isOptionsSelect = choosenType === 'SINGLE_SELECT';

  const uncheckCheckbox = (checkbox_id) => {
    if (
      option ||
      (parameterData && parameterData.field_type == 'SINGLE_SELECT')
    ) {
      var check_boxes = document.getElementsByClassName(
        'option_or_multi_checkbox',
      );
      for (let i = 0; i < check_boxes.length; i++) {
        check_boxes[i].checked = false;
      }
      document.getElementById(checkbox_id).checked = true;
    }
  };
  const onChangeQuestionType = (e, onChange) => {
    onChange(e.value);
    setValue('optionData', []);
  };
  console.log('qtype', selectedType);

  const handleCheckBox = (e, index, onChange, name) => {
    onChange(e);
    if (isOptionsSelect || parameterData?.field_type == 'SINGLE_SELECT') {
      const newArray = cloneDeep(fields);
      newArray.forEach((item, itemIndex) => {
        if (itemIndex === index) {
          setValue(name, e.target.checked);
        } else {
          setValue(`optionData.${itemIndex}.is_answer`, false);
        }
      });
    }
  };
  const handleCheck = (e) => {
    setIsMandatory(!isMandatory);
  };
  console.log('isman', isMandatory);
  const handleShow = () => {
    setIsShow(!isShow);
  };
  const multiArrayCheckbox = (id, e) => {
    setIsEditCheck(!isEditCheck);
    console.log('isedi', isEditCheck);
    if (arrayEdit.length > 0) {
      for (let i = 0; i < arrayEdit.length; i++) {
        if (arrayEdit[i].id == id) {
          if (e.target.checked) {
            arrayEdit[i].is_answer = true;
          } else {
            arrayEdit[i].is_answer = false;
          }
        }
      }
      console.log('cgecj', arrayEdit);
    }
  };
  const onFinish = (values) => {
    console.log('Received values of form:', values);
  };
  const handelBack = () => {
    navigate(props.close(false));
  };
  const handelTextBox = (e) => {
    setShowTextBox(!showTextBox);
  };
  const multiOptionArray = () => {
    if (props.parameterId) {
      parameterData &&
        parameterData?.multi_options?.map((data, index) =>
          arrayEdit.push({
            id: data.id,
            option: data.option,
            is_answer: data.is_answer,
          }),
        );
    }
  };
  useEffect(() => {
    if (props.parameterId) {
      multiOptionArray();
    }
  }, [parameterData && parameterData.field_type == 'MULTI_SELECT']);
  console.log('bd', arrayEdit);

  const editOPtion = (id, e) => {
    console.log('optionid', id);
    console.log('optionEvent', e.target.value);
    if (arrayEdit.length > 0) {
      for (let i = 0; i < arrayEdit.length; i++) {
        if (arrayEdit[i].id == id) {
          arrayEdit[i].option = e.target.value;
        }
      }
      console.log('testarray', arrayEdit);
    }
  };

  const getParameterData = async () => {
    await settingService
      .getPrescreenById(props.parameterId)
      .then((response) => {
        setParameterData(response.data.data);
        prescreenInfo(response.data.data);
        // setIsMandatory(response?.data?.data?.is_mandatory_field)
        // setQuestion(response?.data?.data?.name)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const prescreenInfo = (data) => {
    setValue('name', data?.name);
    setValue('is_mandatory_field', data?.is_mandatory_field);
    setValue('field_type', data?.field_type);
  };

  useEffect(() => {
    if (props.parameterId) {
      getParameterData();
    }
  }, [props.isEdit]);
  useEffect(() => {
    if (isEmpty(parameterData?.multi_options)) return;
    var OPTION_DATA = { optionData: parameterData?.multi_options };

    setValue('optionData', OPTION_DATA.optionData);
    replace(parameterData?.multi_options);
  }, [parameterData]);
  const handleOption = (e) => {
    if (multiple && optionValue) {
      arrayOption.push({
        option: optionValue,
      });
      setIsCheck(false);
      console.log('Oo', arrayOption);
    } else if (option && optionValue) {
      arraySelected.push({
        option: optionValue,
      });
      setShowTextBox(false);
      console.log('optiosnsss', arraySelected);
    }
  };
  const OptionListViews = () => {
    return fields.map((field, index) => (
      <div className="flex pt-10 ">
        <div class="w-3/4 px-8">
          <Input
            key={field.id}
            name={`optionData.${index}.option`}
            label={false}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            //className="rounded-md border border-gray-400 shadow-md h-12 w-full flex-shrink-0 pl-4"
          />
        </div>
        <div className="px-8">
          <Checkbox
            key={field.id}
            name={`optionData.${index}.is_answer`}
            showUpperLabel={false}
            label="isAnswer"
            control={control}
            onChange={(e, onChange) =>
              handleCheckBox(
                e,
                index,
                onChange,
                `optionData.${index}.is_answer`,
              )
            }
            // disabled
            // rules={{
            //   required: 'Please check.',
            //   maxLength: 20
            // }}
          />
        </div>
        <div className="px-8 mt-1">
          <MinusCircleOutlined
            onClick={() => remove(index)}
            className="list_icon"
          />
        </div>

        {/* <input
              key={field.id} // important to include key with field's id
              {...register(`test.${index}.value`)}
              className="w-f h-10  rounded-md border pl-4"
            /> */}
      </div>
    ));
  };
  const sendData = async (data) => {
    // if (multiple || option) {
    //     var arrays = document.getElementsByClassName("optiontextbox")
    //     arrayOption = []
    //     for (let i = 0; i < arrays.length; i++) {
    //         // Do something with each element, for example, add a class
    //         var checkbox = document.getElementById('option_check_' + arrays[i].getAttribute('data-optionKey'))
    //         arrayOption.push({
    //             option: arrays[i].value,
    //             is_answer: checkbox.checked,
    //         });
    //     }
    // }
    // if (shortAns) {
    //     data['name'] = data.name
    //     data['field_type'] = data.field_type
    //     data['is_mandatory_field'] = isMandatory
    // }
    // if (option) {
    //     data['name'] = data.name
    //     data['field_type'] = data.field_type.value
    //     data['is_mandatory_field'] = isMandatory
    //     data['multi_options'] = arrayOption
    // }
    // else if (multiple) {
    //     data['name'] = data.name
    //     data['field_type'] = data.field_type.value
    //     data['is_mandatory_field'] = isMandatory
    //     data['multi_options'] = arrayOption
    // }
    // else {
    //     data['name'] = data.name
    //     data['field_type'] = data.field_type.value
    //     data['is_mandatory_field'] = isMandatory
    // }
    console.log('prescreendata', data.optionData);
    if (
      (isOptionsSelect || isMultiSelect) &&
      data.optionData.length == 0
    ) {
      toast.error('Please Add Options ');
    } else if (data.optionData.length > 0 && data.optionData.length < 3) {
      toast.error('minimum three options required');
    } else {
      await settingService
        .addPrescreenParameter(data)
        .then(
          (response) => {
            console.log(response.data.data);
            toast.success('Successfully added new item..!!');
            props.close(false);
          },
          (error) => {
            console.log('erroe', error?.response?.data?.error_info);
            const errorsObj = error?.response?.data?.error_info;
            console.log('objerror', Object.keys(errorsObj));
            Object.keys(errorsObj).forEach((field) => {
              const messages = errorsObj[field];
              setError(field, {
                type: 'server',
                message: messages.join('. '),
              });
            });
          },
        )
        .catch((error) => {
          console.log('errr');
          toast.error('Something went wrong..!!');
          props.close(false);
        });
    }
  };
  const updateData = async (data) => {
    // if (parameterData?.field_type == "SINGLE_SELECT" || parameterData?.field_type == "MULTI_SELECT") {
    //     var arrays = document.getElementsByClassName("optiontextbox")
    //     var arrayEdit = [];
    //     for (let i = 0; i < arrays.length; i++) {
    //         if (arrays[i].getAttribute('data-optionKey') != null) {
    //             var checkbox = document.getElementById('option_check_' + arrays[i].getAttribute('data-optionKey'))
    //         } else {
    //             var checkbox = document.getElementById(arrays[i].getAttribute('data-optionKeyExisted'))
    //         }
    //         arrayEdit.push({
    //             option: arrays[i].value,
    //             is_answer: checkbox.checked,
    //         });
    //     }
    // }
    // if (parameterData && parameterData.field_type == "CHAR") {
    //     data['name'] = data.name
    //     data['field_type'] = "CHAR"
    //     data['is_mandatory_field'] = isMandatory
    // }

    // if (parameterData && parameterData.field_type == "SINGLE_SELECT") {
    //     data['name'] = data.name
    //     data['field_type'] = "SINGLE_SELECT"
    //     data['is_mandatory_field'] = isMandatory
    //     data['multi_options'] = arrayEdit
    // }
    // if (parameterData && parameterData.field_type == "MULTI_SELECT") {
    //     data['name'] = data.name
    //     data['field_type'] = "MULTI_SELECT"
    //     data['is_mandatory_field'] = isMandatory
    //     data['multi_options'] = arrayEdit
    // }
    if (
      (isOptionsSelect || isMultiSelect) &&
      data.optionData.length == 0
    ) {
      toast.error('Please Add Options ');
    } else if (data.optionData.length > 0 && data.optionData.length < 3) {
      toast.error('minimum three options required');
    } else {
      await settingService
        .editPrescreenParameter(props.parameterId, data)
        .then((response) => {
          console.log(response.data.data);
          toast.success('Successfully Edited!!');
          props.close(false);
        })
        .catch((error) => {
          console.log('errr');
          toast.error('Something went wrong..!!');
        });
    }
  };
  const onSubmit = async (data) => {
    if (props.parameterId) {
      updateData(data);
    } else {
      sendData(data);
    }
  };
  const OptionListView = (data) => {
    return (
      <div className="pl-5">
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          style={{
            maxWidth: '75%',
          }}
          autoComplete="off"
        >
          {parameterData &&
            parameterData?.multi_options?.map((item, index) => (
              <div className="flex">
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="w-1/2"
                >
                  <Input
                    type="text"
                    data-optionKeyExisted={
                      'option_check_already_existed_field_' + index
                    }
                    defaultValue={item.option ? item.option : ''}
                    key={index}
                    placeholder="Option Name"
                    className="rounded-md h-10 mt-5  mr-40 p-4 optiontextbox"
                    onChange={(e) => editOPtion(item.id, e)}
                  />
                </Form.Item>
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="pl-8 mt-6 ml-6 flex"
                >
                  {parameterData &&
                    parameterData.field_type == 'MULTI_SELECT' && (
                      <Checkbox.Group
                        defaultValue={
                          item.is_answer == true ? 'isAnswer' : ''
                        }
                      >
                        <Checkbox
                          id={
                            'option_check_already_existed_field_' + index
                          }
                          value={'isAnswer'}
                          onChange={(e) => multiArrayCheckbox(item.id, e)}
                        >
                          isAnswer
                        </Checkbox>
                      </Checkbox.Group>
                    )}
                </Form.Item>
                <Form.Item
                  key={index}
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="pl-4 mt-2 flex"
                >
                  {parameterData &&
                    parameterData.field_type == 'SINGLE_SELECT' && (
                      <div className="flex absolute ">
                        <input
                          defaultChecked={item?.is_answer}
                          className={
                            'border-4 w-5 h-4 border-black ml-10 mt-[6px] option_or_multi_checkbox ' +
                            'option_element_' +
                            index
                          }
                          id={
                            'option_check_already_existed_field_' + index
                          }
                          type="checkbox"
                          onChange={() =>
                            uncheckCheckbox(
                              'option_check_already_existed_field_' +
                                index,
                            )
                          }
                        />

                        <label className="pl-3 pb-6 mt-[2px]">
                          isAnswer
                        </label>
                      </div>
                    )}
                </Form.Item>
              </div>
            ))}
          <Form.List name="users" className="w-full">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing options',
                        },
                      ]}
                      className="w-full mt-4"
                    >
                      <Input
                        control={control}
                        data-optionKey={name}
                        placeholder="Option Name"
                        className="rounded-md h-[36px] flex-shrink-0 p-4 optiontextbox"
                        onChange={(e) => setOptionValue(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing options',
                        },
                      ]}
                      className="w-1/2 pt-4"
                    >
                      {(multiple ||
                        (parameterData &&
                          parameterData.field_type == 'MULTI_SELECT')) && (
                        <Checkbox.Group
                          // onChange={onCommunicationChange}
                          //   value={communicationValue}
                          className="multiple-choice ml-5"
                        >
                          <Checkbox
                            id={'option_check_' + name}
                            className={
                              'option_or_multi_checkbox ' +
                              'option_element_' +
                              name
                            }
                            value={'isAnswer'}
                            //onChange={(e) => handleCheckBox(e)}
                            //onChange={() => uncheckCheckbox('option_element_'+name)}
                          >
                            isAnswer
                          </Checkbox>
                        </Checkbox.Group>
                      )}
                      {(option ||
                        (parameterData &&
                          parameterData.field_type ==
                            'SINGLE_SELECT')) && (
                        <div className="flex absolute ">
                          <input
                            className={
                              'border-4 w-5 h-4 border-black ml-10 mt-[6px] option_or_multi_checkbox ' +
                              'option_element_' +
                              name
                            }
                            id={'option_check_' + name}
                            type="checkbox"
                            onChange={() =>
                              uncheckCheckbox('option_check_' + name)
                            }
                          />
                          <label className="pl-3 pb-6 mt-[2px]">
                            isAnswer
                          </label>
                        </div>
                      )}
                      {(multiple ||
                        (parameterData &&
                          parameterData.field_type == 'MULTI_SELECT')) && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="list_icon pr-20 w-1/3"
                        />
                      )}
                      {(option ||
                        (parameterData &&
                          parameterData.field_type ==
                            'SINGLE_SELECT')) && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="option-minus-circle w-1/4"
                          id="multiple-minus-circle"
                        />
                      )}
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
                    onClick={() => {
                      add();
                      // handleOption();
                    }}
                    icon={
                      <PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />
                    }
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </div>
    );
  };
  return (
    <div className="w-full">
      <div
        className="flex items-center cursor-pointer"
        type="text"
        onClick={handelBack}
      >
        <ArrowBackIcon />{' '}
        <span className="ml-4 text-base font-medium">Settings</span>
      </div>
      <div className="mx-4">
        <h3 className="text-black text-base font-medium mb-4 mt-4">
          {props.parameterId
            ? 'Edit Add Prescreen Parameter'
            : 'Add Prescreen Parameter'}
        </h3>
        <form
          className="pt-5 w-full h-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-secondaryBgMain w-full rounded-md h-fit pb-10 ">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-2/3 mt-4 w-full px-6">
                <Input
                  name="name"
                  label="Prescreen Question"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="w-1/3 mt-16 px-10">
                <Checkbox
                  name="is_mandatory_field"
                  showUpperLabel={false}
                  label="Mandatory"
                  control={control}
                  // disabled
                  // rules={{
                  //   required: 'Please check.',
                  //   maxLength: 20
                  // }}
                />
              </div>
            </div>
            <div className="flex md:flex-row flex-col">
              <div className="w-1/2 px-6">
                <Select
                  name="field_type"
                  control={control}
                  label="Parameter Type"
                  placeholder="Select Parameter Type"
                  onChange={onChangeQuestionType}
                  options={parameterTypes}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
            {(isOptionsSelect || isMultiSelect) && (
              <div className="w-1/2 flex pb-8">
                <div>
                  {OptionListViews()}
                  <Button
                    type="border"
                    className="w-[107px] h-[30px] flex-shrink-0 rounded-4 bg-teal-600 text-white ml-[580px] mt-[23px]"
                    onClick={() => {
                      append({
                        option: '',
                        is_answer: false,
                      });
                    }}
                    icon={
                      <PlusOutlined className="w-[10px] h-[9.677px] flex-shrink-0 text-white" />
                    }
                  >
                    Add field
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-wrap justify-end mt-2">
            <button className="popup_skills primary-btn px-1 ">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  // return (
  //     <div className="w-full">
  //         <div className="flex items-center cursor-pointer" type="text" onClick={handelBack}>
  //             <ArrowBackIcon /> <span className="ml-4 text-base font-medium">Settings</span>
  //         </div>
  //         <h3 className="text-black text-base font-medium mb-4 mt-4">{props.parameterId ? "Edit Add Prescreen Parameter" : "Add Prescreen Parameter"}</h3>
  //         <form className="pt-5 w-full h-auto" onSubmit={handleSubmit(onSubmit)}>
  //             <div className="w-full rounded-md bg-white  h-fit pb-10 p-4 mb-4 ">
  //                 <div className="w-full px-3 mb-2">
  //                     <div className="w-full mt-[20px]">
  //                         <label className="text-black font-poppins text-xm mb-2 font-normal leading-normal pb-[9px]">
  //                             Presceen Question
  //                         </label>
  //                     </div>
  //                     <div className="flex md:flex-row flex-col">
  //                         <div className="md:w-2/3 mt-4 w-full">
  //                             <input
  //                                 type="text"
  //                                 {...register("name", {
  //                                     required: "This field is required",
  //                                 })}
  //                                 className="w-full h-10  rounded-md border pl-4"
  //                             />
  //                             {errors && errors.question && (
  //                                 <p className="text-red-600 text-xs font-semibold text-start ml-[50px]">
  //                                     *{errors.question.message}
  //                                 </p>
  //                             )}
  //                         </div>

  //                         <div className="w-1/3 flex relative md:ml-20 ml-2 mt-8">
  //                             <input
  //                                 // id={data.id}
  //                                 type="checkbox"
  //                                 defaultChecked={parameterData?.is_mandatory_field}
  //                                 onChange={(e) => handleCheck(e)}
  //                                 className="border-4 w-5 h-4 border-black"
  //                             />
  //                             <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2 text-start">
  //                                 Mandatory
  //                             </label>
  //                         </div>
  //                     </div>
  //                     <div className="w-full mt-[24px]">
  //                         {/* <label className="text-black text-xs font-normal leading-normal">
  //                             Parameter Type
  //                         </label> */}
  //                         <div className="flex flex-wrap">
  //                             <div className="w-1/2 mt-[9px]">
  //                             <Select
  //                                 name="field_type"
  //                                 control={control}
  //                                 label="Parameter Type"
  //                                 placeholder="Select Parameter Type"
  //                                 onChange={onChangeQuestionType}
  //                                 options={parameterTypes}
  //                                 rules={{
  //                                     required: 'This field is required'
  //                                 }}

  //                             />
  //                                 {/* <select
  //                                     id="countries"
  //                                     className="w-full h-12 flex-shrink-0 rounded-md border border-gray-400 border-opacity-75 shadow-md pl-4"
  //                                     disabled={parameterData?.is_field_type_editable == true}
  //                                     {...register("field_type", {
  //                                         required: "This field is required",
  //                                     })}
  //                                     onChange={onChangeQuestionType}
  //                                 >
  //                                     <option selected value="">Choose Parameter Type</option>
  //                                     <option value="CHAR">Text</option>
  //                                     <option value="SINGLE_SELECT">Options</option>
  //                                     <option value="MULTI_SELECT">Multiple Choices</option>
  //                                     <option value="DATE">Date Field</option>
  //                                     <option value="QUALIFICATION">Qualification</option>
  //                                     <option value="SKILLS">Skills</option>
  //                                     <option value="CERTIFICATION">Certification</option>
  //                                     <option value="BOOL">Yes/No</option>
  //                                 </select> */}
  //                                 {/* {errors && errors.field_type && (
  //                                     <p className="text-red-600 text-xs font-semibold text-start ml-12">
  //                                         *{errors.field_type.message}
  //                                     </p>
  //                                 )} */}
  //                             </div>

  //                         </div>
  //                         {(option || parameterData?.field_type == "SINGLE_SELECT") && (
  //                             <div className="pt-4">
  //                                 {OptionListView()}
  //                             </div>
  //                         )}
  //                         {(multiple || parameterData?.field_type == "MULTI_SELECT") && (
  //                             <div  className="pt-4">
  //                                 {OptionListView()}
  //                             </div>
  //                         )}
  //                     </div>
  //                 </div>

  //             </div>

  //             <div className="flex flex-wrap justify-end mt-2">
  //                 <button className="popup_skills primary-btn px-1 ">Save</button>
  //             </div>
  //         </form>

  //     </div>

  // );
};

export default AddPrescreenForm;
