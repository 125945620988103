import Carousel from 'Components/Carousel';
import { useRef, useState } from 'react';
import guidLineOne from '../../Assets/images/interview/guildline1_new.png';
import guidLineTwo from '../../Assets/images/interview/guidline2_new.png';
import guidLineThree from '../../Assets/images/interview/guidline3_new.png';
import guidLineFour from '../../Assets/images/interview/guidline4_new.png';
import guidLineFive from '../../Assets/images/interview/guidline5_new.png';
// import InterviewContains from 'Components/InHouseTest/InterviewContains';
import AITestScreen from './MobileScreens/AITestScreen';
import { Checkbox } from 'antd';

const GuidlinesScreen = ({ interviewInfoData }) => {
  const [isTermsAndConditionAgree, setTermsAndConditionAgree] =
    useState(true);

  const [isIncludeScreen, setIncludeScreen] = useState(false);

  const carouselEvent = useRef();

  const goToIncludeScreen = () => {
    setIncludeScreen(true);
  };

  const items = [
    <div
      className="guidline_item flex items-center px-10 py-4 relative"
      data-value="1"
    >
      <div className="tab:w-[55%] w-[75%]">
        <p className="guidlinesText tab:text-[26px] text-[20px] ms:text-left text-center">
          Please put your face covering 30% of the video screen through out
          the interview
        </p>
      </div>
      <div className="tab:w-[45%] sm:w-[60%] w-[70%]">
        <img src={guidLineOne} alt="img" />
      </div>
    </div>,
    <div
      className="guidline_item flex items-center px-10 py-4 relative"
      data-value="2"
    >
      <div className="tab:w-[55%] w-[75%]">
        <p className="guidlinesText tab:text-[26px] text-[20px] ms:text-left text-center">
          Please ensure enough lighting on face through out the interview.
          Dimming the light on face may impact your selection process
        </p>
      </div>
      <div className="tab:w-[45%] sm:w-[60%] w-[70%]">
        <img src={guidLineTwo} alt="img" />
      </div>
    </div>,
    <div
      className="guidline_item flex items-center px-10 py-4 relative"
      data-value="3"
    >
      <div className="tab:w-[55%] w-[75%]">
        <p className="guidlinesText tab:text-[26px] text-[20px] ms:text-left text-center">
          Microphone (preferably ear phone with mic) and mobile should have
          adequate power backup to cover the full interview period
        </p>
      </div>
      <div className="tab:w-[45%] sm:w-[60%] w-[70%]">
        <img src={guidLineThree} alt="img" />
      </div>
    </div>,
    <div
      className="guidline_item flex items-center px-10 py-4 relative"
      data-value="4"
    >
      <div className="tab:w-[55%] w-[75%]">
        <p className="guidlinesText tab:text-[26px] text-[20px] ms:text-left text-center">
          Ensure proper stable internet connectivity. It may impact
          answering to the questions and may impact your selection process
        </p>
      </div>
      <div className="tab:w-[45%] sm:w-[60%] w-[70%]">
        <img src={guidLineFour} alt="img" />
      </div>
    </div>,
    <div
      className="guidline_item flex items-center px-10 py-4 relative"
      data-value="4"
    >
      <div className="tab:w-[55%] w-[75%]">
        <p className="guidlinesText tab:text-[26px] text-[20px] ms:text-left text-center">
          Do not read questions aloud, which may disqualify you !
        </p>
      </div>
      <div className="tab:w-[45%] sm:w-[60%] w-[70%]">
        <img src={guidLineFive} alt="img" />
      </div>
    </div>,
  ];

  const buildGuidlineLayout = () => {
    return (
      <div className="guidlines">
        <div className="guidlinesHeaderDiv pb-0">
          <h1>Guidelines</h1>
        </div>
        <div className="guidlinesContent_div">
          <div style={{ flexGrow: '1', width: '40%' }}>
            <Carousel
              autoPlay={false}
              slideItem={items}
              refEvent={carouselEvent}
              disableButtonsControls={false}
            />
          </div>
        </div>
        <div className="guidlinesFooterDiv pt-0">
          <div className="guidline_btn_div">
            <Checkbox
              onChange={() => {
                setTermsAndConditionAgree(!isTermsAndConditionAgree);
              }}
              className='round_checkbox'
            >
              I agree to terms and conditions
            </Checkbox>
            {/* <p className='flex items-center gap-2'>
              <input
                type="checkbox"
                onChange={() => {
                  setTermsAndConditionAgree(!isTermsAndConditionAgree);
                }}
                className='round_checkbox'
              />
              I agree to terms and conditions
            </p> */}
            <button
              className="goToInterview mt-7"
              disabled={isTermsAndConditionAgree}
              onClick={goToIncludeScreen}
            >
              Go to Test Screen
            </button>
          </div>
        </div>
        {/* <button onClick={(e) => carouselEvent?.current?.slideNext(e)}>Next</button> */}
      </div>
    );
  };

  return isIncludeScreen ? (
    <AITestScreen nextScreen={(value) => {}} />
  ) : (
    buildGuidlineLayout()
  );
};
export default GuidlinesScreen;
