import { useMemo, useState, useCallback } from 'react';
import {
  DataGridPro,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  GridPagination,
  gridPageSizeSelector,
  useGridRootProps,
  useGridApiRef,
  gridFilteredTopLevelRowCountSelector,
  GridLogicOperator,
  GridFilterForm,
  gridColumnVisibilityModelSelector,
  getGridStringOperators,
  getGridNumericOperators,
} from '@mui/x-data-grid-pro';
// import 'Test/Test2.scss';
import MuiPagination from '@mui/material/Pagination';
import './DataGridLayout.scss';
import { LicenseInfo } from '@mui/x-license-pro';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import authService from 'Services/authService';

const dataTableLicense =
  '4b53847c8b097ec7a72dfa613c3d0928Tz02NzUyMSxFPTE3MTcwNDk0MDI1NDgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
LicenseInfo.setLicenseKey(dataTableLicense);

const getPageCount = (rowCount, pageSize) => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }
  return 0;
};

const AUTOSIZE_COLUMNS_VALUES = {
  includeOutliers: false,
  includeHeaders: true,
  expand: true,
};

export default function DataGridLayout({
  // old
  boxShadow = 0,
  hideFooterPagination = false,
  handelRowClick = () => null,
  defaultStyles = {},
  defaultColumn = {},
  columnsFrom = null,
  columnsTabStatus = null,
  useNewPagination = false,
  // new
  showCustomPagination = true,
  apiRef,
  results, // custom prop
  checkboxSelection = false,
  onRowSelectionModelChange = () => null,
  rowSelectionModel = [],
  paginationModel,
  onPaginationModelChange,
  paginationMode = 'server',
  pageSizeOptions = [25, 50, 100],
  loading = false,
  keepNonExistentRowsSelected = false,
  pagination = true,
  onFilterModelChange = () => null,
  getRowClassName = () => null,
  filterMode = 'server',
  onRowClick = () => null,
  showQuickFilterOption = false,
  onSearchDataGrid = () => null,
  onDoubleClick = () => null,
  disableExportButton = false,
  disableColumnSelectorMenu = false,
  disableFiltersSelectorMenu = false,
  disableDensitySelectorMenu = false,
  onColumnOrderChange = () => null,
  ...others
}) {
  const { column } = others;
  const stylePresets = {
    '& .MuiDataGrid-panelContent': {
      maxHeight: '280px',
    },
    '& .MuiDataGrid-menuIcon': {
      visibility: 'visible',
      marginRight: '10px',
    },
    '& .MuiButton-root': {
      color: '#0c2556',
    },
    '& .MuiDataGrid-autoHeight': {
      width: '100%',
      overflowX: 'auto',
    },
    '& .MuiDataGrid-cell--withRenderer div': {
      paddingLeft: '0!important',
    },
    '& .MuiDataGrid-main .MuiDataGrid-virtualScroller': {
      marginTop: '0 !important',
      overflowY: 'hidden',
      ...((loading || results?.count < 1) && { minHeight: '8rem' }),
    },
  };

  const styles = {
    ...stylePresets,
    ...defaultStyles,
  };

  {
    /* -----------------------  old content starts ----------------------- */
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState(defaultColumn);

  const activeColumnCount = Object.values(columnVisibilityModel)?.filter(
    (item) => !!item,
  )?.length;

  const changeVisibilityModel = useCallback(
    (data) => {
      if (Object.keys(data).length === 0) {
        data = gridColumns.reduce((model, column) => {
          model[column.field] = true;
          return model;
        }, {});
      }
      setColumnVisibilityModel(data);
      apiRef.current.autosizeColumns(AUTOSIZE_COLUMNS_VALUES);
      //TODO: the "Show All" button throws an empty object. Saving it as
      //      a preference erases earlier changes. Made a temporary workaround
      //      ignoring the empty object.
    },
    [columnVisibilityModel],
  );

  {
    /* -----------------------  old content ends ----------------------- */
  }

  // show filter column based on selection
  const filterColumns = ({ field, columns, currentFilters }) => {
    console.log(
      'field, columns, currentFilters',
      field,
      columns,
      currentFilters,
    );
    return columns
      .filter((col) => {
        if (columnVisibilityModel[col.field] === false) {
          return false;
        }
        return true;
      })
      .map((col) => col.field);
  };

  // add StringOperators

  const filterOperatorsString = getGridStringOperators().filter(
    ({ value }) =>
      ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
  );

  // add NumberOperators

  const filterOperatorsNumber = getGridNumericOperators().filter(
    ({ value }) => ['=', '>', '>=', '<', '<='].includes(value),
  );

  const gridColumns = useMemo(() => {
    const newColumns = cloneDeep(column);
    if (newColumns.length > 0) {
      newColumns.map((item) => {
        if (item?.type === 'number') {
          item.filterOperators = filterOperatorsNumber;
        } else {
          item.filterOperators = filterOperatorsString;
        }

        if (activeColumnCount < 7) {
          item['flex'] = 1;
        } else {
          item['minWidth'] = 150;
        }
      });
    }

    return newColumns;
  }, [column, activeColumnCount, columnVisibilityModel]);

  return (
    <div className={`dateGridTable ${useNewPagination ? 'new' : 'old'}`}>
      <DataGridPro
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLogicOperator.Or,
            },
          },
        }}
        pagination={pagination}
        pageSizeOptions={pageSizeOptions}
        paginationMode={paginationMode}
        rowSelectionModel={rowSelectionModel}
        // server side pagination related
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        rowCount={results?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={onRowSelectionModelChange}
        hideFooterSelectedRowCount
        // existing items
        sx={{
          boxShadow: boxShadow,
          ...styles,
        }}
        columns={gridColumns}
        rows={others.row}
        slots={{
          pagination: useNewPagination
            ? showCustomPagination
              ? CustomPagination
              : null
            : null,
          toolbar: GridToolbar,
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              filterColumns,
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { m: '3px' },
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { m: '3px' },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { m: '3px' },
                },
              },
              deleteIconProps: {
                sx: {
                  '& .MuiSvgIcon-root': {
                    color: '#d32f2f',
                    marginBottom: '6px',
                  },
                },
              },
            },
            disableAddFilterButton: true,
            disableRemoveAllButton: true,
          },
          toolbar: {
            csvOptions: { disableToolbarButton: disableExportButton },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: showQuickFilterOption,
            onChange: debounce((event) => {
              onSearchDataGrid(event.target.value);
            }, 500),
          },
        }}
        //disableFilteringHiddenColumns={true}
        checkboxSelection={checkboxSelection}
        loading={loading}
        rowHeight={40}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          changeVisibilityModel(newModel);
        }}
        scrollbarSize={2}
        getRowClassName={getRowClassName}
        onFilterModelChange={debounce((e, type) => {
          console.log('filter', e.items[0]);
          onFilterModelChange(e, type);
        }, 500)}
        filterMode={filterMode}
        onRowClick={onRowClick}
        autosizeOnMount={true}
        autosizeOptions={AUTOSIZE_COLUMNS_VALUES}
        onCellDoubleClick={onDoubleClick}
        disableColumnFilter={disableFiltersSelectorMenu}
        disableColumnSelector={disableColumnSelectorMenu}
        disableDensitySelector={disableDensitySelectorMenu}
        onColumnOrderChange={(params) => onColumnOrderChange(params)}
      />
    </div>
  );
}

function Pagination({ page, onPageChange, className, ...others }) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector,
  );
  const rootProps = useGridRootProps();
  const pageCount = getPageCount(
    rootProps.rowCount ?? visibleTopLevelRowCount,
    pageSize,
  );
  // console.log('Pagination',apiRef);
  // console.log('visibleTopLevelRowCount',visibleTopLevelRowCount);
  // console.log('pageSize',pageSize);
  return (
    <>
      <MuiPagination
        color="primary"
        className={`${className} dataGridCustomPagination`}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          console.log('event, newPage', event, newPage);
          onPageChange(event, newPage - 1);
        }}
      />
    </>
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

/*
  page = 0 for 1st page
  pageSize = number of items needed in page
  totalItems = total number of results
*/
const calculatePaginationData = (page, totalItems, pageSize) => {
  //console.log("testings",{page, totalItems, pageSize});
  const start = page * pageSize + 1;
  const end =
    (page + 1) * pageSize < totalItems
      ? (page + 1) * pageSize
      : totalItems;
  // offset is 0 for first element
  const offset = page * pageSize;
  return {
    page,
    pageSize,
    offset,
    start,
    end,
  };
  // "page": 0,
  // "pageSize": 5,
  // "offset": 0
};

const DEFAULT_DATA_GRID_CONFIG = {
  defaultPageSize: 25,
};

export const useDataGridLayout = ({
  defaultPageSize,
} = DEFAULT_DATA_GRID_CONFIG) => {
  const apiRef = useGridApiRef();
  const [selectedRowItems, setSelectedRowItems] = useState([]);
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const handleRowSelectModal = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: defaultPageSize,
    offset: 0,
    start: 0,
    end: 0,
  });

  const handlePaginationModalChange = (model, details) => {
    const pageNumber = model.page;
    const limit = model.pageSize;
    const offset = pageNumber * limit;
    const paginationModelData = {
      ...model,
      offset: offset,
    };
    console.log('paginationModelData', paginationModelData);
    setPaginationModel(paginationModelData);
    return paginationModelData;
  };

  // function to set selected item by default using id
  const getSelectedRowItemObject = useCallback((entireList, id, label) => {
    if (!id) {
      setSelectedRowItem(null);
      return null;
    }
    const selectedObject = entireList.find((item) => item[label] === id);
    setSelectedRowItem(selectedObject);
    return selectedObject;
  }, []);
  // function to set selected items by default as an array
  const getAllSelectedRowItemObjects = useCallback(
    (entireList, newRowSelectionModel, label) => {
      const filteredItems = entireList.filter((item) =>
        newRowSelectionModel.includes(item[label]),
      );
      setSelectedRowItems(filteredItems);
      return filteredItems;
    },
    [],
  );
  const handleRowSelectionModelChange = useCallback(
    (newRowSelectionModel, entireList = [], label = 'id') => {
      if (
        entireList?.results?.length > 0 &&
        newRowSelectionModel.length > 0
      ) {
        getAllSelectedRowItemObjects(
          entireList.results,
          newRowSelectionModel,
          label,
        );
        if (newRowSelectionModel.length === 1) {
          getSelectedRowItemObject(
            entireList.results,
            newRowSelectionModel[0],
            label,
          );
        } else {
          getSelectedRowItemObject(entireList.results, null, label);
        }
      } else {
        getAllSelectedRowItemObjects([], newRowSelectionModel);
        getSelectedRowItemObject([], null);
      }
      setRowSelectionModel(newRowSelectionModel);
    },
    [getSelectedRowItemObject, getAllSelectedRowItemObjects],
  );

  const checkBoxSelectionCountText = useMemo(() => {
    if (isEmpty(rowSelectionModel)) return;
    const isMoreThanOneSelected = rowSelectionModel.length > 1;
    return `${rowSelectionModel.length} item${
      isMoreThanOneSelected ? 's' : ''
    } selected`;
  }, [rowSelectionModel]);

  return {
    paginationModel,
    setPaginationModel,
    handleRowSelectModal,
    rowSelectionModel,
    handlePaginationModalChange,
    handleRowSelectionModelChange,
    apiRef,
    calculatePaginationData,
    selectedRowItem,
    selectedRowItems,
    setRowSelectionModel,
    checkBoxSelectionCountText,
  };
};
