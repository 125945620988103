export default function createActivityLoggerId() {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  // const id = userDetails?.company_settings?.id;
  // const username = userDetails?.username;
  const uuid = crypto.randomUUID();

  const loggerId = `${uuid}`;

  return loggerId;
}
