import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Button from 'Components/CommonComponents/Button/Button';
import { useCreateExpirySettings, useCreateReviewttings, useGetExpirySettings } from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { convertMinutesToDays, days_to_minutes, days_to_seconds } from 'Utilities/utils';
const { Panel } = Collapse;

const LinkExpirySettings = (props) => {
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prescreen_expiry: 0,
      is_first_reminder: false,
      first_reminder: 0,
      is_second_reminder: false,
      second_reminder: 0,
      is_third_reminder: false,
      third_reminder: 0,
    },
  });
  const expirySettings = watch('is_expiry_settings');
  const is_first_reminder = watch('is_first_reminder');
  const is_second_reminder = watch('is_second_reminder');
  const is_third_reminder = watch('is_third_reminder');

  const { data: settingData, refetch: isRefetch } =
  useGetExpirySettings({
    paramsData: {
      companyId: props.comapnyId,
    },
  });

  const { mutateAsync: createExpirySettings } = useCreateExpirySettings({
    onSuccess: async (data, variables, context) => {
      toast.success('Successfully Submitted..!!');
    },
    onError: async (error) => {
      console.error('Creation Error:', error);
      const message = error?.response?.data?.error_info;
      await Object.entries(message)?.forEach(([key, message]) => {
        toast.error(message || DEFAULT_ERROR_MESSAGE);
        //   messageApi.open({
        //     type: 'error',
        //     content: message ?? DEFAULT_ERROR_MESSAGE,
        //   });
      });
    },
  });

  useEffect(()=> {
    if(settingData){
        setValue("prescreen_expiry",convertMinutesToDays(settingData?.prescreen_expiry))
        setValue("is_first_reminder",settingData?.is_first_reminder)
        setValue("first_reminder",settingData?.first_reminder)
        setValue("is_second_reminder",settingData?.is_second_reminder)
        setValue("second_reminder",settingData?.second_reminder)
        setValue("is_third_reminder",settingData?.is_third_reminder)
        setValue("third_reminder",settingData?.third_reminder)
    }

  },[settingData])

  const onSubmit = async (datas) => {
    const data = {
        prescreen_expiry: datas?.prescreen_expiry? days_to_minutes(datas?.prescreen_expiry) : 0,
        first_reminder: datas?.first_reminder? datas?.first_reminder : 0,
        second_reminder: datas?.second_reminder? datas?.second_reminder : 0,
        third_reminder: datas?.third_reminder? datas?.third_reminder : 0,
        is_first_reminder: datas?.is_first_reminder,
        is_second_reminder: datas?.is_second_reminder,
        is_third_reminder: datas?.is_third_reminder,
        id: settingData?.id
    }
    createExpirySettings({data});
  };
  return (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            className="bg-white rounded-lg"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="mt-4">
                  {!isActive ? (
                    <img src={iconAccordion} alt="accordion" />
                  ) : (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  )}
                </div>
              );
            }}
          >
            {() => {
              return (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <p className="mt-8 px-6  text-medium font-medium">
                        Link Expiry Settings
                      </p>
                      {/* <div className="[&>*]:m-0 mt-8 px-6 ">
                        <Switch
                          name="is_expiry_settings"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div> */}
                    </div>
                  }
                  key={expirySettings ? '1' : '0'}
                  //style={panelStyle}
                  className=""
                  // extra={genExtra()}
                >
                  <div className="px-6 ">
                    <div className="w-full">
                      <Input
                        name="prescreen_expiry"
                        label="Link Expiry(days)*"
                        control={control}
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Please enter only numbers',
                          },
                        }}
                      />
                    </div>
                    <div className="mt-8 mb-6  h-[1px] w-full bg-[#E1E1E1]"></div>

                    <div>
                      <div className="w-full flex mt-4">
                        <div className="w-1/3">
                          <Checkbox
                            showUpperLabel={false}
                            name="is_first_reminder"
                            label="First Reminder"
                            control={control}
                          />
                          {is_first_reminder === true && (
                            <div className="pr-12">
                              <Input
                                name="first_reminder"
                                label="First Reminder(days)*"
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                  pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Please enter only numbers',
                                  },
                                  validate: (value) => {
                                    const prescreenExpiry =
                                      watch('prescreen_expiry');
                                    if (
                                      parseInt(value) >=
                                      parseInt(prescreenExpiry)
                                    ) {
                                      return 'First reminder must be less than Link Expiry';
                                    }
                                    return true;
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {is_first_reminder && (
                          <div className="w-1/3">
                            <Checkbox
                              showUpperLabel={false}
                              name="is_second_reminder"
                              label="Second Reminder"
                              control={control}
                            />
                            {is_second_reminder === true && (
                              <div className="pr-12">
                                <Input
                                  name="second_reminder"
                                  label="Second Reminder(days)*"
                                  control={control}
                                  rules={{
                                    required: 'This field is required',
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: 'Please enter only numbers',
                                    },
                                    validate: (value) => {
                                      const first_reminder =
                                        watch('first_reminder');
                                      if (
                                        parseInt(value) >=
                                        parseInt(first_reminder)
                                      ) {
                                        return 'Second reminder must be less than First Reminder';
                                      }
                                      return true;
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {is_second_reminder === true && (
                          <div className="w-1/3">
                            <Checkbox
                              showUpperLabel={false}
                              name="is_third_reminder"
                              label="Third Reminder"
                              control={control}
                            />
                            {is_third_reminder === true && (
                              <div className="pr-12">
                                <Input
                                  name="third_reminder"
                                  label="Third Reminder(days)*"
                                  control={control}
                                  rules={{
                                    required: 'This field is required',
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: 'Please enter only numbers',
                                    },
                                    validate: (value) => {
                                      const second_reminder =
                                        watch('second_reminder');
                                      if (
                                        parseInt(value) >=
                                        parseInt(second_reminder)
                                      ) {
                                        return 'Third reminder must be less than Second Reminder';
                                      }
                                      return true;
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-12 mb-8">
                    <Button
                      className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
        </form>
      </Card>
    </div>
  );
};
export default LinkExpirySettings;
