import { useGetFinalScreenData } from 'ServiceHooks/interviewHooks';
import { useLocation, useParams } from 'react-router-dom';
import dots from '../../Assets/images/dots.png';
import LOGO from '../../Assets/images/logo.png';
import Happy from '../../Assets/images/happy.png';
//import "Styles/InHouseInterview.scss";
import 'Styles/InterviewScreen.scss';
import { formatTime } from 'Utilities/utils';
import bot from '../../Assets/images/interview/Final2.png';
import Button from 'Components/CommonComponents/Button/Button';

const closeTab = () => {
  window.open('', '_parent', '');
  window.close();
};

const SubmitScreen = () => {
  const location = useLocation();
  var params = useParams();

  const {
    data: finalScreenData,
    isFetching: isfinalScreenData,
    status: finalScreenDataStatus,
    error: finalScreenDataError,
  } = useGetFinalScreenData({ paramsData: params.token });

  return (
    <div className="interviewMainDiv">
      <div className="finalScreen_maindiv">
        <div className="finalScreen_div">
          <div className="finalScreen_leftdiv">
            <img src={bot} alt="" />
            <h1>INTERVIEW SUCCESSFULLY SUBMITTED</h1>
            <h2>
              Our team will contact you for further hiring procedures if
              you are shortlisted.
            </h2>
            <Button
              type={'ghost'}
              onClick={() => closeTab()}
              className="h-11 px-10 mt-4 rounded-3xl border border-black justify-center items-center text-black text-base font-semibold"
            >
              Exit
            </Button>
          </div>
          <div className="finalScreen_rightdiv">
            <h1>{finalScreenData?.title}</h1>
            <ul>
              <li>
                <span className="left_header">JOB Code: </span>
                <span className="right_header" id="jobCode">
                  {finalScreenData?.job_reference_number}
                </span>
              </li>
              <li>
                <span className="left_header"> Submission time: </span>
                <span className="right_header" id="submitTime">
                  {new Date().toLocaleTimeString()}
                </span>
              </li>
              <li>
                <span className="left_header"> Total Duration: </span>
                <span className="right_header" id="Duration">
                  {finalScreenData?.total_hours}
                </span>
              </li>

              <li>
                <span className="left_header">Time Taken: </span>
                <span className="right_header" id="Duration_taken">
                  {/* {buildTotalTimetaken()} */}
                  {formatTime(location?.state?.submitTime)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitScreen;
