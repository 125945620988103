
import LoginDialogHeader from "../../Components/CommonComponents/LoginDialogHeader";

const ErrorPage = () => {
  return (
    <LoginDialogHeader>
      <div className="login">
        <h1 className="error_h1">Invalid Link!</h1>
      </div>
   
    </LoginDialogHeader>
  );
};
export default ErrorPage;
