import { useState } from 'react';
import { useForm } from 'react-hook-form';

import jobService from '../../Services/JobService';
import Button from 'Components/CommonComponents/Button/Button';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';

var strSelectedRecruiter = [];

const AddRecruiter = (props) => {
  const [List, setList] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [isError, setError] = useState();

  const onSearch = async (e) => {
    await jobService
      .getRecuiter(e, props.jobId)
      .then((oResponse) => {
        console.log(oResponse.data.data);
        var arrList = getListOption(oResponse.data.data.results);
        setList(arrList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListOption = (optionList) => {
    var arrData = [];
    optionList &&
      optionList.map((odata) => {
        arrData.push({
          //key: odata.first_name,
          cat: odata.id,
          email: odata.email,
          first_name: odata.first_name,
          id: odata.id,
          mobile_number: odata.mobile_number,
          hire_manage_user: odata.hire_manage_user,
          assigned_jobs: odata.assigned_jobs,
          designation: odata.designation,
          key: odata.id,
          value: odata.first_name,
        });
      });
    return arrData;
  };

  const getSelectedRecuiter = (recuiter) => {
    List.map((item) => {
      if (item.id === recuiter.key) {
        console.log('item', item);
        strSelectedRecruiter.push(item);
      }
    });
    setError(false);
    console.log(strSelectedRecruiter);
  };

  const onSelect = (e) => {
    console.log('fullobject', e);
    strSelectedRecruiter = e;
  };

  const submitRecuiter = () => {
    if (strSelectedRecruiter.length > 0) {
      props.onSelectedValue(strSelectedRecruiter);
      props.isClose();
      strSelectedRecruiter = [];
    } else {
      setError(true);
    }
  };

  const onSubmit = (data) => {
    console.log('strSelectedRecruiter', data);
  };

  const onDeselect = (deSelectData) => {
    const filterRecruiter = strSelectedRecruiter.filter(
      (item) => item.id !== deSelectData.key,
    );
    console.log('filterRecruiter', filterRecruiter);
    strSelectedRecruiter = filterRecruiter;
  };

  return (
    <div className="addReviewerMainDiv border-t-2">
      <div
        style={{ marginTop: '40px', textAlign: 'center', padding: '15px' }}
      >
        <div style={{ height: '210px', padding: '10px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Select
              label={'Name of the ' + props.strHeaderName + ' *'}
              placeholder={'Search for ' + props.strHeaderName}
              options={List}
              name={'testRec'}
              control={control}
              showArrow={false}
              rules={{
                required: 'This field is required',
              }}
              style={{
                textAlign: 'left',
                marginButton: '1px',
              }}
              mode="multiple"
              onSearch={(e) => {
                onSearch(e);
              }}
              onSelect={(e) => {
                console.log(e);
                getSelectedRecuiter(e);
              }}
              onDeselect={(e) => {
                console.log(e);
                onDeselect(e);
              }}
            />

            {isError && (
              <p className="validate_message">
                * Please Select The Recruiter
              </p>
            )}
            <Button
              className="popup_button"
              onClick={() => submitRecuiter()}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddRecruiter;
