import { Select as AntSelect, Form, Spin } from "antd";
import { useController } from "react-hook-form";
import "./SearchSelect.scss";
import { SearchOutlined } from "@ant-design/icons";

function SingleSearchSelect({
  onSearch,
  options,
  fetching,
  mode = null,
  // hook form specific
  onChange: customOnChange = null,
  name,
  control,
  defaultValue,
  rules = {},
  label,
  searchURL = null,
  useDefaultTheme = true,
  ...props
}) {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const errorText = error?.message;
  const status = errorText ? "error" : "";
  return (
    <>
      <div className="rfhSelect capitalize">
        <Form.Item
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          help={errorText}
          validateStatus={status}
        >
          <AntSelect
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              if (customOnChange) {
                customOnChange(e, onChange);
              } else {
                onChange(e);
              }
            }}
            // select props
            mode={mode}
            labelInValue
            onSearch={(e) => onSearch(e, searchURL, name)}
            // filterOption={false}
            notFoundContent={
              fetching ? (
                <div className="flex justify-center py-5">
                  <Spin size="small" />
                </div>
              ) : null
            }
            options={options}
            status={status}
            {...props}
            defaultValue={defaultValue}
            showSearch
            suffixIcon={<SearchOutlined />}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default SingleSearchSelect;
