import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import { CANDIDATE_DETAILS_TAB_KEYS } from 'Utilities/constants';
import { useForm } from 'react-hook-form';
import RequiredFieldProfile from './RequiredFieldProfile';
import ExperienceTab from 'Components/Candidate/ExperienceTab';
import { isEmpty } from 'lodash';
import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import ResumeFormExperience from './ResumeFormExperience';
import RequiredFormExperience from './RequiredFormExperience';
import RequiredFormProjects from './RequiredFormProjects';

const RequiredFieldForm = ({ fields, candidate_id, setEnable,setRequiredFields }) => {
  const {
    clearErrors,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const tabsArray = [
    {
      key: CANDIDATE_DETAILS_TAB_KEYS.PROFILE,
      tabName: 'Profile',
      children: (
        <RequiredFieldProfile
          nCandidateId={candidate_id}
          fields={fields}
          setEnable={setEnable}
          setRequiredFields={setRequiredFields}
          // onClose={() => closeCandidateForm()}
        />
      ),
      enable: fields?.filter((f)=> f !== "experiences" && f !== "projects").length > 0
    },
    {
      key: CANDIDATE_DETAILS_TAB_KEYS.EXPERIENCE,
      tabName: 'Experience',
      children: (
        <RequiredFormExperience
        experiences={[]}
        candidateId={candidate_id}
        setEnable={setEnable}
        setRequiredFields={setRequiredFields}
        />
      ),
      enable: fields?.includes("experiences")
    },
    {
      key: 'projects',
      tabName: 'Projects',
      children: (
        <RequiredFormProjects
        experiences={[]}
        candidateId={candidate_id}
        setEnable={setEnable}
        setRequiredFields={setRequiredFields}
        />
      ),
      enable: fields?.includes("projects")
    },
  ].filter((item) => !isEmpty(item) && item.enable === true);

  const tabsItems = tabsArray.map((item) => {
    return {
      key: item.key,
      label: item.tabName,
      children: item.children,
    };
  });

  return (
    <div className="flex items-center flex-col justify-center">
      <Tabs
        items={tabsItems}
        className="w-full"
        // activeKey={infoTabName}
        defaultActiveKey={CANDIDATE_DETAILS_TAB_KEYS.PROFILE}
      />
    </div>
  );
};

export default RequiredFieldForm;
