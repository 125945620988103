import { Checkbox, Table, message } from 'antd';
import { UpdatePersistedStore } from './constants';
import { usePersistedStore } from 'Store/persistedStore';
import { useLayoutStore } from 'Store/LayoutStore';
import { useState } from 'react';

const ColumnPreference = ({
  grid_columns,
  setGridColumn,
  table_name,
  preference
}) => {
  const { column_preferece, setGridColumnPreference } = useLayoutStore(
    (state) => ({
      column_preferece: state.column_preferece,
      setGridColumnPreference: state.setGridColumnPreference,
    }),
  );

  const showWarning = () => {
    message.warning('Two Fields can be fixed only')
    return ""
  }

  const handleCheckboxChange = (dataIndex, field, value) => {
    setGridColumn((prevColumns) => {
      let fixed_count = prevColumns?.filter((item)=> item?.fixed === 'left').length
      console.log(fixed_count,"fixed_count");
      const updatedColumns = prevColumns.map((column) => {
        if (column.dataIndex === dataIndex) {
          console.log(column.fixed,"column.fixed");
          return {
            ...column,
            // fixed: field === 'fieldShow' && value === false ?  "": column.fixed,
            [field]: field === 'fixed' && value === true && fixed_count < 2 ? 'left' :field === 'fixed' && value === true ? showWarning(): value,
          };
        }
        return column;
      });
      const sortedColumns = updatedColumns
        .filter((column) => column.fixed === 'left')
        .concat(
          updatedColumns
            .filter((column) => column.fixed !== 'left')
            .sort((a, b) => a.position - b.position),
        );
      const updated_preferences = UpdatePersistedStore(
        column_preferece,
        sortedColumns,
        table_name,
        preference
      );
      setGridColumnPreference(updated_preferences)
      return sortedColumns;
    });
  };


  const preference_column = [
    {
      title: 'Columns',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Show',
      dataIndex: 'fieldShow',
      key: 'fieldShow',
      render: (_, record) => (
        <Checkbox
          checked={record?.fieldShow}
          onChange={(e) =>
            handleCheckboxChange(
              record.dataIndex,
              'fieldShow',
              e.target.checked,
            )
          }
        ></Checkbox>
      ),
      width: 80
    },
    // {
    //   title: 'Expand',
    //   dataIndex: 'isExpanded',
    //   key: 'isExpanded',
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={record?.isExpanded}
    //       onChange={(e) =>
    //         handleCheckboxChange(
    //           record.dataIndex,
    //           'isExpanded',
    //           e.target.checked,
    //         )
    //       }
    //     ></Checkbox>
    //   ),
    // },
    // {
    //   title: 'Search',
    //   dataIndex: 'is_search',
    //   key: 'is_search',
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={record?.is_search}
    //       onChange={(e) =>
    //         handleCheckboxChange(
    //           record.dataIndex,
    //           'is_search',
    //           e.target.checked,
    //         )
    //       }
    //     ></Checkbox>
    //   ),
    // },
    // {
    //   title: 'Sort',
    //   dataIndex: 'is_sort',
    //   key: 'is_sort',
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={record?.is_sort}
    //       onChange={(e) =>
    //         handleCheckboxChange(
    //           record.dataIndex,
    //           'is_sort',
    //           e.target.checked,
    //         )
    //       }
    //     ></Checkbox>
    //   ),
    // },
    {
      title: 'Fixed',
      dataIndex: 'fixed',
      key: 'fixed',
      render: (_, record) => (
        <Checkbox
          checked={record?.fixed === 'left'}
          onChange={(e) =>
            handleCheckboxChange(
              record.dataIndex,
              'fixed',
              e.target.checked,
            )
          }
        ></Checkbox>
      ),
      width: 80
    },
  ];

  return (
    <div className="w-[350px]">
      <Table
        scroll={{
          y: 450,
        }}
        dataSource={grid_columns?.filter((i)=> i.dataIndex !== "actions")}
        columns={preference_column}
        pagination={false}
      />
    </div>
  );
};

export default ColumnPreference;
