import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse, Popover, Slider, Tooltip, message } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import {
  useCreateInterviewSettings,
  useInterviewettings,
} from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
const { Panel } = Collapse;

const InterviewSettings = (props) => {
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [selectedMonthNumber, setSelectedMonthNumber] = useState(null);
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prefix_needed: false,
      year_required: false,
      month_require: false,
      company_code: false,
      send_interview_pass_result: false,
      send_interview_fail_result: false,
      interviewer_review_in_result_pdf: false,
      show_AI_interview_skipped_questions_again: false,
      auto_interview_schedule: false,
      auto_prescreen_schedule: false,
    },
  });

  const interviewReferenceType = watch('interview_settings_enabled');
  const interviewPrefixType = watch('prefix_needed');
  const isInterviewYear = watch('year_required');
  const isInterviewMonth = watch('month_required');
  const comapnyCodeType = watch('company_code');
  const prescreenScore = watch('auto_prescreen_schedule');
  const resumeScore = watch('auto_interview_schedule');

  const optionData = [
    { label: 'yy', value: 'YY' },
    { label: 'Y', value: 'Y' },
  ];
  const optionMonth = [
    { label: 'DD', value: 'DD' },
    { label: 'D', value: 'D' },
  ];
  const optionYear = [
    isInterviewYear ? { label: 'Year', value: 'Year' } : null,
    { label: 'Reference Number', value: 'Reference Number' },
    isInterviewMonth ? { label: 'month', value: 'month' } : null,
    comapnyCodeType
      ? { label: 'Company code', value: 'comapny Code' }
      : null,
  ].filter((item) => item !== null);

  const { data: interviewData, refetch: isInterviewRefectch } =
    useInterviewettings({
      paramsData: {
        companyId: props.comapnyId,
      },
    });

  useEffect(() => {
    if (isEmpty(interviewData)) return;
    reset(interviewData);
  }, [interviewData]);

  const { mutateAsync: createInterviewSettings } =
    useCreateInterviewSettings({
      onSuccess: async (data, variables, context) => {
        toast.success('Successfully Registered..!!');
        await isInterviewRefectch();
      },
      onError: async (error) => {
        console.error('Creation Error:', error);
        const message = error?.response?.data?.error_info;
        // await Object.entries(message)?.forEach(([key, message]) => {
        toast.error(message || DEFAULT_ERROR_MESSAGE);
        //   messageApi.open({
        //     type: 'error',
        //     content: message ?? DEFAULT_ERROR_MESSAGE,
        //   });
        // });
      },
    });

  const handleSelectChange = (name, selectedOption, onChange) => {
    onChange(selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : null;
    setValue(name, selectedValue);

    // Clear the value of other select dropdowns if the same value is selected
    // if (name === 'isYearNumber' && selectedValue === watch('reference_number')) {
    //     setValue('reference_number', null);
    // } else if (name === 'reference_number' && selectedValue === watch('isYearNumber')) {
    //     setValue('isYearNumber', null);
    // } else if (name === 'isMonthNUmber' && selectedValue === watch('company_type')) {
    //     setValue('company_type', null);
    // } else if (name === 'company_type' && selectedValue === watch('isMonthNUmber')) {
    //     setValue('isMonthNUmber', null);
    // }
    const dropdowns = [
      'refno_format_value_1',
      'refno_format_value_2',
      'refno_format_value_3',
      'refno_format_value_4',
    ];
    dropdowns.forEach((dropdown) => {
      if (dropdown !== name && selectedValue === watch(dropdown)) {
        setValue(dropdown, null);
      }
    });
  };

  const onSubmit = async (data) => {
    console.log('datatest', data);

    const refrenceNumberFormat =
      (data.refno_format_value_2
        ? `{${data.refno_format_value_2}} - `
        : '') +
      (data.refno_format_value_1
        ? `{${data.refno_format_value_1}} - `
        : '') +
      (data.refno_format_value_3
        ? `{${data.refno_format_value_3}} - `
        : '') +
      (data.refno_format_value_4 ? `{${data.refno_format_value_4}}` : '');
    console.log('ssssdd', refrenceNumberFormat);
    createInterviewSettings({
      data,
      refrenceNumberFormat,
      comapnyId: props.comapnyId,
    });
  };

  return (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            className="bg-white rounded-lg"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="mt-4">
                  {!isActive ? (
                    <img src={iconAccordion} alt="accordion" />
                  ) : (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  )}
                </div>
              );
            }}
          >
            {() => {
              return (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <p className="mt-8 px-6  text-medium font-medium">
                        Interview Settings
                      </p>
                      <div className="[&>*]:m-0 mt-8 px-6 ">
                        <Switch
                          name="interview_settings_enabled"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                  }
                  key={interviewReferenceType ? '1' : '0'}
                  //style={panelStyle}
                  className=""
                  // extra={genExtra()}
                >
                  <div className="px-2 ">
                    <div className="w-full">
                      <Input
                        name="interview_refno"
                        label="Interview Reference Number*"
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </div>
                    <div className="mt-10 mb-8  h-[1px] w-full bg-[#E1E1E1]"></div>

                    <div className="flex justify-between">
                      <p className="text-medium font-medium">
                        Prefix Needed
                      </p>
                      <div className="[&>*]:m-0 ">
                        <Switch
                          name="prefix_needed"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>

                    {interviewPrefixType === true && (
                      <div>
                        <div className="w-full flex mt-4">
                          <div className="w-1/2">
                            <Checkbox
                              showUpperLabel={false}
                              name="year_required"
                              label="Year Required"
                              control={control}
                            />
                            {isInterviewYear === true && (
                              <div className="pr-12">
                                <Select
                                  name="year_required_format"
                                  label=""
                                  placeholder="Year"
                                  control={control}
                                  defaultValue={optionData[0]}
                                  options={optionData.map((oOption) => {
                                    return {
                                      value: oOption.value,
                                      label: oOption.label,
                                    };
                                  })}
                                  onChange={(e, onChange) => onChange(e)}
                                />
                              </div>
                            )}
                          </div>
                          <div className="w-1/2 px-12">
                            <Checkbox
                              showUpperLabel={false}
                              name="month_required"
                              label="Month Required"
                              control={control}
                            />
                            {isInterviewMonth === true && (
                              <Select
                                name="month_required_format"
                                label=""
                                placeholder="Month"
                                control={control}
                                value="DD"
                                defaultValue={optionMonth[0]}
                                options={optionMonth.map((oOption) => {
                                  return {
                                    value: oOption.value,
                                    label: oOption.label,
                                  };
                                })}
                                onChange={(e, onChange) => onChange(e)}
                                rules={{
                                  required: 'This field is required',
                                }}
                                // valueLabel="role_name"
                                // optionLabel="role_name"
                              />
                            )}
                          </div>
                        </div>
                        <div className="w-full flex mt-4">
                          <div className="w-1/2">
                            <Checkbox
                              showUpperLabel={false}
                              name="company_code"
                              label="Company Code"
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="w-full flex mt-7">
                      {isInterviewYear === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_1"
                            label=""
                            placeholder="select"
                            control={control}
                            options={optionYear}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_1',
                                e,
                                onChange,
                              )
                            }
                          />
                        </div>
                      )}
                      {isInterviewYear === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_2"
                            label=""
                            placeholder="select"
                            control={control}
                            options={optionYear}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_2',
                                e,
                                onChange,
                              )
                            }
                          />
                        </div>
                      )}
                      {isInterviewMonth === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_3"
                            label=""
                            placeholder="select"
                            control={control}
                            options={optionYear}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_3',
                                e,
                                onChange,
                              )
                            }
                          />
                        </div>
                      )}
                      {comapnyCodeType === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_4"
                            label=""
                            placeholder="select"
                            control={control}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_4',
                                e,
                                onChange,
                              )
                            }
                            options={optionYear}
                          />
                        </div>
                      )}
                    </div>

                    <div className="w-full flex mt-10">
                      <div className="w-1/2 ">
                        <Input
                          name="default_interview_slot_duration"
                          label="Interview Slot Duration"
                          control={control}
                          rules={{
                            required: 'This field is required',
                          }}
                        />
                      </div>
                      <div className="w-1/2 mt-10 pl-10">
                        <Checkbox
                          showUpperLabel={false}
                          name="send_interview_pass_result"
                          label="Send interview passed result to candidate"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="w-full flex mt-8">
                      <div className="w-1/2  ">
                        <Checkbox
                          showUpperLabel={false}
                          name="send_interview_fail_result"
                          label="Send interview failed result to candidate"
                          control={control}
                        />
                      </div>
                      <div className="w-1/2 pl-10">
                        <Checkbox
                          showUpperLabel={false}
                          name="interviewer_review_in_result_pdf"
                          label="Attach interviewer review in result pdf to candidate"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="w-full flex mt-8">
                      <div className="w-1/2  ">
                        <Checkbox
                          showUpperLabel={false}
                          name="show_AI_interview_skipped_questions_again"
                          label="Show AI interview Skipped questions again"
                          control={control}
                        />
                      </div>
                      <div className="w-1/2 pl-10">
                        <Checkbox
                          showUpperLabel={false}
                          name="send_jd_pdf_with_interview"
                          label="Attach Job Description in Interview Invitation Mail"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="w-full flex mt-8">
                      <div className="w-1/2  ">
                        <Checkbox
                          showUpperLabel={false}
                          name="auto_prescreen_schedule"
                          label="Auto prescreen schedule"
                          control={control}
                        />
                      </div>
                      <div className="w-1/2 pl-10 ">
                        <Checkbox
                          showUpperLabel={false}
                          name="auto_interview_schedule"
                          label="Auto interview schedule"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="w-full flex mt-8">
                      {prescreenScore && (
                        <div className="w-1/2  ">
                          <Input
                            name="auto_prescreen_schedule_score"
                            label="Resume Score for Auto prescreen schedule"
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                      )}
                      {resumeScore && (
                        <div className="w-1/2 pl-10 ">
                          <Input
                            name="auto_interview_schedule_score"
                            label="Resume Score for Auto interview schedule"
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end mt-14 mb-9">
                    <Button
                      className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
        </form>
      </Card>
    </div>
  );
};
export default InterviewSettings;
