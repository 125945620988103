import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, debounce } from 'lodash';

import { useGetClients } from 'ServiceHooks/jobHooks';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';

export default function ClientFormModal(props) {
  const { addClients, close } = props;
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      clients: [],
    },
  });
  const [search, setSearch] = useState(null);

  const { data: clientList, isFetching: isFetchingClients } =
    useGetClients({
      params: {
        ...(search !== '' && { search }),
      },
    });

  const clientOptions = useMemo(() => {
    if (isEmpty(clientList)) return [];
    const options = clientList?.results.map((item) => {
      return {
        label: item.client,
        value: item.id,
      };
    });
    return options;
  }, [clientList]);

  const onSubmit = (values) => {
    addClients(values?.clients);
    close();
  };

  return (
    <form
      className="flex flex-col w-2/3 mx-auto my-24"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full">
        <Select
          allowClear
          name={'clients'}
          control={control}
          showSearch={true}
          options={clientOptions}
          fetching={isFetchingClients}
          placeholder="Choose a Client Name"
          label={
            <p>
              Name of the Client
              <span className="">*</span>
            </p>
          }
          onSearch={debounce((value) => setSearch(value), 500)}
          onClear={() => {
            setValue('clients', []);
            setSearch(null);
          }}
          rules={{
            required: 'This Field is Required',
          }}
          onChange={(field, onChange) => onChange(field)}
        />
      </div>
      <div className="mx-auto">
        <Button
          type="primary"
          size={'large'}
          className="m-4"
          htmlType="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
