import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { useEffect } from "react";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  headerView: {
    fontSize: 15,
    padding: 15,
    width: "100%",
    height: "80px",
    backgroundColor: "#F0F6F6",
    textAlign: "center",
  },

  qNumber: {
    fontSize: 15,
    marginBottom: 10,
    color: "#0a7f8f",
  },

  headerName: {
    fontSize: 15,
    marginBottom: 10,
    backgroundColor: "#F0F6F6",
    padding: "10px",
    marginTop: "5%",
  },

  movieContainer: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    padding: 5,
    borderBottom: "1px solid #ededed",
  },

  movieDetails: {
    display: "flex",
    marginLeft: 5,
  },
  movieTitle: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold",
  },
  movieOverview: {
    fontSize: 10,
  },

  image: {
    height: 200,
    width: 150,
  },

  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    // width: 150,
    width: "100%",
    alignItems: "center",
    marginBottom: 12,
    paddingLeft: "10px",
  },

  aiStatus: {
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingTop: "10px",
  },

  aiStatusHeaderText: {},

  aiStatusContent: {
    paddingLeft: "10px",
  },

  vote: {
    display: "flex",
    flexDirection: "row",
  },

  rating: {
    height: 10,
    width: 10,
  },

  vote_text: {
    fontSize: 14,
    marginBottom: 10,
  },

  feedback_text: {
    fontSize: 14,
    marginBottom: 10,
    border: "1px soild gray",
    // backgroundColor:"gray",
    padding: "10px",
  },

  vote_pop: {
    fontSize: 14,
    padding: 2,
    // backgroundColor: "#61C74F",
    color: "#61C74F",
  },

  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4,
  },
  overviewContainer: {
    minHeight: 110,
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row",
  },
  lang: {
    fontSize: 8,
    fontWeight: 700,
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: "bold",
  },
});

const TestPDF = (props) => {
  useEffect(() => {
    console.log(
      "props.feedbackHeader",
      props.feedbackHeader,
      "nid",
      props.nInterviewId,
      "Qdata",
      props.QData,
      "cStatus",
      props.cStatus?.data.data
    );
  }, []);

  return (
    <>
      {props.QData && (
        <Document>
          <Page size="A4" style={styles.page} wrap={true}>
            <View style={styles.section}>
              <View style={styles.headerView}>
                <Text>
                  {props.feedbackHeader &&
                    props.feedbackHeader?.interview_level}
                </Text>
                <Text>
                  {props.feedbackHeader && props.feedbackHeader?.job_title}
                </Text>
                <Text>
                  Candidate Name:
                  {props.feedbackHeader && props.feedbackHeader?.candidate}
                </Text>
              </View>

              <View style={styles.headerName}>
                <Text>Annotations</Text>
              </View>

              {props.QData
                ? props.QData.map((oQuestion, index) => {
                    if (
                      oQuestion?.question_answer?.question_type === "OPTIONS" ||
                      oQuestion?.question_answer?.question_type ===
                        "MULTI_CHOICE"
                    ) {
                      return (
                        <View key={index} style={styles.movieContainer}>
                          <View style={styles.movieDetails}>
                            <Text style={styles.qNumber}>
                              Question {index + 1}
                            </Text>

                            <Text style={styles.movieTitle}>
                              {oQuestion?.question_answer?.question}
                            </Text>

                            <Text style={styles.vote_text}>Options</Text>

                            {oQuestion.question_answer.multi_options.map(
                              (oOption, index) => {
                                return (
                                  <View>
                                    <Text style={styles.vote_text}>
                                      {index + 1}. {oOption.option}
                                    </Text>
                                  </View>
                                );
                              }
                            )}

                            <Text style={styles.vote_pop}>Answered Text:-</Text>

                            {oQuestion.question_answer.multi_options.map(
                              (oOption, index) => {
                                return oQuestion.question_answer.multi_option_answers.map(
                                  (ans) => {
                                    if (oOption.id === ans) {
                                      return (
                                        <Text style={styles.vote_text}>
                                          {oOption.option}
                                        </Text>
                                      );
                                    }
                                  }
                                );
                              }
                            )}

                            <View style={styles.aiStatus}>
                              <View>
                                <Text style={styles.aiStatusHeaderText}>
                                  AI Feedback :
                                </Text>
                                <Text style={styles.aiStatusContent}>
                                  {oQuestion?.is_correct_answer
                                    ? "Passed"
                                    : "Failed"}
                                </Text>
                              </View>

                              <View>
                                <Text style={styles.aiStatusHeaderText}>
                                  Score :
                                </Text>
                                <Text style={styles.aiStatusContent}>
                                  {oQuestion?.interviewer_feedback
                                    ? oQuestion?.interviewer_feedback.score
                                    : ""}
                                </Text>
                              </View>
                            </View>

                            <View style={styles.aiStatus}>
                              <Text style={styles.aiStatusHeaderText}>
                                Interviwer Comment :
                              </Text>
                              <Text style={styles.aiStatusContent}>
                                {oQuestion?.interviewer_feedback
                                  ? oQuestion?.interviewer_feedback.comments
                                  : ""}
                              </Text>
                            </View>

                            <View style={styles.detailsFooter}>
                              <Text style={styles.lang}></Text>
                              <Text style={styles.vote_average}></Text>
                              <Text style={styles.vote_average}></Text>
                            </View>
                          </View>
                        </View>
                      );
                    } else {
                      return (
                        <View key={index} style={styles.movieContainer}>
                          <View style={styles.movieDetails}>
                            <Text style={styles.qNumber}>
                              Question {index + 1}
                            </Text>

                            <Text style={styles.movieTitle}>
                              {oQuestion?.question_answer?.question}
                            </Text>

                            <Text style={styles.vote_pop}>Answered Text:-</Text>

                            <View style={styles.subtitle}>
                              <View style={styles.vote}>
                                <Text style={styles.vote_text}>
                                  {oQuestion?.question_answer?.text_answer}
                                </Text>
                              </View>
                            </View>

                            <View style={styles.aiStatus}>
                              <Text style={styles.aiStatusHeaderText}>
                                AI Feedback :-
                              </Text>
                              <Text style={styles.aiStatusContent}>
                                {oQuestion?.is_correct_answer
                                  ? "Passed"
                                  : "Failed"}
                              </Text>
                            </View>

                            <View style={styles.aiStatus}>
                              <Text style={styles.aiStatusHeaderText}>
                                Interviwer Comment :
                              </Text>
                              <Text style={styles.aiStatusContent}>
                                {oQuestion?.interviewer_feedback
                                  ? oQuestion?.interviewer_feedback.comments
                                  : ""}
                              </Text>
                            </View>

                            <View style={styles.detailsFooter}>
                              <Text style={styles.lang}></Text>
                              <Text style={styles.vote_average}></Text>
                              <Text style={styles.vote_average}></Text>
                            </View>
                          </View>
                        </View>
                      );
                    }
                  })
                : ""}

              <View style={{ marginTop: "10px" }}></View>

              <View style={styles.headerName}>
                <Text> Interviwer Feedback</Text>
              </View>

              <View style={{ paddingLeft: "10px" }}>
                {props.cStatus
                  ? props.cStatus?.data.data.questions.map((s) => {
                      if (s.section_label !== "Hiring Status") {
                        return (
                          <View
                            style={{
                              padding: "15px",
                              border: "1px solid black",
                              marginRight: "5px",
                              marginTop: "5px",
                            }}
                          >
                            <Text style={styles.qNumber}>
                              {s.section_label}
                            </Text>
                            {s.questions &&
                              s.questions.map((oQuestion) => {
                                //  if(oQuestion.question.question)
                                if (
                                  oQuestion.question.answer_type === "SHORT_ANS"
                                ) {
                                  return (
                                    <View>
                                      <Text style={styles.movieTitle}>
                                        {oQuestion.question.question}
                                      </Text>

                                      {/* <Text style={styles.vote_pop}>
                                        Answered Text:-
                                      </Text> */}

                                      <Text style={styles.feedback_text}>
                                        {oQuestion.question.answer}
                                      </Text>
                                    </View>
                                  );
                                } else if (
                                  oQuestion.question.answer_type === "OPTIONS"
                                ) {
                                  return (
                                    <View>
                                      <Text style={styles.movieTitle}>
                                        {oQuestion.question.question}
                                      </Text>
                                      {oQuestion.question.multi_options_answers
                                        ? oQuestion.question.multi_options_answers.map(
                                            (oOp, index) => {
                                              return (
                                                <View>
                                                  <Text
                                                    style={styles.vote_text}
                                                  >
                                                    {index + 1}. {oOp.option}
                                                  </Text>
                                                </View>
                                              );
                                            }
                                          )
                                        : ""}
                                      {/*                                         
                                      <Text style={styles.vote_pop}>
                                        Answered Text:-
                                      </Text> */}

                                      {oQuestion.question.multi_options_answers
                                        ? oQuestion.question.multi_options_answers.map(
                                            (oOp, index) => {
                                              if (
                                                oOp.isOptionSelected === oOp.id
                                              ) {
                                                return (
                                                  <View>
                                                    <Text
                                                      style={
                                                        styles.feedback_text
                                                      }
                                                    >
                                                      {oOp.option}
                                                    </Text>
                                                  </View>
                                                );
                                              }
                                            }
                                          )
                                        : ""}
                                    </View>
                                  );
                                } else if (
                                  oQuestion.question.answer_type === "LONG_ANS"
                                ) {
                                  return (
                                    <View>
                                      <Text style={styles.movieTitle}>
                                        {oQuestion.question.question}
                                      </Text>
                                      {/* <Text style={styles.vote_pop}>
                                        Answered Text:-
                                      </Text> */}
                                      <Text style={styles.feedback_text}>
                                        {oQuestion.question.answer}
                                      </Text>
                                    </View>
                                  );
                                }
                              })}
                          </View>
                        );
                      }
                    })
                  : ""}
              </View>

              <View style={{ marginTop: "10px" }}></View>

              <View style={styles.headerName}>
                <Text>Assesment Result</Text>
              </View>
              {props.cStatus
                ? props.cStatus?.data.data.questions.map((s) => {
                    return (
                      <View style={{ paddingLeft: "10px" }}>
                        <Text style={styles.vote_text}>{s.status}</Text>
                      </View>
                    );
                  })
                : ""}
            </View>
          </Page>
        </Document>
      )}
    </>
  );
};

export default TestPDF;
