import React from 'react';
import PreScreenComp from '../../../Components/PreScreen/PreScreenComp';

const PreScreen = () => {

  return (
    <div className="main-height">
      <PreScreenComp />
    </div>
  );
};

export default PreScreen;