import RegistrationManagementComp from 'Components/RegistrationManagement/RegistrationManagementComp';

const RegistrationManagement = () => {
  return (
    <>
      <RegistrationManagementComp />
    </>
  );
};

export default RegistrationManagement;
