import './Status404.scss';
import InActiveIcons from 'Assets/images/time-schedule.png';

const InActiveLink = () => {
  return (
    <div class="status404_wraper">
      {/* <div class="status404_wraper candidatesInfoPage flex justify-center items-center"> */}
      <div class="">
        <section class="card-sec">
          <div class="card yvi-card flex item-center justify-center">
            <div class="card-body">
              <div class="intro-image">
                <img src={InActiveIcons} class="img-fluid" />
              </div>
              <h2 class="wrong-message">Something<br />
                                went wrong!
              </h2>
              <a href="#" class="co-admin">The schedule is different time frame. <br />
                                Please check your time or contact  <span class="admin-link">Yvi Support Team</span></a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default InActiveLink;