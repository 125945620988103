import { create } from 'zustand';

const EditJobStore = create((set) => ({
  EditJobResponse: '',
  setEditJobResponse: (response) => {
    set(() => ({
      EditJobtResponse: response,
    }));
  },
}));

export default EditJobStore;
