import device from './../../../Assets/images/interview/review_icons/device.svg';
import face from './../../../Assets/images/interview/review_icons/face.svg';
import Light from './../../../Assets/images/interview/review_icons/lowLight.svg';
import speed from './../../../Assets/images/interview/review_icons/speed.svg';
import mic from './../../../Assets/images/interview/review_icons/mic.svg';
import video from './../../../Assets/images/interview/review_icons/video.svg';
import tick from './../../../Assets/images/interview/review_icons/tick.svg';
import profile_image from '../../../Assets/images/interview/profile_image.png';
import notMatch from '../../../Assets/images/interview/review_icons/image_not_match.svg';
import matched from '../../../Assets/images/interview/review_icons/matched.svg';
import demo from '../../../Assets/images/interview/review_icons/demo.jpeg';
import demo_multiface from '../../../Assets/images/interview/review_icons/demo_multiface.png';
import not_found_multiface from '../../../Assets/images/interview/review_icons/multiface.svg';
import image_matched from '../../../Assets/images/interview/review_icons/image_matched.svg';
import { Suspense, useEffect, useState } from 'react';
import interviewService from 'Services/interviewService';
import { Avatar, List, Skeleton, Tabs } from 'antd';
import { formatTime } from 'Utilities/utils';
import { isEmpty } from 'lodash';
import Poor from './../../../Assets/images/poor.svg';
import wrong from './../../../Assets/images/wrong.svg';

const IdentityCheckNew = (props) => {
  const [identityCheckData, setIdentityCheckData] = useState();
  const [assesmentsData, setAssesmentData] = useState();
  const [tabInfractionData, setTabInfractionData] = useState();
  const [imageMatchData, setImageMatchData] = useState();

  useEffect(() => {
    getIdentityCheckData();
    getAssessmentData();
    getMultiFaceInfractionData();
    getImageMatchData();
  }, []);

  const getIdentityCheckData = async () => {
    await interviewService
      .getIdentityCheck(props.nInterviewId)
      .then((data) => {
        console.log('getIdentityCheckData', data.data);
        setIdentityCheckData(data.data.data);
      });
  };

  const getAssessmentData = async () => {
    await interviewService
      .getAssessment(props.nInterviewId, '')
      .then((data) => {
        console.log('getAssessment', data.data);
        setAssesmentData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMultiFaceInfractionData = async () => {
    await interviewService
      .getMultifaceQuestion(props.nInterviewId)
      .then((data) => {
        setTabInfractionData(data?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getImageMatchData = async () => {
    await interviewService
      .getCheckMatch(props.nInterviewId, true)
      .then((data) => {
        setImageMatchData(data?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (key) => {
    console.log(key);
  };

  const image_check_datas = [
    {
      qNo: 5,
      image: demo,
      time: '00:10:01',
    },
    {
      qNo: 6,
      image: demo,
      time: '00:13:01',
    },
    {
      qNo: 7,
      image: demo,
      time: '00:15:01',
    },
    {
      qNo: 8,
      image: demo,
      time: '00:17:01',
    },
    {
      qNo: 9,
      image: demo,
      time: '00:19:01',
    },
    {
      qNo: 10,
      image: demo,
      time: '00:21:01',
    },
  ];

  const multiface_datas = [
    {
      qNo: 5,
      image_frame: demo_multiface,
      time: '00:10:01',
    },
    {
      qNo: 6,
      image_frame: demo_multiface,
      time: '00:13:01',
    },
    {
      qNo: 7,
      image_frame: demo_multiface,
      time: '00:15:01',
    },
    {
      qNo: 8,
      image_frame: demo_multiface,
      time: '00:17:01',
    },
  ];

  console.log(imageMatchData, 'imageMatchData');

  const NotMatchingList = (items, type = 'image_match') => {
    return (
      <div>
        <p className="text-[#D60404] text-[12px] font-medium">
          Not Matching List
        </p>
        {items?.length > 0 ? (
          <div className="flex justify-start gap-9 flex-wrap  mt-4 customScrollBar max-h-[500px] h-[480px] overflow-y-auto w-full">
            {items?.map((item, index) => (
              <>
                {item?.is_infraction === true && (
                  <div className="md:h-[200px] sm:h-[180px] h-[160px] md:w-[30%] sm:w-[44%] w-full rounded-md border-[1.5px] border-[#F61616] mb-5 aspect-square">
                    {item?.image_frame ? (
                      <img
                        src={item?.image_frame}
                        className="rounded-md w-full h-full"
                      />
                    ) : (
                      <Skeleton
                        // active
                        paragraph={{ rows: 4, width: '100%' }}
                        shape="square"
                        className="w-full rounded-md h-full p-4"
                      />
                    )}

                    <div className="flex items-center justify-between mt-2">
                      <span className="text-[#000] text-[11px] ">
                        Question No: {index + 1}
                      </span>
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M4 0C1.82857 0 0 1.82857 0 4C0 6.17143 1.82857 8 4 8C6.17143 8 8 6.17143 8 4C8 1.82857 6.17143 0 4 0ZM5.31057 5.71429L3.71429 4.11714V1.42857H4.28571V3.88057L5.71429 5.31029L5.31057 5.71429Z"
                            fill="#FA7777"
                          />
                        </svg>
                        <span className="text-[#FA7777] text-[11px]">
                          {item?.is_infraction_taken_time}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        ) : (
          <div className="max-h-[500px] h-[480px] w-full flex justify-center items-center">
            {type === 'image_match' ? (
              <div className="flex flex-col justify-center items-center gap-4">
                <img src={image_matched} />
                <p className="text-[#000] text-[16px] font-medium text-center">
                  Images Matched
                </p>
                <p className="text-[#363636] text-center text-[12px]">
                  Question image and interview image matched. Not found any
                  infractions
                </p>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center gap-4">
                <img src={not_found_multiface} />
                <p className="text-[#000] text-[16px] font-medium text-center">
                  Multi Face Infractions Not Detected
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  console.log(tabInfractionData, 'tabInfractionData');
  const items = [
    {
      key: '1',
      label: 'Question Image and Interview Image Check',
      children: NotMatchingList(imageMatchData, 'image_match'),
    },
    {
      key: '2',
      label: 'Multi Face Detection',
      children: NotMatchingList(tabInfractionData, 'multiface'),
    },
  ];

  let testScreenDatas = [
    {
      image: device,
      result:
        identityCheckData &&
        identityCheckData.device_info.map((oData) => {
          return oData + ', ';
        }),
      title: 'Device',
    },
    {
      image: mic,
      title: 'Mic',
      result: identityCheckData && identityCheckData.microphone_issue,
    },
    {
      image: video,
      title: 'Video Quality',
      result: identityCheckData && identityCheckData.video_quality,
    },
    {
      image: speed,
      title: 'Internet Speed',
      result: identityCheckData && identityCheckData.internet_speed,
    },
    {
      image: Light,
      title: 'Lighting Condition',
      result: identityCheckData && identityCheckData.low_light,
    },
    {
      image: face,
      title: 'Multiple face detection',
      result: 'Not Detected',
    },
  ];

  if (identityCheckData && identityCheckData.multiple_face) {
    testScreenDatas[5].result = 'Detected';
  }

  const buildTick = (title, condition) => {
    if (title === 'Device') {
      return <img src={tick} alt="" />;
    } else if (condition === 'Detected') {
      return <img src={Poor} alt="" />;
    } else if (condition === 'Not Detected') {
      return <img src={tick} alt="" />;
    } else if (condition === 'GOOD') {
      return <img src={tick} alt="" />;
    } else if (condition === 'POOR') {
      return <img src={Poor} alt="" />;
    } else if (condition === 'BAD') {
      return <img src={wrong} alt="" />;
    }
  };

  const testScreenCard = (img, responseData, title) => {
    return (
      <div className="rounded-[7px] border border-[#EBEBEB] bg-[#F8F9FA] p-4 mb-8">
        <div className="w-full flex items-center justify-end">
          {buildTick(title, responseData)}
        </div>
        <div className="flex items-center gap-3">
          <div className="h-[43px] w-[43px] p-2 bg-[#FFFFFF] rounded-full flex items-center justify-center">
            <img src={img} className="" />
          </div>
          <div>
            <p className="text-[#7A7E89] text-[12px] font-medium">
              {title}
            </p>
            <p className="text-[#454545] text-[14px] font-semibold">
              {responseData}
            </p>
          </div>
        </div>
      </div>
    );
  };

  console.log(
    assesmentsData?.tab_switch_infraction_with_questions,
    'assesmentsData?.tab_switch_infraction_with_questions',
  );

  return (
    <>
      <h3 className="text-[#000] text-[16px] font-medium my-4">
        Identity Check
      </h3>
      <div className="rounded-md bg-white p-8 flex justify-between flex-wrap">
        <div className="rounded-[7px] border border-[#EBEBEB] p-8 2xl:w-[65%] xl:w-[66%] lg:w-[68%] w-full">
          <h2 className="text-[#000] text-[14px] font-medium">
            Interview Image Infractions
          </h2>
          <div className="py-7 sm:px-[50px] px-4">
            <h3 className="text-[#000] text-[12px] font-medium">
              First Image Check
            </h3>
            <div className="flex items-center flex-wrap justify-between gap-4 mt-4 border-b border-b-[#DFDFDF] pb-11">
              <div className="md:w-[30%] sm:w-[48%] md:h-[222px] sm:h-[200px] h-[180px] w-full sm:mt-0 mt-[30px]">
                <div className="rounded-md w-full h-full">
                  <img
                    src={identityCheckData?.candidate_image}
                    className="rounded-md w-full h-full"
                  />
                </div>
                <p className="text-[#535353] text-[11px] font-medium mt-2">
                  Profile Image
                </p>
              </div>
              <div className="md:w-[30%] sm:w-[48%] w-full md:h-[222px] sm:h-[200px] h-[180px] sm:mt-0 mt-[30px]">
                <div className="rounded-md w-full h-full">
                  <img
                    src={identityCheckData?.identity_image}
                    className="rounded-md w-full h-full"
                  />
                </div>
                <p className="text-[#535353] text-[11px] font-medium mt-2">
                  Interview Image
                </p>
              </div>
              <div className="md:w-[30%] sm:w-[48%] w-full md:h-[222px] sm:h-[200px] h-[180px] sm:mt-0 mt-[30px]">
                <div
                  className={`${identityCheckData?.identity_matching ? 'bg-[#1FC361]' : 'bg-[#FF758F]'} rounded-md w-full h-full flex items-center justify-center`}
                >
                  {identityCheckData?.identity_matching ? (
                    <div className="flex items-center justify-center flex-col gap-5">
                      <img
                        src={matched}
                        className="rounded-md w-[50px] h-[50px]"
                      />
                      <p className="text-[#FFF] text-center text-[12px] font-medium">
                        Images Matched
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center flex-col gap-5">
                      <img
                        src={notMatch}
                        className="rounded-md w-[50px] h-[50px]"
                      />
                      <p className="text-[#FFF] text-center text-[12px] font-medium">
                        Images Not Matched
                      </p>
                    </div>
                  )}
                </div>
                <p className="text-[#535353] text-[11px] font-medium mt-2">
                  Result
                </p>
              </div>
            </div>
            <div className="p-4 mt-4">
              <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-[30%] w-full lg:mt-0 mt-4">
          {(assesmentsData?.tab_switch_infractions &&
            assesmentsData?.tab_switch_infraction_with_questions
              ?.length > 0) ? (
              <div className="bg-[#636F82] rounded-[7px] p-8 mb-8">
                <h2 className="text-[#FFF] text-[14px] font-semibold">
                  Tab Infraction List
                </h2>
                {assesmentsData &&
                  assesmentsData.tab_switch_infractions !== 0 && (
                    <div className="customScrollBar max-h-[200px] h-[135px] overflow-y-auto">
                      {assesmentsData?.tab_switch_infraction_with_questions?.map(
                        (i, index) => (
                          <>
                            {i?.is_infraction && (
                              <div className="border-l-[2px] border-l-[#8AF1FF] mt-8 text-white pl-4">
                                <p className="text-[#CED6E1] text-[13px] font-medium">
                                  Question No : {index + 1}
                                </p>
                                <p className="text-[#FFF] text-[12px] font-medium">
                                  Tab Switch Time :{' '}
                                  {formatTime(
                                    i.infraction__tab_switched_time,
                                  )}
                                </p>
                              </div>
                            )}
                          </>
                        ),
                      )}
                    </div>
                  )}
              </div>
            ): null}

          <div className="">
            {testScreenDatas?.map((test) =>
              testScreenCard(test?.image, test?.result, test?.title),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IdentityCheckNew;
