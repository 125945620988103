import axios from 'axios';

import Config from './Config';
import authHeader from './authHeader';

export const getCompanyInformation = (params = {}) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/company/company-information/`,
    config,
  );
};
