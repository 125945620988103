import axios from 'axios';
import Config from './Config';
import authHeader from './authHeader';

//!---------------InterviewsModuleAPIs--------------------!----->>
const getInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};

const getTodayInterviewData = () => {
  return axios.get(
    Config.API_URL + 'api/v1/interview/list/?status=TODAY',
    {
      headers: authHeader(),
    },
  );
};

const getInterviewList = (params) => {
  return axios.get(Config.API_URL + `api/v1/interview/list/`, {
    params,
    headers: authHeader(),
  });
};

const getInterviewSearchList = (jobStatus, serachValue) => {
  return axios.get(
    Config.API_URL +
      'api/v1/interview/list/?status=' +
      jobStatus +
      '&search=' +
      serachValue,
    {
      headers: authHeader(),
    },
  );
};

const cancelScheduledInterview = (data) => {
  const config = {
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL +
      `api/v1/interview/cancel-interview/${data?.interview_id}/`,
    {
      reason: data?.reason,
    },
    config,
  );
};

const getUpcommingInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=UPCOMING&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getPendingInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=PENDING&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getIdleInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=IDLE&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getReviewPendingInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=REVIEW_PENDING&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getCompletedInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=REVIEW_COMPLETED&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getCancelledInterviewData = (limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/list/?status=CANCELLED&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};

const getInterviewDataById = (id) => {
  return axios.get(`${Config.API_URL}api/v1/interview/details/${id}/`, {
    headers: authHeader(),
  });
};

const getInterviewCountData = () => {
  return axios.get(Config.API_URL + 'api/v1/interview/status-count/', {
    headers: authHeader(),
  });
};
const interviewReschedule = (data) => {
  let payload = {
    status: 'RESCHEDULED',
    interview_date_time_from: data.startDate,
    interview_date_time_to: data.endDate,
  };
  return axios.put(
    `${Config.API_URL}api/v1/interview/details/${data.id}/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};
const cancelInterview = (data) => {
  let payload = {
    status: 'CANCELLED',
  };
  return axios.put(
    `${Config.API_URL}api/v1/interview/cancel/${data.id}/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};

const getInterviewQuestionList = (data) => {
  return axios.get(
    `${Config.API_URL}api/v1/jobs/job-question-allocation/?job=${data.job_id}&interview_level=${data.interview_level}`,
    {
      headers: authHeader(),
    },
  );
};

const getInterviewInfoById = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/info/?interview=${id}`,
    {
      headers: authHeader(),
    },
  );
};

const getCurrentInterviewInfo = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(`${Config.API_URL}api/v1/interview/info/`, config);
};

const updateInterviewInformation = (params, body) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.put(
    `${Config.API_URL}api/v1/interview/info/`,
    body,
    config,
  );
};

const getScheduleDetails = (id) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/jobs/interview-details/${id}/`,
    config,
  );
};

const setNoShowInterview = (body, id) => {
  const config = {
    headers: authHeader(),
  };
  return axios.patch(
    `${Config.API_URL}api/v1/interview/no-show-status/${id}/`,
    body,
    config,
  );
};

const getInterviewQuestionById = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/evaluation-question-naire/${id}/`,
    {
      headers: authHeader(),
    },
  );
};

const getInterviewHistory = (interviewId) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/interview/interview-level-history/${interviewId}/`,
    config,
  );
};

const interviewFeedbackSubmit = (data) => {
  return axios.post(
    `${Config.API_URL}api/v1/interview/evaluation-question-answers/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

const getInterviewAnswerById = (id) => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/interview/evaluation-question-answers/${id}`,
    config,
  );
};

const getAIInterviewDetails = (nId) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/get-ai-interview-review-details/${nId}/`,
    {
      headers: authHeader(),
    },
  );
};

const getAssessment = (nId) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/ai-assesments/${nId}/`,
    {
      headers: authHeader(),
    },
  );
};

const getInfraction = (nId, infractionType) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/list-infraction-images/${nId}/?is_tab_switch=${infractionType}`,
    {
      headers: authHeader(),
    },
  );
};

const getCheckMatch = (nId, infractionType) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/list-candidate-interview-images/${nId}/?is_face_not_matched=${infractionType}`,
    {
      headers: authHeader(),
    },
  );
};

const getMultifaceQuestion = (nId) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/list-candidate-infraction-images-per-question/${nId}`,
    {
      headers: authHeader(),
    },
  );
};

const getIdentityCheck = (nId) => {
  console.log(nId, 'nIdnId');
  return axios.get(
    `${Config.API_URL}api/v1/interview/get-ai-interview-candidate-device-info/${nId}/`,
    {
      headers: authHeader(),
    },
  );
};

const getAiAssessmentQuestionAnswers = (urlId) => {
  return axios.get(
    `${Config.API_URL}api/v1/interview/ai-assesments-questions-answers/${urlId}/`,
    {
      headers: authHeader(),
    },
  );
};

const getInfractionImages = (params) => {
  const { interviewId, objectId } = params;
  let url = `${Config.API_URL}api/v1/interview/list-infraction-images/${interviewId}/`;
  if (objectId) {
    url = `${Config.API_URL}api/v1/interview/list-infraction-images/${interviewId}/${objectId}`;
  }
  return axios.get(url, {
    headers: authHeader(),
  });
};

const submitInterviewFeedback = (params) => {
  const { urlParams, ...others } = params;
  return axios.put(
    `${Config.API_URL}api/v1/interview/ai-interview-interviewer-feebback-per-question/${urlParams.interviewId}/${urlParams.questionId}/${urlParams.interviewer_evaluation_feedback}/`,
    others,
    {
      headers: authHeader(),
    },
  );
};

const sendFile = (interviewId, file, candidateName) => {
  console.log('imgObj_blob', file, 'id', interviewId);
  var pdfFile = new File([file], candidateName + '.pdf');
  console.log('file', pdfFile);
  var oFormData = new FormData();
  oFormData.append('interview_id', interviewId);
  oFormData.append('review_pdf', pdfFile, candidateName + '.pdf');

  var config = {
    method: 'POST',
    url: `${Config.API_URL}api/v1/interview/save-preview-pdf/`,
    headers: authHeader(),
    data: oFormData,
  };

  return axios(config);
};

const getCompanyDetails = () => {
  return axios.get(
    `${Config.API_URL}api/v1/settings/company-basic-info/`,
    {
      headers: authHeader(),
    },
  );
};
const getBig5SelectionList = () => {
  return axios.get(`${Config.API_URL}api/v1/interview/personality-test/`, {
    headers: authHeader(),
  });
};

const addBigFiveSelection = (data, interviewId) => {
  return axios.post(
    `${Config.API_URL}api/v1/interview/personality-test/?interview_id=${interviewId}`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const interviewService = {
  getInterviewData,
  getTodayInterviewData,
  getPendingInterviewData,
  getUpcommingInterviewData,
  getIdleInterviewData,
  getReviewPendingInterviewData,
  getCompletedInterviewData,
  getCancelledInterviewData,
  getInterviewDataById,
  getInterviewCountData,
  interviewReschedule,
  cancelInterview,
  getCurrentInterviewInfo,
  updateInterviewInformation,
  getScheduleDetails,
  setNoShowInterview,
  cancelScheduledInterview,
  getInterviewQuestionList,
  getInterviewInfoById,
  getInterviewQuestionById,
  interviewFeedbackSubmit,
  getInterviewAnswerById,
  getAIInterviewDetails,
  getInterviewHistory,
  getInfraction,
  getAssessment,
  getIdentityCheck,
  getAiAssessmentQuestionAnswers,
  getInfractionImages,
  submitInterviewFeedback,
  getInterviewList,
  getInterviewSearchList,
  sendFile,
  getCompanyDetails,
  getBig5SelectionList,
  addBigFiveSelection,
  getCheckMatch,
  getMultifaceQuestion,
};

export default interviewService;
