import AiTechnicalScoreChart from './ReviewCharts/AiTechnicalScoreChart';
import interviewService from 'Services/interviewService';
import { isEmpty } from 'lodash';
import React, { PureComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfractionsChart from './ReviewCharts/InfractionsChart';
import { useInterviewsStore } from 'Store/interviewsStore';
import CommunicationChart from './ReviewCharts/CommunicationChart';
import InfractionsChartNew from './ReviewCharts/InfractionsChartNew';
import { formatTime } from 'Utilities/utils';

const AssessmentNew = (props) => {
  const params = useParams();

  const paramsInterviewId = params?.interviewId;
  const [assesmentsData, setAssesmentData] = useState();
  const [infractionData, setInfractionData] = useState();
  const [tabInfractionData, setTabInfractionData] = useState();

  const { reviewMetadata } = useInterviewsStore((state) => ({
    reviewMetadata: state.reviewMetadata,
  }));

  useEffect(() => {
    getAssessmentData();
    getInfractionData();
  }, []);

  const getAssessmentData = async () => {
    await interviewService
      .getAssessment(
        !isEmpty(params) ? paramsInterviewId : props.nInterviewId,
        '',
      )
      .then((data) => {
        console.log('getAssessment', data.data);
        setAssesmentData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInfractionData = async () => {
    await interviewService
      .getInfraction(
        !isEmpty(params) ? paramsInterviewId : props.nInterviewId,
        '',
      )
      .then((data) => {
        console.log('getInfraction', data.data.data);
        setInfractionData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <h3 className="text-[#000] text-[16px] font-medium my-4">
        Assessment
      </h3>
      <div className="rounded-md bg-white flex items-center justify-between w-full p-8 flex-wrap gap-2">
        {assesmentsData && !isEmpty(assesmentsData?.technical_score) && (
          <div
            className={`rounded border border-[#EBEBEB] bg-white ${assesmentsData.communication && 'md:w-[32%]'} sm:w-[48%] w-full p-6`}
          >
            <h2 className="text-[#171717] text-[13px] font-medium">
              AI Technical Score
            </h2>
            <p className="text-[#6A6767] text-[11px] ">
              Out of {assesmentsData?.total_questions} Questions
            </p>
            <AiTechnicalScoreChart
              data={assesmentsData?.technical_score}
            />
          </div>
        )}
        {assesmentsData && !isEmpty(assesmentsData.all_infractions) && (
          <div
            className={`rounded border border-[#EBEBEB] bg-white ${assesmentsData.communication && 'md:w-[32%]'} sm:w-[48%] w-full p-6`}
          >
            <h2 className="text-[#171717] text-[13px] font-medium">
              Infractions
            </h2>
            <p className="text-[#6A6767] text-[11px] ">
              Out of {assesmentsData?.total_questions} Questions
            </p>
            <InfractionsChartNew
              data={assesmentsData?.tab_switch_infraction_with_questions}
              multiface_data={
                assesmentsData?.multiface_infraction_with_questions
              }
              total_questions={assesmentsData?.total_questions}
              interviewId={
                !isEmpty(params) ? paramsInterviewId : props.nInterviewId
              }
              interview_type={props?.interview_type}
            />
          </div>
        )}
        {assesmentsData &&
          !isEmpty(assesmentsData.communication) &&
          assesmentsData.communication !== false && (
            <div className="rounded border border-[#EBEBEB] bg-white md:w-[32%] sm:w-[48%] w-full p-6 h-full">
              <>
                <h2 className="text-[#171717] text-[13px] font-medium">
                  Communication
                </h2>
                <CommunicationChart data={assesmentsData?.communication} interview_type={props?.interview_type} />
              </>
            </div>
          )}
      </div>
      {props?.interview_type === 'Online Test' &&
      assesmentsData?.tab_switch_infractions &&
      assesmentsData?.tab_switch_infraction_with_questions?.length > 0 ? (
        <div className="bg-[#636F82] rounded-[7px] p-8 mt-5 w-[33%]">
          <h2 className="text-[#FFF] text-[14px] font-semibold">
            Tab Infraction List
          </h2>
          {assesmentsData &&
            assesmentsData.tab_switch_infractions !== 0 && (
              <div className="customScrollBar max-h-[200px] h-[135px] overflow-y-auto">
                {assesmentsData?.tab_switch_infraction_with_questions?.map(
                  (i, index) => (
                    <>
                      {i?.is_infraction && (
                        <div className="border-l-[2px] border-l-[#8AF1FF] mt-8 text-white pl-4">
                          <p className="text-[#CED6E1] text-[13px] font-medium">
                            Question No : {index + 1}
                          </p>
                          <p className="text-[#FFF] text-[12px] font-medium">
                            Tab Switch Time :{' '}
                            {formatTime(i.infraction__tab_switched_time)}
                          </p>
                        </div>
                      )}
                    </>
                  ),
                )}
              </div>
            )}
        </div>
      ) : null}
    </>
  );
};

export default AssessmentNew;
