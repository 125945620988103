import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse, Popover, Slider, Tooltip, message } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import {
  useCreateInterviewSettings,
  useCreateJobSettings,
  useCreateQuestionnireSettings,
  useInterviewettings,
  useJobSettings,
  useQuestionnaireSettings,
} from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
const { Panel } = Collapse;
const QuestionnaireSettings = (props) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prefix_needed: false,
      year_required: false,
      month_required: false,
      company_code: false,
      auto_suggest_previous_job_data: false,
      is_recommend_candidates_score: false,
    },
  });
  const questionnaireSettingsType = watch('is_questionnaire_settings');
  const questionnairePrefrenceType = watch('prefix_needed');
  const selectedYearType = watch('year_required');
  const selectedMonthType = watch('month_required');
  const comapnyCodeType = watch('company_code');
  const isReviewMonth = watch('is_review_month');
  const isReviewyear = watch('is_Review_year');
  const optionData = [
    { label: 'yy', value: 'YY' },
    { label: 'Y', value: 'Y' },
  ];
  const optionMonth = [
    { label: 'DD', value: 'DD' },
    { label: 'd', value: 'Y' },
  ];
  const optionYear = [
    selectedYearType ? { label: 'Year', value: 'YY' } : null,
    { label: 'Reference Number', value: 'Reference Number' },
    selectedMonthType ? { label: 'month', value: 'month' } : null,
    comapnyCodeType
      ? { label: 'Company code', value: 'Company code' }
      : null,
  ].filter((item) => item !== null);

  const handleSelectChange = (name, selectedOption, onChange) => {
    onChange(selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : null;
    setValue(name, selectedValue);
    const dropdowns = [
      'refno_format_value_1',
      'refno_format_value_2',
      'refno_format_value_3',
      'refno_format_value_4',
    ];
    dropdowns.forEach((dropdown) => {
      if (dropdown !== name && selectedValue === watch(dropdown)) {
        setValue(dropdown, null);
      }
    });
  };

  const { data: questionnaireSettingsData, refetch: isRefectch } =
    useQuestionnaireSettings({
      paramsData: {
        companyId: props.companyId,
      },
    });

  useEffect(() => {
    if (isEmpty(questionnaireSettingsData)) return;
    reset(questionnaireSettingsData);
  }, [questionnaireSettingsData]);

  const { mutateAsync: createQuestinnaire } =
    useCreateQuestionnireSettings({
      onSuccess: async (data, variables, context) => {
        toast.success('Successfully Registered..!!');
        await isRefectch();
      },
      onError: async (error) => {
        console.error('Creation Error:', error);
        const message = error?.response?.data?.error_info;
        // await Object.entries(message)?.forEach(([key, message]) => {
        // toast.error(DEFAULT_ERROR_MESSAGE);
        messageApi.open({
          type: 'error',
          content: message ?? DEFAULT_ERROR_MESSAGE,
        });
        //});
      },
    });
  const onSubmit = async (data) => {
    console.log('datatest', data);

    const refrenceFormat =
      (data.refno_format_value_2
        ? `{${data.refno_format_value_2}} - `
        : '') +
      (data.refno_format_value_1
        ? `{${data.refno_format_value_1}} - `
        : '') +
      (data.refno_format_value_3
        ? `{${data.refno_format_value_3}} - `
        : '') +
      (data.refno_format_value_4 ? `{${data.refno_format_value_4}}` : '');
    console.log('ssssdd', refrenceFormat);
    createQuestinnaire({
      data,
      refrenceFormat,
      comapnyId: props.companyId,
    });
  };
  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion
          className="bg-white rounded-lg"
          showArrow={false}
          collapsible="icon"
          expandIcon={({ isActive }) => {
            return (
              <div className="mt-4">
                {!isActive ? (
                  <img src={iconAccordion} alt="accordion" />
                ) : (
                  <img
                    src={iconAccordion}
                    alt="accordion"
                    className="rotate-180"
                  />
                )}
              </div>
            );
          }}
        >
          {() => {
            return (
              <Panel
                header={
                  <div className="flex justify-between">
                    <p className="mt-8 px-6  text-medium font-medium">
                      Questionnaire Settings
                    </p>
                    <div className="[&>*]:m-0 mt-8 px-6 ">
                      <Switch
                        name="is_questionnaire_settings"
                        control={control}
                        offText="disabled"
                        onText="enabled"
                      />
                    </div>
                  </div>
                }
                key={questionnaireSettingsType ? '1' : '0'}
                //style={panelStyle}
                className=""
                // extra={genExtra()}
              >
                <div className="px-6 ">
                  <div className="w-full">
                    <Input
                      name="questionnaire_reference_number"
                      label="Questionnaire Reference Number*"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                  <div className="mt-8 mb-6  h-[1px] w-full bg-[#E1E1E1]"></div>
                  <div className="flex justify-between">
                    <p className="text-medium font-medium">
                      Prefix Needed
                    </p>
                    <div className="[&>*]:m-0 ">
                      <Switch
                        name="prefix_needed"
                        control={control}
                        offText="disabled"
                        onText="enabled"
                      />
                    </div>
                  </div>
                  {questionnairePrefrenceType === true && (
                    <div>
                      <div className="w-full flex mt-4">
                        <div className="w-1/2">
                          <Checkbox
                            showUpperLabel={false}
                            name="year_required"
                            label="Year Required"
                            control={control}
                          />
                          {selectedYearType === true && (
                            <div className="pr-12">
                              <Select
                                name="year_required_format"
                                label=""
                                placeholder="Year"
                                control={control}
                                defaultValue={optionData[0]}
                                options={optionData.map((oOption) => {
                                  return {
                                    value: oOption.value,
                                    label: oOption.label,
                                  };
                                })}
                              />
                            </div>
                          )}
                        </div>
                        <div className="w-1/2 px-12">
                          <Checkbox
                            showUpperLabel={false}
                            name="month_required"
                            label="Month Required"
                            control={control}
                          />
                          {selectedMonthType === true && (
                            <Select
                              name="month_required_format"
                              label=""
                              placeholder="Month"
                              control={control}
                              defaultValue={optionMonth[0]}
                              options={optionMonth.map((oOption) => {
                                return {
                                  value: oOption.value,
                                  label: oOption.label,
                                };
                              })}
                              rules={{
                                required: 'This field is required',
                              }}
                              // valueLabel="role_name"
                              // optionLabel="role_name"
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-full flex mt-4">
                        <div className="w-1/2">
                          <Checkbox
                            showUpperLabel={false}
                            name="company_code"
                            label="Company Code"
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="w-full flex mt-7">
                    {selectedYearType === true && (
                      <div className="pr-12 w-1/4">
                        <Select
                          name="refno_format_value_1"
                          label=""
                          placeholder="select"
                          control={control}
                          options={optionYear.map((oOption) => {
                            return {
                              value: oOption.value,
                              label: oOption.label,
                            };
                          })}
                          onChange={(e, onChange) =>
                            handleSelectChange(
                              'refno_format_value_1',
                              e,
                              onChange,
                            )
                          }
                        />
                      </div>
                    )}
                    {selectedYearType === true && (
                      <div className="pr-12 w-1/4">
                        <Select
                          name="refno_format_value_2"
                          label=""
                          placeholder="select"
                          control={control}
                          onChange={(e, onChange) =>
                            handleSelectChange(
                              'refno_format_value_2',
                              e,
                              onChange,
                            )
                          }
                          options={optionYear.map((oOption) => {
                            return {
                              value: oOption.value,
                              label: oOption.label,
                            };
                          })}
                        />
                      </div>
                    )}
                    {selectedMonthType === true && (
                      <div className="pr-12 w-1/4">
                        <Select
                          name="refno_format_value_3"
                          label=""
                          placeholder="select"
                          control={control}
                          onChange={(e, onChange) =>
                            handleSelectChange(
                              'refno_format_value_3',
                              e,
                              onChange,
                            )
                          }
                          options={optionYear.map((oOption) => {
                            return {
                              value: oOption.value,
                              label: oOption.label,
                            };
                          })}
                        />
                      </div>
                    )}
                    {comapnyCodeType === true && (
                      <div className="pr-12 w-1/4">
                        <Select
                          name="refno_format_value_4"
                          label=""
                          placeholder="select"
                          control={control}
                          onChange={(e, onChange) =>
                            handleSelectChange(
                              'refno_format_value_4',
                              e,
                              onChange,
                            )
                          }
                          options={optionYear.map((oOption) => {
                            return {
                              value: oOption.value,
                              label: oOption.label,
                            };
                          })}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-12 mb-10">
                  {messageContextHolder}
                  <Button
                    className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                    htmlType="submit"
                    type="primary"
                  >
                    Save
                  </Button>
                </div>
              </Panel>
            );
          }}
        </Accordion>
      </form>
    </Card>
  );
};
export default QuestionnaireSettings;
