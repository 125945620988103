import { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import LeftArrow from '../Assets/images/interview/left_arrow.svg';
import RightArrow from '../Assets/images/interview/right_arrow.svg';

const Carousel = ({
  autoPlay = false,
  autoPlayInterval = '',
  // activeIndex = 0,
  slideItem = [],
  refEvent = null,
  disableButtonsControls = true,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hideButton, setHideButton] = useState(false);
  const [isNextSlideDisabled, setNextSlideDisable] = useState(false);
  const [isPrevSlideDisabled, setPrevSlideDisable] = useState(true);

  const handleSlideChanged = (e)=> {
    console.log(e);
    setActiveIndex(e.item);
    setNextSlideDisable(e.isNextSlideDisabled)
    setPrevSlideDisable(e.isPrevSlideDisabled)
    setHideButton(false)
  }

  return (
    <>
      <AliceCarousel
        //infinite
        autoPlay={autoPlay}
        // autoPlayInterval={autoPlayInterval}
        activeIndex={activeIndex}
        items={slideItem}
        autoWidth={false}
        autoHeight={false}
        touchTracking
        // disableButtonsControls={disableButtonsControls}
        renderPrevButton={() => {
          return (
            <img
              src={LeftArrow}
              className={`${isPrevSlideDisabled && 'invisible'} absolute translate-y-[-50%] top-[35%] left-[16%] p-2 cursor-pointer ${hideButton ? 'opacity-5' : 'opacity-100'} ease-in-out tab:block hidden`}
            />
          );
        }}
        renderNextButton={(e,v) => {
          return (
            <img
              src={RightArrow}
              className={`${isNextSlideDisabled && 'invisible'} absolute translate-y-[-50%] top-[35%] right-[15%] p-2 cursor-pointer ${hideButton ? 'opacity-5' : 'opacity-100'} ease-in-out tab:block hidden`}
            />
          );
        }}
        ref={refEvent}
        onSlideChange={(e) => setHideButton(true)}
        onSlideChanged={(e) => handleSlideChanged(e)}
      />
    </>
  );
};
export default Carousel;
