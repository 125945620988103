import { Space } from 'antd';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { CURRENCY_CODES } from 'Utilities/constants';

const { Compact } = Space;

export default function CTCField({ control }) {
  return (
    <Compact>
      <Select
        allowClear
        showArrow={false}
        control={control}
        name={'ctc_currency_code'}
        options={CURRENCY_CODES}
        label={<p className="mr-4">Code</p>}
      />
      <Input
        control={control}
        name={'current_ctc'}
        placeholder={'Eg. 400,000'}
        label={<p>Current C.T.C.</p>}
        rules={{
          pattern: {
            value: /^[0-9]+$/,
            message: 'Please use numbers only',
          },
        }}
      />
      <Input
        control={control}
        name={'expected_ctc'}
        placeholder={'Eg. 400,000'}
        label={<p>Expected C.T.C.</p>}
        rules={{
          pattern: {
            value: /^[0-9]+$/,
            message: 'Please use numbers only',
          },
        }}
      />
    </Compact>
  );
}
