import './Status404.scss';
import expiredIcons from 'Assets/images/yvi-url-expired.png';

const Status404 = () => {
  return (
    <div class="status404_wraper">
      {/* <div class="status404_wraper candidatesInfoPage flex justify-center items-center"> */}
      <div class="">
        <section class="card-sec">
          <div class="card yvi-card flex item-center justify-center">
            <div class="card-body">
              <div class="intro-image">
                <img src={expiredIcons} class="img-fluid" />
              </div>
              <h2 class="expired-message">Oops, this link <br />
                                has expired!
              </h2>
              <h4 class="co-admin flex item-center justify-center">Contact <span class="admin-link pl-2">Yvi Support Team</span></h4>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Status404;