import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { BiArrowBack } from 'react-icons/bi';

import 'Styles/commonStyles.scss';
import { downloadFile } from 'Utilities/utils';
import { useGetCompanyInformation } from 'ServiceHooks/layoutHooks';
import Button from 'Components/CommonComponents/Button/Button';

export default function CompanyInformation(props) {
  const navigate = useNavigate();

  const { data: info } = useGetCompanyInformation({});

  const isExistingQrLink = Boolean(info?.career_qr_code);

  return (
    <div>
      <Button
        type="ghost"
        className="flex items-center mb-4 cursor-pointer font-bold px-0"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BiArrowBack /> <span className="ml-2">Back</span>
      </Button>
      <div className="max-h-[calc(91vh-9rem)] w-4/5 m-auto px-12 py-11 bg-white overflow-x-hidden overflow-y-auto customScrollBar">
        <h1 className="text-black text-base font-medium">
          Company Information
        </h1>

        <div className="mt-11 flex">
          <div className="mr-6">
            {' '}
            <img src={info?.logo} className="h-[4.5rem]" />{' '}
          </div>
          <div className="px-7 border-l border-neutral-200 flex flex-col space-y-4 font-normal text-base">
            <div>
              Company Name:{' '}
              <span className="ml-2 font-medium">
                {info?.name ?? 'No Data'}
              </span>
            </div>
            <div>
              Company Website:
              <span className="ml-2 font-medium">
                {info?.company_website_urls ?? 'No Data'}
              </span>
            </div>
          </div>
        </div>

        <h2 className="mt-10 text-black text-base font-medium">Address</h2>

        <div className="text-black text-sm font-normal">
          <p className="w-2/5">{info?.company_address}</p>

          <div className="mt-4">
            Phone: {info?.company_contact_number ?? 'No Data'}
          </div>
          <div>Email: {info?.company_email ?? 'No Data'}</div>
        </div>

        <Divider className="my-8" />

        <div className="flex justify-between items-start w-3/4">
          <div>
            <h3 className="font-normal text-base">Career Portal URL</h3>
            <p className="font-medium text-sm ">
              {info?.career_url ?? 'No Data'}
            </p>
          </div>

          {isExistingQrLink && (
            <div className="flex flex-col items-center space-y-4 text-black">
              <h3 className="font-normal text-base">
                Career Portal QR Code
              </h3>
              <div>
                <img src={info?.career_qr_code} />
              </div>
              <Button
                type={'primary'}
                onClick={() =>
                  downloadFile(info?.career_qr_code, 'QR Code')
                }
                children={'Download QR Code'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
