import activity from 'Assets/latest_icons/activity.svg';
import activity_active from 'Assets/latest_icons/activity_active.svg';
import add_candidate from 'Assets/latest_icons/add_candidate.svg';
import add_candidate_active from 'Assets/latest_icons/add_candidate_active.svg';
import add_company from 'Assets/latest_icons/add_company.svg';
import add_company_active from 'Assets/latest_icons/add_company_active.svg';
import add_job from 'Assets/latest_icons/add_job.svg';
import add_job_active from 'Assets/latest_icons/add_job_active.svg';
import calender from 'Assets/latest_icons/calender.svg';
import calender_active from 'Assets/latest_icons/calender_active.svg';
import card from 'Assets/latest_icons/card.svg';
import card_active from 'Assets/latest_icons/card_active.svg';
import column from 'Assets/latest_icons/column.svg';
import column_active from 'Assets/latest_icons/column_active.svg';
import company_info from 'Assets/latest_icons/company_info.svg';
import company_info_active from 'Assets/latest_icons/company_info_active.svg';
import compare from 'Assets/latest_icons/compare.svg';
import compare_active from 'Assets/latest_icons/compare_active.svg';
import copy from 'Assets/latest_icons/copy.svg';
import copy_active from 'Assets/latest_icons/copy_active.svg';
import create_interview_slot from 'Assets/latest_icons/create_interview_slot.svg';
import create_interview_slot_active from 'Assets/latest_icons/create_interview_slot_active.svg';
import create_license from 'Assets/latest_icons/create_license.svg';
import create_license_active from 'Assets/latest_icons/create_license_active.svg';
import create_questionnire from 'Assets/latest_icons/create_questionnire.svg';
import create_questionnire_active from 'Assets/latest_icons/create_questionnire_active.svg';
import delete_icon from 'Assets/latest_icons/delete.svg';
import delete_active_icon from 'Assets/latest_icons/delete_active.svg';
import dislike from 'Assets/latest_icons/dislike.svg';
import dislike_reject from 'Assets/latest_icons/dislike_reject.svg';
import download from 'Assets/latest_icons/download.svg';
import download_active from 'Assets/latest_icons/download_active.svg';
import edit from 'Assets/latest_icons/edit.svg';
import edit_active from 'Assets/latest_icons/edit_active.svg';
import education from 'Assets/latest_icons/education.svg';
import education_active from 'Assets/latest_icons/education_active.svg';
import excel_upload from 'Assets/latest_icons/excel_upload.svg';
import excel_upload_active from 'Assets/latest_icons/excel_upload_active.svg';
import experience from 'Assets/latest_icons/experience.svg';
import experience_active from 'Assets/latest_icons/experience_active.svg';
import filter from 'Assets/latest_icons/filter.svg';
import filter_active from 'Assets/latest_icons/filter_active.svg';
import job from 'Assets/latest_icons/job.svg';
import job_active from 'Assets/latest_icons/job_active.svg';
import job_description from 'Assets/latest_icons/job_description.svg';
import job_description_active from 'Assets/latest_icons/job_description_active.svg';
import job_title from 'Assets/latest_icons/job_title.svg';
import job_title_active from 'Assets/latest_icons/job_title_active.svg';
import language from 'Assets/latest_icons/language.svg';
import language_active from 'Assets/latest_icons/language_active.svg';
import list from 'Assets/latest_icons/list.svg';
import list_active from 'Assets/latest_icons/list_active.svg';
import location from 'Assets/latest_icons/location.svg';
import location_active from 'Assets/latest_icons/location_active.svg';
import mail_send from 'Assets/latest_icons/mail_send.svg';
import mail_send_active from 'Assets/latest_icons/mail_send_active.svg';
import maximize from 'Assets/latest_icons/maximize.svg';
import maximize_active from 'Assets/latest_icons/maximize_active.svg';
import menu from 'Assets/latest_icons/menu.svg';
import menu_active from 'Assets/latest_icons/menu_active.svg';
import minimize from 'Assets/latest_icons/minimize.svg';
import minimize_active from 'Assets/latest_icons/minimize_active.svg';
import notification from 'Assets/latest_icons/notification.svg';
import notification_active from 'Assets/latest_icons/notification_active.svg';
import offer from 'Assets/latest_icons/offer.svg';
import offer_active from 'Assets/latest_icons/offer_active.svg';
import parameter from 'Assets/latest_icons/parameter.svg';
import parameter_active from 'Assets/latest_icons/parameter_active.svg';
import prescreening from 'Assets/latest_icons/prescreening.svg';
import prescreening_active from 'Assets/latest_icons/prescreening_active.svg';
import qrCode from 'Assets/latest_icons/qrCode.svg';
import qrCode_active from 'Assets/latest_icons/qrCode_active.svg';
import question_regenerate from 'Assets/latest_icons/question_regenerate.svg';
import question_regenerate_active from 'Assets/latest_icons/question_regenerate_active.svg';
import remove_from_job from 'Assets/latest_icons/remove_from_job.svg';
import remove_from_job_active from 'Assets/latest_icons/remove_from_job_active.svg';
import reset from 'Assets/latest_icons/reset.svg';
import reset_active from 'Assets/latest_icons/reset_active.svg';
import resume_builder from 'Assets/latest_icons/resume_builder.svg';
import resume_builder_active from 'Assets/latest_icons/resume_builder_active.svg';
import rupee from 'Assets/latest_icons/rupee.svg';
import rupee_active from 'Assets/latest_icons/rupee_active.svg';
import search from 'Assets/latest_icons/search.svg';
import search_active from 'Assets/latest_icons/search_active.svg';
import skill from 'Assets/latest_icons/skill.svg';
import skill_active from 'Assets/latest_icons/skill_active.svg';
import skill_set from 'Assets/latest_icons/skill_set.svg';
import skill_set_active from 'Assets/latest_icons/skill_set_active.svg';
import status_update from 'Assets/latest_icons/status_update.svg';
import status_update_active from 'Assets/latest_icons/status_update_active.svg';
import table_menu from 'Assets/latest_icons/table_menu.svg';
import table_menu_active from 'Assets/latest_icons/table_menu_active.svg';
import time from 'Assets/latest_icons/time.svg';
import time_active from 'Assets/latest_icons/time_active.svg';
import view from 'Assets/latest_icons/view.svg';
import view_active from 'Assets/latest_icons/view_active.svg';
import work_mode from 'Assets/latest_icons/work_mode.svg';
import work_mode_active from 'Assets/latest_icons/work_mode_active.svg';
import dashBordIcon from 'Assets/latest_icons/dashbordIcon.svg';
import OPEN_JOB_TAB from 'Assets/latest_icons/activeJobIcon.svg';
import APPROVAL_PENDING_JOB_TAB from 'Assets/latest_icons/approvalIcon.svg';
import OVERDUE_JOB_TAB from 'Assets/latest_icons/overdueIcon.svg';
import DRAFT_JOB_TAB from 'Assets/latest_icons/draftIcon.svg';
import HOLD_JOB_TAB from 'Assets/latest_icons/hold.svg';
import CLOSED_JOB_TAB from 'Assets/latest_icons/onboardCompletedIcon.svg';
import ONBOARDING_PROGRESS_JOB_TAB from 'Assets/latest_icons/onboarding_progress.svg';
import QUESTIONAIRE_AWAITING_JOB_TAB from 'Assets/latest_icons/awaitingQuestionnaireIcon.svg';
import FINALIZED_CANDIDATES from 'Assets/latest_icons/candiate_finalizedIcon.svg';
import VIEW_CONFLICTED_CANDIDATES from 'Assets/latest_icons/conflicted_icon.svg';
import UPCOMING_INTERVIEWS_TAB from 'Assets/latest_icons/interview_upcomingIcon.svg';
import REVIEW_PENDING_INTERVIEWS_TAB from 'Assets/latest_icons/interview_reviewpending.svg';
import REVIEW_COMPLETED_INTERVIEWS_TAB from 'Assets/latest_icons/interview_reviewCompleted.svg';
import CANCELLED_INTERVIEWS_TAB from 'Assets/latest_icons/interview_cancelledIcon.svg';
import ALL_INTERVIEWS_TAB from 'Assets/latest_icons/all_interviewIcon.svg';
import PIPLELINE_CANDIDATES from 'Assets/latest_icons/pipeline_icon.svg';
import OFFERED_CANDIDATES from 'Assets/latest_icons/candidate-offered-icon.svg';
import REJECTED_CANDIDATES from 'Assets/latest_icons/conflicted_icon.svg';
import NO_SHOW_CANDIDATES from 'Assets/latest_icons/no-show-icon.svg';
import QUESTIONNAIRE from 'Assets/latest_icons/create-questionnaire.svg';
import VIEW_DASHBOARD_RECRUITEMENT_STATISTICS from 'Assets/latest_icons/recruitment-statstics.svg';
import VIEW_DASHBOARD_INTERVIEWS from 'Assets/latest_icons/interview-statistics.svg';
import VIEW_DASHBOARD_RECRUITEMENT_ANALYZE from 'Assets/latest_icons/Recruiters.svg';
import VIEW_DASHBOARD_DEPARTMENT_VACANCIES from 'Assets/latest_icons/departmentwise_statistics.svg';

const dashbordIconsList = {
  OPEN_JOB_TAB,
  APPROVAL_PENDING_JOB_TAB,
  OVERDUE_JOB_TAB,
  DRAFT_JOB_TAB,
  HOLD_JOB_TAB,
  CLOSED_JOB_TAB,
  QUESTIONAIRE_AWAITING_JOB_TAB,
  FINALIZED_CANDIDATES,
  VIEW_CONFLICTED_CANDIDATES,
  ONBOARDING_PROGRESS_JOB_TAB,
  UPCOMING_INTERVIEWS_TAB,
  REVIEW_PENDING_INTERVIEWS_TAB,
  REVIEW_COMPLETED_INTERVIEWS_TAB,
  CANCELLED_INTERVIEWS_TAB,
  ALL_INTERVIEWS_TAB,
  PIPLELINE_CANDIDATES,
  OFFERED_CANDIDATES,
  REJECTED_CANDIDATES,
  NO_SHOW_CANDIDATES,
  QUESTIONNAIRE,
  VIEW_DASHBOARD_RECRUITEMENT_STATISTICS,
  VIEW_DASHBOARD_INTERVIEWS,
  VIEW_DASHBOARD_RECRUITEMENT_ANALYZE,
  VIEW_DASHBOARD_DEPARTMENT_VACANCIES,
};
const dashboardLabelList = {
  OPEN_JOB_TAB: 'Total Active Jobs',
  APPROVAL_PENDING_JOB_TAB: 'Total Pending Approval',
  OVERDUE_JOB_TAB: 'Overdue Jobs',
  DRAFT_JOB_TAB: 'Draft Jobs',
  HOLD_JOB_TAB: 'Hold Jobs',
  ONBOARDING_PROGRESS_JOB_TAB: 'Offered',
  QUESTIONAIRE_AWAITING_JOB_TAB: 'Awaiting Questionnaire',
  ALL_STATUS_JOB_TAB: 'All Status Jobs',
  FINALIZED_CANDIDATES: 'Finalized Candidates',
  VIEW_CONFLICTED_CANDIDATES: 'Conflicted Candidates',
  CLOSED_JOB_TAB: 'Closed',
  UPCOMING_INTERVIEWS_TAB: 'Upcoming Interviews',
  REVIEW_PENDING_INTERVIEWS_TAB: 'Review Pending',
  REVIEW_COMPLETED_INTERVIEWS_TAB: 'Review Completed',
  CANCELLED_INTERVIEWS_TAB: 'Cancelled Interview',
  ALL_INTERVIEWS_TAB: 'All Interviews',
  PIPLELINE_CANDIDATES: 'Pipeline Candidates',
  OFFERED_CANDIDATES: 'Offered Candidates',
  REJECTED_CANDIDATES: 'Rejected Candidates',
  NO_SHOW_CANDIDATES: 'No Show Candidates',
  QUESTIONNAIRE: 'Create Questionnaire',
  VIEW_DASHBOARD_RECRUITEMENT_STATISTICS: 'Recruitement Statistics',
  VIEW_DASHBOARD_INTERVIEWS: 'Interviews',
  VIEW_DASHBOARD_RECRUITEMENT_ANALYZE: 'Recruiters Analyze',
  VIEW_DASHBOARD_DEPARTMENT_VACANCIES: 'Department Wise vacancies',
};
const dashboardNavigationList = {
  OPEN_JOB_TAB: `/hr/jobs/${'ACTIVE'}`,
  APPROVAL_PENDING_JOB_TAB: `/hr/jobs/${'PENDING'}`,
  OVERDUE_JOB_TAB: `/hr/jobs/${'OVERDUE'}`,
  DRAFT_JOB_TAB: `/hr/jobs/${'DRAFT'}`,
  HOLD_JOB_TAB: `/hr/jobs/${'HOLD'}`,
  ONBOARDING_PROGRESS_JOB_TAB: `/hr/jobs/${'ONBOARDING_IN_PROGRESS'}`,
  QUESTIONAIRE_AWAITING_JOB_TAB: `/hr/jobs/${'QUESTIONAIRE'}`,
  FINALIZED_CANDIDATES: `/hr/candidates/${'finalized'}`,
  VIEW_CONFLICTED_CANDIDATES: `/hr/candidates/${'conflicted'}`,
  CLOSED_JOB_TAB: `/hr/jobs/${'CLOSED'}`,
  PIPLELINE_CANDIDATES: `/hr/candidates/${'pipeline'}`,
  OFFERED_CANDIDATES: `/hr/candidates/${'offered'}`,
  REJECTED_CANDIDATES: `/hr/candidates/${'rejected'}`,
  NO_SHOW_CANDIDATES: `/hr/candidates/${'no-show'}`,
  UPCOMING_INTERVIEWS_TAB: `/hr/interviewer`,
  REVIEW_PENDING_INTERVIEWS_TAB: `/hr/interviewer`,
  REVIEW_COMPLETED_INTERVIEWS_TAB: `/hr/interviewer`,
  CANCELLED_INTERVIEWS_TAB: `/hr/interviewer`,
  ALL_INTERVIEWS_TAB: `/hr/interviewer`,
  QUESTIONNAIRE: `/hr/question-bank`,
};

const getIcons = (icon) => {
  const activeIcons = dashbordIconsList[icon];
  console.log('activeIconsdashbordIconsList', dashbordIconsList);
  console.log('activeIconsicon', icon);
  return activeIcons;
};
const getLabels = (iconLabels) => {
  const actvieLables = dashboardLabelList[iconLabels];
  return actvieLables;
};
const getRoutes = (iconLabels) => {
  const actvieRoutes = dashboardNavigationList[iconLabels];
  return actvieRoutes;
};
import options from 'Assets/latest_icons/options.svg';
import auto_generate from 'Assets/latest_icons/auto_generate.svg';
import add_hiring_team from 'Assets/latest_icons/add_hiring_team.svg';
import hiring_team from 'Assets/latest_icons/hiring_team.svg';
import notes from 'Assets/latest_icons/notes.svg';
import small_trash from 'Assets/latest_icons/small_trash.svg';
import small_edit from 'Assets/latest_icons/small_edit.svg';
import prescreen from 'Assets/latest_icons/prescreen.svg';
import interview_level from 'Assets/latest_icons/interview_level.svg';
import publish from 'Assets/latest_icons/publish.svg';
import schedule from 'Assets/latest_icons/schedule.svg';
import send from 'Assets/latest_icons/send.svg';
import reviewer from 'Assets/latest_icons/reviewer.svg';
import client from 'Assets/latest_icons/client.svg';
import consultancy from 'Assets/latest_icons/consultancy.svg';

export const TableActionsIcon = ({ className = '' }) => (
  <div className="flex items-center justify-center">
    <img src={table_menu} className={`rotate-90 ${className}`} />
  </div>
);

export {
  activity,
  activity_active,
  add_candidate,
  add_candidate_active,
  add_company,
  add_company_active,
  add_job,
  add_job_active,
  calender,
  calender_active,
  card,
  card_active,
  column,
  column_active,
  company_info,
  company_info_active,
  compare,
  compare_active,
  copy,
  copy_active,
  create_interview_slot,
  create_interview_slot_active,
  create_license,
  create_license_active,
  create_questionnire,
  create_questionnire_active,
  delete_icon,
  delete_active_icon,
  dislike,
  dislike_reject,
  download,
  download_active,
  edit,
  edit_active,
  education,
  education_active,
  excel_upload,
  excel_upload_active,
  experience,
  experience_active,
  filter,
  filter_active,
  job,
  job_active,
  job_description,
  job_description_active,
  job_title,
  job_title_active,
  language,
  language_active,
  list,
  list_active,
  location,
  location_active,
  mail_send,
  mail_send_active,
  maximize,
  maximize_active,
  menu,
  menu_active,
  minimize,
  minimize_active,
  notification,
  notification_active,
  offer,
  offer_active,
  parameter,
  parameter_active,
  prescreening,
  prescreening_active,
  qrCode,
  qrCode_active,
  question_regenerate,
  question_regenerate_active,
  remove_from_job,
  remove_from_job_active,
  reset,
  reset_active,
  resume_builder,
  resume_builder_active,
  rupee,
  rupee_active,
  search,
  search_active,
  skill,
  skill_active,
  skill_set,
  skill_set_active,
  status_update,
  status_update_active,
  table_menu,
  table_menu_active,
  time,
  time_active,
  view,
  view_active,
  work_mode,
  work_mode_active,
  dashBordIcon,
  getIcons,
  getLabels,
  options,
  auto_generate,
  add_hiring_team,
  hiring_team,
  notes,
  small_trash,
  small_edit,
  prescreen,
  interview_level,
  publish,
  schedule,
  send,
  reviewer,
  client,
  consultancy,
  getRoutes,
};
