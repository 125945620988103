import { create } from 'zustand';

const EditJobHiringStageStore = create((set) => ({
  EditJobHiringStageResponse: '',
  setEditJobHiringStageResponse: (response) => {
    set(() => ({
        EditJobHiringStageResponse: response,
    }));
  },
}));

export default EditJobHiringStageStore;
