import { useState ,createContext} from "react";

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [company_id, setCompanyID] = useState(localStorage.getItem("company_id"))

  return (
    <MyContext.Provider value={{company_id, setCompanyID}}>
      {children}
    </MyContext.Provider>
  );
};