import React, { useState, useEffect, useRef } from "react";
import TextEdit from "../CommonComponents/TextEdit";
import { Space, Switch } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import CommunicationService from "../../Services/communicationService";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import Empty from 'Components/CommonComponents/Empty/Empty';

var updatedText;
const PushNotification = () => {

  const [nameCopied, setNameCopied] = useState(false);
  const [companyCopied, setCompanyCopied] = useState(false);
  const [roleCopied, setRoleCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [getTransactionId, setGetTransactionId] = useState("");
  const [activeForm, setActiveForm] = useState(false);
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [role, setRole] = useState();
  const [url, setUrl] = useState();
  const [transcationData, setTranscationData] = useState();
  const [details, setDetails] = useState();
  const [error, setError] = useState(false);
  const [isValidateError, setIsisValidateError] = useState(false);
  const [transDetails, setTransDetails] = useState({
    name: "Hari",
    company: "Web",
    role: "Developer",
    url: `https://mui.com/material-ui/material-icons/?query=Cop`,
  });
  const inputRef = useRef();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const transcationInfo = async () => {
    await CommunicationService.getTranscationData().then((response) => {
      setTranscationData(response.data.data);
    });
  };
  useEffect(() => {
    transcationInfo();
  }, []);
  const getTranscationDetails = async (id) => {
    await CommunicationService.getTranscationNotificationById(id).then((response) => {
      setDetails(response.data.data);
    });
  };
  const handleReset = async () => {
    await CommunicationService.getResetNotificationById(getTransactionId).then((response) => {
      setDetails(response.data.data);
      toast.success("Successfully Updated");
    })
      .catch((err) => {
        console.log(err);
      })

  }
  // useEffect(() => {
  //   getTranscationDetails();
  // }, [getTransactionId]);

  const handleNameCopy = (item) => {
    updatedText = `{${item}}`;
    navigator.clipboard.writeText(updatedText);
    setNameCopied(true);
    setTimeout(() => {
      setNameCopied(false);
    }, 2000);
    toast.success("Copied");
  };

  const handleCompanyCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(transDetails.company);
    setCompanyCopied(true);
    setTimeout(() => {
      setCompanyCopied(false);
    }, 2000);
  };

  const handleRoleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(transDetails.role);
    setRoleCopied(true);
    setTimeout(() => {
      setRoleCopied(false);
    }, 2000);
  };

  const handleURLCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(transDetails.url);
    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };
  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  const onSubmit = async (data, e) => {
    var content = removeTags(data.message).replace(/&nbsp;/g, "");
    const array = details?.parameters
    const emptyBracesRegex = /{\s*}/g;
    const regex = /{([^{}]+)}/g;
    const matches = description.match(regex);

    // Test if the input matches the regex pattern
    const emptybracesvalidate = emptyBracesRegex.test(description)
    const isValidSentence = matches.some((match) => {
      // Remove curly braces from the match
      const element = match.slice(1, -1).trim();
      return !array.includes(element);
    });

    console.log("tesdes", isValidSentence);
    if (emptybracesvalidate) {
      if (emptybracesvalidate === true) {
        setError(true)
      }
      else {
        setError(false)
      }

    }
    else if (isValidSentence === true) {
      setIsisValidateError(true)
    }
    else if (details?.id == null) {
      await CommunicationService.addPushNotification(
        data,
        getTransactionId,
        content
      )
        .then(
          (res) => {
            toast.success("Successfully Created");
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            toast.error("Something went wrong..!!");
          }
        )
        .catch((err) => {
        });
    } else if (details?.id) {
      await CommunicationService.editPushNotification(
        data,
        getTransactionId,
        details?.id,
        content
      )
        .then(
          (res) => {
            toast.success("Successfully Created");
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            toast.error("Something went wrong..!!");
          }
        )
        .catch((err) => {
        });
    }
  };
  const handleSwtich = async (e) => {
    await CommunicationService.activeNotificationTranscation(getTransactionId, e)
      .then((res) => {
        toast.success("Successfully updated");
        transcationInfo();
      })
      .catch((err) => {
      })
  }
  const selectTransactionHandler = (e) => {
    setGetTransactionId(e.target.value);

    // Form Activate
    if (e.target.value === "") {
      setActiveForm(false);
    } else {
      setActiveForm(true);
    }
    getTranscationDetails(e.target.value)
  };

  const pushNotificationSubmitHandler = (e) => {
    e.preventDefault();
    alert("PushNotification.js --> submitted");

  };
  return (
    <>
      <div className="w-full scrollbar-hide ">
        <div className="bg-secondaryBgMain rounded-lg mt-6 pb-8">
          <div className="ml-8">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex md:flex-row flex-col flex-wrap">
                <div className="md:w-1/2 px-6 mt-10 rounded-md border border-gray-300">
                  <div className="w-full mt-8">
                    <div className="mt-4 w-full">
                      <label className="pr-2 pb-2 flex text-black text-sm font-normal">
                        Transaction
                      </label>
                      <select
                        class="bg-white  rounded-md  border border-gray-400 border-opacity-75 shadow-md text-gray-900 text-sm w-60 md:w-full p-2.5 h-12"
                        onChange={(e) => selectTransactionHandler(e)}
                      >
                        <option value="">Select Option</option>
                        {transcationData &&
                          transcationData.results.map((trans, i) => (
                            <option value={trans.key}>
                              {trans.value}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mt-8 w-full">
                      <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                        Subject
                      </label>
                      <input
                        type="text"
                        {...register("title", {
                          required: "",
                        })}
                        defaultValue={details && details.title}
                        required
                        className="text-sm  h-12 w-60 md:w-full px-2 py-2 flex bg-white rounded-md border border-gray-400 border-opacity-75 shadow-md" />
                    </div>
                    <div className="w-full mt-8 pb-10">
                      <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                        Content
                      </label>
                      <Controller
                        ref={inputRef}
                        name="message"
                        control={control}
                        className="public-DraftStyleDefault-block"
                        register={register("message", {
                          required: "",
                        })}
                        render={({ field: { onChange } }) => (
                          <TextEdit
                            id="description"
                            onChange={onChange}
                            initialValue={
                              details?.id ? details && details.message : ""
                            }
                          />
                        )}
                      />
                      {errors && errors.content_html && (
                        <p className="text-red-600 text-xs font-semibold text-start">
                          *{errors.content_html.message}
                        </p>
                      )}
                      {error &&
                        (
                          <p className="text-red-600 text-xs font-semibold text-start">
                            Curly braces cannot be empty
                          </p>
                        )}
                      {
                        isValidateError && (
                          <p className="text-red-600 text-xs font-semibold text-start">
                            Invalid data inside curly braces
                          </p>
                        )
                      }

                    </div>
                  </div>

                </div>
                <div className="md:w-1/2 px-8">
                  {activeForm ? (
                    <div className="w-full h-fit p-2 mt-10 rounded-md border-dashed border-gray-300 border-2">
                      <div className="pl-4 w-full flex-col flex ">
                        {details && details.parameters.map((item, index) => (
                          <div>

                            {index === 0 && (
                              <div className="activeButton mb-4">
                                <Switch
                                  checked={item.is_active}
                                  checkedChildren="Active"
                                  unCheckedChildren="InActive"
                                  //  className="text-gray-700"
                                  onChange={(e) => handleSwtich(e)}
                                // onChange={(e) => handleSwitch(e, item.id)}
                                />
                              </div>
                            )}
                            <div className="flex ">
                              <input
                                className="text-sm w-full lg:w-full px-2 py-2 mb-8 flex bg-white focus:outline-none rounded-sm border border-blue-500 border-opacity-50 "
                                type="text" value={item} key={index}
                              //onChange={(e) => setName(item)}
                              />
                              <button
                                className="text-indigo-300 flex flex-wrap"
                                title="copy"
                                //onClick={handleNameCopy}
                                onClick={() => handleNameCopy(item)}
                              >
                                <span className="mt-[6px] ml-[5px]">
                                  <ContentCopy />
                                </span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <button
                        // className="bg-red-600 hover:bg-red-500 py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
                        className="secondary-btn restButton px-4 mx-2 mr-5 "
                        onClick={handelReset}
                      >
                        Reset
                      </button> */}
                    </div>
                  ) :
                    <div className="flex items-center justify-center">
                      <Empty
                        description={(
                          <>
                            {
                              <p> No Selected Transaction</p>
                            }
                          </>
                        )}
                      />
                    </div>}
                </div>
              </div>

              <div className="flex text-white text-sm justify-end items-center mt-20 font-semibold mr-10 ">
                <div className="flex items-center justify-between">
                  <button
                    // className="bg-red-600 hover:bg-red-500 py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
                    className="cancel_btn px-1 ml-6 mt-4"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    // className="bg-red-600 hover:bg-red-500 py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
                    className="popup_skills primary-btn px-1 ml-6"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
  // return (
  //   <>
  //     <div className="pt-2 ml-[7px] mr-4 mb-4 w-full flex flex-col flex-wrap md:flex-row h-fit">
  //       <form className="w-1/2" onSubmit={handleSubmit(onSubmit)}>
  //         <div className="p-2 m-2 w-full h-fit flex flex-wrap md:flex">
  //           <div className="w-full">
  //             <div className="m-2 p-2 w-full">
  //               <label className="text-sm font-semibold pr-2 pb-2 flex">
  //                 Transaction
  //               </label>
  //               <select
  //                 class="bg-white border-[0.1px] border-gray-300 text-gray-900 text-sm rounded-[10px] block w-60 md:w-full p-2.5 focus:outline-none hover:drop-shadow-md h-12"
  //                 onChange={selectTransactionHandler}
  //               >
  //                 <option value="">Select Option</option>
  //                 {transcationData &&
  //                   transcationData.results.map((trans, i) => (
  //                     <option value={trans.key}>
  //                       {trans.value}
  //                     </option>
  //                   ))}


  //               </select>
  //             </div>
  //             <div className="m-2 p-2 w-full">
  //               <label className="text-sm font-semibold pr-2 pb-2 flex">
  //                 Subject
  //               </label>
  //               <input
  //                 type="text"
  //                 {...register("title", {
  //                   required: "",
  //                 })}
  //                 defaultValue={details && details.title}
  //                 className="text-sm hover:drop-shadow-md rounded-[10px] h-12 w-60 md:w-full px-2 py-2 flex bg-white border-[0.1px] border-gray-300 focus:outline-none"
  //               />
  //             </div>
  //             <div className="m-2 p-2 w-full">
  //               <label className="text-sm font-semibold pr-2 pb-2 flex">
  //                 Content
  //               </label>
  //               <Controller
  //                 ref={inputRef}
  //                 name="message"
  //                 control={control}
  //                 className="public-DraftStyleDefault-block"
  //                 register={register("message", {
  //                   required: "",
  //                 })}
  //                 render={({ field: { onChange } }) => (
  //                   <TextEdit
  //                     id="description"
  //                     onChange={onChange}
  //                     initialValue={
  //                       details?.id ? details && details.message : ""
  //                     }
  //                   />
  //                 )}
  //               />
  //               {errors && errors.content_html && (
  //                 <p className="text-red-600 text-xs font-semibold text-start">
  //                   *{errors.content_html.message}
  //                 </p>
  //               )}
  //             </div>
  //             <div className="flex text-white text-sm justify-end items-start font-semibold">
  //               <button

  //                 className="primary-btn"
  //               >
  //                 Submit
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </form>
  //       {activeForm ? (
  //         <div className="w-1/2 h-fit mt-2 p-2">
  //           <div className="m-4 p-1 mt-8 pl-8 w-full flex-col flex">

  //             {details &&
  //               details.parameters.map((item, index) => (
  //                 <div>
  //                   {index === 0 && (
  //                     <div className="activeButton mb-4">
  //                       <Switch
  //                         checked={item.is_active}
  //                         checkedChildren="Active"
  //                         unCheckedChildren="InActive"
  //                         className="text-gray-700"
  //                         onChange={(e) => handleSwtich(e)}
  //                       // onChange={(e) => handleSwitch(e, item.id)}
  //                       />
  //                     </div>
  //                   )}
  //                   <div className="flex">
  //                     <input
  //                       className="text-sm w-52 lg:w-3/4 px-2 py-2 mb-8 flex bg-white border border-indigo-300 focus:outline-none rounded-[10px]"
  //                       type="text"
  //                       value={item}
  //                       key={index}
  //                     //onChange={(e) => setName(item)}
  //                     />
  //                     <button
  //                       className="text-indigo-300 flex flex-wrap"
  //                       title="copy"
  //                       //onClick={handleNameCopy}
  //                       onClick={() => handleNameCopy(item)}
  //                     >
  //                       <span className="mt-[6px] ml-[5px]">
  //                         <ContentCopy />
  //                       </span>
  //                     </button>
  //                     {/* {nameCopied ? (
  //                   <span className="text-green-500 text-sm">Copied</span>
  //                 ) : null} */}
  //                   </div>
  //                 </div>
  //               ))}
  //           </div>
  //           <button
  //             // className="bg-red-600 hover:bg-red-500 py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
  //             className="secondary-btn restButton"
  //             onClick={handleReset}
  //           >
  //             Reset
  //           </button>
  //         </div>
  //       ) : null}
  //     </div>
  //   </>
  // );
};

export default PushNotification;
