import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { message, Spin } from 'antd';
import dayjs from 'dayjs';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import DatePicker from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import RangePicker from 'Components/CommonComponents/ReactHooKForm/RangePicker/RangePicker';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Button from 'Components/CommonComponents/Button/Button';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import { useCandidateInfo } from 'ServiceHooks/candidatesHooks';
import {
  useAddCandidateExperience,
  useEditCandidateExperience,
} from 'ServiceHooks/candidatesHooks';
import 'Styles/commonStyles.scss';
import { FORM_TYPES } from 'Components/Candidate/ExperienceTab';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';

export default function ResumeFormExperienceModal(props) {
  const { isEditForm, close,setTemplateData, experiences,editIndex } = props;
  const params = useParams();
  console.log(props?.details,"props?.details");
  const defaultValues = {
    company: isEditForm ? props?.details?.company : '',
    location: isEditForm ? props?.details?.location : '',
    designation: isEditForm ? props?.details?.designation : '',
    isCurrentCompany: isEditForm
      ? props?.details?.isCurrentCompany || (props?.details?.worked_from && !props?.details?.worked_till)
      : false,
    worked_from: isEditForm ? dayjs(props?.details?.worked_from) : '',
    duration: isEditForm && props?.details?.worked_till && props?.details?.worked_till !== "Present"
      ? [
          dayjs(props?.details?.worked_from),
          dayjs(props?.details?.worked_till),
        ]
      : [],
    responsibilities: isEditForm ? props?.details?.responsibilities : '',
  };

  const {
    handleSubmit,
    control,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'all',
  });



  const isCurrentCompany = useWatch({ control, name: 'isCurrentCompany' });
  const [messageApi, contextHolder] = message.useMessage();


  const onSubmit = async (fields) => {
    try {
      const { company, designation, isCurrentCompany, responsibilities,location } =
        fields;

      const data = {
        candidate: params?.candidateId,
        company,
        designation,
        isCurrentCompany: isCurrentCompany,
        responsibilities,
        location,
      };

      if (isCurrentCompany) {
        data['worked_from'] = dayjs(fields.worked_from).format(
          'YYYY-MM-DD',
        );
        data['worked_till'] = "Present";
      } else {
        data['worked_from'] = dayjs(fields.duration[0]).format(
          'YYYY-MM-DD',
        );
        data['worked_till'] = dayjs(fields.duration[1]).format(
          'YYYY-MM-DD',
        );
      }
      // let newExpr = [...experiences,data]
      // console.log(newExpr,"newExprnewExpr");
      console.log(experiences, "ENTERRRRRRRRR",data);
      if(isEditForm){
        experiences[editIndex]["company"] = data?.company
        experiences[editIndex]["responsibilities"] = data?.responsibilities
        experiences[editIndex]["designation"] = data?.designation
        experiences[editIndex]["worked_from"] = data?.worked_from
        experiences[editIndex]["worked_till"] = data?.worked_till? data?.worked_till : "Present"
        experiences[editIndex]["location"] = data?.location
        experiences[editIndex]["isCurrentCompany"] = data?.isCurrentCompany
        setTemplateData((prev)=> {
          return {
            ...prev,
            experiences:{value:experiences}
          }
        })
      }else {
        console.log(data,"datadata");
        data["worked_till"] = data?.worked_till ? data?.worked_till : "Present"
        setTemplateData((prev)=> {
          return {
            ...prev,
            experiences:{value:[...prev?.experiences?.value,data]}
          }
        })
      }
      
      close()
    } catch (error) {
      console.error('Submission Error', error);
    }
  };


  return (
    <div className="w-full px-10 pt-8">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="w-full">
          <Input
            label="Company"
            name="company"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Invalid Company Name',
              },
            }}
            placeholder="Name"
          />
        </div>
        {/* <div className="w-full">
          <Input
            label="Location"
            name="location"
            control={control}
            rules={{
              required: 'This Field is Required',
              // pattern: {
              //   value: /[a-zA-Z]/g,
              //   message: 'Invalid Location Name',
              // },
            }}
            placeholder="Name"
          />
        </div> */}
        <div className="w-full">
          <Input
            label="Designation"
            name="designation"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Please enter letters only',
              },
            }}
          />
        </div>
        <div className="w-full">
          <Checkbox
            label="Current Company?"
            name="isCurrentCompany"
            control={control}
            showUpperLabel={false}
          />
        </div>
        <div className="w-full">
          {isCurrentCompany ? (
            <DatePicker
              label="Starting Date"
              name="worked_from"
              control={control}
              noTextInput
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
              format="DD/MM/YYYY"
            />
          ) : (
            <RangePicker
              label="Duration"
              name="duration"
              control={control}
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
              format="DD/MM/YYYY"
            />
          )}
        </div>
        <div>
          <div>responsibilities</div>
          <TextArea
            rows={4}
            name="responsibilities"
            control={control}
            className="h-[250px]"
            rules={{
            //   required: 'This field is required',
            }}
          />
        </div>

        <div className="flex ml-auto mt-4 gap-4">
          {contextHolder}
          <Button
            isCancel
            htmlType="submit"
            disabled={false}
            children="Cancel"
            onClick={close}
          />
          <Button type="primary" htmlType="submit" disabled={false}>
            Add
          </Button>
        </div>
      </form>
    </div>
  );
}
