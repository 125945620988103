import { useContext, useState, useMemo, useEffect, useRef } from 'react';
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { Modal, message, Avatar, Badge, Dropdown, Popover } from 'antd';
import { IoMdNotificationsOutline, IoIosArrowDown } from 'react-icons/io';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import routes from 'Routes';
import CompanyInfoImg from 'Assets/icons/CompanyInfoImg.svg';
import Button from 'Components/CommonComponents/Button/Button';
import { useLayoutStore } from 'Store/LayoutStore';
import SideNavigation from './SideNavigation';
import useDeviceDetect from '../../Hooks/useDeviceDetect';
import {
  useBlacklistJwtToken,
  useGetCompanyList,
} from 'ServiceHooks/authHooks';
import NotificationScreen from './NotificationScreen';
import Card from 'Components/CommonComponents/Card/Card';
import ResetPassword from 'pages/Auth/Reset Password';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { MyContext } from 'Context/context';
import { usePersistedStore } from 'Store/persistedStore';
import {
  company_info,
  company_info_active,
  notification,
  notification_active,
  dashBordIcon,
} from 'Components/CommonComponents/Icons';
import DashBoardModal from './dashboardModal';

export default function Layout(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const redirect_url = searchParams.get('redirect_url');
    if (!user) {
      navigate(`/login?redirect_url=${redirect_url}`);
    }
  }, [navigate, searchParams]);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isResetPassword, setRestPassword] = useState(false);
  const isMobileDevice = useDeviceDetect();
  const [messageApi, contextHolder] = message.useMessage();
  const [companySearch, setCompanySearch] = useState('');
  const [isInitiallyFetched, toggleIsInitiallyFetched] = useToggle(true);
  const [open, toggleOpen] = useToggle(false);
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const { company_id, setCompanyID } = useContext(MyContext);
  const drawerRef = useRef();
  const details = JSON.parse(localStorage?.getItem('user'));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDashboardOpen(false);
      }
    };

    if (isDashboardOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDashboardOpen]);

  const { mutateAsync: logout } = useBlacklistJwtToken({
    onError: async (error) => {
      const isLoggedOut =
        error?.error_info?.detail?.includes('is blacklisted');
      const type = isLoggedOut ? 'info' : 'error';
      const content = isLoggedOut
        ? 'Already logged out.'
        : DEFAULT_ERROR_MESSAGE;
      await messageApi.open({
        type,
        content,
      });
    },
  });

  const isCompanyListVisible =
    [routes.ENQUIRY, routes.LICENSE, routes.REGISTRATION]
      .map((item) => location?.pathname.includes(item))
      .filter((item) => !!item).length < 1;
  const isDashbordIconVisible = location?.pathname.includes(
    routes.DASHBOARD,
  );

  const {
    notifications,
    toggleNotification,
    clearReadNotifications,
    resetGridColumPreference,
  } = useLayoutStore((state) => ({
    notifications: state.notifications,
    toggleNotification: state.toggleNotification,
    clearReadNotifications: state.clearReadNotifications,
    resetGridColumPreference: state.resetGridColumPreference,
  }));

  const { data: companyList, isLoading: isCompanyListFetching } =
    useGetCompanyList({
      params: {
        ...(companySearch !== '' && { name__istartswith: companySearch }),
      },
      enabled: Boolean(details?.is_superadmin),
    });

  const companyListOptions = useMemo(() => {
    if (isEmpty(companyList?.results)) return [];
    const options = companyList?.results?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    return options;
  }, [companyList]);

  const { control, setValue } = useForm({});

  useEffect(() => {
    const initialOption = companyListOptions?.filter(
      (item) => item.value === Number(company_id),
    );
    if (
      isInitiallyFetched &&
      !isEmpty(initialOption) &&
      !isEmpty(companyListOptions)
    ) {
      setValue('companyList', initialOption);
      toggleIsInitiallyFetched();
    }
  }, [companyListOptions]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleResetPassword = () => {
    setRestPassword(true);
  };

  const signOut = async () => {
    messageApi.open({
      type: 'success',
      content: 'Logged Out',
    });
    toggleNotification(false);
    clearReadNotifications();
    await logout();
    localStorage.removeItem('user');
    localStorage.removeItem('user_id');
    localStorage.removeItem('userPreference');
    localStorage.removeItem('company_id');
    localStorage.removeItem('yvi-persisted');
    resetGridColumPreference(); //removed for temp data grid development
    await navigate(routes.LOGIN);
  };

  const items = [
    {
      key: '0',
      label: 'Reset Password',
      onClick: handleResetPassword,
    },
    {
      key: '1',
      label: 'Logout',
      onClick: signOut,
    },
  ];

  const unreadCount = notifications.filter(
    (note) => note.read !== true,
  ).length;

  const buildHeaderLayout = () => {
    const username = details?.first_name;
    const userAvatarInitials = username?.charAt(0)?.toUpperCase();

    return (
      <Card>
        <header className="my-4 pl-spacing3 pr-spacing3 max-h-[4.5rem] flex justify-between bg-primaryBgMain">
          <div className="font-semibold">
            <div className="flex h-16">
              <img
                src={details?.company_logo}
                alt="company logo"
                className="pr-1"
              />
              <span className="uppercase" style={{ fontSize: 'xx-large' }}>
                {details ? details?.short_name : null}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-end">
            {!isMobileDevice && (
              <>
                {isDashbordIconVisible && (
                  <div>
                    <Popover
                      content={<div>Custom your dashboard</div>}
                      placement="bottom"
                    >
                      <button
                        className="mr-4"
                        onClick={() => setDashboardOpen(true)}
                      >
                        <img src={dashBordIcon} alt="dashBordIcon" />
                      </button>
                    </Popover>
                    {isDashboardOpen && (
                      <div className="" ref={drawerRef}>
                        <DashBoardModal
                          open={isDashboardOpen}
                          onClose={() => setDashboardOpen(false)}
                          drawerRef={drawerRef}
                        />
                      </div>
                    )}
                  </div>
                  // <div>
                  //   <button
                  //     className="mr-4"
                  //     onClick={() => setDashboardOpen(true)}
                  //   >
                  //     <img src={dashBordIcon} alt="dashBordIcon" />
                  //   </button>

                  // </div>
                )}

                <Button
                  type={'text'}
                  onClick={() => {
                    navigate(routes.VIEW_COMPANY_INFORMATION);
                  }}
                  className="mb-3 mx-4 w-48 flex items-center justify-between text-cyan-700 font-normal text-base"
                >
                  <img
                    src={company_info_active}
                    className="min-h-[0.75rem]"
                  />
                  Company Info
                </Button>
                {/* company list */}
                {details?.is_superadmin && isCompanyListVisible && (
                  <div className="mr-6 w-48">
                    <Select
                      showArrow
                      allowClear
                      showSearch
                      control={control}
                      name={'companyList'}
                      label={null}
                      className="w-full"
                      placeholder="Select Company"
                      options={companyListOptions}
                      fetching={isCompanyListFetching}
                      onChange={(item, onChange) => {
                        if (item) {
                          localStorage.setItem('company_id', item.value);
                          setCompanyID(item.value);
                        }
                        onChange(item);
                      }}
                      onSearch={(value) => setCompanySearch(value)}
                      onClear={() => {
                        setCompanySearch('');
                        localStorage.removeItem('company_id');
                        setCompanyID(null);
                      }}
                    />
                  </div>
                )}
                {/* Notification */}
                <div className=" mr-9">
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    arrow={{
                      pointAtCenter: true,
                    }}
                    overlayInnerStyle={{
                      padding: 0,
                      boxShadow: '0px 3px 28px 0px rgba(0, 0, 0, 0.06)',
                    }}
                    content={<NotificationScreen />}
                    onOpenChange={() => toggleOpen()}
                    open={open}
                  >
                    <Badge
                      count={unreadCount}
                      size="small"
                      color="#FFB800"
                    >
                      {/* <IoMdNotificationsOutline
                        size={'1.5rem'}
                        className="text-primaryMain"
                      /> */}
                      <img
                        src={open ? notification_active : notification}
                        className="h-[1.5rem] w-[1.5rem] text-primaryMain cursor-pointer"
                      />
                    </Badge>
                  </Popover>
                </div>
                <div className="mx-2">
                  <Avatar
                    shape="circle"
                    className="cursor-pointer bg-[#B2CFD9]"
                    size="small"
                    alt={username}
                  >
                    {userAvatarInitials}
                  </Avatar>
                </div>
                <Dropdown
                  trigger={'click'}
                  menu={{
                    items,
                  }}
                >
                  <div className="flex">
                    {contextHolder}
                    <span className="user_name">{username}</span>
                    <span>
                      <IoIosArrowDown
                        color="grey"
                        className="cursor-pointer"
                      />
                    </span>
                  </div>
                </Dropdown>
              </>
            )}
          </div>
        </header>
      </Card>
    );
  };

  return (
    <div className="flex">
      <SideNavigation
        isMobile={mobileOpen}
        handleDrawer={handleDrawerToggle}
      />
      <main className="flex flex-col grow w-[calc(100%-16rem)]">
        {buildHeaderLayout()}
        <div className="max-w-full h-full max-h-screen-lg pl-spacing3 pr-spacing3 pb-8 scrollbar-hide overflow-y-auto bg-primaryBgMain">
          {/* {props.children} */}
          <Outlet />
        </div>
      </main>
      <Modal
        width={600}
        open={isResetPassword}
        title={'Change Password'}
        onCancel={() => {
          setRestPassword(false);
        }}
        footer={null}
        destroyOnClose
      >
        <ResetPassword close={setRestPassword} />
      </Modal>
    </div>
  );
}
