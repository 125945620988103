import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { includes, isEmpty } from 'lodash';
import { BiArrowBack } from 'react-icons/bi';

import { useJobFields } from 'ServiceHooks/jobHooks';
import style from '../../../Styles/addJob.module.scss';
import Accordion from 'Components/CommonComponents/Accordion/Accordion';
import HiringTeamNew from 'Components/Jobs/HiringTeamNew';
import HiringStageNew from 'Components/Jobs/HiringStageNew';
import Progress from 'Components/CommonComponents/Progress';
import Button from 'Components/CommonComponents/Button/Button';
import viewOption_Icon from '../../../Assets/images/vewMoreOption.svg';
import AddJobInfoNew from 'Components/Jobs/AddJobInfoNew';
import Publish from 'Components/Jobs/Publish';
import routes from 'Routes';
import { JOB_DETAILS_TAB_KEYS, JOB_TAB_TYPES } from 'Utilities/constants';
import { options, view } from 'Components/CommonComponents/Icons';
import { Tooltip } from 'antd';
import EditJobHiringStore from 'Store/EditJobHiringStore';
import jobService from 'Services/JobService';
import { logDOM } from '@testing-library/react';

const AddJobNew = () => {
  const [isEditJobprocessing, setEditJobProcessing] = useState(true);
  const [isViewMoreField, setViewMoreFields] = useState(false);
  const [isActiveAccordian, setActiveAccordion] = useState([1]);
  const { setEditJobHiringResponse } = EditJobHiringStore();
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location?.pathname.includes('editJob');
  const [activeJobTab, setActiveJobTab] = useState('JOB_CREATION_INITIAL');
  const job_tabs = [
    {
      label: 'Job Information',
      key: 'JOB_CREATION_INITIAL',
    },
    {
      label: 'Job Hiring team',
      key: 'JOB_HIRING_TEAM',
    },
    {
      label: 'Job Hiring stages',
      key: 'JOB_HIRING_STAGES',
    },
    {
      label: 'Job Publish',
      key: 'JOB_PUBLISH',
    },
  ];

  const {
    data: jobFields,
    isError: isJobFieldError,
    isSuccess: isJobSucess,
    isInitialLoading: isJobFieldLoading,
  } = useJobFields({
    enabled: true,
  });
  console.log('jobFields', jobFields);
  const editJobHiringStage = async () => {
    await jobService.getHiringTeam(param?.jobId).then((oResponse) => {
      console.log('setEditJobHiringTeamData', oResponse.data.data);
      setEditJobHiringResponse(oResponse.data.data);
    });
  };
  useEffect(() => {
    if (isEdit) {
      editJobHiringStage();
    }
  }, [isEdit, param?.jobId]);
  const AddMoreField = () => {
    setViewMoreFields(true);
  };

  const activeSelectedAccordian = (index) => {
    setActiveAccordion([index]);
  };

  const buildAccordion = (allFields, index) => {
    if (
      allFields.codename === 'JOB_CREATION_INITIAL' &&
      activeJobTab === 'JOB_CREATION_INITIAL'
    ) {
      return (
        <div className="h-[100vh] overflow-y-auto flex w-[94%] customScrollBar">
          <AddJobInfoNew
            process={setEditJobProcessing}
            fields={allFields.field_data}
            index={index}
            isViewMoreField={isViewMoreField}
            setAccordionActive={activeSelectedAccordian}
            currentActive={index + 1}
            setActiveJobTab={setActiveJobTab}
          />
        </div>
      );
    } else if (
      allFields.codename === 'JOB_HIRING_TEAM' &&
      activeJobTab === 'JOB_HIRING_TEAM'
    ) {
      return (
        <div className="bg-white w-[94%] rounded-lg border border-[0.5px] border-[#D7D7D7]">
          <HiringTeamNew
            fields={allFields.field_data}
            index={index}
            isViewMoreField={isViewMoreField}
            process={setEditJobProcessing}
            setAccordionActive={activeSelectedAccordian}
            currentActive={index + 1}
            setActiveJobTab={setActiveJobTab}
          />
        </div>
      );
    } else if (
      allFields.codename === 'JOB_HIRING_STAGES' &&
      activeJobTab === 'JOB_HIRING_STAGES'
    ) {
      return (
        <div className="h-[80vh] bg-white w-[94%]">
          <HiringStageNew
            fields={allFields.field_data}
            index={index}
            isViewMoreField={isViewMoreField}
            process={setEditJobProcessing}
            setAccordionActive={activeSelectedAccordian}
            currentActive={index + 1}
            setActiveJobTab={setActiveJobTab}
          />
        </div>
      );
    } else if (
      allFields.codename === 'JOB_PUBLISH' &&
      activeJobTab === 'JOB_PUBLISH'
    ) {
      return (
        <div className="h-[80vh] overflow-y-auto w-[85%] overflow-x-hidden">
          <Publish
            fields={allFields.field_data}
            isViewMoreField={isViewMoreField}
            process={setEditJobProcessing}
            setActiveJobTab={setActiveJobTab}
          />
        </div>
      );
    }
    // if (allFields.codename === 'JOB_CREATION_INITIAL') {
    //   return (
    //     <Accordion
    //       bordered
    //       className="rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
    //       showArrow={true}
    //       useDefaultTheme
    //       ghost
    //       items={[
    //         {
    //           key: index + 1,
    //           label: (
    //             <p className="jobs_header">{`${index + 1}. ${
    //               allFields.label
    //             }`}</p>
    //           ),
    //           children: (
    //             <AddJobInfoNew
    //               process={setEditJobProcessing}
    //               fields={allFields.field_data}
    //               index={index}
    //               isViewMoreField={isViewMoreField}
    //               setAccordionActive={activeSelectedAccordian}
    //               currentActive={index + 1}
    //             />
    //           ),
    //         },
    //       ]}
    //       onChange={() => {
    //         setActiveAccordion([index + 1]);
    //       }}
    //       activeKey={isActiveAccordian}
    //     />
    //   );
    // } else if (allFields.codename === 'JOB_HIRING_TEAM') {
    //   return (
    //     <Accordion
    //       bordered
    //       className="rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
    //       showArrow={true}
    //       useDefaultTheme
    //       ghost
    //       items={[
    //         {
    //           key: index + 1,
    //           label: (
    //             <p className="jobs_header">{`${index + 1}. ${
    //               allFields.label
    //             }`}</p>
    //           ),
    //           children: (
    //             <HiringTeamNew
    //               fields={allFields.field_data}
    //               index={index}
    //               isViewMoreField={isViewMoreField}
    //               process={setEditJobProcessing}
    //               setAccordionActive={activeSelectedAccordian}
    //               currentActive={index + 1}
    //             />
    //           ),
    //         },
    //       ]}
    //       onChange={() => {
    //         setActiveAccordion([index + 1]);
    //       }}
    //       activeKey={isActiveAccordian}
    //     />
    //   );
    // } else if (allFields.codename === 'JOB_HIRING_STAGES') {
    //   return (
    //     <Accordion
    //       bordered
    //       className="border border-gray-300 rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
    //       showArrow={true}
    //       useDefaultTheme
    //       ghost
    //       items={[
    //         {
    //           key: index + 1,
    //           label: (
    //             <p className="jobs_header">{`${index + 1}. ${
    //               allFields.label
    //             }`}</p>
    //           ),
    //           children: (
    //             <HiringStageNew
    //               fields={allFields.field_data}
    //               index={index}
    //               isViewMoreField={isViewMoreField}
    //               process={setEditJobProcessing}
    //               setAccordionActive={activeSelectedAccordian}
    //               currentActive={index + 1}
    //             />
    //           ),
    //         },
    //       ]}
    //       onChange={() => {
    //         setActiveAccordion([index + 1]);
    //       }}
    //       activeKey={isActiveAccordian}
    //     />
    //   );
    // } else if (allFields.codename === 'JOB_PUBLISH') {
    //   return (
    //     <Accordion
    //       bordered
    //       className="border border-gray-300 rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
    //       showArrow={true}
    //       useDefaultTheme
    //       ghost
    //       items={[
    //         {
    //           key: index + 1,
    //           label: (
    //             <p className="jobs_header">{`${index + 1}. ${
    //               allFields.label
    //             }`}</p>
    //           ),
    //           children: (
    //             <Publish
    //               fields={allFields.field_data}
    //               isViewMoreField={isViewMoreField}
    //               process={setEditJobProcessing}
    //             />
    //           ),
    //         },
    //       ]}
    //       onChange={() => {
    //         setActiveAccordion([index + 1]);
    //       }}
    //       activeKey={isActiveAccordian}
    //     />
    //   );
    // }
  };

  return (
    <div className={`${style.addJobMainDiv} scrollbar-hide`}>
      <div className="flex items-center justify-between pt-4 pb-2 px-4">
        <Button
          className="flex items-center cursor-pointer"
          type="ghost"
          onClick={() => {
            let route;
            if (!Boolean(param.jobId)) {
              route = `${routes.JOBS}/${JOB_TAB_TYPES.ACTIVE}`;
            } else {
              route = `${routes.JOB_DETAILS}/${param.jobId}/${JOB_DETAILS_TAB_KEYS.JOB_DETAILS}`;
            }
            navigate(route);
          }}
        >
          <BiArrowBack />
          <span className="ml-4 font-bold">
            {!param.jobId ? 'Post a New Job' : 'Edit Job'}
          </span>
        </Button>
        {/* {activeJobTab === 'JOB_CREATION_INITIAL' && (
          <Tooltip title="View Optional Info" placement="bottom">
            <Button onClick={() => AddMoreField()} className="mr-[5rem]">
              <img alt="view" className="min-w-[1rem]" src={options} />
            </Button>
          </Tooltip>
        )} */}
        <Tooltip title="View Optional Info" placement="bottom">
            <Button onClick={() => AddMoreField()} className="mr-[5rem]">
              <img alt="view" className="min-w-[1rem]" src={options} />
            </Button>
          </Tooltip>
        {/* <button className="addField " onClick={() => AddMoreField()}>
          <img src={viewOption_Icon} alt=""></img>
          <span style={{ paddingLeft: '3px' }}>View Optional Info</span>
        </button> */}
      </div>
      <div className="w-full flex justify-between">
        <div className="w-2/6">
          <div className="p-5 mt-8 flex flex-col gap-5 ml-[5rem]">
            {job_tabs?.map((job, index) => (
              <span
                onClick={() => setActiveJobTab(job.key)}
                className={`${activeJobTab === job?.key ? 'text-[#1C4980] font-semibold text-xl' : 'text-[#5B6B79] font-medium'} font-sans cursor-pointer hover:text-[#1C4980] hover:font-semibold text-lg transition`}
              >
                {index + 1}. {job?.label}
              </span>
            ))}
          </div>
        </div>
        <div className="stepper_accordion_div scrollbar-hide">
          {!isEmpty(jobFields) ? (
            jobFields.map((oJobFields, index) => {
              if (oJobFields.show) {
                return buildAccordion(oJobFields, index);
              }
            })
          ) : (
            <div className="flex justify-center items-center py-5">
              <p>Job Fields Loading!...</p>
            </div>
          )}
        </div>
      </div>

      {isEditJobprocessing && <Progress />}
    </div>
  );
};
export default AddJobNew;
