import axios from "axios";
import Config from "../Services/Config";
import authHeader from "./authHeader";

// Question allocation
const questionAllocation = (selectedId, selectedLevel, jobId) => {
  return axios.post(
    Config.API_URL + `api/v1/jobs/job-question-allocation/`,
    {
      job: jobId,
      questions: selectedId,
      interview_level: selectedLevel,
    },
    {
      headers: authHeader(),
    }
  );
};
// 
const getInterviewInfo = (jobId) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/interview-levels/?job=${jobId}`,
    {
      headers: authHeader(),
    }
  );
};
// List of jobs based on interviewer
const interviewerJobList = () => {
  return axios.get(
    Config.API_URL + `api/v1/interview/list-jobs-based-on-interviewer/`,
    {
      headers: authHeader(),
    }
  );
};

// -------------------------Intrerview pipeline details API module---------------------->
// Status count
const getInterviewCountData = () => {
  return axios.get(Config.API_URL + "api/v1/interview/status-count/", {
    headers: authHeader(),
  });
};
// Interview list status=TODAY
const getTodayInterviewData = (limit,offset) => {
  return axios.get(Config.API_URL + `api/v1/interview/list/?status=TODAY&limit=${limit}&offset=${offset}`, {
    headers: authHeader(),
  });
};
// Interview list status=UPCOMING
const getUpcommingInterviewData = (limit,offset) => {
  return axios.get(Config.API_URL + `api/v1/interview/list/?status=UPCOMING&?limit=${limit}&offset=${offset}`, {
    headers: authHeader(),
  });
};
// Interview list status=REVIEW_PENDING
const getReviewPendingInterviewData = (limit,offset) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/list/?status=REVIEW_PENDING&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    }
  );
};
// Interview list status=REVIEW_COMPLETED
const getCompletedInterviewData = (limit,offset) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/list/?status=REVIEW_COMPLETED&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    }
  );
};
// Interview slot booking
const getMyavailabilityData = () => {
  return axios.get(Config.API_URL + "api/v1/interview/slot-booking/", {
    headers: authHeader(),
  });
};

const getAvailableSlotsForCandidate = (token) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/interviewer-available-slots/${token}`
    );
  }

const setCandidateChosenSlot = (variables) => {
  const { slots, token } = variables;

  return axios.post(
    Config.API_URL + `api/v1/interview/interviewer-available-slots/${token}/`,
    slots,
  );
}


const InterviewPipeLine = {
  questionAllocation,
  getInterviewInfo,
  interviewerJobList,
  getInterviewCountData,
  getTodayInterviewData,
  getUpcommingInterviewData,
  getReviewPendingInterviewData,
  getCompletedInterviewData,
  getMyavailabilityData,
  getAvailableSlotsForCandidate,
  setCandidateChosenSlot,
};
export default InterviewPipeLine;
