import DOMPurify from 'dompurify';

export default function ViewParsedText({ content = '', className = '' }) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content),
      }}
    />
  );
}
