import { useState, useEffect } from 'react';
import settingService from '../../Services/settingsService';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddEmployerForm = (props) => {
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [isCountryLayout, setCountryLayout] = useState(false);
  const [phoneCode, setPhonecode] = useState();
  const [inputValue, setInputValue] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('storage', user);

  useEffect(() => {
    // getSettingsDepartmentsData();
    // getSettingsProfileAndRolesData();
  }, []);

  const handelInputChange = (e) => {
    // const { value } = e.target;
    // const regex = /^[0-9\b]+$/; // Regex pattern to accept only numbers

    // if (regex.test(value)) {
    //   setInputValue(value);
    // }
    if (e.target.value !== '') {
      var test = Number(e.target.value);
      var test1 = Number.isInteger(test);
      if (test1) {
        setCountryLayout(true);
        setPhonecode('+91');
      } else {
        setCountryLayout(false);
        setPhonecode('');
      }
    } else {
      setCountryLayout(false);
      setPhonecode('');
    }
  };
  const handelChangePhone = (e) => {
    var value = `+${e}`;
    setPhonecode(value);
  };
  const onSubmit = async (value) => {
    console.log('onInviteClick', value);

    // let user_name = props.data.email.split("@");
    let payload = {
      // email: props.data.email,
      // company: props.data.company,
      // department: props.data.department,
      user: props.data.user,
      link_expiry: props.data.link_expiry,
      application_username: value.application_username,
      first_name: value.first_name,
      last_name: value.last_name,
      mobile_number: phoneCode + value.mobile_number,
      password: value.password,
      confirm_password: value.confirm_password,
      view_all: true,
    };
    // let user_name = value.email.split("@");
    // let payload = {
    //   // mobile_number: value.mobile,
    //   email: value.email,
    //   // first_name: value.name,
    //   // application_username: user_name[0],
    //   role: value.role_name,
    //   department: value.department,
    //   // view_other: viewOthers,
    // };

    await settingService
      .addNewMembers(payload)
      .then(
        (oResponse) => {
          messageApi.open({
            type: 'success',
            content: 'Registered Successfully',
          });
          localStorage.removeItem('user');
          navigate('/');
        },
        (error) => {
          console.log('erroe', error?.response?.data?.error_info);
          const errorsObj = error?.response?.data?.error_info;
          console.log('objerror', Object.keys(errorsObj));
          Object.keys(errorsObj).forEach((field) => {
            const messages = errorsObj[field];
            setError(field, {
              type: 'server',
              message: messages.join('. '),
            });
          });
        },
      )
      .catch((err) => {
        console.log('testerror', err);
      });
  };

  return (
    <div className="flex flex-wrap w-full h-auto">
      <div className="flex flex-wrap items-center">
        <h3 className="text-xl font-bold text-start">Sign Up</h3>
      </div>
      <div className=" w-full h-fit p-4 overflow-auto flex">
        <form className="pt-5 w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full px-3 mb-2 flex flex-wrap">
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                First Name *
              </label>
              <input
                type="text"
                className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                {...register('first_name', {
                  required: 'This field is required',
                })}
              />
              {errors && errors.first_name && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.first_name.message}
                </p>
              )}
            </div>
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                Last Name *
              </label>
              <input
                type="text"
                className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                {...register('last_name', {
                  required: 'This field is required',
                })}
              />
              {errors && errors.last_name && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.last_name.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full px-3 mb-2 flex flex-wrap">
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                User Name *
              </label>
              <input
                type="text"
                autocomplete="off"
                className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                {...register('application_username', {
                  required: 'This field is required',
                })}
              />
              {errors && errors.application_username && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.application_username.message}
                </p>
              )}
            </div>
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                Mobile Number *
              </label>
              <div style={{ display: 'flex' }}>
                {isCountryLayout && (
                  <span
                    style={{
                      width: '15%',
                      paddingLeft: '1px',
                      paddingTop: '5px',
                      borderRadius: '1rem',
                    }}
                  >
                    <PhoneInput
                      country="In"
                      value="91"
                      // className="react-tel-input .selected-flag"
                      inputStyle={{
                        height: '38px',
                        width: '2%',
                        paddingLeft: '0px',
                      }}
                      onChange={(e) => handelChangePhone(e)}
                    />
                  </span>
                )}
                <input
                  className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                  {...register('mobile_number', {
                    required: 'This field is required',
                  })}
                  defaultValue={phoneCode}
                  onChange={handelInputChange}
                ></input>
              </div>
              {errors && errors.mobile_number && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.mobile_number.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full px-3 mb-2 flex flex-wrap">
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                Password *
              </label>
              <input
                type="password"
                autocomplete="new-password"
                className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                {...register('password', {
                  required: 'This field is required',
                })}
              />
              {errors && errors.password && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.password.message}
                </p>
              )}
            </div>
            <div className="md:w-1/2 sm:w-full p-2">
              <label className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2 text-start">
                Confirm Password *
              </label>
              <input
                type="password"
                className="rounded-2xl primary-box-shadow h-12 w-full p-4 mb-2"
                {...register('confirm_password', {
                  required: 'This field is required',
                })}
              />
              {errors && errors.confirm_password && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.confirm_password.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center items-center mt-10">
            {messageContextHolder}
            {/* <button className="secondary-btn px-4 mx-2" onClick={onCancel}>
              Cancel
            </button> */}
            <button type="submit" className="primary-btn px-4 mx-2">
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmployerForm;
