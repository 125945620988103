import { Spin } from "antd";
import Card from "../Card/Card";

const PageLoader = ({
  loadingText = 'Loading...'
}) => {
  return (
   <Card>
     <div className="pageScreenLoader h-screen flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 bg-white z-[9999]">
      <div className="flex justify-center flex-col">
        <Spin />
        {
          loadingText
          &&
          <p className="mt-4 dark:text-white">{loadingText}</p>
        }
      </div>
    </div>
   </Card>
  ) 
};

export default PageLoader;