import Button from 'Components/CommonComponents/Button/Button';
import CountryCodeInput from 'Components/CommonComponents/Input/CountryCodeInput';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import TextEdit from 'Components/CommonComponents/TextEdit';
import { useGetKnownLanguages } from 'ServiceHooks/candidatesHooks';
import candidateService from 'Services/candidateService';
import { message } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const RequiredFieldProfile = (props) => {
  const [messageApi, messageContextHolder] = message.useMessage();

  const {
    clearErrors,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const [languagesSearch, setLanguagesSearch] = useState('');
  const [qualificationList, setQualificationList] = useState();
  const [skillsList, setSkillsList] = useState();

  const { data: languages, isFetching: isLanguagesFetching } =
    useGetKnownLanguages({
      params: {
        search: languagesSearch,
      },
      enabled: true,
    });

  const onQualificationSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getQualificationsList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            key: oOption.option_id,
            value: oOption.option_value,
          });
        });
        setQualificationList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSkillSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getSkillsList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            key: oOption.option_id,
            value: oOption.option_value,
          });
        });
        setSkillsList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onTextAreaChange = (formName, text) => {
    if (!isEmpty(errors?.[formName]) && text.length > 0) {
      clearErrors(formName);
    }
    setValue(formName, text);
  };

  const fieldVisible = (name) => {
    const { fields } = props;
    let value = false;
    if (fields?.includes(name)) {
      value = true;
    }
    return value;
  };

  console.log(errors,"errors");

  const onSubmit = (data) => {
    console.log(data,"datadata");
    let candidate_fields = ["first_name","middle_name","last_name","email","mobile_number"]
    let result = {}
    let candidate_credentials = {}
    let keysData = Object.keys(data)
    keysData?.map((k)=> {
      if(fieldVisible(k)){
        console.log("ENTERRR",k);
        if(candidate_fields.includes(k)){
          candidate_credentials[k] = data[k]
        }else {
          if(k=== "skills" || k === "qualifications"){
            const idsArray = data[k].map(obj => obj.key);
            result[k] = idsArray
          }else {
            result[k] = data[k]
          }
        }
      }
    })
    if(candidate_credentials["mobile_number"]){
      let country = "+91"
      if(data?.country_code){
        country = "+" + data?.country_code
      }
      candidate_credentials["mobile_number"] = country + data["mobile_number"]
    }
    
      result.candidate_credentials = candidate_credentials
      console.log(result,"resultresult");
      updateData(result);
  };

  const updateData = async (json) => {
    try {
      const response = await candidateService.patchCandidate(
        json,
        props.nCandidateId,
      );
      await messageApi.open({
        type: 'success',
        content: 'Successfully Updated',
      });
      props?.setEnable(true)
      props?.setRequiredFields([])
    } catch (error) {
      const errorsObj = error.response.data.error_info;
      Object.keys(errorsObj).forEach((field) => {
        if (field !== 'candidate_credentials') {
          const messages = errorsObj?.[field];
          setError(field, {
            type: 'server',
            message: messages?.join('. '),
          });
          messageApi.open({
            type: 'error',
            content: message,
          });
        } else {
          Object.values(errorsObj[field]).forEach((message) => {
            messageApi.open({
              type: 'error',
              content: message,
            });
          });
        }
      });
    }
  };

  console.log(watch(),">>>>>>>>>>>");

  const languagesOptions = useMemo(() => {
    if (isEmpty(languages?.results)) return [];
    const options = languages?.results.map((item) => {
      return {
        label: item.language,
        value: item.id,
      };
    });
    return options;
  }, [languages]);



  return (
    <div className="edit_candidate_maindiv">
      {messageContextHolder}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="editCandidate_form w-full">
          <div className={`form_content ${fieldVisible('first_name')? "block": "hidden"}`}>
            <Input
              name="first_name"
              label={
                <p>
                  First Name<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="First Name"
              rules={{
                required: fieldVisible('first_name') ? 'This field is required' : false,
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Please enter letters only',
                },
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('first_name')? "block": "hidden"}`}>
            <Input
              name="last_name"
              label={
                <p>
                  Last Name<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="Last Name"
              rules={{
                required: fieldVisible('last_name') ? 'This field is required' : false,
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Please enter letters only',
                },
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('email')? "block": "hidden"}`}>
            <Input
              name="email"
              label={
                <p>
                  Email ID<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="Email ID"
              rules={{
                required: fieldVisible('email') ? 'This field is required' : false,
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('mobile_number')? "block": "hidden"}`}>
            <CountryCodeInput
              name="mobile_number"
              country_code_name="country_code"
              country_code_value={watch('country_code')}
              value={watch('mobile_number')}
              setValue={setValue}
              label="Contact Number"
              placeholder="Contact Number"
              required={true}
              control={control}
              rules={{
                required: fieldVisible('mobile_number') ? 'This field is required' : false,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numbers',
                },
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('date')? "block": "hidden"}`}>
            <Input
              type="date"
              name="dob"
              label={<p>Date of Birth</p>}
              control={control}
              placeholder="Date of Birth"
              max={dayjs().format('DD-MM-YYYY')}
              rules={{
                required: fieldVisible('dob') ? 'This field is required' : false,
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('designation')? "block": "hidden"}`}>
            <Input
              name="designation"
              label={
                <p>
                  Designation<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="Designation"
              rules={{
                required: fieldVisible('designation') ? 'This field is required' : false,
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('total_exp')? "block": "hidden"}`}>
            <Input
              name="total_exp"
              label={
                <p>
                  Total Experience<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="total_exp"
              rules={{
                required: fieldVisible('total_exp') ? 'This field is required' : false,
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('languages')? "block": "hidden"}`}>
            <Select
              allowClear
              showSearch
              showArrow={true}
              control={control}
              name="languages"
              mode={'multiple'}
              placeholder="Spoken Languages"
              label={<p>Known Languages</p>}
              options={languagesOptions}
              fetching={isLanguagesFetching}
              onSearch={(text) => setLanguagesSearch(text)}
              onClear={() => {
                setValue('languages', undefined);
              }}
            />
          </div>

          <div className={`form_content ${fieldVisible('qualifications')? "block": "hidden"}`}>
            <Select
              label={<p>Education<span className="text-errorMain">*</span></p>}
              placeholder="Education"
              options={qualificationList}
              name="qualifications"
              control={control}
              showArrow={true}
              mode="multiple"
              onSearch={(e) => {
                onQualificationSearch(e);
              }}
              rules={{
                required: fieldVisible('qualifications') ? 'This field is required' : false,
              }}
            />
          </div>
          <div className={`form_content ${fieldVisible('skills')? "block": "hidden"}`}>
            <Select
              label={<p>Skills<span className="text-errorMain">*</span></p>}
              placeholder="Skills"
              options={skillsList}
              name={'skills'}
              control={control}
              showArrow={true}
              mode="multiple"
              onSearch={(e) => {
                onSkillSearch(e);
              }}
              rules={{
                required: fieldVisible('skills') ? 'This field is required' : false,
              }}
            />
          </div>

          <div className={`form_content ${fieldVisible('about_me')? "block": "hidden"} w-full`}>
            <div>About Me</div>
            <TextEdit
              initialValue=""
              onChange={(value) => onTextAreaChange('about_me', value)}
            />
            {errors?.about_me && (
              <div className="text-errorMain">
                {errors?.about_me?.message}
              </div>
            )}
          </div>
        </div>
        <div className="float-right mt-4">
          <Button type="primary" className="ml-4" htmlType="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RequiredFieldProfile;
