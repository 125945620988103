import { create } from 'zustand';

// Possibly legacy
const EditJobPublish = create((set) => ({
  EditJobPublishResponse: '',
  setEditJobPublishResponse: (response) => {
    set(() => ({
      EditJobPublishResponse: response,
    }));
  },
}));

export default EditJobPublish;
