import { useState } from 'react';
import QuestionDisplay from './QuestionDisplay';
import { isEmpty } from 'lodash';

const InterviewContains = ({ interviewInfoData }) => {
  const [isQuestionDisplayScreen, setQuestionDisplayScreen] =
    useState(false);

  console.log('interviewInfoData', interviewInfoData);

  return isQuestionDisplayScreen ? (
    <QuestionDisplay jobTitle={interviewInfoData?.title} />
  ) : (
    <div className="guidlines">
      <div className="guidlinesHeaderDiv">
        <h1>Interview Contains</h1>
      </div>

      {!isEmpty(interviewInfoData) && (
        <div className="contains_maindiv">
          {interviewInfoData?.questions_category?.single_choice !== 0 && (
            <div className="QuestionCount_maindiv applyBorderBottom">
              <div className="flex-1">
                <div className="QuestionCountHeader">Multiple Choice</div>
                <div className="QuestionCountSubHeader">
                  Single choice, Single Response
                </div>
              </div>
              <div className="questionCountNumber flex items-center">
                {interviewInfoData?.questions_category?.single_choice}
              </div>
            </div>
          )}
          {interviewInfoData?.questions_category?.multi_choice !== 0 && (
            <div className="QuestionCount_maindiv applyBorderBottom">
              <div className="flex-1">
                <div className="QuestionCountHeader">Multi Response</div>
                <div className="QuestionCountSubHeader">
                  Multiple choice, objective response or MCQ
                </div>
              </div>
              <div className="questionCountNumber flex items-center">
                {interviewInfoData?.questions_category?.multi_choice}
              </div>
            </div>
          )}
          {interviewInfoData?.questions_category?.essay !== 0 && (
            <div className="QuestionCount_maindiv applyBorderBottom">
              <div className="flex-1">
                <div className="QuestionCountHeader">Essays</div>
                <div className="QuestionCountSubHeader">
                  Descriptive answer
                </div>
              </div>
              <div className="questionCountNumber flex items-center">
                {interviewInfoData?.questions_category?.essay}
              </div>
            </div>
          )}
          <div className="QuestionCount_maindiv">
            <div className="flex-1">
              <div className="QuestionCountHeader">Total Questions</div>
            </div>
            <div className="questionCountNumber flex items-center">
              {interviewInfoData?.total_questions}
            </div>
          </div>
        </div>
      )}

      <div className="guidlinesFooterDiv">
        <div className="flex justify-center mt-7 w-full">
          <button
            className="goToInterview"
            onClick={() => setQuestionDisplayScreen(true)}
          >
            Start Interview
          </button>
        </div>
      </div>
    </div>
  );
};
export default InterviewContains;
