import axios from "axios";
import authHeader from "./authHeader";
import Config from "./Config";


const getResumeTemplates = (config) => {
    return axios.get(
      Config.API_URL + `api/v1/candidates/list-resumes-templates/`,
      {
        headers: authHeader(),
      }
    );
  }

  const getResumeTemplatesData = ({candidate_id,template_id}) => {
    console.log(candidate_id,"candidate_id",template_id);
    return axios.get(
      Config.API_URL + `api/v1/candidates/generate-resume/?template_id=${template_id}&candidate_id=${candidate_id}`,
      {
        headers: authHeader(),
      }
    );
  }

  const getExportResumePdf = (data) => {
    return axios.post(
      Config.API_URL +
        'api/v1/jobs/convert-to-pdf/',
      data,
      {
        headers: authHeader(),
        responseType: 'blob'
      },
    );
  }


  const ResumeBuilderService = {
    getResumeTemplates,
    getResumeTemplatesData,
    getExportResumePdf
  }

  export default ResumeBuilderService;