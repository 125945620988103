import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { message, Spin } from 'antd';
import dayjs from 'dayjs';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import DatePicker from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import RangePicker from 'Components/CommonComponents/ReactHooKForm/RangePicker/RangePicker';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Button from 'Components/CommonComponents/Button/Button';
import 'Styles/commonStyles.scss';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import { useAddCandidateProject, useEditCandidateProject } from 'ServiceHooks/candidatesHooks';

export default function RequiredFieldProjectModal(props) {
  const { isEditForm, close, projects,editIndex,candidateId } = props;
  const params = useParams();


  const defaultValues = {
    company: isEditForm ? props?.details?.company : '',
    project: isEditForm ? props?.details?.project : '',
    designation: isEditForm ? props?.details?.designation : '',
    isCurrentProject: isEditForm
      ? props?.details?.is_current_project
      : false,
    workedFrom: isEditForm ? dayjs(props?.details?.worked_from) : '',
    duration: isEditForm
      ? [
          dayjs(props?.details?.worked_from),
          dayjs(props?.details?.worked_till),
        ]
      : [],
    description: isEditForm ? props?.details?.description : '',
  };

  const {
    handleSubmit,
    control,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'all',
  });



  const isCurrentProject = useWatch({ control, name: 'isCurrentProject' });
  const [messageApi, contextHolder] = message.useMessage();

  const { mutateAsync: addProject, isLoading: isAdding } =
    useAddCandidateProject({
      onSuccess: async () => {
        await messageApi.open({
          type: 'success',
          content: 'Adding Complete.',
        });
        props?.setEnable(true)
      },
      onError: async (error) => {
        await messageApi.open({
          type: 'error',
          content: DEFAULT_ERROR_MESSAGE,
        });
        console.error('Project Error: ', error);
      },
    });

    const { mutateAsync: editProject, isLoading: isEditing } =
    useEditCandidateProject({
      onSuccess: async () => {
        await messageApi.open({
          type: 'success',
          content: 'Editing Complete.',
        });
      },
      onError: async (error) => {
        await messageApi.open({
          type: 'error',
          content: DEFAULT_ERROR_MESSAGE,
        });
        console.error('Project Error: ', error);
      },
    });


  const onSubmit = async (fields) => {
    try {
      const { company,project, designation, isCurrentProject, description } =
        fields;

      const data = {
        candidate: candidateId,
        company,
        project,
        designation,
        is_current_project: isCurrentProject,
        description,
      };

      if (isCurrentProject) {
        data['worked_from'] = dayjs(fields.workedFrom).format(
          'YYYY-MM-DD',
        );
      } else {
        data['worked_from'] = dayjs(fields.duration[0]).format(
          'YYYY-MM-DD',
        );
        data['worked_till'] = dayjs(fields.duration[1]).format(
          'YYYY-MM-DD',
        );
      }
      
      if (isEditForm) {
        await editProject({ body: data, projectId: props?.details?.id });
      } else {
        await addProject(data);
      }
      
      close()
    } catch (error) {
      console.error('Submission Error', error);
    }
  };

  const onTextAreaChange = (text) => {
    if (!isEmpty(errors?.responsibilities) && text.length > 0) {
      clearErrors('responsibilities');
    }
    setValue('responsibilities', text);
  };


  return (
    <div className="w-full px-10 pt-8">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="w-full">
          <Input
            label="Company"
            name="company"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Invalid Company Name',
              },
            }}
            placeholder="Name"
          />
        </div>
        <div className="w-full">
          <Input
            label="Designation"
            name="designation"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Please enter letters only',
              },
            }}
          />
        </div>
        <div className="w-full">
          <Input
            label="Project"
            name="project"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Please enter letters only',
              },
            }}
          />
        </div>
        <div className="w-full">
          <Checkbox
            label="Current Project?"
            name="isCurrentProject"
            control={control}
            showUpperLabel={false}
          />
        </div>
        <div className="w-full">
          {isCurrentProject ? (
            <DatePicker
              label="Starting Date"
              name="workedFrom"
              control={control}
              noTextInput
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
            />
          ) : (
            <RangePicker
              label="Duration"
              name="duration"
              control={control}
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
            />
          )}
        </div>
        <div>
          <div>Responsibilities</div>
          <TextEdit
            initialValue={
              isEditForm ? props?.details?.responsibilities : ''
            }
            onChange={onTextAreaChange}
          />
          {errors?.responsibilities && (
            <div className="text-errorMain">
              {errors?.responsibilities?.message}
            </div>
          )}
        </div>

        <div className="flex ml-auto mt-4 gap-4">
          {contextHolder}
          <Button
            isCancel
            htmlType="submit"
            disabled={false}
            children="Cancel"
            onClick={close}
          />
          <Button type="primary" htmlType="submit" disabled={false}>
            Add
          </Button>
        </div>
      </form>
    </div>
  );
}
