import React from 'react';
import CommunicationComp from '../../../Components/Communication/CommunicationComp';
import { PERMISSION_TYPES } from 'Utilities/constants';
import WithAuthentication from 'Components/HOC/WithAuthentication';

const Communication = () => {
  return (
    <>
      <CommunicationComp />
    </>
  );
};


export default WithAuthentication(PERMISSION_TYPES.VIEW_COMMUNICATION_MENU)(Communication);
