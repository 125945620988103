import Accordion from '../CommonComponents/Accordion/Accordion';
import { Collapse, Popover, Slider, Tooltip, message } from 'antd';
import Card from '../CommonComponents/Card/Card';
import iconAccordion from '../../Assets/icons/accordionIcon.svg';
import Switch from 'Components/CommonComponents/ReactHooKForm/Switch/Switch';
import { useForm } from 'react-hook-form';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Button from 'Components/CommonComponents/Button/Button';
import {
  useCreateInterviewSettings,
  useCreateJobSettings,
  useInterviewettings,
  useJobSettings,
} from 'ServiceHooks/registrationHooks';
import { toast } from 'react-hot-toast';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
const { Panel } = Collapse;
const JobSettings = (props) => {
  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      is_prefix_needed: false,
      year_required: false,
      month_required: false,
      company_code: false,
      auto_suggest_previous_job_data: false,
      is_recommend_candidates_score: false,
    },
  });

  const jobReferenceType = watch('is_enabled');
  console.log('is_enabled', jobReferenceType);
  const prefixJobReferenceType = watch('is_prefix_needed');
  const selectedYearType = watch('year_required');
  const selectedMonthType = watch('month_required');
  const comapnyCodeType = watch('company_code');
  const resumeScoreValue = watch('is_recommend_candidates_score');

  const optionData = [
    { label: 'yy', value: 'YY' },
    { label: 'Y', value: 'Y' },
  ];
  const optionMonth = [
    { label: 'DD', value: 'DD' },
    { label: 'd', value: 'Y' },
  ];
  const optionYear = [
    selectedYearType ? { label: 'Year', value: 'YY' } : null,
    { label: 'Reference Number', value: 'Reference Number' },
    selectedMonthType ? { label: 'month', value: 'month' } : null,
    comapnyCodeType
      ? { label: 'Company code', value: 'Company code' }
      : null,
  ].filter((item) => item !== null);

  const { data: jobSettingsData, refetch: isJobRefectch } = useJobSettings(
    {
      paramsData: {
        companyId: props.companyId,
      },
    },
  );

  useEffect(() => {
    if (isEmpty(jobSettingsData)) return;
    reset(jobSettingsData);
  }, [jobSettingsData]);

  const { mutateAsync: createJobSettings } = useCreateJobSettings({
    onSuccess: async (data, variables, context) => {
      toast.success('Successfully Registered..!!');
      await isJobRefectch();
    },
    onError: async (error) => {
      console.error('Creation Error:', error);
      const message = error?.response?.data?.error_info;
      // await Object.entries(message)?.forEach(([key, message]) => {
      toast.error(message || DEFAULT_ERROR_MESSAGE);
      //   messageApi.open({
      //     type: 'error',
      //     content: message ?? DEFAULT_ERROR_MESSAGE,
      //   });
      // });
    },
  });
  const handleSelectChange = (name, selectedOption, onChange) => {
    onChange(selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : null;
    setValue(name, selectedValue);
    const dropdowns = [
      'refno_format_value_1',
      'refno_format_value_2',
      'refno_format_value_3',
      'refno_format_value_4',
    ];
    dropdowns.forEach((dropdown) => {
      if (dropdown !== name && selectedValue === watch(dropdown)) {
        setValue(dropdown, null);
      }
    });
  };

  const onSubmit = async (data) => {
    console.log('datatest', data);

    const refrenceFormat =
      (data.refno_format_value_2
        ? `{${data.refno_format_value_2}} - `
        : '') +
      (data.refno_format_value_1
        ? `{${data.refno_format_value_1}} - `
        : '') +
      (data.refno_format_value_3
        ? `{${data.refno_format_value_3}} - `
        : '') +
      (data.refno_format_value_4 ? `{${data.refno_format_value_4}}` : '');
    console.log('ssssdd', refrenceFormat);
    createJobSettings({
      data,
      refrenceFormat,
      comapnyId: props.companyId,
    });
  };
  return (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            className="bg-white rounded-lg"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="mt-4">
                  {!isActive ? (
                    <img src={iconAccordion} alt="accordion" />
                  ) : (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  )}
                </div>
              );
            }}
          >
            {() => {
              return (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <p className="mt-8 px-6  text-medium font-medium">
                        Job Settings
                      </p>
                      <div className="[&>*]:m-0 mt-8 px-6 ">
                        <Switch
                          name="is_enabled"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                  }
                  key={jobReferenceType ? '1' : '0'}
                >
                  <div className="px-6">
                    <div className="w-full">
                      <Input
                        name="reference_number"
                        label="Job Reference Number*"
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                        // defaultValue={}
                      />
                    </div>
                    <div className="mt-8 mb-6  h-[1px] w-full bg-[#E1E1E1]"></div>
                    <div className="flex justify-between">
                      <p className="text-medium font-medium">
                        Prefix Needed
                      </p>
                      <div className="[&>*]:m-0 ">
                        <Switch
                          name="is_prefix_needed"
                          control={control}
                          offText="disabled"
                          onText="enabled"
                        />
                      </div>
                    </div>
                    {prefixJobReferenceType === true && (
                      <div>
                        <div className="w-full flex mt-4">
                          <div className="w-1/2">
                            <Checkbox
                              showUpperLabel={false}
                              name="year_required"
                              label="Year Required"
                              control={control}
                            />
                            {selectedYearType === true && (
                              <div className="pr-12">
                                <Select
                                  name="year_required_format"
                                  label=""
                                  placeholder="Year"
                                  control={control}
                                  defaultValue={optionData[0]}
                                  options={optionData.map((oOption) => {
                                    return {
                                      value: oOption.value,
                                      label: oOption.label,
                                    };
                                  })}
                                />
                              </div>
                            )}
                          </div>
                          <div className="w-1/2 px-12">
                            <Checkbox
                              showUpperLabel={false}
                              name="month_required"
                              label="Month Required"
                              control={control}
                            />
                            {selectedMonthType === true && (
                              <Select
                                name="month_required_format"
                                label=""
                                placeholder="Month"
                                control={control}
                                defaultValue={optionMonth[0]}
                                options={optionMonth.map((oOption) => {
                                  return {
                                    value: oOption.value,
                                    label: oOption.label,
                                  };
                                })}
                                rules={{
                                  required: 'This field is required',
                                }}
                                // valueLabel="role_name"
                                // optionLabel="role_name"
                              />
                            )}
                          </div>
                        </div>
                        <div className="w-full flex mt-4">
                          <div className="w-1/2">
                            <Checkbox
                              showUpperLabel={false}
                              name="company_code"
                              label="Company Code"
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="w-full flex mt-7">
                      {selectedYearType === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_1"
                            label=""
                            placeholder="select"
                            control={control}
                            options={optionYear.map((oOption) => {
                              return {
                                value: oOption.value,
                                label: oOption.label,
                              };
                            })}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_1',
                                e,
                                onChange,
                              )
                            }
                          />
                        </div>
                      )}
                      {selectedYearType === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_2"
                            label=""
                            placeholder="select"
                            control={control}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_2',
                                e,
                                onChange,
                              )
                            }
                            options={optionYear.map((oOption) => {
                              return {
                                value: oOption.value,
                                label: oOption.label,
                              };
                            })}
                          />
                        </div>
                      )}
                      {selectedMonthType === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_3"
                            label=""
                            placeholder="select"
                            control={control}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_3',
                                e,
                                onChange,
                              )
                            }
                            options={optionYear.map((oOption) => {
                              return {
                                value: oOption.value,
                                label: oOption.label,
                              };
                            })}
                          />
                        </div>
                      )}
                      {comapnyCodeType === true && (
                        <div className="pr-12 w-1/4">
                          <Select
                            name="refno_format_value_4"
                            label=""
                            placeholder="select"
                            control={control}
                            onChange={(e, onChange) =>
                              handleSelectChange(
                                'refno_format_value_4',
                                e,
                                onChange,
                              )
                            }
                            options={optionYear.map((oOption) => {
                              return {
                                value: oOption.value,
                                label: oOption.label,
                              };
                            })}
                          />
                        </div>
                      )}
                    </div>
                    <div className="w-full flex mt-2 space-x-12">
                      <div className="w-1/2 mt-14">
                        <Checkbox
                          showUpperLabel={false}
                          name="auto_suggest_previous_job_data"
                          label="Automatically Suggest the Previous Job Data with Similar Job Title"
                          control={control}
                        />
                      </div>
                      <div className="w-1/2 mt-14">
                        <Checkbox
                          showUpperLabel={false}
                          name="is_recommend_candidates_score"
                          label=" Enable Candidate  Recommendation"
                          control={control}
                        />
                      </div>
                    </div>
                    {resumeScoreValue == true && (
                      <div className="w-full flex mt-7 space-x-12">
                        <div className="w-1/2 ">
                          <Input
                            name="neglect_applicants_already_applied"
                            label="Negelect Applicants Applied Previously"
                            placeholder="Number of days"
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                        <div className="w-1/2 ">
                          <Input
                            name="recommend_candidates_score"
                            label="Recommendation Candidate Score "
                            placeholder="Number of days"
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-end mt-10 mb-6">
                    <Button
                      className="ProfileSubmitButton py-2 px-8 text-sm font-semibold"
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
        </form>
      </Card>
    </div>
  );
};
export default JobSettings;
