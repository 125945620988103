import { useQueryClient } from '@tanstack/react-query';
import { useGetEvents } from 'ServiceHooks/interviewPipelineHooks';
import dayjs from 'dayjs';
// import { queryClient } from 'index';
import React, { useEffect } from 'react';

const Test2 = () => {
  const queryClient =  useQueryClient();
  const {
    data: calendarEvents,
    isLoading : isCalendarEventsLoading,
    status,
  } = useGetEvents({
    params: {
      rangeInfo : {
        startDate : dayjs('2023-09-30T18:30:00.000Z')?.toISOString(),
        endDate : dayjs('2023-11-11T18:30:00.000Z')?.toISOString()
      },
    },
    enabled: true
  });

  useEffect(()=>{
    console.log('calendarEvents',calendarEvents);
  },[calendarEvents]);
  return (
    <div>
      <button
        onClick={()=>{
          const previousTodos = queryClient.getQueryData({ queryKey : 'eventList'});

          queryClient.setQueryData(['eventList', { 
            startDate : '2023-09-30T18:30:00.000Z',endDate : '2023-11-11T18:30:00.000Z'
          }], []);
        }}
      >

        click
      </button>
      <p>calendarEvents :: <pre>{JSON.stringify(calendarEvents, null, 2)}</pre></p>
      
    </div>
  );
};

export default Test2;