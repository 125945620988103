import interviewService from 'Services/interviewService';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';

const InfractionsChartNew = ({
  data,
  multiface_data,
  total_questions,
  interviewId,
  interview_type
}) => {
  const [imageMatchData, setImageMatchData] = useState();
  const [multifaceInfractionData, setMultifaceInfractionData] =
    useState(multiface_data);
  const [tabInfractionData, setTabInfractionData] = useState(data);

  useEffect(() => {
    getImageMatchData();
    // getMultiFaceInfractionData();
  }, []);

  const getImageMatchData = async () => {
    await interviewService
      .getCheckMatch(interviewId, '')
      .then((data) => {
        setImageMatchData(data?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getMultiFaceInfractionData = async () => {
  //   await interviewService
  //     .getInfraction(interviewId, '')
  //     .then((data) => {
  //       setMultifaceInfractionData(data?.data?.data?.results);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const getToolTip = (qNo, time) => {
    return (
      <div className="p-2">
        <p className="text-[#5C5757] text-[10px] my-2">
          Question:{' '}
          <span className="text-[#3E3B3B] text-[10px] font-medium">
            {qNo}
          </span>
        </p>
        <div className="flex items-center gap-2 border-t border-dotted py-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 7 7"
            fill="none"
          >
            <path
              opacity="0.8"
              d="M3.5 0C1.6 0 0 1.6 0 3.5C0 5.4 1.6 7 3.5 7C5.4 7 7 5.4 7 3.5C7 1.6 5.4 0 3.5 0ZM4.64675 5L3.25 3.6025V1.25H3.75V3.3955L5 4.6465L4.64675 5Z"
              fill="#B5B5B5"
            />
          </svg>
          <span className="text-[#555556] text-[10px]">{time}</span>
        </div>
      </div>
    );
  };

  const getInfractionCount = (data) => {
    let infractionList = data?.filter((inf) => inf?.is_infraction == true);
    return infractionList?.length;
  };

  const InfractionGraph = ({
    title,
    errorMsg,
    mainBg,
    subBg,
    bottom_border = true,
    data,
  }) => {
    console.log(data, 'data');

    return (
      <div
        className={`${bottom_border ? 'border-b-2' : 'border-b-0'} border-b-[#F1F1F1] my-4`}
      >
        <p className="text-[#535353] text-[10px] font-medium">{title}</p>
        <div
          className={`h-[20px] ${mainBg} rounded-full w-full mt-5 flex items-center justify-around`}
        >
          {data?.map((i, index) => (
            <Tooltip
              placement="top"
              title={getToolTip(index + 1, i?.is_infraction_taken_time)}
              color="#FFF"
            >
              <div
                key={index}
                className={`h-[25px] w-[25px] ${subBg} border-2 border-[#FFF] shadow-lg cursor-pointer ${i?.is_infraction ? 'visible' : 'invisible'}`}
              ></div>
            </Tooltip>
          ))}
        </div>
        <p
          className={`text-[#EB6B6B] text-[10px] ${bottom_border ? 'my-4' : 'mt-4'}`}
        >
          {errorMsg}
        </p>
      </div>
    );
  };

  console.log(tabInfractionData, 'tabInfractionData');

  return (
    <div className={`h-[320px] ${interview_type === 'Online Test' && 'flex flex-col justify-center'}`}>
      {interview_type !== 'Online Test' && (
        <InfractionGraph
          title="Multiface infractions"
          errorMsg={
            getInfractionCount(multifaceInfractionData) +
            ' Multiface infractions detected'
          }
          mainBg="bg-[#D7EDFF]"
          subBg="bg-[#25A2FF]"
          data={multifaceInfractionData}
        />
      )}

      <InfractionGraph
        title="Tab Infraction"
        errorMsg={
          getInfractionCount(tabInfractionData) +
          ' Tab Infraction detected'
        }
        mainBg="bg-[#FFEBD2]"
        subBg="bg-[#FF9922]"
        data={tabInfractionData}
        bottom_border={interview_type === 'Online Test' ? false : true}
      />
      {interview_type !== 'Online Test' && (
        <InfractionGraph
          title="Question Image & Interview Image Check"
          errorMsg={
            getInfractionCount(imageMatchData) + ' Infractions detected'
          }
          mainBg="bg-[#E6DDFE]"
          subBg="bg-[#885DF9]"
          bottom_border={false}
          data={imageMatchData}
        />
      )}
    </div>
  );
};

export default InfractionsChartNew;
