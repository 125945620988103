export const candidatesSubTableLevelFilterList = [
  {
    text: 'Onboarding',
    value: 'Onboarding',
  },
  {
    text: 'No Activity',
    value: 'No Activity',
  },
  {
    text: 'Application Received',
    value: 'Application recieved',
  },
];

export const candidatesSubTableLevelStatusFilterList = [
  {
    text: 'No Activity',
    value: 'No Activity',
  },
  {
    text: 'Yet To Attend',
    value: 'yet to attend',
  },
  {
    text: 'Attended',
    value: 'Attended',
  },
  {
    text: 'Applied',
    value: 'Applied',
  },
  {
    text: 'Scheduled',
    value: 'Scheduled',
  },
  {
    text: 'Rescheduled',
    value: 'Rescheduled',
  },
  {
    text: 'Review Pending',
    value: 'Review Pending',
  },
  {
    text: 'Review Completed',
    value: 'Review Completed',
  },
  {
    text: 'Interview Passed',
    value: 'Interview Passed',
  },
  {
    text: 'Interview Failed',
    value: 'Interview Failed',
  },
  {
    text: 'Interview On Hold',
    value: 'Interview On Hold',
  },
];

export const candidatesSubTableSourceFilterList = [
  {
    text: 'Manual Upload',
    value: 'Manual Upload',
  },
  {
    text: 'Excel Upload',
    value: 'Excel Upload',
  },
];

export const candidatesSubTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Grade',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    align: 'center',
  },
  {
    fixed: '',
    width: 200,
    title: 'Current Level',
    dataIndex: 'current_level',
    key: 'current_level',
    fieldShow: true,
    isExpanded: false,
    is_sort: true,
    is_search: false,
    is_filter: true,
    // filters: candidatesSubTableLevelFilterList,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current Level Status',
    dataIndex: 'current_level_status',
    key: 'current_level_status',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_filter: true,
    filters: candidatesSubTableLevelStatusFilterList,
    is_sort: true,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Source',
    dataIndex: 'registration_source',
    key: 'registration_source',
    fieldShow: true,
    isExpanded: false,
    is_sort: true, // add filters field in the component because of API dependency.
    position: 6,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Time',
    dataIndex: 'created_time',
    key: 'created_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 8,
  },
];

export const consultancyCandidateTableColumnData = [
  {
    fieldID: 'id',
    headerName: 'ID',
  },
  {
    fieldID: 'first_name',
    headerName: 'First Name',
  },
  {
    fieldID: 'last_name',
    headerName: 'Last Name',
  },
  {
    fieldID: 'email',
    headerName: 'Email',
  },
  {
    fieldID: 'mobile_number',
    headerName: 'Phone',
  },
  {
    fieldID: 'resume_score',
    headerName: 'Score',
  },
  {
    fieldID: 'consultancy',
    headerName: 'Consultancy',
  },
  {
    fieldID: 'created_date',
    headerName: 'Created Date',
  },
  {
    fieldID: 'created_time',
    headerName: 'Created Time',
  },
];

export const mainCandidatesTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Grade',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    align: 'center',
  },
  {
    fixed: '',
    width: 200,
    title: 'Source',
    dataIndex: 'registration_source',
    key: 'registration_source',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Time',
    dataIndex: 'created_time',
    key: 'created_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    fixed: 'right',
    width: 90,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    is_filter: false,
    position: 7,
  },
];

export const pipelineComponentColumnData = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'fullname',
    key: 'fullname',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Job',
    dataIndex: 'job',
    key: 'job',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
  },
  {
    width: 200,
    title: 'Level',
    dataIndex: 'level',
    key: 'level',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date',
    key: 'created_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created Time',
    dataIndex: 'created_time',
    key: 'created_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    width: 200,
    title: 'Status',
    dataIndex: 'level_status',
    key: 'level_status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
  },
  {
    fixed: 'right',
    width: 90,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    is_filter: false,
    position: 8,
  },
];

export const noShowCandidatesColumns = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'first_name',
    key: 'first_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Job',
    dataIndex: 'job',
    key: 'job',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
  },
  {
    width: 200,
    title: 'Level',
    dataIndex: 'level',
    key: 'level',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Scheduled Date',
    dataIndex: 'scheduled_date',
    key: 'scheduled_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Scheduled Time',
    dataIndex: 'scheduled_time',
    key: 'scheduled_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    fixed: 'right',
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    is_filter: false,
    position: 7,
  },
];

export const TYPES = {
  SET_RESUME_FILES: 'SET_RESUME_FILES',
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  SET_SELECTED_JOB_ITEMS: 'SET_SELECTED_JOB_ITEMS',
  SET_ADD_CANDIDATES_MODAL_OPEN: 'SET_ADD_CANDIDATES_MODAL_OPEN',
  SET_ADD_CANDIDATES_TABLE_MODAL_OPEN:
    'SET_ADD_CANDIDATES_TABLE_MODAL_OPEN',
  RESET_RESUMES_FILE_UPLOAD: 'RESET_RESUMES_FILE_UPLOAD',
  SET_RESUMES_FILE_UPLOAD: 'SET_RESUMES_FILE_UPLOAD',
  SET_SCHEDULE_MODAL_OPEN: 'SET_SCHEDULE_MODAL_OPEN',
  SET_UPDATE_CANDIDATE_MODAL_OPEN: 'SET_UPDATE_CANDIDATE_MODAL_OPEN',
  SET_COMPARE_MODAL_OPEN: 'SET_COMPARE_MODAL_OPEN',
  SET_GRID_SEARCH_TEXT: 'SET_GRID_SEARCH_TEXT',
  SET_JOB_SEARCH_TEXT: 'SET_JOB_SEARCH_TEXT',
};
