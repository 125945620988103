import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Checkbox as AntCheckbox,
  Input as AntInput,
  message,
  Select,
  Tooltip,
} from 'antd';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Button from 'Components/CommonComponents/Button/Button';
import preScreenImg from '../../Assets/images/Prescreening.png';
import levelImg from '../../Assets/images/level.png';
import jobService from 'Services/JobService';
import DialogLayout from 'Components/CommonComponents/DialogLayout';
import preScreen from '../../Assets/images/preScreen.png';
import EditJobStore from 'Store/EditJobStore';
import Progress from 'Components/CommonComponents/Progress';
import { INTERVIEW_TYPES } from 'Utilities/constants';

import { JOB_ACTIVITY_LOGGER_TYPES } from 'Utilities/constants';
import createActivityLoggerId from 'Utilities/Jobs/createActivityLoggerId';
import {
  useCreateActivityLoggerId,
  useGetHiringStagesDetails,
} from 'ServiceHooks/jobHooks';
import {
  interview_level,
  prescreen,
  small_edit,
  small_trash,
} from 'Components/CommonComponents/Icons';
import EditJobHiringStageStore from 'Store/EditJobHiringStageStore';

const detectUpdatedFields = (initial, updated) => {
  const fields = [];
  const { interviews, prescreeningList } = updated;
  if (!isEqual(initial?.interviews, interviews)) {
    fields.push('interviews');
  }
  if (!isEqual(initial?.prescreen_order, prescreeningList)) {
    fields.push('prescreen_order');
  }
  return fields;
};

const HiringStageNew = (props) => {
  const [isAddPrescreening, setAddPrescreening] = useState();
  const [isAddLevel, setAddLevel] = useState();
  const [prescreeningList, setPrescreeningList] = useState();
  const [isRadioButtonShow, setRadioButtonShow] = useState(false);
  const [strInterviewType, setInterviewType] = useState();
  const [InterviwerList, setInterviwerList] = useState();
  const [selectedInterviwer, setSelectedInterviwer] = useState();
  const [slotType, setInterviewSlot] = useState(true);
  const [selectedInterviewLevel, setInterviewLevel] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isPrescreeningPreview, setPrescreeningPreview] = useState();
  const [editInterviewLevel, setEditInterviewLevel] = useState();
  const [editlevelIndex, setEditLevelIndex] = useState();

  const oEditJobResponse = EditJobStore((state) => state.EditJobtResponse);
  const oEditJobHiringStageResponse = EditJobHiringStageStore(
    (state) => state.EditJobHiringStageResponse,
  );
  const [messageApi, contextHolder] = message.useMessage();
  const { setEditJobHiringStageResponse } = EditJobHiringStageStore();
  const [interviewTypeError, setInterviewTypeError] = useState();
  const [selectInterviwerError, setselectInterviwerError] = useState();
  const [questionaireList, setQuestionaireList] = useState();
  const [displyQuestionaireList, setDisplayQuestionaireList] = useState();
  const [interviewQuestionaireId, setInterviewQuestionaireId] = useState();
  const [isQIdError, setQIdError] = useState(false);
  const [isBoardInterview, setBoardInterview] = useState();
  const [searchValue, setSearchVal] = useState('');
  const [isProcessing, setProcessing] = useState();
  const [roundName, setRoundName] = useState('');
  const [roundNameError, setRoundNameError] = useState(false);

  const [isBoardIcon, showBoardCheckBox] = useState(false);
  const [isJobCreatedOnTime, setJobCreateOnTime] = useState('');
  const [isInterviewRequired, setInterviewRequired] = useState();

  const param = useParams();

  const arePrescreenParametersAvailable = selectedQuestions?.length > 0;

  const { mutate: sendActivityLog } = useCreateActivityLoggerId({});

  const { data: initialHiringStagesDetails } = useGetHiringStagesDetails({
    params: {
      jobId: param?.jobId,
    },
    enabled: !!param?.jobId,
  });

  const initialStageDetails = initialHiringStagesDetails?.results?.[0];

  const { control, setValue, resetField } = useForm({
    mode: 'all',
    defaultValues: {
      schedules: [false],
      schedule_prescreen_initially: false,
      resume_score_to_schedule_interview: '',
      prescreen_score_to_schedule_interview: '',
    },
  });

  useEffect(() => {
    if (isEmpty(initialHiringStagesDetails)) return;

    const schedules = initialStageDetails?.interviews?.map(
      (item) => item?.auto_interview_schedule,
    );
    const values = {
      schedules,
      schedule_prescreen_initially:
        initialStageDetails?.schedule_prescreen_initially,
      resume_score_to_schedule_interview:
        initialStageDetails?.resume_score_to_schedule_interview,
      prescreen_score_to_schedule_interview:
        initialStageDetails?.prescreen_score_to_schedule_interview,
    };

    Object.entries(values).map(([key, value]) => {
      setValue(key, value);
    });
  }, [initialHiringStagesDetails]);

  const resettingFields = [
    'schedule_prescreen_initially',
    'resume_score_to_schedule_interview',
  ];

  const isAutoPrescreenSchedulingApplied = useWatch({
    control,
    name: 'schedule_prescreen_initially',
  });

  const watchLevelsForm = useWatch({
    control,
  });

  useEffect(() => {
    getPrescreening();
    getQuestionaireList();
    editJobHiringStage();
  }, []);

  const interviewLevelCount = selectedInterviewLevel?.length;
  const isEditFormActive = editInterviewLevel?.length > 0;

  const isFirstLevelBeingEdited = editlevelIndex < 1 && isEditFormActive;
  const isFirstLevelBeingAdded =
    interviewLevelCount < 1 && !isEditFormActive;

  //   api call's start

  const editJobHiringStage = async () => {
    setProcessing(true);
    await jobService
      .getHiringStage(param?.jobId || oEditJobResponse?.job_id)
      .then((oResponse) => {
        setEditJobHiringStageResponse(oResponse.data.data.results[0]);
        setValue(
          'schedule_prescreen_initially',
          oResponse.data.data.results[0]?.schedule_prescreen_initially,
        );
        setValue(
          'resume_score_to_schedule_interview',
          oResponse.data.data.results[0]
            ?.resume_score_to_schedule_interview,
        );
        setInterviewLevel(oResponse.data.data.results[0].interviews);
        setSelectedQuestions(
          oResponse.data.data.results[0].prescreening_fields,
        );
        props.changePanel(0);
        props.process(false);
        setProcessing(false);
      })
      .catch((err) => {
        props.process(false);
        setProcessing(false);
      });
  };
  const isPersonalityTestSelected =
    strInterviewType?.method_type === INTERVIEW_TYPES.PERSONALITY;

  const isAIInterviewSelecetd =
    strInterviewType?.method_type === INTERVIEW_TYPES.AI_VIDEO;
  const isOnlineTestInterviewSelecetd =
    strInterviewType?.method_type ===
    INTERVIEW_TYPES.AI_INTERVIEW_WITHOUT_VIDEO;

  const isEditAiInterview =
    editInterviewLevel &&
    editInterviewLevel[0]?.method_type === 'AI_VIDEO';
  const isEditOnlineTest =
    editInterviewLevel &&
    editInterviewLevel[0]?.method_type === 'AI_INTERVIEW_WITHOUT_VIDEO';
  const getQuestionaireList = async () => {
    await jobService
      .getQuestionaire()
      .then((oResponse) => {
        setQuestionaireList(oResponse.data.data);
        var arrOptions = [];
        oResponse.data.data.map((oData) => {
          arrOptions.push({
            value: oData.id,
            label: oData.title,
          });
        });
        setDisplayQuestionaireList(arrOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrescreening = async () => {
    await jobService
      .getPrescreeningValue(true)
      .then((oResponse) => {
        setPrescreeningList(oResponse.data.data);
        if (
          oEditJobResponse?.job_id !== oEditJobHiringStageResponse?.job
        ) {
          const is_common =
            oResponse?.data?.data?.prescreening_fields?.filter(
              (item) =>
                item?.auto_scoring_field !== null &&
                item?.auto_scoring_field !== 'DEFAULT',
            );
          setSelectedQuestions(is_common);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onInterviwerSearch = async (e) => {
    await jobService
      .getAlluser(e)
      .then((oResponse) => {
        var arrList = getInterviwerListOption(oResponse.data.data.results);
        setInterviwerList(arrList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterviwerListOption = (optionList) => {
    var arrData = [];
    optionList &&
      optionList.map((odata) => {
        arrData.push({
          key: odata.id,
          cat: odata.id,
          email: odata.email,
          first_name: odata.first_name,
          id: odata.id,
          mobile_number: odata.mobile_number,
          hire_manage_user: odata.hire_manage_user,
          assigned_jobs: odata.assigned_jobs,
          designation: odata.designation,
          value: odata.id,
          label: odata.first_name,
        });
      });
    return arrData;
  };

  const searchPrescreening = (value) => {
    setSearchVal(value);
    if (value === '') {
      //   setDisplayPreScreening(oPrescreeningResponse.prescreening_fields);
    } else {
      //   setDisplayPreScreening("");
    }
  };

  const getHiringStagePayload = () => {
    const {
      schedules,
      schedule_prescreen_initially,
      resume_score_to_schedule_interview,
      prescreen_score_to_schedule_interview,
    } = watchLevelsForm;
    var interviewObject = [];
    selectedInterviewLevel.map((interviewLevel, index) => {
      var arrInterviwerId = [];
      interviewLevel.interviewer.map((item) => {
        return arrInterviwerId.push(item.id);
      });
      if (interviewLevel.id) {
        interviewObject.push({
          interviewer: arrInterviwerId,
          interview_type: interviewLevel.interview_type,
          interview_level: index + 1,
          auto_interview_schedule: schedules?.length
            ? schedules[index]
            : false,
          is_due_date_interview_type:
            interviewLevel.is_due_date_interview_type,
          evaluation_questionaire: interviewLevel.evaluation_questionaire,
          id: interviewLevel.id,
          is_board_interview: interviewLevel.is_board_interview,
          is_review_required: interviewLevel.is_review_required,
          round_name: interviewLevel?.round_name,
        });
      } else {
        interviewObject.push({
          interviewer: arrInterviwerId,
          interview_type: interviewLevel.interview_type,
          interview_level: index + 1,
          auto_interview_schedule: schedules?.length
            ? schedules[index]
            : false,
          is_due_date_interview_type:
            interviewLevel.is_due_date_interview_type,
          evaluation_questionaire: interviewLevel.evaluation_questionaire,
          is_board_interview: interviewLevel.is_board_interview,
          is_review_required: interviewLevel.is_review_required,
          round_name: interviewLevel?.round_name,
        });
      }
      return interviewObject;
    });

    var arrRequired_prescreen_fields = [];
    var arrOptional_prescreen = [];
    var arrOrder_prescreen = [];
    for (let i = 0; i < selectedQuestions?.length; i++) {
      if (selectedQuestions[i].is_mandatory_field) {
        arrRequired_prescreen_fields.push(selectedQuestions[i].id);
      } else if (!selectedQuestions[i].is_mandatory_field) {
        arrOptional_prescreen.push(selectedQuestions[i].id);
      }
      arrOrder_prescreen.push(selectedQuestions[i].id);
    }
    var hiringStagePayload = {
      required_prescreen_fields: arrRequired_prescreen_fields,
      optional_prescreen: arrOptional_prescreen,
      prescreen_order: arrOrder_prescreen,
      interviews: interviewObject,
      bulk_upload: true,
      manual_upload: true,
      job_site: true,
      auto_interview_schedule: schedule_prescreen_initially
        ? false
        : schedules?.[0],
      schedule_prescreen_initially,
      resume_score_to_schedule_interview,
      prescreen_score_to_schedule_interview,
    };
    if (oEditJobResponse?.job_hiring_stages_id === false) {
      hiringStagePayload['job'] =
        oEditJobResponse && oEditJobResponse?.job_id;
    }
    return hiringStagePayload;
  };

  const addHiringStage = async () => {
    setProcessing(true);
    const logger_id = createActivityLoggerId();
    const payload = getHiringStagePayload();
    const chosenLevels = payload?.interviews?.map(
      (item) => item.interview_level,
    );
    console.log(
      oEditJobResponse?.job_hiring_stages_id,
      'oEditJobResponse?.job_hiring_stages_id',
    );
    console.log(
      oEditJobHiringStageResponse,
      'oEditJobHiringStageResponse,oEditJobResponse?.job_hiring_stages_id',
    );
    if (!oEditJobHiringStageResponse && isJobCreatedOnTime === '') {
      try {
        if (!oEditJobResponse?.job_id) {
          throw new Error('Need Job Fields for this form.');
        }
        const response = await jobService.saveHiringStage(payload, {
          logger_id,
        });
        setEditJobHiringStageResponse(response?.data?.data);
        const log = {
          logger_id,
          job: oEditJobResponse?.job_id,
          level_no: chosenLevels,
          activity: JOB_ACTIVITY_LOGGER_TYPES.INTERVIEW_LEVEL_ADDED,
        };
        sendActivityLog(log);

        setJobCreateOnTime(response.data.data.job_hiring_stages_id);
        props.setAccordionActive(props.currentActive + 1);
        props.setActiveJobTab('JOB_PUBLISH');
        setProcessing(false);
      } catch (error) {
        console.log(
          'Hiring Stage Error: ',
          error?.response?.data?.error_info ?? error,
        );

        alert('Please select the Prescreening Parameters or Level');
        setProcessing(false);
      }
    } else {
      const logger_id = createActivityLoggerId();
      const hiring_stagesID =
        oEditJobHiringStageResponse?.job_hiring_stages_id
          ? oEditJobHiringStageResponse?.job_hiring_stages_id
          : isJobCreatedOnTime;
      try {
        const response = await jobService.UpdateHiringStage(
          payload,
          hiring_stagesID,
          { logger_id },
        );

        const updated = {
          interviews: selectedInterviewLevel,
          prescreeningList: payload?.prescreen_order,
        };
        const updated_fields = detectUpdatedFields(
          initialHiringStagesDetails?.results[0],
          updated,
        );

        const logForEditJob =
          !isEmpty(param?.jobId) && !isEmpty(updated_fields);

        const log = {
          logger_id,
          job: oEditJobResponse?.job_id,
          level_no: chosenLevels,
          activity: isEmpty(param?.jobId)
            ? JOB_ACTIVITY_LOGGER_TYPES.INTERVIEW_LEVEL_ADDED
            : JOB_ACTIVITY_LOGGER_TYPES.INTERVIEW_LEVEL_UPDATED,
          ...(logForEditJob && { updated_fields }),
        };
        sendActivityLog(log);

        props.setAccordionActive(props.currentActive + 1);
        props.setActiveJobTab('JOB_PUBLISH');
        setProcessing(false);
      } catch (error) {
        console.log(
          'Hiring Stage Error: ',
          error?.response?.data?.error_info ?? error,
        );
        setProcessing(false);
      }
    }
  };

  //  api call's end

  const changeInterviewType = (e) => {
    setInterviewRequired(false);
    // var value = prescreeningList.interview_types.filter(function (item) {
    //   return item.id == e.target.value;
    // });
    var value = prescreeningList.interview_types.filter(function (item) {
      return item.id == e;
    });
    setRadioButtonShow(value[0].interview_type_selection_required);
    setInterviewType(value[0]);
    setInterviewTypeError(false);
    if (value[0]?.method_type === 'PERSONALITY') {
      setQIdError(false);
    }
  };

  const onSelectTheInterviwer = (e) => {
    setSelectedInterviwer(e);
    if (e?.length > 1) {
      showBoardCheckBox(true);
    } else {
      showBoardCheckBox(false);
    }
  };

  const getSelectedInterviwer = (Interviwer) => {
    InterviwerList.map((item) => {
      if (item.id === Interviwer.value) {
        setSelectedInterviwer((prevState) => {
          const newState = [...prevState];
          newState.push(item);
          return newState;
        });
      }
    });
    setselectInterviwerError(false);
  };

  const onDeselect = (deSelectData) => {
    const filterInterviwer = selectedInterviwer.filter(
      (item) => item.id !== deSelectData.value,
    );
    setSelectedInterviwer(filterInterviwer);
  };

  const onRemoveValue = (e) => {
    setSelectedInterviwer(e);
    if (e?.length <= 1) {
      showBoardCheckBox(false);
    } else {
      showBoardCheckBox(true);
    }
  };

  const handelRadioChange = (e) => {
    if (e.target.value === 'dueDate') {
      setInterviewSlot(true);
    } else {
      setInterviewSlot(false);
    }
  };

  const handlePrescreeningCheckBox = (e, data) => {
    if (e.target.checked) {
      setSelectedQuestions([...selectedQuestions, data]);
    } else {
      const newArray = selectedQuestions.filter(
        (obj) => obj.id !== data.id,
      );
      setSelectedQuestions([...newArray]);
    }
  };

  const getSelectedPrescreeningId = (id) => {
    var test = selectedQuestions.map((item) => item.id).includes(id);
    return test;
  };

  const onAddLevel = () => {
    if (
      selectedInterviwer?.length > 0 &&
      strInterviewType !== '' &&
      roundName !== '' &&
      (strInterviewType?.method_type === 'PERSONALITY' ||
        ((strInterviewType?.method_type === 'LIVE' ||
          strInterviewType?.method_type === 'F2F' ||
          strInterviewType?.method_type === 'TELEPHONIC') &&
          interviewQuestionaireId) ||
        ((strInterviewType?.method_type === 'AI_VIDEO' ||
          strInterviewType?.method_type ===
            'AI_INTERVIEW_WITHOUT_VIDEO') &&
          ((isInterviewRequired && interviewQuestionaireId) ||
            !isInterviewRequired)))
    ) {
      let arrInterviwer = [];
      selectedInterviwer.map((data) => {
        arrInterviwer.push({
          email: data.email,
          first_name: data.first_name,
          id: data.id,
          mobile_number: data.mobile_number,
          hire_manage_user: data.hire_manage_user,
          assigned_jobs: data.assigned_jobs,
          name: data.first_name,
          designation: data.designation,
        });
      });
      let oInterviewType = {
        interview_type: strInterviewType.id,
        interview_type_selection_required:
          strInterviewType.interview_type_selection_required,
        method_type: strInterviewType.method_type,
        interview_type_name: strInterviewType.name,
        interviewer: arrInterviwer,
        round_name: roundName,
        evaluation_questionaire:
          isPersonalityTestSelected ||
          (!isInterviewRequired &&
            (isAIInterviewSelecetd || isOnlineTestInterviewSelecetd))
            ? null
            : interviewQuestionaireId,
        //slot: slotType === "Slot" ? false : true,
        is_due_date_interview_type: slotType,
        is_board_interview: isBoardInterview,
        is_review_required: isInterviewRequired,
      };
      setInterviewLevel((prevState) => {
        const newState = [...prevState];
        newState.push(oInterviewType);
        return newState;
      });
      setAddLevel(false);
    } else {
      if (strInterviewType === '') {
        setInterviewTypeError(true);
      } else {
        setInterviewTypeError(false);
      }
      if (selectedInterviwer?.length === 0) {
        setselectInterviwerError(true);
      } else {
        setselectInterviwerError(false);
      }
      if (roundName === '') {
        setRoundNameError(true);
      } else {
        setRoundNameError(false);
      }

      if (
        ((strInterviewType?.method_type === 'AI_VIDEO' ||
          strInterviewType?.method_type ===
            'AI_INTERVIEW_WITHOUT_VIDEO') &&
          isInterviewRequired &&
          (interviewQuestionaireId === undefined ||
            interviewQuestionaireId === null)) ||
        (strInterviewType?.method_type !== 'PERSONALITY' &&
          strInterviewType?.method_type !== 'AI_VIDEO' &&
          strInterviewType?.method_type !== 'AI_INTERVIEW_WITHOUT_VIDEO' &&
          (interviewQuestionaireId === undefined ||
            interviewQuestionaireId === null))
      ) {
        setQIdError(true);
      } else {
        setQIdError(false);
      }
      // if((isInterviewRequired && (strInterviewType?.method_type === 'AI_VIDEO' || strInterviewType?.method_type === 'AI_INTERVIEW_WITHOUT_VIDEO') && interviewQuestionaireId)
      // || (strInterviewType?.method_type !== 'PERSONALITY' && interviewQuestionaireId) || strInterviewType?.method_type === 'PERSONALITY'){
      //   setQIdError(false);
      // }else {
      //   setQIdError(true);
      // }
    }

    // if (
    //   (selectedInterviwer.length > 0 &&
    //     strInterviewType !== '' &&
    //     interviewQuestionaireId && roundName !== '') ||
    //   strInterviewType?.method_type === 'PERSONALITY' ||
    //   (!isInterviewRequired &&
    //     (strInterviewType?.method_type === 'AI_VIDEO' ||
    //       strInterviewType?.method_type ===
    //         'AI_INTERVIEW_WITHOUT_VIDEO') &&
    //     selectedInterviwer.length > 0)
    // ) {
    //   let arrInterviwer = [];
    //   selectedInterviwer.map((data) => {
    //     arrInterviwer.push({
    //       email: data.email,
    //       first_name: data.first_name,
    //       id: data.id,
    //       mobile_number: data.mobile_number,
    //       hire_manage_user: data.hire_manage_user,
    //       assigned_jobs: data.assigned_jobs,
    //       name: data.first_name,
    //       designation: data.designation,
    //     });
    //   });
    //   let oInterviewType = {
    //     interview_type: strInterviewType.id,
    //     interview_type_selection_required:
    //       strInterviewType.interview_type_selection_required,
    //     method_type: strInterviewType.method_type,
    //     interview_type_name: strInterviewType.name,
    //     interviewer: arrInterviwer,
    //     round_name: roundName,
    //     evaluation_questionaire:
    //       isPersonalityTestSelected ||
    //       (!isInterviewRequired &&
    //         (isAIInterviewSelecetd || isOnlineTestInterviewSelecetd))
    //         ? null
    //         : interviewQuestionaireId,
    //     //slot: slotType === "Slot" ? false : true,
    //     is_due_date_interview_type: slotType,
    //     is_board_interview: isBoardInterview,
    //     is_review_required: isInterviewRequired,
    //   };
    //   setInterviewLevel((prevState) => {
    //     const newState = [...prevState];
    //     newState.push(oInterviewType);
    //     return newState;
    //   });
    //   setAddLevel(false);
    // } else {
    //   if (selectedInterviwer.length === 0) {
    //     setselectInterviwerError(true);
    //   } else {
    //     setselectInterviwerError(false);
    //   }
    //   if (strInterviewType === '') {
    //     setInterviewTypeError(true);
    //   } else {
    //     setInterviewTypeError(false);
    //   }

    //   if (
    //     isPersonalityTestSelected ||
    //     (!isInterviewRequired &&
    //       (isAIInterviewSelecetd || isOnlineTestInterviewSelecetd) &&
    //       interviewQuestionaireId !== undefined)
    //   ) {
    //     setQIdError(false);
    //   } else {
    //     setQIdError(true);
    //   }
    //   if(roundName === ''){
    //     setRoundNameError(true)
    //   }else {
    //     setRoundNameError(false)
    //   }
    // }
  };

  const updateLevel = () => {
    if (
      selectedInterviwer?.length > 0 &&
      strInterviewType !== '' &&
      roundName !== '' &&
      roundName !== null &&
      (strInterviewType?.method_type === 'PERSONALITY' ||
        ((strInterviewType?.method_type === 'LIVE' ||
          strInterviewType?.method_type === 'F2F' ||
          strInterviewType?.method_type === 'TELEPHONIC') &&
          interviewQuestionaireId) ||
        ((strInterviewType?.method_type === 'AI_VIDEO' ||
          strInterviewType?.method_type ===
            'AI_INTERVIEW_WITHOUT_VIDEO') &&
          ((isInterviewRequired && interviewQuestionaireId) ||
            !isInterviewRequired)))
    ) {
      let arrInterviwer = [];
      if (selectedInterviwer?.length > 0) {
        selectedInterviwer.map((data) => {
          arrInterviwer.push({
            email: data.email,
            first_name: data.first_name,
            id: data.id,
            mobile_number: data.mobile_number,
            hire_manage_user: data.hire_manage_user,
            assigned_jobs: data.assigned_jobs,
            name: data.first_name,
            designation: data.designation,
          });
        });
      } else {
        editInterviewLevel[0].interviewer?.map((editItem) => {
          arrInterviwer.push({
            email: editItem.email,
            first_name: editItem.first_name,
            id: editItem.id,
            mobile_number: editItem.mobile_number,
            hire_manage_user: editItem.hire_manage_user,
            assigned_jobs: editItem.assigned_jobs,
            name: editItem.first_name,
            designation: editItem.designation,
            round_name: roundName,
          });
        });
      }

      setInterviewLevel((prevState) => {
        const newState = [...prevState];

        newState.map((oData, index) => {
          if (index == editlevelIndex) {
            // eslint-disable-next-line no-lone-blocks
            {
              if (strInterviewType !== '') {
                oData.interview_type = strInterviewType.id;
                oData.interview_type_selection_required =
                  strInterviewType.interview_type_selection_required;
                oData.method_type = strInterviewType.method_type;
                oData.interview_type_name = strInterviewType.name;
                oData.interviewer = arrInterviwer;
                oData.is_due_date_interview_type = slotType;
                oData.round_name = roundName;
                //newState[i].questionaireId= interviewQuestionaireId;
              } else {
                const newArray = prescreeningList.interview_types?.filter(
                  (obj) => obj.id == oData.interview_type,
                );

                oData.interview_type = newArray[0].id;
                oData.interview_type_selection_required =
                  newArray[0].interview_type_selection_required;
                oData.method_type = newArray[0].method_type;
                oData.interview_type_name = newArray[0].name;
                oData.interviewer = arrInterviwer;
                oData.is_due_date_interview_type = slotType;
                oData.round_name = roundName;
                // newState[i].questionaireId= interviewQuestionaireId;
              }
              oData.evaluation_questionaire =
                isPersonalityTestSelected ||
                (!isInterviewRequired &&
                  (isAIInterviewSelecetd || isOnlineTestInterviewSelecetd))
                  ? null
                  : interviewQuestionaireId !== undefined
                    ? interviewQuestionaireId
                    : oData.evaluation_questionaire;

              oData.is_board_interview =
                isBoardInterview !== undefined
                  ? isBoardInterview
                  : oData.is_board_interview;

              oData.is_review_required =
                isInterviewRequired !== undefined
                  ? isInterviewRequired
                  : oData.is_review_required;
            }
          }
        });

        // for (let i = 0; i < newState?.length; i++) {
        //   if (
        //     newState[i] == editlevelIndex

        //   ) {
        //     if (strInterviewType !== "") {
        //       newState[i].interview_type = strInterviewType.id;
        //       newState[i].interview_type_selection_required =
        //         strInterviewType.interview_type_selection_required;
        //       newState[i].method_type = strInterviewType.method_type;
        //       newState[i].interview_type_name = strInterviewType.name;
        //       newState[i].interviewer = arrInterviwer;
        //       newState[i].is_due_date_interview_type = slotType;
        //       //newState[i].questionaireId= interviewQuestionaireId;
        //     } else {
        //       const newArray = prescreeningList.interview_types?.filter(
        //         (obj) => obj.id == editlevelIndex
        //       );

        //       newState[i].interview_type = newArray[0].id;
        //       newState[i].interview_type_selection_required =
        //         newArray[0].interview_type_selection_required;
        //       newState[i].method_type = newArray[0].method_type;
        //       newState[i].interview_type_name = newArray[0].name;
        //       newState[i].interviewer = arrInterviwer;
        //       newState[i].is_due_date_interview_type = slotType;
        //       // newState[i].questionaireId= interviewQuestionaireId;
        //     }
        //     newState[i].evaluation_questionaire =
        //       interviewQuestionaireId !== undefined
        //         ? interviewQuestionaireId
        //         : newState[i].evaluation_questionaire;
        //   }
        // }
        return newState;
      });
      setAddLevel(false);
    } else {
      if (strInterviewType === '') {
        setInterviewTypeError(true);
      } else {
        setInterviewTypeError(false);
      }
      if (selectedInterviwer?.length === 0) {
        setselectInterviwerError(true);
      } else {
        setselectInterviwerError(false);
      }
      if (roundName === '' || roundName == null) {
        setRoundNameError(true);
      } else {
        setRoundNameError(false);
      }

      if (
        ((strInterviewType?.method_type === 'AI_VIDEO' ||
          strInterviewType?.method_type ===
            'AI_INTERVIEW_WITHOUT_VIDEO') &&
          isInterviewRequired &&
          (interviewQuestionaireId === undefined ||
            interviewQuestionaireId === null)) ||
        (strInterviewType?.method_type !== 'PERSONALITY' &&
          strInterviewType?.method_type !== 'AI_VIDEO' &&
          strInterviewType?.method_type !== 'AI_INTERVIEW_WITHOUT_VIDEO' &&
          (interviewQuestionaireId === undefined ||
            interviewQuestionaireId === null))
      ) {
        setQIdError(true);
      } else {
        setQIdError(false);
      }
    }
  };

  const checkMandatory = (e) => {
    const clonedQuestions = cloneDeep(selectedQuestions);
    const currentId = e.target.value;
    const newChecked = e.target.checked;
    const chosenQuestion = clonedQuestions.find(
      (item) => item.id === currentId,
    );
    chosenQuestion.is_mandatory_field = newChecked;
    console.log(clonedQuestions, 'clonedQuestions');
    setSelectedQuestions(clonedQuestions);
  };

  const handelDelete = (data) => {
    document.getElementById(data.id).checked = false;
    const newArray = selectedQuestions.filter((obj) => obj.id !== data.id);
    setSelectedQuestions([...newArray]);
  };

  const handelSort = () => {
    // Duplicate items
    let sortData = [...selectedQuestions];
    // Remove and saved item content
    const draggedItemContent = sortData.splice(dragItem.current, 1)[0];
    // Switch position
    sortData.splice(dragOverItem.current, 0, draggedItemContent);
    // Reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // Update the actual array
    setSelectedQuestions(sortData);
  };

  // Save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleAssign = () => {
    if (selectedQuestions?.length > 0) {
      setAddPrescreening(false);
    } else {
      resettingFields.forEach((field) => resetField(field));
      messageApi.info("Please Select the Prescreening Question's!");
    }
  };

  const deleteInterviewLevel = (index) => {
    const filteredSchedules = watchLevelsForm?.schedules?.filter(
      (item, scheduleIndex) => scheduleIndex !== index,
    );
    const filteredState = selectedInterviewLevel.filter(
      (item, n) => n !== index,
    );

    setValue('schedules', filteredSchedules);
    setInterviewLevel(filteredState);
  };

  const editLevel = (nId, index) => {
    setSelectedInterviwer([]);
    setInterviewType('');
    setInterviewSlot(undefined);
    setInterviewQuestionaireId(undefined);
    setEditLevelIndex(index);

    prescreeningList.interview_types?.map((iTypeList) => {
      if (iTypeList.id == nId) {
        setRadioButtonShow(iTypeList.interview_type_selection_required);
      }
    });

    // const filteredState = selectedInterviewLevel.filter(
    //   (item) => item.interview_type === nId
    // );
    const filteredState = selectedInterviewLevel.filter(
      (item, n) => n === index,
    );

    setEditInterviewLevel(filteredState);
    setInterviewQuestionaireId(filteredState[0]?.evaluation_questionaire);
    setRoundName(filteredState[0]?.round_name);
    // new line
    setSelectedInterviwer(filteredState[0]?.interviewer);

    if (filteredState[0]?.interviewer?.length > 1) {
      showBoardCheckBox(true);
      setBoardInterview(filteredState.is_board_interview);
    } else {
      showBoardCheckBox(false);
    }
    setAddLevel(true);
  };

  const getDefaultList = () => {
    if (!strInterviewType && editInterviewLevel[0]) {
      const strInterview = {
        id: editInterviewLevel[0]?.interview_type,
        name: editInterviewLevel[0]?.interview_type_name,
        method_type: editInterviewLevel[0]?.method_type,
        interview_type_selection_required:
          editInterviewLevel[0]?.interview_type_selection_required,
      };
      setInterviewType(strInterview);
    }

    let defaultArr = [];
    editInterviewLevel[0]?.interviewer?.map((oData) => {
      defaultArr.push({
        key: oData.first_name,
        cat: oData.id,
        email: oData.email,
        first_name: oData.first_name,
        id: oData.id,
        mobile_number: oData.mobile_number,
        hire_manage_user: oData.hire_manage_user,
        assigned_jobs: oData.assigned_jobs,
        label: oData.first_name,
        value: oData.id,
      });
    });

    return defaultArr;
  };

  const addLevel = () => {
    setEditInterviewLevel([]);
    setSelectedInterviwer([]);
    setInterviewQuestionaireId(undefined);
    setInterviewType('');
    setRoundName('');
    setRadioButtonShow(false);
    setInterviewSlot(undefined);
    showBoardCheckBox(false);
    setAddLevel(true);
  };

  const cancel = () => {
    // props.changePanel(props.index - 1);
    props.setAccordionActive(props.currentActive - 1);
    props.setActiveJobTab('JOB_HIRING_TEAM');
  };

  const selectQuestionaire = (e) => {
    setInterviewQuestionaireId(e);
    setQIdError(false);
  };

  const handelBoardChange = (e) => {
    if (e.target.checked) {
      setBoardInterview(e.target.checked);
    } else {
      setBoardInterview(false);
    }
  };
  const handleInterviewRequired = (e) => {
    if (e.target.checked) {
      setInterviewRequired(e.target.checked);
    } else {
      setInterviewRequired(false);
    }
  };
  const buildQuestionViewLayout = (ele, index) => {
    return (
      <div
        className="rounded-lg p-4 border-2 border-dashed border-gray-300 h-fit m-3 cursor-move hover:bg-sky-100"
        style={{ width: '95%', display: 'flex' }}
        draggable
        onDragStart={(e) => (dragItem.current = index)}
        onDragEnter={(e) => (dragOverItem.current = index)}
        onDragOver={(e) => e.preventDefault()}
        onDragEnd={handelSort}
      >
        <div style={{ flexGrow: '2' }}>
          <div>
            <h3
              style={{
                fontSize: '14px',
                fontFamily: 'sans-serif',
              }}
            >
              {ele?.name}
            </h3>
          </div>
          <div>
            <AntCheckbox
              value={ele.id}
              className="pt-1"
              checked={ele.is_mandatory_field}
              onChange={(e) => checkMandatory(e)}
            />{' '}
            <span>Mandatory</span>
          </div>
        </div>
        <div>
          <i className="Icondelete" style={{ float: 'right' }}>
            <DeleteOutlined
              onClick={() => {
                handelDelete(ele);
              }}
            />
          </i>
        </div>
      </div>
    );
  };

  const interviewLevelCard = (item, index) => {
    return (
      <div className="rounded p-3 bg-white border-[0.5px] border-[#D7D7D7] mt-4">
        <ul>
          <li className="flex items-center justify-between">
            <span>Level {index + 1}</span>{' '}
            <div className="flex items-center gap-2">
              <Tooltip title="Edit" placement="bottom">
                <img
                  onClick={() => editLevel(item.interview_type, index)}
                  src={small_edit}
                  alt="edit"
                  className="cursor-pointer"
                  width={15}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="bottom">
                <img
                  onClick={() => deleteInterviewLevel(index)}
                  src={small_trash}
                  alt="delete"
                  className="cursor-pointer"
                  width={15}
                />
              </Tooltip>
            </div>
          </li>
          <li>
            <span className="level_leftHeading">Round Name :</span>
            <span className="level_rightContent">
              {item?.round_name ? item?.round_name + ', ' : '-'}
            </span>
          </li>
          <li>
            <span className="level_leftHeading">Interview Type :</span>
            <span className="level_rightContent">
              {' '}
              {prescreeningList &&
                prescreeningList.interview_types?.map((iTypeList) => {
                  if (iTypeList.id == item.interview_type) {
                    //setRadioButtonShow(iTypeList.interview_type_selection_required)
                    return iTypeList.name;
                  }
                })}
            </span>
          </li>
          <li>
            <span className="level_leftHeading">Interviewer :</span>
            <span className="level_rightContent">
              {' '}
              {item.interviewer?.map((data) => {
                return data.first_name + ', ';
              })}
            </span>
          </li>
          <li>
            <span className="level_leftHeading">Email :</span>
            <span className="level_rightContent">
              {' '}
              {item.interviewer?.map((data) => {
                return data.email + ', ';
              })}
            </span>
          </li>
          <li>
            <span className="level_leftHeading">Phone No :</span>
            <span className="level_rightContent">
              {' '}
              {item.interviewer?.map((data) => {
                return data.mobile_number + ', ';
              })}
            </span>
          </li>
          <li>
            <span className="level_leftHeading">Designation :</span>
            <span className="level_rightContent">
              {' '}
              {item.interviewer?.map((data) => {
                return data.designation + ', ';
              })}
            </span>
          </li>
        </ul>
        <ul
          style={{ borderTop: '1px solid lightgray', paddingTop: '2px' }}
        >
          <li className="level_heading">Other Jobs assigned</li>
          <li>
            {item.interviewer?.map((data) => {
              return data.assigned_jobs?.length > 0 ? (
                data.assigned_jobs.map((jobName) => {
                  return jobName + ', ';
                })
              ) : (
                <h6 style={{ color: 'red' }}>No Job Assigned</h6>
              );
            })}{' '}
          </li>
        </ul>
        <ul
          style={{ borderTop: '1px solid lightgray', paddingTop: '2px' }}
        >
          <li className="level_heading">Questionnaire</li>
          <li>
            {' '}
            {questionaireList &&
              questionaireList?.map((iTypeList) => {
                if (iTypeList.id == item.evaluation_questionaire) {
                  return iTypeList.title;
                }
              })}
            {item.evaluation_questionaire === undefined ||
              (item.evaluation_questionaire === null && (
                <h6 style={{ color: 'red' }}>No Questionnaire Assigned</h6>
              ))}
          </li>
        </ul>
      </div>
    );
  };

  function buildBoardCheckbox() {
    if (selectedInterviwer?.length > 1) {
      return (
        <h5>
          {' '}
          <input
            type="checkbox"
            onChange={(e) => handelBoardChange(e)}
            value="board"
            checked={isBoardInterview}
            defaultChecked={
              editInterviewLevel &&
              editInterviewLevel[0]?.is_board_interview
            }
          ></input>
          <span style={{ paddingLeft: '10px' }}>Board</span>
        </h5>
      );
    }
  }

  const interviewLevelAutomatedFieldType = {
    label: isAutoPrescreenSchedulingApplied ? 'Prescreen Grade' : 'Grade',
    // placeholder: isAutoPrescreenSchedulingApplied
    //   ? 'Enter Prescreen Grade'
    //   : 'Allowed Grade Types: A, B, C',
    placeholder: 'Allowed Grade Types: A, B, C',
    name: isAutoPrescreenSchedulingApplied
      ? 'prescreen_score_to_schedule_interview'
      : 'resume_score_to_schedule_interview',
    rules: {
      required: false,
      validate: {
        invalidInput: (value) => {
          const validInputs = ['A', 'B', 'C'];
          if (!validInputs.includes(value)) {
            return 'Permitted Grades: A, B, C';
          } else {
            return true;
          }
        },
      },
    },
  };

  return (
    <>
      {contextHolder}
      <div className="w-full h-[90%] rounded-lg p-2.5 flex border border-[0.5px] border-[#D7D7D7] bg-[#FFF]">
        {props.fields.map((odata, index) => {
          if (
            odata.show &&
            odata.field_heading_code === 'PRESCREEN_PARAMETERS'
          ) {
            return (
              <div className="w-1/2 p-4 border-r border-[#E8E8E8] h-full">
                <h3 className="font-medium">
                  {index + 1}. Prescreening Parameters
                </h3>
                <div className="flex justify-center flex-col h-full">
                  <div className="align-middle text-center justify-center pb-12 mt-5">
                    <img
                      alt=""
                      src={prescreen}
                      className="m-auto object-contain"
                    />
                    <Button
                      className="mr-2"
                      onClick={() => setPrescreeningPreview(true)}
                    >
                      Preview
                    </Button>
                    <Button
                      className="bg-[#1C4980] text-white"
                      onClick={() => setAddPrescreening(true)}
                    >
                      Add
                    </Button>
                    {arePrescreenParametersAvailable && (
                      <>
                        <div className="w-full mt-6 text-black text-sm font-medium">
                          <Checkbox
                            label="Auto Prescreen Schedule"
                            name="schedule_prescreen_initially"
                            control={control}
                            showUpperLabel={false}
                          />
                        </div>
                        <div className="mx-auto w-3/4 mt-2 text-center text-zinc-600 text-xs font-normal">
                          To schedule the prescreen to candidate
                          automatically having the prescreen parameters
                          resume score
                        </div>
                      </>
                    )}
                    {isAutoPrescreenSchedulingApplied && (
                      <div className="mx-auto w-3/4 mt-2">
                        <Input
                          control={control}
                          label={'Grade'}
                          placeholder="Allowed Grade Types: A, B, C"
                          name="resume_score_to_schedule_interview"
                          rules={{
                            required: false,
                            validate: {
                              invalidInput: (value) => {
                                const validInputs = ['A', 'B', 'C'];
                                if (!validInputs.includes(value)) {
                                  return 'Permitted Grades: A, B, C';
                                } else {
                                  return true;
                                }
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          } else if (
            odata.show &&
            odata.field_heading_code === 'INTERVIEWS'
          ) {
            return (
              <div className="w-1/2 p-4">
                <div className="flex items-center justify-between mb-8">
                  <h3 className="font-medium">
                    {index + 1}. Interview Levels
                  </h3>
                  <Tooltip title="Add Level" placement="bottom">
                    <img
                      alt="add level"
                      src={interview_level}
                      className="cursor-pointer"
                      onClick={() => addLevel()}
                    />
                  </Tooltip>
                </div>
                <div className="flex gap-2 h-full">
                  {selectedInterviewLevel?.length > 0 ? (
                    <div className="bg-white w-full h-[60vh] overflow-y-auto customScrollBar">
                      {selectedInterviewLevel?.map(
                        (interview_item, index) => {
                          return interviewLevelCard(interview_item, index);
                        },
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex justify-center items-center flex-col"
                      style={{ width: '100%' }}
                    >
                      <img alt="" src={levelImg} className="" />
                      <p className="text-[#5B6B79] text-center text-xs">
                        Not any Interview Level Added yet!
                      </p>
                      {/* <Button
                      className="bg-blue-500 text-white"
                      onClick={() => addLevel()}
                    >
                      Add Level
                    </Button> */}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
      <div style={{ textAlign: 'end', marginTop: '4px', padding: '10px' }}>
        <Button
          className="cancel"
          style={{ marginRight: '5px' }}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        <Button className="save" onClick={() => addHiringStage()}>
          {!oEditJobResponse?.job_id ? 'Save and Next' : 'Update and Next'}
        </Button>
      </div>

      {/* popups */}

      {isAddLevel && (
        <DialogLayout
          isOpen={isAddLevel}
          strHeaderName=" Add Level"
          isClose={() => {
            setAddLevel(false);
            setInterviewTypeError(false);
            setselectInterviwerError(false);
            setQIdError(false);
          }}
          titleColor="black"
          titleLayoutColor="white"
        >
          <div className="addLevel_div border-t-2">
            <h5>Interview Type </h5>
            <Select
              style={{ width: '100%', textAlign: 'start' }}
              defaultValue={
                editInterviewLevel && editInterviewLevel[0]?.interview_type
              }
              onChange={(e) => changeInterviewType(e)}
              options={
                prescreeningList.interview_types &&
                prescreeningList.interview_types.map((odata) => ({
                  label: odata.name,
                  value: odata.id,
                }))
              }
              placeholder="Select"
            />
            {isRadioButtonShow === true && (
              <div
                style={{
                  display: 'flex',
                  textAlign: 'start',
                  paddingTop: '10px',
                }}
              >
                <input
                  type="radio"
                  name="slot"
                  value="dueDate"
                  onChange={(e) => handelRadioChange(e)}
                  defaultChecked={
                    editInterviewLevel &&
                    editInterviewLevel[0]?.is_due_date_interview_type
                  }
                  //defaultChecked
                ></input>
                <span style={{ marginRight: '15px', paddingLeft: '8px' }}>
                  Due Date
                </span>
                <input
                  type="radio"
                  name="slot"
                  value="slotDate"
                  defaultChecked={
                    editInterviewLevel &&
                    editInterviewLevel[0]?.is_due_date_interview_type ===
                      false
                  }
                  onChange={(e) => handelRadioChange(e)}
                ></input>
                <span style={{ paddingLeft: '8px' }}>Slot</span>
              </div>
            )}
            {interviewTypeError && (
              <p className="validate_message">
                Please Select The Interview Type
              </p>
            )}
            {(isAIInterviewSelecetd ||
              isOnlineTestInterviewSelecetd ||
              (isEditAiInterview && isAIInterviewSelecetd) ||
              (isEditOnlineTest && isOnlineTestInterviewSelecetd)) && (
              <div className="mt-2">
                <h5>
                  {' '}
                  <input
                    type="checkbox"
                    onChange={(e) => handleInterviewRequired(e)}
                    value="interview"
                    checked={isInterviewRequired}
                    defaultChecked={
                      editInterviewLevel &&
                      editInterviewLevel[0]?.is_review_required
                    }
                  ></input>
                  <span style={{ paddingLeft: '10px' }}>
                    Interviewer Review Required{' '}
                  </span>
                </h5>
              </div>
            )}

            <div>
              <h5>Interviewer</h5>
              <Select
                style={{ width: '100%', textAlign: 'start' }}
                options={InterviwerList}
                placeholder="Search for Interviewer"
                mode="multiple"
                showArrow={false}
                notFoundContent={null}
                showSearch={true}
                labelInValue
                filterOption={false}
                onSearch={(e) => {
                  onInterviwerSearch(e);
                }}
                onSelect={(e) => {
                  getSelectedInterviwer(e);
                }}
                onDeselect={(e) => {
                  onDeselect(e);
                }}
                defaultValue={editInterviewLevel && getDefaultList()}
              />
              {selectInterviwerError && (
                <p className="validate_message">
                  Please Select The Interviewer
                </p>
              )}
              {buildBoardCheckbox()}
            </div>

            <h5>Interviewer Evaluation Questionnaire</h5>
            <Select
              showSearch
              style={{
                width: '100%',
                outline: 'none',
                textAlign: 'start',
              }}
              placeholder="Search"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={displyQuestionaireList}
              onSelect={(e) => selectQuestionaire(e)}
              defaultValue={
                editInterviewLevel &&
                editInterviewLevel[0]?.evaluation_questionaire
              }
              disabled={
                isPersonalityTestSelected ||
                (!isInterviewRequired &&
                  (isAIInterviewSelecetd || isOnlineTestInterviewSelecetd))
              }
            />
            {isQIdError && (
              <p className="validate_message">
                Please Select Questionaire
              </p>
            )}

            <div>
              <h5>Round Name</h5>
              <AntInput
                name="round_name"
                value={roundName}
                onChange={(e) => {
                  setRoundName(e.target.value);
                  setRoundNameError(false);
                }}
                placeholder="Enter Round Name"
              />
              {roundNameError && (
                <p className="validate_message">
                  Please Select The Round Name
                </p>
              )}
            </div>

            <div className="w-full mt-8 flex flex-col items-start text-black text-sm font-normal">
              <Checkbox
                control={control}
                showUpperLabel={false}
                name={`schedules.[${isEditFormActive ? editlevelIndex : interviewLevelCount}]`}
                label="Auto Interview Schedule"
              />
            </div>
            {(isFirstLevelBeingAdded || isFirstLevelBeingEdited) && (
              <div className="w-full mt-4">
                <p className="text-zinc-500 text-xs font-normal text-start">
                  To schedule the interview to candidate automatically
                  having the prescreen parameters resume score
                </p>
                <Input
                  control={control}
                  name={interviewLevelAutomatedFieldType.name}
                  label={interviewLevelAutomatedFieldType.label}
                  placeholder={
                    interviewLevelAutomatedFieldType.placeholder
                  }
                  rules={interviewLevelAutomatedFieldType.rules}
                />
              </div>
            )}

            {editInterviewLevel?.length > 0 && (
              <Button
                type="default"
                className="popup_button"
                onClick={() => updateLevel()}
                style={{ marginTop: '30px' }}
              >
                Update
              </Button>
            )}
            {!editInterviewLevel?.length > 0 && (
              <Button
                type="default"
                className="popup_button"
                onClick={() => onAddLevel()}
                style={{ marginTop: '30px' }}
              >
                Submit
              </Button>
            )}
          </div>
        </DialogLayout>
      )}

      {isAddPrescreening && (
        <DialogLayout
          isOpen={isAddPrescreening}
          strHeaderName="Prescreen Parameters"
          isClose={() => {
            if (!arePrescreenParametersAvailable) {
              resettingFields.forEach((field) => resetField(field));
            }
            setAddPrescreening(false);
          }}
          // isFullScreen={"fullScreen"}
          dialogSize="lg"
          titleColor="black"
          titleLayoutColor="white"
        >
          <div
            className="h-fit p-1 mt-2 border-t-2"
            style={{ width: '100%', height: '400px' }}
          >
            <div
              className="pt-3 grid grid-cols-2"
              style={{ width: '100%' }}
            >
              <div
                className="box_border rounded-md p-4 pt-6"
                style={{ width: '95%' }}
              >
                <div
                  className="mb-3 flex flex-wrap"
                  style={{ width: '100%' }}
                >
                  <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      class="h-8 w-full p-4 pl-9 text-sm border border-gray-300 rounded-sm bg-gray-50   dark:placeholder-gray-400 dark:text-white outline-none"
                      placeholder="Search"
                      onChange={(e) => searchPrescreening(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className="mt-2 h-96  overflow-auto rounded-lg"
                  style={{ width: '100%' }}
                >
                  <ul className="p-2">
                    {prescreeningList &&
                      prescreeningList.prescreening_fields
                        ?.filter((fdata) =>
                          fdata?.name
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase()),
                        )
                        ?.map((odata) => {
                          return (
                            <li className="border-b-2 border-dashed border-gray-300 pb-1 pt-1">
                              <div style={{ display: 'flex' }}>
                                <AntCheckbox
                                  className="Pre"
                                  id={odata.id}
                                  //type="checkbox"
                                  value={odata.id}
                                  onChange={(e) =>
                                    handlePrescreeningCheckBox(e, odata)
                                  }
                                  checked={getSelectedPrescreeningId(
                                    odata.id,
                                  )}
                                />

                                <h3
                                  style={{
                                    paddingLeft: '10px',
                                    fontSize: '14px',
                                    fontFamily: 'sans-serif',
                                  }}
                                >
                                  {odata.name}
                                </h3>
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
              <div
                className="box_border rounded-md p-2 pt-6"
                style={{ width: '100%', backgroundColor: '#F0F6F6' }}
              >
                <div
                  className="mb-3 flex flex-wrap"
                  style={{ width: '100%' }}
                >
                  <h5 className="pl-5 font-bold">Selected Prescreen</h5>
                </div>
                <div
                  className="ml-2 h-96 overflow-auto rounded-lg"
                  style={{ width: '100%' }}
                >
                  {selectedQuestions &&
                    selectedQuestions.map((items, index) => {
                      return (
                        <div>{buildQuestionViewLayout(items, index)}</div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="items-center w-fit m-0 float-right">
              <Button
                className="popup_button primary-btn px-1 "
                onClick={() => {
                  handleAssign();
                }}
              >
                Assign
              </Button>
            </div>
          </div>
        </DialogLayout>
      )}

      {isPrescreeningPreview && (
        <DialogLayout
          isOpen={isPrescreeningPreview}
          strHeaderName="Prescreen Parameters"
          isClose={() => setPrescreeningPreview(false)}
          dialogSize="lg"
        >
          <div
            className="h-fit p-1 mt-2"
            style={{ width: '100%', height: '400px' }}
          >
            <div className=" w-full">
              <div className="flex p-2 bg-gray-100 rounded-xl shadow-lg flex-col md:flex-row w-full h-full">
                {/* Image */}
                <div className="w-full md:w-3/12 mt-[30px] mb-[10px]">
                  <img
                    src={preScreen}
                    alt="preScreen Img"
                    className="inset-0 w-full md:h-full h-60 object-cover"
                  />
                </div>

                {/* Form */}
                <div className="w-3/4 mt-[30px] mb-[10px]">
                  <div>
                    <div className="w-48 md:w-full flex-wrap grid  gap-x-[20%] gap-y-[10px] p-4">
                      {selectedQuestions &&
                        selectedQuestions.map((oData, index) => {
                          return (
                            <div>
                              <h5
                                // className="left_Header"
                                className="m-1 text-start font-sans text-sm font-semibold"
                              >
                                {index + 1}. {oData.name}
                              </h5>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogLayout>
      )}

      {isProcessing && <Progress />}
    </>
  );
};

export default HiringStageNew;
