import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { message } from 'antd';

import routes from 'Routes';
import authService from '../../Services/authService';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import LoginDialogHeader from '../../Components/CommonComponents/LoginDialogHeader';

const ConfirmPassword = () => {
  const { handleSubmit, control } = useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const param = useParams();

  const onSubmit = async (data) => {
    const password = data.password;
    const confirmPassword = data.confirm_password;
    if (password === confirmPassword) {
      try {
        const response = await authService.confirmPassword(
          data,
          param.uid,
          param.token,
        );

        const content = response?.data?.data?.message;

        await messageApi.open({
          type: 'success',
          content,
        });
        navigate(routes.LOGIN);
      } catch (error) {
        const errors = error?.response?.data?.error_info?.errors;

        if (Object.hasOwn(errors, 'password')) {
          const message = (
            <div className="flex flex-col justify-center">
              {errors?.password.map((message) => (
                <p>{message}</p>
              ))}
            </div>
          );
          messageApi.open({
            type: 'warning',
            content: message,
            duration: 5,
          });
        } else {
          messageApi.open({
            type: 'error',
            content: errors,
          });
        }
      }
    } else {
      await messageApi.open({
        type: 'warning',
        content: 'Password And Confirm Password are not matching.',
      });
    }
  };

  return (
    <LoginDialogHeader>
      <div className="login">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
            control={control}
            rules={{
              required: 'This field is required',
            }}
          />
          <Input
            type="password"
            placeholder="Password"
            name="confirm_password"
            label="Confirm Password"
            control={control}
            rules={{
              required: 'This field is required',
            }}
          />
          <div className="flex mb-3 pt-2">
            {contextHolder}
            <button type="submit">Reset</button>
          </div>
        </form>
      </div>
    </LoginDialogHeader>
  );
};
export default ConfirmPassword;
