import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import CreateRegistration from './CreateRegistration';
import CreateCommunication from './CreateCommunication';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Subscrption from './Subscrption';
import BranchSettings from './BranchSettings';

const RegistartionDetails = (props) => {
  const handleBack = () => {
    props.close();
  };
  const tabsArray = [
    {
      tabName: 'Basic Details',
      children: (() => {
        return (
          <>
            <CreateRegistration
              companyId={props.compnyId}
              close={props.close}
            />
          </>
        );
      })(),
    },
    {
      tabName: 'Subscription',
      children: (
        <div className="">
          <Subscrption />
        </div>
      ),
    },
    {
      tabName: 'Communication',
      children: <CreateCommunication />,
    },
    {
      tabName: ' Branch Settings',
      children: (
        <div className="">
          <div>
            <BranchSettings companyId={props.compnyId} />
          </div>
        </div>
      ),
    },
  ];
  const tabsItems = tabsArray.map((item, index) => {
    return {
      key: item.tabName,
      label: item.tabName,
      children: item.children,
    };
  });
  return (
    <div className="w-full">
      <button className="flex justify-start" onClick={handleBack}>
        <ArrowBackIcon className="cursor-pointer" />
        <h3 className="text-black text-base font-medium px-2 cursor-pointer">
          Back
        </h3>
      </button>

      <div className="w-full">
        <Tabs
          defaultActiveKey={tabsItems[0]?.label}
          items={tabsItems}
          onChange={(value) => {
            console.log('value', value);
          }}
        />
      </div>
    </div>
  );
};
export default RegistartionDetails;
