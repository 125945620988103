import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import authService from 'Services/authService';
import toast from 'react-hot-toast';

const ResetPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    var strOldPassword = data.old_password;
    var strNewPassword = data.new_password;
    await authService
      .changePassword(data)
      .then(
        (oResponse) => {
          console.log('forgotpassword_oResponse', oResponse);
          toast.success(oResponse.data.data.message);
          props.close(false);
        },
        (error) => {
          console.log('loginError', error);
          const errorMessage = error.response.data.error_info.errors;
          console.log(errorMessage);
          toast.error(errorMessage);
        },
      )
      .catch((err) => {
        console.log(err);
      });
    // }
    // else {
    //     toast.error("Old Password And New Password is not matching")
    // }
  };
  return (
    <div className="pt-35 pb-10">
      <div className="pt-10  w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="w-full px-3 mb-2 flex flex-wrap">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-start">
                Old Password
              </label>
              <input
                type="text"
                {...register('old_password', {
                  required: 'This field is required',
                })}
                className="w-full h-12 flex-shrink-0 rounded-md border border-gray-400 shadow-md"
              />
              {errors && errors.old_password && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.old_password.message}
                </p>
              )}
            </div>
            <div className="w-full px-3 mb-2 flex flex-wrap ">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-start">
                New Password
              </label>
              <input
                type="text"
                {...register('new_password', {
                  required: 'This field is required',
                })}
                className=" w-full h-12 flex-shrink-0 rounded-md border border-gray-400 shadow-md"
              />
              {errors && errors.new_password && (
                <p className="text-red-600 text-xs font-semibold text-start">
                  *{errors.new_password.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-end items-center mt-10">
            <button className="primary-btn px-4 mx-2" type="submit">
              Save
            </button>
          </div>
          {/* <div>
                        <button >Reset</button>
                    </div> */}
          {/* <input
                        placeholder="Password"
                        {...register("password", {
                            required: "Please Enter The Password",
                        })}
                    ></input>
                    {errors && errors.password && (
                        <span className="required">{errors.password.message}</span>
                    )}
                    <input
                        placeholder="ConfirmPassword"
                        {...register("confirm_password", {
                            required: "Please Enter The ConfirmPassword",
                        })}
                    ></input>
                    {errors && errors.confirm_password && (
                        <span className="required">{errors.confirm_password.message}</span>
                    )}
                    <div style={{ display: "flex" }}>
                        {/* <button onClick={() => cancel()}>Cancel</button> */}
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
