import React, { useEffect } from "react";
import preScreen from "../../Assets/images/preScreen.png";
import PreScreenForm from "./PreScreenForm";
import { useParams } from "react-router-dom";
import precreemImage from "../../Assets/prescreenGroup.png"
import ndzLogo from "../../Assets/logo3.png"
import PrescreenService from "../../Services/prescreenService";
import { useState } from "react";

const PreScreenComp = () => {
  let urlData = useParams();
   const [logo, setLogo] = useState()
  const getLogo = async () => {
    await PrescreenService.getCompanyLogo()
      .then((oResponse) => {
       console.log("oResponse",oResponse.data.data);
       setLogo(oResponse.data.data)
        //setSingleSelectValue({...singleSelectValue, [id]:dropDownOPtions(oResponse.data.data.prescreen_fields)})
      })
      .catch((err) => err);
  }
  useEffect(() =>{
    getLogo()
  },[])
  
  return (
    <>
      <div className="w-full h-screen overflow_scroll scrollbar-hide">
        <div className="md:flex md:flex-wrap  md:flex-row flex-col mt-16 mx-16">
          <div className="md:w-2/6 w-full bg-[#ECF5F5] mb-10">
            <div className="mx-10 mt-12">
              <div>
                <img
                  src={logo?.company_logo}
                  alt="ndzlogo"
                 className="w-20"
                />
              </div>
              <div className="flex flex-wrap justify-center items-center mt-20">
                <img
                  src={precreemImage}
                  alt="precreemImage"
                className="2xl:w-3/4 xl:w-full lg:w-full"
                />
              </div>

            </div>
          </div>
          <div className="md:w-4/6 w-full">
            <div className="2xl:mx-24 xl:mx-24 lg:mx-12 md:mx-10">
              <p className="text-gray-700 text-lg font-medium">Please Fill the Details</p>
              <div className="">
                <PreScreenForm urlData={urlData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="flex justify-center items-center">
        <div className="h-screen p-4 w-full">
          <div className="flex p-2 bg-gray-100 rounded-xl shadow-lg flex-col md:flex-row w-full">
            <div className="sm:w-full md:w-3/12 mt-[30px] mb-[10px]">
              <img
                src={preScreen}
                alt="preScreen Img"
                className="inset-0 md:w-full sm:w-1/2 md:h-full sm:h-90 object-cover"
              />
            </div>
           

          </div>
          <div className="p-2"></div>
        </div>
      </section> */}
    </>
  );
};

export default PreScreenComp;
