import Empty from 'Components/CommonComponents/Empty/Empty';
import { useAuthentication } from 'ServiceHooks/authHooks';
import { checkPermissions } from 'Utilities/utils';

const WithAuthentication = (permissionName) => (ComponentPassed, FallbackComponent = null) => {
  console.log('WithAuthentication', permissionName);
  if(!permissionName) throw new Error('Permission name is required, Please check passed permissions');
  const WithAuthenticationComponent = () => {
    const { userDetails } = useAuthentication();
    const hasPermission = checkPermissions(permissionName, userDetails?.permissions || {});
    if(hasPermission)
    {
      return <ComponentPassed/>;
    }
    else
    {
      if(FallbackComponent) return FallbackComponent;
      return (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <Empty
            className="flex item-center justify-center"
            description={(
              <p>You don't have the necessary permission to view this page.</p>
            )}
          />
        </div>
      );
    }
  };
  return WithAuthenticationComponent;
};

export default WithAuthentication;

