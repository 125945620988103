import Button from 'Components/CommonComponents/Button/Button';
import { Image, Modal, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToggle } from 'react-use';
import CandidateExperienceImg from 'Assets/images/candidateExperience.png';
import ExperienceForm from 'Components/Candidate/ExperienceForm';
import { FORM_TYPES } from 'Components/Candidate/ExperienceTab';
import ResumeFormExperienceModal from './ResumeFormExperienceModal';
import dayjs from 'dayjs';

const ResumeFormExperience = (props) => {
  const { experiences, setTemplateData } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [isAddExperienceModalOpen, toggleAddExperienceModal] =
    useToggle(false);
  const [currentEditingExperience, setCurrentEditingExperience] =
    useState();
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    if (!isAddExperienceModalOpen) {
      setCurrentEditingExperience({});
      setEditIndex(null);
    }
  }, [isAddExperienceModalOpen]);

  const RemoveExperience = (index) => {
    setTemplateData((prev) => {
      const experiences = prev?.experiences?.value || [];
      const updatedExperiences = experiences.filter((_, i) => i !== index);
      return {
        ...prev,
        experiences: {
          ...prev.experiences,
          value: updatedExperiences,
        },
      };
    });
  };
  console.log(experiences, 'experiencesexperiences');
  return (
    <div>
      <div className="border rounded-md p-spacing3 w-full">
        <div className="flex flex-row flex-wrap justify-between items-center">
          <div className="text-lg font-medium">Experience</div>
          <Button isSecondary onClick={toggleAddExperienceModal}>
            Add Experience
          </Button>
        </div>
        <section className="experienceSection max-h-[30rem] overflow-x-hidden overflow-y-auto customScrollBar my-4">
          {!isEmpty(experiences) ? (
            <div className="flex flex-wrap -mx-2">
              {experiences?.map((experienceItem, index) => {
                return (
                  <div
                    className="w-full px-2 mb-spacing4 flex flex-row justify-between"
                    key={`experience-item-${index}`}
                  >
                    <div className="flex flex-row items-baseline gap-4">
                      <div className="rounded-full bg-primaryMain h-2 w-2"></div>
                      <div>
                        <h1 className="font-medium text-lg">
                          {experienceItem?.designation}
                        </h1>
                        <p className="font-medium text-base">
                          {experienceItem?.company}
                        </p>
                        <p className="text-[#848484] text-sm">
                          {experienceItem?.worked_from &&
                          dayjs(experienceItem?.worked_from).format(
                            'DD/MM/YYYY',
                          ) !== 'Invalid Date'
                            ? dayjs(experienceItem?.worked_from).format(
                                'DD/MM/YYYY',
                              )
                            : 'No Data'}{' '}
                          -{' '}

                          {experienceItem?.worked_till &&
                          dayjs(experienceItem?.worked_till).format(
                            'DD/MM/YYYY',
                          ) !== 'Invalid Date'
                            ? dayjs(experienceItem?.worked_till).format(
                                'DD/MM/YYYY',
                              )
                            : experienceItem?.worked_from ? 'Present' : ''}
                        </p>
                        <p className="text=[#535353] text-sm">
                          {experienceItem?.responsibilities}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row gap-4">
                      <FiEdit
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={() => {
                          setCurrentEditingExperience(experienceItem);
                          toggleAddExperienceModal();
                          setEditIndex(index);
                        }}
                      />
                      <FiTrash
                        className="text-primaryMain cursor-pointer"
                        size={'1.25rem'}
                        onClick={() => RemoveExperience(index)}
                      />
                      {contextHolder}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <Image
                height={128}
                preview={false}
                alt="no-experience"
                src={CandidateExperienceImg}
              />
              <div className="text=[#656565] text-lg font-light capitalize pb-28">
                Not any Experience Added yet!
              </div>
            </div>
          )}
        </section>
      </div>
      {/* -----------------------  Modal for Add Experience starts ----------------------- */}
      {isAddExperienceModalOpen && (
        <Modal
          className="addExperienceModal"
          width={700}
          open={isAddExperienceModalOpen}
          title={'Add Experience'}
          onCancel={toggleAddExperienceModal}
          footer={null}
          destroyOnClose
        >
          <ResumeFormExperienceModal
            isEditForm={!isEmpty(currentEditingExperience)}
            close={toggleAddExperienceModal}
            setTemplateData={setTemplateData}
            experiences={experiences}
            details={currentEditingExperience}
            editIndex={editIndex}
          />
        </Modal>
      )}
    </div>
  );
};

export default ResumeFormExperience;
