import Carousel from "Components/Carousel";
import { useRef, useState } from "react";
import guidLineOne from "../../Assets/images/interview/online-In.png";
import guidLineTwo from "../../Assets/images/interview/online-2.png";
import InterviewContains from "./InterviewContains";

const GuidlinesScreen = ({interviewInfoData}) => {
  const [isTermsAndConditionAgree, setTermsAndConditionAgree] = useState(true);

  const [isIncludeScreen, setIncludeScreen] = useState(false);

  const carouselEvent = useRef();

  const goToIncludeScreen= ()=>
  {
    setIncludeScreen(true)
  }

  const items = [
    <div className="guidline_item" data-value="1">
      <div>
        <p className="guidlinesText">
          Mobile should have adequate power backup to cover the full interview
          period
        </p>
      </div>
      <div>
        <img src={guidLineOne} alt="img" />
      </div>
    </div>,
    <div className="guidline_item" data-value="2">
      <div>
        <p className="guidlinesText">
        Ensure proper stable internet connectivity. It may impact answering to 
        the questions and may impact your selection process
        </p>
      </div>
      <div>
        <img src={guidLineTwo} alt="img" />
      </div>
    </div>
  ];

  const buildGuidlineLayout = ()=>
  {
    return <div className="guidlines"> <div className="guidlinesHeaderDiv">
            <h1>Guidelines</h1>
          </div>
            <div className="guidlinesContent_div">
              <div style={{ flexGrow: "1", width: "40%" , padding:"20px"}}>
                <Carousel 
                  // autoPlay 
                  // autoPlayInterval={6000} 
                  slideItem={items} 
                  refEvent={carouselEvent}
                />
              </div>
            </div>
            <div className="guidlinesFooterDiv">
              <div className="guidline_btn_div">
                <p>
                  <input
                    type="checkbox"
                    onChange={() => {
                      setTermsAndConditionAgree(!isTermsAndConditionAgree);
                    }}
                  />{" "}
                  I agree to terms and conditions
                </p>
                <button className="goToInterview mt-7" disabled={isTermsAndConditionAgree} onClick={goToIncludeScreen}>
                  Go to Test Screen
                </button>
              </div>
            </div>
            {/* <button onClick={(e) => carouselEvent?.current?.slideNext(e)}>Next</button> */}
        </div>
  }

  return isIncludeScreen ?  <InterviewContains interviewInfoData={interviewInfoData}/> :  buildGuidlineLayout()
};
export default GuidlinesScreen;
