import { useState } from 'react';
import { Card } from 'antd';
import dayjs from 'dayjs';

const format = 'DD_MM_YYYY__HH_mm_ss';

const Test1 = () => {
  const thing = JSON.parse(localStorage.getItem('user'));
  const id = thing?.company_settings?.id;
  const username = thing?.username;
  const [newDateUuid, setNewDateUuid] = useState(dayjs());
  const [uuid, setUuid] = useState(crypto.randomUUID());
  const now = newDateUuid;
  const nowToISO = now.toISOString();
  const nowFormatted = now.format(format);

  return (
    <div className="p-4">
      <Card>
        Now : Dynamic
        <p>ISO : {nowToISO}</p>
        <p>Now : {nowFormatted}</p>
        <p>UID : {`${id}-${username}-${uuid}`}</p>
      </Card>
      <button
        onClick={() => {
          setNewDateUuid(dayjs());
          setUuid(crypto.randomUUID());
        }}
      >
        Click meee
      </button>
    </div>
  );
};

export default Test1;

console.log(dayjs(new Date()).toISOString());
