import { create } from 'zustand';

const EditJobHiringStore = create((set) => ({
  EditJobHiringResponse: '',
  setEditJobHiringResponse: (response) => {
    console.log(response, "setEditJobHiringResponsesetEditJobHiringResponse");
    set(() => ({
        EditJobHiringResponse: response,
    }));
  },
}));

export default EditJobHiringStore;
