import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { message, Spin } from 'antd';
import dayjs from 'dayjs';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import DatePicker from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import RangePicker from 'Components/CommonComponents/ReactHooKForm/RangePicker/RangePicker';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Button from 'Components/CommonComponents/Button/Button';
import 'Styles/commonStyles.scss';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import candidateService from 'Services/candidateService';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';

export default function ResumeFormEducationModal(props) {
  const { isEditForm, close, setTemplateData, qualifications, editIndex } =
    props;
  const params = useParams();
  console.log(props?.details, "props?.details");
  const defaultValues = {
    qualification: isEditForm ? props?.details?.qualification : '',
    college: isEditForm ? props?.details?.college : '',
    isCurrently: isEditForm ? props?.details?.isCurrently || (props?.details?.course_start_date && !props?.details?.course_completed_date) : false,
    course_start_date: isEditForm
      ? dayjs(props?.details?.course_start_date)
      : '',
    course_completed_date: isEditForm && props?.details?.course_completed_date && props?.details?.course_completed_date !== "Present"
      ? [
          dayjs(props?.details?.course_start_date),
          dayjs(props?.details?.course_completed_date),
        ]
      : [],
  };


  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  const isCurrently = useWatch({ control, name: 'isCurrently' });
  const [messageApi, contextHolder] = message.useMessage();

  const onSubmit = async (fields) => {
    try {
      const {
        qualification,
        college,
        isCurrently,
        responsibilities,
      } = fields;

      const data = {
        candidate: params?.candidateId,
        qualification,
        college,
        isCurrently,
        responsibilities,
      };

      if (isCurrently) {
        data['course_start_date'] = dayjs(fields.course_start_date).format('DD/MM/YYYY');
        data['course_start_date'] = "Present"
      } else {
        data['course_start_date'] = dayjs(fields.course_completed_date[0]).format('DD/MM/YYYY');
        data['course_completed_date'] = dayjs(fields.course_completed_date[1]).format('DD/MM/YYYY');
      }

      if (isEditForm) {
        console.log(data, "dataqualifications", qualifications);
        qualifications[editIndex]['qualification'] = data?.qualification;
        qualifications[editIndex]['college'] = data?.college;
        qualifications[editIndex]['isCurrently'] = data?.isCurrently;
        qualifications[editIndex]['course_start_date'] = data?.course_start_date;
        qualifications[editIndex]['course_completed_date'] = data?.course_completed_date? data?.course_completed_date :"Present" ;
        setTemplateData((prev) => {
          return {
            ...prev,
            qualifications: { value: qualifications },
          };
        });
      } else {
        setTemplateData((prev) => {
          return {
            ...prev,
            qualifications: {
              value: [...prev?.qualifications?.value, data],
            },
          };
        });
      }

      close();
    } catch (error) {
      console.error('Submission Error', error);
    }
  };

  return (
    <div className="w-full px-10 pt-8">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="w-full">
          <Input
            label="qualification"
            name="qualification"
            control={control}
            rules={{
              required: 'This Field is Required',
              //   pattern: {
              //     value: /[a-zA-Z]/g,
              //     message: 'Invalid Company Name',
              //   },
            }}
            placeholder="qualification"
          />
        </div>
        <div className="w-full">
          <Input
            label="college"
            name="college"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              //   pattern: {
              //     value: /[a-zA-Z]/g,
              //     message: 'Please enter letters only',
              //   },
            }}
          />
        </div>

        <div className="w-full">
          <Checkbox
            label="Currently Studying?"
            name="isCurrently"
            control={control}
            showUpperLabel={false}
          />
        </div>
        <div className="w-full">
          {isCurrently ? (
            <DatePicker
              label="Starting Date"
              name="course_start_date"
              control={control}
              noTextInput
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
              format="DD/MM/YYYY"
            />
          ) : (
            <RangePicker
              label="End Date"
              name="course_completed_date"
              control={control}
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
              format="DD/MM/YYYY"
            />
          )}
        </div>

        <div className="flex ml-auto mt-4 gap-4">
          {contextHolder}
          <Button
            isCancel
            htmlType="submit"
            disabled={false}
            children="Cancel"
            onClick={close}
          />
          <Button type="primary" htmlType="submit" disabled={false}>
            Add
          </Button>
        </div>
      </form>
    </div>
  );
}
