import { useMutation, useQuery } from '@tanstack/react-query';
import settingService from 'Services/settingsService';
import interviewPipeLineService from 'Services/interviewPipeline';
import { transformEventsWithStylingData, transformEventsWithLogicDetails } from 'Utilities/componentSpecificUtilities';
import { DEFAULT_QUERY_ARGS } from 'Utilities/constants';

/* 
  For getting full events
*/
export const CANDIDATE_SLOT_QUERY_NAME = 'candidateList';
const useGetEvents = (config = DEFAULT_QUERY_ARGS) => {
  const { 
    params,
    ...queryProps
  } = config;
  const query = useQuery({
    queryKey: ['eventList', params.rangeInfo],
    queryFn: async () => {
      const { 
        rangeInfo : { 
          startDate,
          endDate,
          user_id
        } 
      } = params;
      const details = await settingService.getEvents({startDate, endDate, user_id});
      const eventsList =  details?.data?.data;
      const transformedSlotsWithStylingData = transformEventsWithStylingData(eventsList.slots);
      return {
        ...eventsList,
        slots : transformEventsWithLogicDetails(transformedSlotsWithStylingData)
      };
    },
    refetchOnWindowFocus : false,
    ...queryProps
  });
  return query;
};

const useGetCandidateSlots = (config = DEFAULT_QUERY_ARGS) => {
  const { 
    params,
    ...queryProps
  } = config;
  const query = useQuery({
    queryKey: [CANDIDATE_SLOT_QUERY_NAME, params.token],
    queryFn: async () => {
      const { 
        token
      } = params;
      const details = await interviewPipeLineService.getAvailableSlotsForCandidate(token);
      const slotsList =  details?.data?.data;
      return slotsList;
    },
    refetchOnWindowFocus : false,
    ...queryProps
  });
  return query;
};

const useSetCandidateChosenSlot = (config = DEFAULT_QUERY_ARGS) => {
  const { 
    onError = () => null, 
    onSuccess = () => null,
  } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const response = await interviewPipeLineService.setCandidateChosenSlot(params);
      return response;
    },
    onError,
    onSuccess,
  });
  return mutation;
};



export {
  useGetEvents,
  useGetCandidateSlots,
  useSetCandidateChosenSlot,
};