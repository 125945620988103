import {
  CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES,
  INTERVIEW_TAB_TYPES,
} from 'Utilities/constants';
import { create } from 'zustand';
import { shared } from 'use-broadcast-ts';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { tableColumn } from 'pages/Hr/Jobs/components';

export const usePersistedStore = create(
  immer(
    shared(
      devtools(
        persist(
          (set, get) => {
            return {
              createdQuestionnaireFormData: null,
              setCreatedQuestionnaireFormData: (value) =>
                set(
                  (state) => {
                    state.createdQuestionnaireFormData = value;
                  },
                  false,
                  'questionnaire/set-form-data',
                ),
              clearCreatedQuestionnaireFormData: () =>
                set(
                  (state) => {
                    state.createdQuestionnaireFormData = null;
                  },
                  false,
                  'questionnaire/clear-form-data',
                ),
              currentQuestionnaireFormState: [
                CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES.NONE,
              ],
              setCurrentQuestionnaireFormState: (value) =>
                set(
                  (state) => {
                    const existingPhases =
                      state.currentQuestionnaireFormState;
                    const mergedState = [...existingPhases, value].slice(
                      -5,
                    );
                    state.currentQuestionnaireFormState = mergedState;
                  },
                  false,
                  'questionnaire/set-form-state',
                ),
              clearCurrentQuestionnaireFormState: (value) =>
                set(
                  (state) => {
                    state.currentQuestionnaireFormState = [
                      CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES.NONE,
                    ];
                  },
                  false,
                  'questionnaire/clear-form-state',
                ),
              createdQuestions: [],
              setCreatedQuestions: (value) =>
                set(
                  (state) => {
                    state.createdQuestions = value;
                  },
                  false,
                  'questionnaire/add-created-questions',
                ),
              appendCreatedQuestions: (questions) =>
                set(
                  (state) => {
                    const existingQuestions = state.createdQuestions;
                    questions.forEach((newQuestion, index) => {
                      const existingCategory = existingQuestions?.some(
                        (item) =>
                          item.question_type === newQuestion.question_type,
                      );
                      if (existingCategory) {
                        const splicingIndex = existingQuestions.findIndex(
                          (item) =>
                            item.question_type ===
                            newQuestion.question_type,
                        );
                        state.createdQuestions[splicingIndex].questions =
                          existingQuestions[
                            splicingIndex
                          ].questions.concat(newQuestion.questions);
                      } else {
                        state.createdQuestions.push(newQuestion);
                      }
                    });
                  },
                  false,
                  'questionnaire/append-new-questions',
                ),
              clearCreatedQuestions: () =>
                set(
                  (state) => {
                    state.createdQuestions = null;
                  },
                  false,
                  'questionnaire/clear-questions',
                ),
              currentInterviewerTab: INTERVIEW_TAB_TYPES.MY_AVAILABILITY,
              setCurrentInterviewerTab: (value) =>
                set(
                  (state) => {
                    state.currentInterviewerTab = value;
                  },
                  false,
                  'redirect/set-last-active-interviewer-tab',
                ),
              resetCurrentInterviewerTab: () =>
                set(
                  (state) => {
                    state.currentInterviewerTab =
                      INTERVIEW_TAB_TYPES.MY_AVAILABILITY;
                  },
                  false,
                  'redirect/set-default-interviewer-tab',
                ),
              lastActiveJobTab: '',
              setLastActiveJobTab: (value) =>
                set(
                  (state) => {
                    state.lastActiveJobTab = value;
                  },
                  false,
                  'redirect/set-last-active-job-tab',
                ),
              clearLastActiveJobTab: (value) =>
                set(
                  (state) => {
                    state.lastActiveJobTab = '';
                  },
                  false,
                  'redirect/clear-last-active-job-tab',
                ),
              lastActiveCandidateTab: '',
              setLastActiveCandidateTab: (value) =>
                set(
                  (state) => {
                    state.lastActiveCandidateTab = value;
                  },
                  false,
                  'redirect/set-last-active-candidate-tab',
                ),
              clearLastActiveCandidateTab: (value) =>
                set(
                  (state) => {
                    state.lastActiveCandidateTab = '';
                  },
                  false,
                  'redirect/clear-last-active-candidate-tab',
                ),
              currentAiReviewPageStep: 0,
              setCurrentAiReviewPageStep: (value) =>
                set(
                  (state) => {
                    state.currentAiReviewPageStep = value;
                  },
                  false,
                  'ai-review/current-step',
                ),
              personalityReport: {},
              setPersonalityReport: (value) =>
                set(
                  (state) => {
                    state.personalityReport = value;
                  },
                  false,
                  'personality-report/set-report',
                ),
              createdQuestionBankFormData: null,
              setCreatedQuestionBankFormData: (value) =>
                set(
                  (state) => {
                    state.createdQuestionBankFormData = value;
                  },
                  false,
                  'create-questionBank/form-data',
                ),
              setCreatedQuestionCount: (value) =>
                set(
                  (state) => {
                    state.createdQuestionBankFormData.questions_count.allocated =
                      value;
                  },
                  false,
                  'create-questionBank/set-allocated-question-count',
                ),
              questionBankFormData: null,
              setQuestionBankFormData: (value) =>
                set(
                  (state) => {
                    state.questionBankFormData = value;
                  },
                  false,
                  'create-questionBank/form-data',
                ),
              currentQuestionBankData: {},
              setCurrentQuestionBankData: (value) =>
                set(
                  (state) => {
                    state.currentQuestionBankData = value;
                  },
                  false,
                  'view-question-bank/set-bank-data',
                ),
              clearCurrentQuestionBankData: (value) =>
                set(
                  (state) => {
                    state.currentQuestionBankData = {};
                  },
                  false,
                  'view-question-bank/set-bank-data',
                ),
              job_columns: {},
              selectedCandidates: null,
              setSelectedCandidates: (value) =>
                set(
                  (state) => {
                    state.selectedCandidates = value;
                  },
                  false,
                  'view-job/choose-candidate',
                ),
              clearSelectedCandidates: (value) =>
                set(
                  (state) => {
                    state.selectedCandidates = null;
                  },
                  false,
                  'view-job/clear-selected-candidate',
                ),
              setGridJobColumns: (value, name) =>
                set(
                  (state) => {
                    state.job_columns[name] = value ? value : tableColumn;
                  },
                  false,
                  'grid/columns',
                ),
              setGridJobAllColumns: (value) =>
                set(
                  (state) => {
                    state.job_columns = value;
                  },
                  false,
                  'grid/columns',
                ),

                currentPanel: ['1'],
                setMetricPanel: (value) =>
                  set(
                    (state) => {
                      state.currentPanel = value;
                    },
                    false,
                    'metric/panel',
                  ),
            };
          },
          {
            name: 'yvi-persisted', // name of the item in the storage (must be unique)
          },
        ),
      ),
      {
        name: 'persisted-channel', // broadcast channel name
      },
    ),
  ),
);
