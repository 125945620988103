import React from 'react';
import { useController } from 'react-hook-form';
import { AutoComplete, Form, Spin } from 'antd';

const Autocomplete = ({
  label,
  disabled = false,
  // hook form specific
  name,
  control,
  fetching,
  defaultValue,
  rules = {},
  showUpperLabel = true,
  onChange: customOnChange,
  options = [],
  onInputSearch,
  onSelectOption,
  placeholder,
  ...others
}) => {
  const {
    field: { ref, value, onChange: hookFormOnChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  const onChange = (e) => {
    if (customOnChange) {
      customOnChange(e, hookFormOnChange);
    } else {
      hookFormOnChange(e);
    }
  };

  return (
    <Form.Item
      label={showUpperLabel ? label : null}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
      status={status}
    >
      <AutoComplete
        value={value}
        options={options}
        onSelect={onSelectOption}
        onSearch={onInputSearch}
        onChange={onChange}
        placeholder={placeholder}
        control={control}
        notFoundContent={
          fetching ? (
            <div className="flex justify-center py-5">
              <Spin size="small" />
            </div>
          ) : null
        }
        {...others}
      />
    </Form.Item>
  );
};

export default Autocomplete;
