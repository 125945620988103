import CountryCodeInput from 'Components/CommonComponents/Input/CountryCodeInput';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import TextEdit from 'Components/CommonComponents/TextEdit';
import {
  useDownloadPdfResume,
  useGetResumeTemplatesData,
} from 'ServiceHooks/resumeBuilderHooks';
import candidateService from 'Services/candidateService';
import {
  cleanHtmlString,
  getSafeHtml,
  resume_demo_datas,
} from 'Utilities/ResumeBuilder/constants';
import { CANDIDATE_DETAILS_TAB_KEYS } from 'Utilities/constants';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState, isValidElement } from 'react';
import { useForm } from 'react-hook-form';
import ResumeFormProfile from './ResumeFormProfile';
import ResumeFormExperience from './ResumeFormExperience';
import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import { Button, Skeleton } from 'antd';
import ResumeFormProjects from './ResumeFormProjects';
import { DownloadOutlined } from '@ant-design/icons';
import ResumeFormEducation from './ResumeFormEducation';
import RequiredFieldForm from './RequiredFieldForm';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ResumeBuilderService from 'Services/resumeBuilderService';
import axios from 'axios';
import Config from 'Services/Config';

const ResumeForm = ({
  template,
  title,
  fields,
  candidate_id,
  template_id,
}) => {
  const contentRef = useRef(null);
  const [enable, setEnable] = useState(true);
  // const data = resume_demo_datas
  // const isLoading = false
  const { data, isLoading, error, isFetching } = useGetResumeTemplatesData(
    {
      params: {
        candidate_id,
        template_id,
      },
      enabled: enable,
      cacheTime: 0,
    },
  );

  const [resume_templates_data, setTemplateData] = useState();
  const [required_fields, setRequiredFields] = useState([]);
  const [templateFields, setTemplateFields] = useState([]);

  useEffect(() => {
    console.log(error, 'dataDATA', data);
    if (data) {
      setTemplateData(data);
      const temp_fields = Object.keys(data);
      setTemplateFields(temp_fields);
      setRequiredFields([]);
    } else if (error?.response?.data?.error_info) {
      setEnable(false);
      const req_fields = Object.keys(error?.response?.data?.error_info);
      setRequiredFields(req_fields);
    }
  }, [data, error]);

  const downloadPdf = async (htmlCont) => {
    const formData = new FormData();
    formData.append('html', htmlCont);
    // const data = {
    //       html: htmlCont
    //     }
    try {
      const response =
        await ResumeBuilderService.getExportResumePdf(formData);
      // const response = await axios.post(Config.API_URL +
      //   'api/v1/jobs/convert-to-pdf/', formData, {
      //   responseType: 'blob',
      // });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resume.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF', error);
    }
  };


  // const downloadPdf = async (htmlCont) => {
  //   console.log(htmlCont, 'htmlConthtmlCont');
  //   const content = contentRef.current;
  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   const imgWidth = 210; // A4 width in mm
  //   const pageHeight = 290; // A4 height in mm
  //   const scale = 3; // Canvas scale factor for better quality

  //   // Calculate the total height of the content
  //   const totalHeight = content.scrollHeight;

  //   // Initialize the remaining height of content to be captured
  //   let remainingHeight = totalHeight;

  //   for (let pageNum = 0; remainingHeight > 0; pageNum++) {
  //     // Calculate the viewport section to capture
  //     const viewportTop = pageNum * pageHeight;

  //     // Scroll to the correct position
  //     content.scrollTop = viewportTop;

  //     // Allow time for the scroll to complete and the DOM to render
  //     await new Promise((resolve) => setTimeout(resolve, 500));

  //     // Capture the visible part of the content
  //     const canvas = await html2canvas(content, {
  //       scale: scale,
  //       useCORS: true, // Enable cross-origin images
  //       width: imgWidth * scale,
  //       height: pageHeight * scale,
  //       x: 0,
  //       y: viewportTop, // Adjust the Y coordinate
  //     });

  //     const imgData = canvas.toDataURL('image/png');
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //     // Add the image to the PDF
  //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

  //     // Update the remaining height
  //     remainingHeight -= pageHeight;

  //     // Add a new page if there is more content to capture
  //     if (remainingHeight > 0) {
  //       pdf.addPage();
  //     }
  //   }

  //   // Save the PDF
  //   pdf.save('download.pdf');

  //   // const data = {
  //   //   html: htmlCont
  //   // }
  //   // const response = await ResumeBuilderService.getExportResumePdf(data);
  //   // console.log(response?.data,"responseresponse");
  //   // await exportResumePdf(data)
  //   // const input = contentRef.current;
  //   // html2canvas(input).then((canvas) => {
  //   //   const imgData = canvas.toDataURL('image/png');
  //   //   const pdf = new jsPDF('p', 'px', 'a4');
  //   //   const imgWidth = pdf.internal.pageSize.getWidth();
  //   //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   //   pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //   //   pdf.save('resume.pdf');
  //   // });
  // };

  const tabsArray = [
    {
      key: CANDIDATE_DETAILS_TAB_KEYS.PROFILE,
      tabName: 'Profile',
      children: (
        <ResumeFormProfile
          setTemplateData={setTemplateData}
          resume_templates_data={data}
          templateFields={templateFields}
        />
      ),
      enable: true,
    },
    {
      key: CANDIDATE_DETAILS_TAB_KEYS.EXPERIENCE,
      tabName: 'Experience',
      children: (
        <ResumeFormExperience
          // candidateId={id}
          // projects={projects}
          experiences={resume_templates_data?.experiences?.value}
          setTemplateData={setTemplateData}
        />
      ),
      enable: templateFields.includes('experiences'),
    },
    {
      key: 'projects',
      tabName: 'Projects',
      children: (
        <ResumeFormProjects
          // candidateId={id}
          // projects={projects}
          projects={resume_templates_data?.projects?.value}
          setTemplateData={setTemplateData}
        />
      ),
      enable: templateFields.includes('projects'),
    },
    {
      key: 'educations',
      tabName: 'Educations',
      children: (
        <ResumeFormEducation
          // candidateId={id}
          // projects={projects}
          qualifications={resume_templates_data?.qualifications?.value}
          setTemplateData={setTemplateData}
        />
      ),
      enable: templateFields.includes('qualifications'),
    },
  ].filter((item) => !isEmpty(item) && item.enable === true);

  console.log(templateFields, 'templateFields', required_fields);

  const tabsItems = tabsArray.map((item) => {
    return {
      key: item.key,
      label: item.tabName,
      children: item.children,
    };
  });

  return (
    <>
      {required_fields?.length && (!isLoading || !isFetching) ? (
        <RequiredFieldForm
          fields={required_fields}
          candidate_id={candidate_id}
          setEnable={setEnable}
          setRequiredFields={setRequiredFields}
        />
      ) : (
        <div className="flex w-full">
          <div className="lg:w-[50%] w-full">
            <p className="mb-2">{title}</p>
            {isLoading || isFetching ? (
              <Skeleton
                active
                paragraph={{ rows: 20, width: '100%' }}
                shape="square"
                className="w-full rounded-md h-full p-4"
              />
            ) : (
              <>
                <div
                  ref={contentRef}
                  className="w-full bg-[#fff] cursor-pointer border border-gray-200 max-h-[90vh] h-[90vh] overflow-y-auto customScrollBar"
                  dangerouslySetInnerHTML={{
                    __html: getSafeHtml(template, resume_templates_data),
                  }}
                />
                <div className="w-full flex items-center justify-end">
                  <Button
                    onClick={() =>
                      downloadPdf(
                        getSafeHtml(template, resume_templates_data),
                      )
                    }
                    className="mt-2"
                    type="primary"
                    icon={<DownloadOutlined />}
                    size={'medium'}
                  >
                    Download
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="p-10 lg:w-[48%] w-full">
            <Tabs
              items={tabsItems}
              // activeKey={infoTabName}
              defaultActiveKey={CANDIDATE_DETAILS_TAB_KEYS.PROFILE}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ResumeForm;
