import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogContent } from "@mui/material";
import { Spin } from "antd";

const Progress = (props) => {
  return (
    <Dialog
      open={true}
      sx={{
        "& .MuiBackdrop-root": {
          background: "transparent",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent>
        {props.circelProgress ? (
          <>
            <div>
              <p
                style={{ color: "red", fontSize: "large", fontWeight: "500" }}
              >
                Capturing Image Please Wait
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={70}></CircularProgress>
            </div>
          
          </>
        ) : (
          <Spin tip="Loading" size="large"></Spin>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Progress;
