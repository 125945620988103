import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import iconAccordion from 'Assets/icons/iconAccordion.svg';
import './Accordion.scss';

const Accordion = ({
  accordion = false,
  className = '',
  children,
  expandIconPosition = 'end',
  defaultActiveKey = ['1'],
  expandIcon = ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />,
  bordered = false,
  useDefaultTheme = false,
  isActive,
  ...others
}) => {


  const expandIconDetails = useDefaultTheme ? ({ isActive }) => {
    return (
      <div className="customExpandIcon">
        {
          isActive
            ?
            <img src={iconAccordion} alt="accordion" className="rotate-180"/>
            :
            <img src={iconAccordion} alt="accordion" />
        }
      </div>
    );
  } : expandIcon
  return (
    <Collapse
      accordion={accordion}
      bordered={bordered}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition={expandIconPosition}
      className={`yviAccordion customAccordion ${className}`}
      expandIcon={expandIconDetails}
      {...others}
    >
      {children && children()}
    </Collapse>
  );
};


export default Accordion