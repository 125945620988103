import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { message, Spin } from 'antd';
import dayjs from 'dayjs';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import DatePicker from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import RangePicker from 'Components/CommonComponents/ReactHooKForm/RangePicker/RangePicker';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Button from 'Components/CommonComponents/Button/Button';
import 'Styles/commonStyles.scss';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import { removeHtmlTags } from 'Utilities/ResumeBuilder/constants';

export default function ResumeFormProjectModal(props) {
  const { isEditForm, close,setTemplateData, projects,editIndex } = props;
  const params = useParams();

  console.log(props?.details,"props?.details");
  const defaultValues = {
    company: isEditForm ? props?.details?.company : '',
    project_name: isEditForm ? props?.details?.project_name : '',
    designation: isEditForm ? props?.details?.designation : '',
    isCurrentProject: isEditForm
      ? props?.details?.is_current_project
      : false,
    workedFrom: isEditForm ? dayjs(props?.details?.worked_from) : '',
    duration: isEditForm
      ? [
          dayjs(props?.details?.worked_from),
          dayjs(props?.details?.worked_till),
        ]
      : [],
    responsibility: isEditForm ? removeHtmlTags(props?.details?.responsibility) : '',
  };

  const {
    handleSubmit,
    control,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'all',
  });



  const isCurrentProject = useWatch({ control, name: 'isCurrentProject' });
  const [messageApi, contextHolder] = message.useMessage();


  const onSubmit = async (fields) => {
    try {
      const { company,project_name, designation, isCurrentProject, responsibility } =
        fields;

      const data = {
        candidate: params?.candidateId,
        company,
        project_name,
        designation,
        is_current_company: isCurrentProject,
        responsibility,
      };

      if (isCurrentProject) {
        data['worked_from'] = dayjs(fields.workedFrom).format(
          'YYYY-MM-DD',
        );
      } else {
        data['worked_from'] = dayjs(fields.duration[0]).format(
          'YYYY-MM-DD',
        );
        data['worked_till'] = dayjs(fields.duration[1]).format(
          'YYYY-MM-DD',
        );
      }
      
      if(isEditForm){
        projects[editIndex]["project_name"] = data?.project_name
        projects[editIndex]["responsibility"] = data?.responsibility
        setTemplateData((prev)=> {
          return {
            ...prev,
            projects:{value:projects}
          }
        })
      }else {
        setTemplateData((prev)=> {
          return {
            ...prev,
            projects:{value:[...prev?.projects?.value,data]}
          }
        })
      }
      
      close()
    } catch (error) {
      console.error('Submission Error', error);
    }
  };


  return (
    <div className="w-full px-10 pt-8">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        {/* <div className="w-full">
          <Input
            label="Company"
            name="company"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Invalid Company Name',
              },
            }}
            placeholder="Name"
          />
        </div> */}
        {/* <div className="w-full">
          <Input
            label="Designation"
            name="designation"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Please enter letters only',
              },
            }}
          />
        </div> */}
        <div className="w-full">
          <Input
            label="Project"
            name="project_name"
            placeholder="Title/Role Name"
            control={control}
            rules={{
              required: 'This Field is Required',
              pattern: {
                value: /[a-zA-Z]/g,
                message: 'Please enter letters only',
              },
            }}
          />
        </div>
        {/* <div className="w-full">
          <Checkbox
            label="Current Project?"
            name="isCurrentProject"
            control={control}
            showUpperLabel={false}
          />
        </div>
        <div className="w-full">
          {isCurrentProject ? (
            <DatePicker
              label="Starting Date"
              name="workedFrom"
              control={control}
              noTextInput
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
            />
          ) : (
            <RangePicker
              label="Duration"
              name="duration"
              control={control}
              rules={{
                required: 'Please Choose a Date',
              }}
              disableFutureDays
            />
          )}
        </div> */}
        <div>
          <div>Responsibility</div>
          <TextArea
            rows={4}
            name="responsibility"
            control={control}
            className="h-[250px]"
            rules={{
            //   required: 'This field is required',
            }}
          />
        </div>

        <div className="flex ml-auto mt-4 gap-4">
          {contextHolder}
          <Button
            isCancel
            htmlType="submit"
            disabled={false}
            children="Cancel"
            onClick={close}
          />
          <Button type="primary" htmlType="submit" disabled={false}>
            Add
          </Button>
        </div>
      </form>
    </div>
  );
}
