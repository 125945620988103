import { Component } from "react";
import { BiSolidError } from "react-icons/bi";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-screen w-screen flex items-center justify-center">
          <div className="errorMsgWrapper px-4">
            <BiSolidError className="errorIcon h-auto d-block ml-auto mr-auto mb-4 text-red-600" style={{ width : '50px'}}/>
            <h1>Something went wrong, Please refresh your page or try again after some time. </h1>
          </div>
        </div>
      )
    }

    return this.props.children; 
  }
}


export default ErrorBoundary