import React from 'react'
import { Form, Space, Switch as AntSwitch } from 'antd';
import { useController } from 'react-hook-form';
import './Switch.scss'


const Switch = ({
    label,
    // hook form specific
    name, control, defaultValue, rules = {},
    onText="on",
    offText="off",
}) => {
  const {
    field: { ref, value,  onChange },
    fieldState : { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <Form.Item 
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
      status={status}
    >
       <Space direction="vertical" className='customSwitch'>
        <AntSwitch 
          checkedChildren={onText}
          unCheckedChildren={offText}
          checked={value}
          onChange={onChange}
          ref={ref}
        />
      </Space>
    </Form.Item>
  )
}

export default Switch