import AddJobNew from "./AddJobNew";

const EditJob = () => {
  return (
    <>
      <AddJobNew />
    </>
  );
};

export default EditJob;
