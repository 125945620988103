export const ConfirmedField = ({ title, content }) => (
  <div className="mt-1 mb-6 md:w-full sm:w-1/2 px-2 font-normal text-base">
    {title ?? ''}
    <div className="mt-1 px-3 py-2 border rounded-md border-[#A9A9A96B] bg-[#FBFBFB]">
      {content ?? ''}
    </div>
  </div>
);

export const questionnaireTableColumnConfig = [
  {
    fieldID: 'job_code',
    headerName: 'Job Code',
  },
  {
    fieldID: 'job_title',
    headerName: 'Job Name',
  },
  {
    fieldID: 'interview_level',
    headerName: 'Interview Level',
  },
  {
    fieldID: 'major_skill',
    headerName: 'Major Skill',
  },
  {
    fieldID: 'minor_skill',
    headerName: 'Minor Skill',
  },
  {
    fieldID: 'alloted_questions',
    headerName: 'Allotted No. of Questions',
  },
  {
    fieldID: 'total_created_questions',
    headerName: 'Total No. of Questions',
  },
  {
    fieldID: 'total_essay_questions',
    headerName: 'No. of Essay Questions',
  },
  {
    fieldID: 'total_single_choice_questions',
    headerName: 'No. of Multiple Choice Questions',
  },
  {
    fieldID: 'total_mcq_questions',
    headerName: 'No. of Multi Response Questions',
  },
  {
    fieldID: 'source',
    headerName: 'Source',
  },
  {
    fieldID: 'created_date_formatted',
    headerName: 'Created',
  },
  {
    fieldID: 'updated_date_formatted',
    headerName: 'Updated',
  },
];

export const questionnaireColumns = [
  {
    width: 200,
    title: 'Job Code',
    dataIndex: 'job_code',
    key: 'job_code',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'Job Name',
    dataIndex: 'job_title',
    key: 'job_title',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Interview Level',
    dataIndex: 'interview_level',
    key: 'interview_level',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 200,
    title: 'Major Skill',
    dataIndex: 'major_skill',
    key: 'major_skill',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    fixed: '',
  },
  {
    width: 200,
    title: 'Minor Skill',
    dataIndex: 'minor_skill',
    key: 'minor_skill',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
    fixed: '',
  },
  {
    width: 200,
    title: 'Total No. of Questions',
    dataIndex: 'total_created_questions',
    key: 'total_created_questions',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
    fixed: '',
  },
  {
    width: 200,
    title: 'Alloted No. of Qestions',
    dataIndex: 'alloted_questions',
    key: 'alloted_questions',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
    fixed: '',
  },

  {
    width: 200,
    title: 'No. of Multiple Choice Questions',
    dataIndex: 'total_single_choice_questions',
    key: 'total_single_choice_questions',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
    fixed: '',
  },
  {
    width: 200,
    title: 'No. of Multi Response Questions',
    dataIndex: 'total_mcq_questions',
    key: 'total_mcq_questions',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 8,
    fixed: '',
  },
  {
    width: 200,
    title: 'No. of Essay Questions',
    dataIndex: 'total_essay_questions',
    key: 'total_essay_questions',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 9,
    fixed: '',
  },
  {
    width: 200,
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 10,
    fixed: '',
  },
  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created_date_formatted',
    key: 'created_date_formatted',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 11,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 12,
    className: 'ant-table-column-action',
  },
];
