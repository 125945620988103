import CountryCodeInput from 'Components/CommonComponents/Input/CountryCodeInput';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import TextArea from 'Components/CommonComponents/ReactHooKForm/TextArea/TextArea';
import TextEdit from 'Components/CommonComponents/TextEdit';
import { useGetKnownLanguages } from 'ServiceHooks/candidatesHooks';
import { useGetResumeTemplatesData } from 'ServiceHooks/resumeBuilderHooks';
import candidateService from 'Services/candidateService';
import { getSafeHtml } from 'Utilities/ResumeBuilder/constants';
import { CANDIDATE_DETAILS_TAB_KEYS } from 'Utilities/constants';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const ResumeFormProfile = ({
  isLoading,
  setTemplateData,
  resume_templates_data,
  templateFields,
}) => {
  const {
    clearErrors,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });

  useEffect(() => {
    console.log(resume_templates_data,"resume_templates_dataresume_templates_data");
    if (resume_templates_data) {
      setValue(
        'first_name.value',
        resume_templates_data?.first_name?.value,
      );
      setValue('last_name.value', resume_templates_data?.last_name?.value);
      setValue('email.value', resume_templates_data?.email?.value);
      setValue(
        'mobile_number.value',
        resume_templates_data?.mobile_number?.value,
      );
      setValue(
        'location.value',
        resume_templates_data?.location?.value,
      );
      setValue(
        'designation.value',
        resume_templates_data?.designation?.value,
      );
      setValue('skills.value', resume_templates_data?.skills?.value);
      setValue('languages.value', resume_templates_data?.languages?.value);
      setValue(
        'qualifications.value',
        resume_templates_data?.qualifications?.value,
      );
      setValue('about_me.value', resume_templates_data?.about_me?.value);
    }
  }, [resume_templates_data]);

  useEffect(() => {
    handleChange();
  }, [watch('skills')?.value, watch('languages')?.value]);

  const [skillsList, setSkillsList] = useState();
  const [languagesSearch, setLanguagesSearch] = useState('');
  const [qualificationList, setQualificationList] = useState();

  const { data: languages, isFetching: isLanguagesFetching } =
    useGetKnownLanguages({
      params: {
        search: languagesSearch,
      },
      enabled: true,
    });

  const onSkillSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getSkillsList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            label: oOption.option_value,
            value: oOption.option_value,
          });
        });
        setSkillsList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const languagesOptions = useMemo(() => {
    if (isEmpty(languages?.results)) return [];
    const options = languages?.results.map((item) => {
      return {
        label: item.language,
        value: item.language,
      };
    });
    return options;
  }, [languages]);

  const handleChange = () => {
    if (resume_templates_data) {
      console.log(resume_templates_data, 'resume_templates_data>change');
      console.log(watch(),"resume_templates_data");
      // setTemplateData((prevState) => ({
      //   ...prevState,
      //   ...Object?.fromEntries(
      //     Object?.entries(watch())?.map(([key, value]) => [
      //       key,
      //       {
      //         ...prevState[key],
      //         value: value?.value,
      //       },
      //     ]),
      //   ),
      // }));

      setTemplateData((prevState) => {
        if (!prevState) {
          return prevState;
        }
      
        return {
          ...prevState,
          ...Object?.fromEntries(
            Object?.entries(watch())?.map(([key, value]) => [
              key,
              {
                ...prevState[key],
                value: value?.value,
              },
            ]),
          ),
        };
      });
      
    }
  };

  const fieldVisible = (name) => {
    let value = false;
    if (templateFields?.includes(name)) {
      value = true;
    }
    return value;
  };

  console.log(watch(),"watch('skills')");

  return (
    <div className="p-1 w-full max-h-[90vh] h-[90vh] overflow-y-auto customScrollBar">
      <form onChange={(e) => handleChange(e)}>
        <div
          className={`${fieldVisible('first_name') ? 'block' : 'hidden'}`}
        >
          <Input
            name="first_name.value"
            label={<p>First Name</p>}
            control={control}
            placeholder="First Name"
            readOnly={isLoading}
            rules={
              {
                //   required: 'This field is required',
                //   pattern: {
                //     value: /^[a-zA-Z]+$/,
                //     message: 'Please enter letters only',
                //   },
              }
            }
          />
        </div>
        <div
          className={`${fieldVisible('last_name') ? 'block' : 'hidden'}`}
        >
          <Input
            name="last_name.value"
            label={<p>Last Name</p>}
            control={control}
            placeholder="Last Name"
            rules={
              {
                //   required: 'This field is required',
                //   pattern: {
                //     value: /^[a-zA-Z]+$/,
                //     message: 'Please enter letters only',
                //   },
              }
            }
          />
        </div>
        <div className={`${fieldVisible('email') ? 'block' : 'hidden'}`}>
          <Input
            name="email.value"
            label={<p>Email ID</p>}
            control={control}
            placeholder="Email ID"
            rules={
              {
                //   required: 'This field is required',
              }
            }
          />
        </div>
        <div
          className={`${fieldVisible('mobile_number') ? 'block' : 'hidden'}`}
        >
          <Input
            name="mobile_number.value"
            label={<p>Contact Number</p>}
            control={control}
            placeholder="Contact Number"
            rules={
              {
                //   required: 'This field is required',
              }
            }
          />
        </div>
        <div
          className={`${fieldVisible('location') ? 'block' : 'hidden'}`}
        >
          <Input
            name="location.value"
            label={<p>Address</p>}
            control={control}
            placeholder="Address"
            rules={
              {
                //   required: 'This field is required',
              }
            }
          />
        </div>
        <div
          className={`${fieldVisible('designation') ? 'block' : 'hidden'}`}
        >
          <Input
            name="designation.value"
            label={<p>Designation</p>}
            control={control}
            placeholder="Designation"
            rules={
              {
                //   required: 'This field is required',
              }
            }
          />
        </div>
        <div className={`${fieldVisible('skills') ? 'block' : 'hidden'}`}>
          <Select
            label={<p>Skills</p>}
            placeholder="Skills"
            options={skillsList}
            name={'skills.value'}
            control={control}
            showArrow={true}
            mode="multiple"
            onSearch={(e) => {
              onSkillSearch(e);
            }}
          />
        </div>
        <div
          className={`${fieldVisible('languages') ? 'block' : 'hidden'}`}
        >
          <Select
            allowClear
            showSearch
            showArrow={true}
            control={control}
            name="languages.value"
            mode={'multiple'}
            placeholder="Spoken Languages"
            label={<p>Known Languages</p>}
            options={languagesOptions}
            fetching={isLanguagesFetching}
            onSearch={(text) => setLanguagesSearch(text)}
            onClear={() => {
              setValue('languages', undefined);
            }}
          />
        </div>

        <div
          className={`${fieldVisible('about_me') ? 'block' : 'hidden'}`}
        >
          <div>About Me</div>
          <TextArea
            rows={4}
            name="about_me.value"
            control={control}
            className="h-[250px]"
            rules={
              {
                //   required: 'This field is required',
              }
            }
          />
        </div>
      </form>
    </div>
  );
};

export default ResumeFormProfile;
