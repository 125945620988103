import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';

import SMS from './SMS';
import Email from './Email';
import WhatsAPP from './WhatsAPP';
import VoiceBot from './VoiceBot';
import OfferLetter from './OfferLetter';
import PushNotification from './PushNotification';
import { useAuthentication } from 'ServiceHooks/authHooks';
import {
  PERMISSION_TYPES,
  COMMUNICATION_PAGE_TYPES,
} from 'Utilities/constants';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Tabs from 'Components/CommonComponents/Tabs/Tabs';
import routes from 'Routes';

const COMMUNICATION_TYPES = {
  EMAIL: 'Email',
  PUSH_NOTIFICATIONS: 'Push Notification',
  VOICE_BOT: 'Voice Bot',
  SMS: 'SMS',
  WHATS_APP: 'WhatsApp',
  OFFER_LETTER: 'Offer Letter',
};

const getMenuData = (tabs) => {
  const menuData = Object.keys(tabs).map((item, index) => {
    const getComponent = (item) => {
      let component = null;
      if (item === tabs.EMAIL) {
        component = <Email key={index} />;
      } else if (item === tabs.PUSH_NOTIFICATIONS) {
        component = <PushNotification key={index} />;
      } else if (item === tabs.SMS) {
        component = <SMS key={index} />;
      } else if (item === tabs.VOICE_BOT) {
        component = <VoiceBot key={index} />;
      } else if (item === tabs.WHATS_APP) {
        component = <WhatsAPP key={index} />;
      } else if (item === tabs.OFFER_LETTER) {
        component = <OfferLetter key={index} />;
      }

      return component;
    };
    const label = tabs[item];
    return {
      tabName: label,
      component: getComponent(label),
      key: COMMUNICATION_PAGE_TYPES[item],
    };
  });
  return menuData;
};

const CommunicationComp = () => {
  const { fullPermissionInfo } = useAuthentication();
  const params = useParams();
  const navigate = useNavigate();

  const tabNameParam = params?.tabName;

  const [tabs, setTabs] = useState(() => {
    return getMenuData(COMMUNICATION_TYPES);
  });

  const tabsItems = tabs.map((item) => {
    return {
      key: item.key,
      label: item.tabName,
      children: item.component,
    };
  });

  useEffect(() => {
    const communicationInfo = cloneDeep(COMMUNICATION_TYPES);
    const details =
      fullPermissionInfo[PERMISSION_TYPES.VIEW_PUSH_NOTIFICATION_TAB];
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_EMAIL_TAB]) {
      delete communicationInfo.EMAIL;
    }
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_SMS_TAB]) {
      delete communicationInfo.SMS;
    }
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_PUSH_NOTIFICATION_TAB]) {
      delete communicationInfo.PUSH_NOTIFICATIONS;
    }
    // if (!details) {
    //   delete communicationInfo.PUSH_NOTIFICATIONS;
    // }
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_VOICE_BOT_TAB]) {
      delete communicationInfo.VOICE_BOT;
    }
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_WHATSAPP_TAB]) {
      delete communicationInfo.WHATS_APP;
    }
    if (!fullPermissionInfo[PERMISSION_TYPES.VIEW_OFFER_LETTER_TAB]) {
      delete communicationInfo.OFFER_LETTER;
    }
    setTabs(getMenuData(communicationInfo));
  }, [fullPermissionInfo]);

  return (
    <>
      {isEmpty(tabs) ? (
        <div className="h-full w-full flex justify-center items-center">
          <Empty
            description={
              <p>
                You don't have the necessary permission to view this page.
              </p>
            }
          />
        </div>
      ) : (
        <div className="w-full">
          <Tabs
            items={tabsItems}
            activeKey={tabNameParam}
            defaultActiveKey={tabsItems[0]?.key}
            onChange={(newTabKey) => {
              navigate(`${routes.COMMUNICATION}/${newTabKey}`);
            }}
          />
        </div>
      )}
    </>
  );
};

export default CommunicationComp;
