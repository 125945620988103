import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DialogLayout = (props) => {
  return (
    <Dialog
      fullScreen={props.isFullScreen}
      open={props.isOpen}
      fullWidth
      maxWidth={props.dialogSize !== undefined ? props.dialogSize : "xs"}
      className="dialog"
    >
      <DialogTitle
        sx={{
          backgroundColor:
            props.titleLayoutColor !== undefined
              ? props.titleLayoutColor
              : "#0c2556",
          color: props.titleColor !== undefined ? props.titleColor : "white",
          height:"50px"
        }}
      >
        <h4> {props.strHeaderName}</h4>

        <IconButton
          aria-label="close"
          onClick={props.isClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

export default DialogLayout;
