import React from 'react';
import { TimePicker, Form } from 'antd';
import { useController } from 'react-hook-form';
const { RangePicker : AntTimeRangePicker } = TimePicker;

const TimeRangePicker = ({
  label = '',
  format = 'hh:mm:ss A',
  use12Hours = true,
  className = '',
  onChange : customOnChange = null,
   // hook form specific
   name, control, defaultValue, rules = {},disabled,
   ...others
}) => {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState : { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    disabled
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <Form.Item 
    label={label}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
    help={errorText}
    validateStatus={status}
  >

     <AntTimeRangePicker
      ref={ref}
      value={value}
      onChange={(e)=>{
        customOnChange
          ? customOnChange(e, onChange)
          : onChange(e);
      }}
      onBlur={onBlur}
      className={`w-full ${className}`}
      format={format} 
      use12Hours={use12Hours}
      disabled={disabled}
      {...others}
    />

  </Form.Item>
  );
};

export default TimeRangePicker;