import React from 'react';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import PrescreenService from '../../Services/prescreenService';
import Multiselect from 'multiselect-react-dropdown';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import SingleSearchDropDown from '../CommonComponents/SingleSearchDropDown';
import MultiSelectDropDown from '../CommonComponents/MultiSelectDropDown';
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import PrescreenParameter from '../Settings/PrescreenParameter';
import { Button, Modal } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// var multiselcted_values = [];
var multiselcted_values = {};
var is_array_created = false;

const PreScreenForm = (props) => {
  const navigate = useNavigate();
  const [feilds, setFeilds] = useState();
  const [skillValue, setSkillValue] = useState();
  const [optionValue, setOptionValue] = useState();
  const [id, setId] = useState();
  const [dynamicValue, setDynamicValue] = useState();
  const [selecteDynamicValue, setSelecteDynamicValue] = useState();
  const [singleSelectValue, setSingleSelectValue] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [feildId, setFeildId] = useState();
  const [visible, setVisible] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const [messageApi, messageContextHolder] = message.useMessage();

  const handelInputBoxChange = (e) => {};
  const {
    register,
    getValues,
    setError,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const handleSearch = async (search, url, id) => {
    onChangeEvent(id);
    await PrescreenService.getSearchValues(search, url)
      .then((oResponse) => {
        var optionData = getOptions(oResponse?.data?.data?.results, id);
        // setSkillValue(optionData)
        setDynamicValue({ ...dynamicValue, [id]: optionData });
      })
      .catch((err) => err);
  };
  const getOptions = (data, id) => {
    var arr = [];
    feilds?.prescreen_fields?.map((oData) => {
      if (oData.id === id) {
        data.map((oOption) => {
          arr.push({
            id: oOption.id,
            name: oOption.option_value,
          });
        });
      }
    });
    return arr;
  };
  const today = new Date().toISOString().split('T')[0];
  const yesornoOnchange = (e, id) => {
    setSelectedValue(e.target.value);
    createMultiOptionArray();
    onChangeEvent(id);
    multiselcted_values[id] = [];
  };
  const singleSelectOnchange = (e, id) => {
    setSelectedValue(e.target.value);
    createMultiOptionArray();
    onChangeEvent(id);
    multiselcted_values[id] = [e.target.value];
  };
  const handleErroeChange = (e, id) => {
    document.getElementById('error_' + id).textContent = '';
  };
  const handleSelect = (onSelect, id) => {
    createMultiOptionArray();
    multiselcted_values[id] = returnList(onSelect);
  };

  const returnList = (onselect) => {
    var selectedId = [];
    onselect.map((data) => {
      selectedId.push(data.id);
    });
    return selectedId;
  };
  const getPrecreenInfo = async () => {
    await PrescreenService.getPrescreenFeild(
      props.urlData.job,
      props.urlData.user_id,
      props.urlData.token,
    )
      .then((oResponse) => {
        setFeilds(oResponse.data.data);
        setDynamicValue(oResponse.data.data.prescreen_fields);

        //setSingleSelectValue({...singleSelectValue, [id]:dropDownOPtions(oResponse.data.data.prescreen_fields)})
      })
      .catch((error) => {
        if (error.response.status === 400) {
          navigate('/hr/link-expired');
        }
      });
  };
  const dropDownOPtions = (data) => {
    var options = [];
    data?.multi_options?.map((value, index) => {
      options.push({
        id: value.id,
        name: value.option,
      });
    });
    return options;
  };

  function createMultiOptionArray() {
    feilds?.prescreen_fields?.map((data) => {
      if (is_array_created == false) {
        if (
          data?.field_type === 'MULTI_SELECT' ||
          data?.field_type === 'SINGLE_SELECT'
        ) {
          multiselcted_values[data.id] = [];
        }
      }
    });
    is_array_created = true;
  }

  const handleSingleSelect = (onSelect, id) => {
    createMultiOptionArray();
    onChangeEvent(id);
    multiselcted_values[id] = singleSelectList(onSelect);
  };
  const singleSelectList = (onselect) => {
    var selectedId = [];
    onselect.map((data) => {
      selectedId.push(data.id);
    });
    return selectedId;
  };
  useEffect(() => {
    getPrecreenInfo();
  }, []);
  const modalContent = (
    // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    // <div>
    //    <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' ,paddingBottom:'25px',alignItems: 'center', justifyContent: 'center',}} />
    //   <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    //     Thank you for your submission!
    //   </p>
    //   <p>Your response have been saved ! </p>
    // </div>
    //</div>
    <div>
      <CheckCircleOutlined
        style={{
          display: 'flex',
          fontSize: '48px',
          color: '#52c41a',
          paddingBottom: '25px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      />
      <p className="text-[#000] font-bold mb-4 mt-4 flex item-center justify-center">
        Thank you !
      </p>
      <p className="text-[#000] flex item-center justify-center">
        Your response have been saved !{' '}
      </p>
    </div>
  );
  function onChangeEvent(id) {
    document.getElementById('error_' + id).textContent = ' ';
  }

  const onSubmit = async (data) => {
    createMultiOptionArray();
    console.log('testdef', feilds);
    var isMandatofeildError = false;
    feilds?.prescreen_fields?.map((data) => {
      if (
        data?.is_mandatory === true &&
        (data.field_type === 'CHAR' || data.field_type === 'DATE')
      ) {
        if (document.getElementById(data.id).value == '') {
          document.getElementById('error_' + data.id).textContent =
            '*This field is required';
          isMandatofeildError = true;
        }
      }

      if (data?.is_mandatory === true && data.field_type === 'BOOL') {
        if (document.getElementById(data.id).value == '') {
          document.getElementById('error_' + data.id).textContent =
            '*This field is required';
          isMandatofeildError = true;
        }
      }
      if (data?.is_mandatory === true && data.field_type === 'PHONE') {
        if (document.getElementById(data.id).value == '') {
          document.getElementById('error_' + data.id).textContent =
            '*This field is required';
          isMandatofeildError = true;
        }
      }
      if (
        data?.is_mandatory == true &&
        (data?.field_type === 'MULTI_SELECT' ||
          data?.field_type === 'SINGLE_SELECT')
      ) {
        if (multiselcted_values[data.id]?.length == 0) {
          document.getElementById('error_' + data.id).textContent =
            '*This field is required';
          isMandatofeildError = true;
        }
      }
    });
    // // If all mandatory fields are filled, make the API call
    if (!isMandatofeildError) {
      var data_array = [];
      var text_elements =
        document.getElementsByClassName('get_text_input');
      for (var i = 0; i < text_elements.length; i++) {
        data_array.push({
          prescreen_field: text_elements[i].id,
          answer: text_elements[i].value,
          multi_select_answers: [],
        });
      }

      var select_dropdown_elements = document.getElementsByClassName(
        'get-select-dropdown-value',
      );
      for (var j = 0; j < select_dropdown_elements.length; j++) {
        data_array.push({
          prescreen_field: select_dropdown_elements[j].id,
          answer: select_dropdown_elements[j].value,
          multi_select_answers: [],
        });
      }

      for (const [key, value] of Object.entries(multiselcted_values)) {
        data_array.push({
          prescreen_field: key,
          answer: '',
          multi_select_answers: value,
        });
      }

      await PrescreenService.addPrescreenForm(
        data_array,
        props.urlData.job,
        props.urlData.user_id,
        props.urlData.token,
      )
        .then((response) => {
          if (response.data.success) {
            setVisible(true);
          }

          // setTimeout(() => {
          //   setVisible(false);
          // }, 30000);
          //  message.success('Thank you for your submission!');
        })
        // response?.data?.errors?.answers
        .catch((error) => {
          console.log('api', error);
          // console.log('api', error.response.data.error_info.answers);
          if (error.response && error.response.data) {
            const errorInfo = {};
            error.response.data.error_info.answers.forEach(
              (err, index) => {
                if (err.answer && err.answer.length > 0) {
                  errorInfo[index] = err.answer.join(', ');
                }
              },
            );
            setApiErrors(errorInfo);
          } else {
            toast.error('Failed to submit');
          }
        });
      console.log('sssssssssssssssssss', apiErrors);
    }
  };
  const renderError = (fieldIndex) => {
    return apiErrors[fieldIndex] ? (
      <p className="text-red-600 text-sm font-semibold text-start mt-1">
        {apiErrors[fieldIndex]}
      </p>
    ) : null;
  };
  const prescreenInformation = () => {
    return (
      <>
        <div>
          <div className="md:w-full flex-wrap grid md:grid-cols-2 sm:grid-cols-1 sm:w-full gap-x-[5%] md:gap-y-[22px] mt-16 ">
            {feilds?.prescreen_fields?.map((data, index) => {
              var optionValue = data.multi_options?.map(
                (data) => data.option,
              );
              if (data?.field_type === 'CHAR') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *{' '}
                        </span>
                      )}
                    </div>

                    <input
                      // className="w-full h-[40px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm get_text_input"
                      className="w-full rounded-md border border-gray-400 p-2 get_text_input"
                      id={data.id}
                      defaultValue={data?.value ? data?.value : ''}
                      placeholder={
                        data.placeholder ? data.placeholder : ''
                      }
                      onInput={() => onChangeEvent(data.id)}
                    ></input>

                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                  </div>
                );
              }
              if (data?.field_type === 'DATE') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *
                        </span>
                      )}
                    </div>
                    <input
                      type="date"
                      id={data.id}
                      className="w-full rounded-md border border-gray-400 p-2 bg-white get_text_input"
                      // className="w-full h-[40px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm"
                      max={today}
                      onChange={(e) => handelInputBoxChange(e)}
                      onInput={() => onChangeEvent(data.id)}
                    ></input>
                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                  </div>
                );
              }
              if (data?.field_type === 'INT') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *
                        </span>
                      )}
                    </div>
                    {/* <h5
                      // className="left_Header"
                      className="m-1 text-start font-sans text-sm font-semibold"
                    >{data.name} </h5> */}
                    <input
                      id={data.id}
                      // className="w-full h-[40px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm get_text_input"
                      max={today}
                      className="w-full rounded-md border border-gray-400 p-2 get_text_input"
                      placeholder={
                        data.placeholder ? data.placeholder : ''
                      }
                      defaultValue={data?.value ? data?.value : ''}
                      onChange={(e) => handelInputBoxChange(e)}
                      onInput={() => onChangeEvent(data.id)}
                    ></input>
                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                  </div>
                );
              }
              if (data?.field_type === 'MULTI_SELECT') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *
                        </span>
                      )}
                    </div>
                    {data?.option_url == false ? (
                      <Multiselect
                        id={data.id}
                        className="w-full border-gray-400  bg-white get_multi_select_value"
                        options={dropDownOPtions(data)}
                        // placeholder={data.placeholder ? data.placeholder :""}
                        //options={singleSelectValue && singleSelectValue[data.id]} // Options to display in the dropdown
                        onSelect={(e) => handleSingleSelect(e, data.id)} // Function will trigger on select event
                        // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                      />
                    ) : (
                      <Multiselect
                        isObject
                        id={data.id}
                        className="w-full  border-gray-400 get_multi_select_value"
                        options={dynamicValue && dynamicValue[data.id]} // Options to display in the dropdown
                        onSearch={(e) =>
                          handleSearch(e, data.option_url, data.id)
                        }
                        onSelect={(e) => handleSelect(e, data.id)} // Function will trigger on select event
                        // onRemove={handleSearch} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        url={data.option_url}
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                      />
                    )}
                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                    {/* {apiErrors[data.id] && (
                      <span className="text-red-500">
                        {apiErrors[data.id]}
                      </span>
                    )} */}
                  </div>
                );
              }
              if (data?.field_type === 'BOOL') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *
                        </span>
                      )}
                    </div>
                    {data?.option_url == false ? (
                      <select
                        onChange={(e) => handleErroeChange(e, data.id)}
                        id={data.id}
                        className="w-full rounded-md border border-gray-400 p-2 bg-white get-select-dropdown-value"
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                      >
                        <option selected value="">
                          Select
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ) : (
                      <ReactSearchAutocomplete
                        // className="w-full h-[10px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm"
                        className="w-full rounded-md border border-gray-400 p-2 bg-white"
                        items={skillValue}
                        onSearch={(e) => handleSearch(e, data.option_url)}
                        onSelect={handleSearch}
                        // onChange={handleSearch}
                        showIcon={false}
                        placeholder="Search Location"
                        //  inputSearchString={props.locationId ? locationData?.city_state_address: ""}
                        // autoFocus
                      />
                    )}
                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                    {/* {apiErrors[data.id] && (
                      <span className="text-red-500">
                        {apiErrors[data.id]}
                      </span>
                    )} */}
                  </div>
                );
              }
              if (data?.field_type === 'SINGLE_SELECT') {
                return (
                  <div>
                    <div className="flex flex-wrap">
                      <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                        {data.name}
                      </h5>
                      {data?.is_mandatory === true && (
                        <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                          *
                        </span>
                      )}
                    </div>
                    {data?.option_url == false ? (
                      <select
                        // className="w-full h-[40px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm"
                        className="w-full rounded-md border border-gray-400 p-2 bg-white"
                        onChange={(e) => singleSelectOnchange(e, data.id)}
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                      >
                        <option value="">Select</option>
                        {data &&
                          data.multi_options?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.option}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <ReactSearchAutocomplete
                        // className="w-full h-[10px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm"
                        className="w-full rounded-md border border-gray-400 p-2"
                        items={skillValue}
                        onSearch={(e) => handleSearch(e, data.option_url)}
                        onSelect={handleSearch}
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                        showIcon={false}
                      />
                    )}
                    <p
                      className="text-red-600 text-sm font-semibold text-start mt-1"
                      id={'error_' + data.id}
                    ></p>
                    {/* {apiErrors[data.id] && (
                      <span className="text-red-500">
                        {apiErrors[data.id]}
                      </span>
                    )} */}
                  </div>
                );
              }
              if (data?.field_type === 'PHONE') {
                return (
                  <div>
                    <div>
                      <div className="flex flex-wrap">
                        <h5 className="mb-2 text-start font-sans text-sm font-semibold">
                          {data.name}
                        </h5>
                        {data?.is_mandatory === true && (
                          <span className="text-red-500 font-poppins text-lg font-normal leading-normal ">
                            *
                          </span>
                        )}
                      </div>
                      {/* <h5
                      // className="left_Header"
                      className="m-1 text-start font-sans text-sm font-semibold"
                    >{data.name} </h5> */}
                      <input
                        type="tel"
                        id={data.id}
                        // className="w-full h-[40px] rounded-[10px] focus:outline-none border border-opacity-30 border-black text-start bg-white pl-[5px] text-sm get_text_input"
                        max={today}
                        className="w-full rounded-md border border-gray-400 p-2 get_text_input"
                        placeholder={
                          data.placeholder ? data.placeholder : ''
                        }
                        defaultValue={data?.value ? data?.value : ''}
                        onChange={(e) => handelInputBoxChange(e)}
                        onInput={() => onChangeEvent(data.id)}
                      ></input>
                      <p
                        className="text-red-600 text-sm font-semibold text-start mt-1"
                        id={'error_' + data.id}
                      ></p>
                    </div>

                    {apiErrors && renderError(index)}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <form
        className="w-full h-screen overflow_scroll scrollbar-hide"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="text-teal-600 text-3xl  font-semibold mt-2">
          @ {feilds?.job}{' '}
        </p>
        <div>{prescreenInformation()}</div>
        <div className="flex text-white text-sm justify-end items-start mt-10 mb-10">
          {messageContextHolder}
          <button className="popup_skills primary-btn px-1 ">
            Submit
          </button>
          {visible && (
            <Modal
              open={visible}
              // onCancel={() => setVisible(false)}
              footer={null}
              closable={false}
              centered
              width={600}
              style={{ height: '400px' }}
            >
              {modalContent}
            </Modal>
          )}
        </div>
      </form>
    </>
  );
};

export default PreScreenForm;
